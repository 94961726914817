import styled from "styled-components";

export const SideBarContainer = styled.aside`
  width: 290px;
  position: relative;
  height: auto;
  box-shadow: 0.3px 0px 3.1px 0 rgba(0, 0, 0, 0),
    1.4px 0px 6.5px 0 rgba(0, 0, 0, 0), 3.4px 0px 13px 0 rgba(0, 0, 0, 0),
    6.5px 0px 25.5px 0 rgba(0, 0, 0, 0.01),
    11px 0.1px 46.9px 0 rgba(0, 0, 0, 0.01),
    17px 0.1px 80px 0 rgba(0, 0, 0, 0.01);
  background-image: linear-gradient(
    165deg,
    var(--gredient-blue-1) 0%,
    var(--gredient-blue-2) 123%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-left: 12px;
  padding-right: 13px;
  padding-bottom: 486px;
  transition: all 0.2s ease;
  z-index: 34;
  margin-left: ${(props) => (props.sideBarOpen === true ? "0px" : "-230px")};
  .sidebar-footer {
    position: absolute;
    margin-left: -70px;
    bottom: 48px;
    p {
      margin-bottom: 0px;
      font-family: Inter;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: var(--text-color);
    }
  }
`;

export const LogoContainer = styled.div`
  width: 151px;
  height: 34.6px;
  margin: 6px 51px 4.6px 0;
  object-fit: contain;
  margin-top: 23px;
  margin-left: 0px;
  margin-bottom: 70px;
  cursor: pointer;
`;

export const NavItemContainer = styled.div`
  padding-left: 20px;
  padding-right: 12px;
  width: 248px;
  height: 48px;
  text-decoration: none;
  border-radius: 8px;
  background-color: ${(props) =>
    props.active ? `${props.activeColor} !important` : ""};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  transition: all 0.2s ease;
  &:hover {
    background-color: ${(props) => props.activeColor};
  }
`;

export const SubNavItemContainer = styled.div`
  padding-left: 20px;
  padding-right: 12px;
  margin-top: 2px;
  width: 248px;
  height: 35px;
  text-decoration: none;
  border-radius: 8px;
  background-color: ${(props) =>
    props.active ? `${props.activeColor} !important` : ""};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease;
  &:hover {
    background-color: ${(props) => props.activeColor};
  }
`;
export const NavItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;
export const SubNavItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const NavIcon = styled.span``;
export const NavText = styled.p`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--text-color);
`;
export const SubNavText = styled.p`
  font-family: "Inter", sans-serif;
  font-size: 14px;
  text-align: left;
  color: var(--text-color);
`;
export const NavSubContainer = styled.div`
  
`;
export const BottomTextContainer = styled.div`
  position: absolute;
  bottom: 40px;
  margin-right: 10px;
  padding: 12px 16px;
  width: 15.5vw;
  color: white;
  font-weight: 600;
`;
