import { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";

import "./stylesheet.css";
import { Images } from "../../assets/images";

export default function Calculator() {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleTabChange = (e) => {
    setActiveIndex(e.index);
  };
  const tabHeaderITemplate = (options) => {
    let title = options.titleElement.props.children;

    return (
      <button
        type="button"
        onClick={options.onClick}
        className={options.className}
      >
        {options.selected ? (
          <img
            className="mx-2"
            src={Images[`${title}-Active`]?.default}
            width={"25px"}
            alt={`${title}`}
          />
        ) : (
          <img alt={title} className="mx-2" src={Images[title].default} width={"25px"} />
        )}
        {options.titleElement}
      </button>
    );
  };

  return (
    <>
      <div className="d-flex ">
        <div className="w-100">
          <div className="py-5 px-4">
            <h1 className="page-title">Borrowing Calculator</h1>
            <div className="pt-5">
              <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
                <TabPanel
                  headerTemplate={tabHeaderITemplate}
                  header="Vendor Profile"
                ></TabPanel>
                <TabPanel
                  header="Equity Calculator"
                  headerTemplate={tabHeaderITemplate}
                >
                  Content II
                </TabPanel>
                <TabPanel
                  headerTemplate={tabHeaderITemplate}
                  header="Risk Profile"
                >
                  Content III
                </TabPanel>
                <TabPanel
                  headerTemplate={tabHeaderITemplate}
                  header="Income Goal"
                  onClick={() => alert("Income Goal")}
                >
                  Content III
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
