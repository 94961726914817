import { Col, Row } from "react-bootstrap";
import React from "react";
import "./css.css";
import PortfolioMertics1 from "../../assets/images/gerald.png";
import PortfolioMertics2 from "../../assets/images/gerald1.png";
import PortfolioMertics3 from "../../assets/images/gerald2.png";
import PortfolioMertics4 from "../../assets/images/gerald3.png";
import PropertyCard from "./PropertyCard";
import PdfTimeline from "./PdfTimeline";
import { GS } from "../../services/global";
const IncomePdfPage2 = ({ page, allData }) => {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    let startFrom = page * 4 - 4;
    let endAt = startFrom + 4;
    let properties = [];
    for (let i = startFrom; i < endAt; i++) {
      if (GS.SOT.PropertyGenerators[i]) {
        properties.push(GS.SOT.PropertyGenerators[i]);
      }
    }

    setData(properties);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const images = [
    PortfolioMertics1,
    PortfolioMertics2,
    PortfolioMertics3,
    PortfolioMertics4,
  ];

  return (
    <div id={`divToPrint${page}`}>
      <Row
        className="property-cards"
        style={{ padding: "20px 150px 50px 150px" }}
        lg={12}
      >
        {Boolean(data.length) &&
          data.map((property, idx) => (
            <Col key={idx} lg={3} md={3}>
              <PropertyCard
                data={allData}
                details={property}
                images={images}
                idx={idx}
                page={page}
              ></PropertyCard>
            </Col>
          ))}
        {Boolean(data.length) && (
          <PdfTimeline data={data} page={page}></PdfTimeline>
        )}
      </Row>
    </div>
  );
};

export default IncomePdfPage2;
