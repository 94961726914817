
import styled from "styled-components";

export const Text = styled.p`
  font-family: "Inter", sans-serif;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => (props.color ? props.color : "var(--grey-dark)")};
`;

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center !important;
  width: ${(props) => (props.width ? props.width : "56px")};
  height: ${(props) => (props.width ? props.height : "56px")};
  object-fit: contain;
  background-color: var(--grey-dark);
  border-radius: 50px;
  background: var(--grey-dark);
  background-size: cover;
  box-sizing: border-box;
  position: relative;
  z-index:2;
  .file{
    opacity: 0;
    cursor:pointer !important;
    width: ${(props) => (props.width ? props.width : "56px")};

    position: absolute;
    bottom:-10px;
    z-index:4;
  }
 
 .file input[type=file]::-webkit-file-upload-button {
  cursor: pointer;
 
}
  .edit-icon-container{
    position:absolute;
    bottom:-10px;
    background-color:white;
    color:var(--grey-dark);
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:25px;
    height:25px;
    border-radius:50px;
    box-shadow: 0 0.2px 3.1px 0 rgb(0 0 0 / 0%), 0 1px 6.5px 0 rgb(0 0 0 / 1%), 0 2.4px 13px 0 rgb(0 0 0 / 1%), 0 4.6px 25.5px 0 rgb(0 0 0 / 1%), 0 7.8px 46.9px 0 rgb(0 0 0 / 2%), 0 3px 80px 0 rgb(0 0 0 / 1%);
    cursor: pointer;
    z-index:1
  }
`;
