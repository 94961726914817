import React, { useEffect, useRef, useState } from "react";
import { Col, Form, FormGroup, Row, Table } from "react-bootstrap";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import StrategicSpendingTableColumn from "./StrategicSpendingTableColumn";
import { API } from "../../services/api";
import { GS } from "../../services/global";
import FilterIcon from "../../assets/images/filter.png";
import { useDispatch, useSelector } from "react-redux";
import GradientButton from "../Button/GradientButton";
import "./styleSheets.css";

import ArrowIcon from "../../assets/images/arrowIcon1.png";

import CollapseCard from "../CollapseCard";
import { InputNumber } from "primereact/inputnumber";
import { CurrencyFormatter } from "../../utils";
import { Vendors, setGenericData } from "../../redux/actions";
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";
import FloatingBox from "../FloatingBox";
import classes from "../FloatingBox/FloatingBox.module.css";
import { InputText } from "primereact/inputtext";

function StrategicSpending({ vendorid, setActiveIndex, OnChange, onBack }) {
  let today = new Date();
  let month = today.getMonth();
  let nextMonth = (month === 11) ? 0 : month + 1;
  const state = useSelector((state) => state.Vendors);
  const [OverLay, setOverLay] = useState(false);
  const [EquityGains, setEquityGains] = useState([]);
  const [PrincipleData, setPrincipleData] = useState([]);
  const [ShowPrinciples, setShowPrinciples] = useState(false);
  const CalculatePrinciple = () => {
    let InterestValue = GS?.SOT?.Equity?.PPR?.HomeLoanRepaymentsInterestValue;
    let PrincipleValue = GS?.SOT?.Equity?.PPR?.HomeLoanRepaymentsPaymentValue;
    let cal = PrincipleValue - InterestValue;
    let Principles = [
      {
        PropertyName: "PPR",
        PrincipleValue: cal,
      },
    ];

    GS?.SOT?.Equity?.properties?.map((property) => {
      Principles.push({
        PropertyName: property.PropertyName,
        PrincipleValue:
          property.LoanRepaymentsPrincipleInterest -
          property.LoanRepaymentsInterest,
      });
      return null;
    });

    setPrincipleData([...Principles]);
  };

  useEffect(() => {
    const EquityGainsDB = GS.SOT.Equity.EquityGains;
    let EquityGainList = [];
    let Properties = EquityGainsDB?.filter((item) =>
      item.PropertyName.includes("Property")
    );
    if (Properties?.length > 0) {
      EquityGainList = Properties?.filter((item, idx) => {
        let Property = GS?.SOT?.Equity?.properties;
        if (Property[idx]) {
          return {
            PropertyName: item.PropertyName,
            Percentage: item.Percentage,
            startDate: item.startDate,
            endDate: item.endDate,
            noOfMonths: item.noOfMonths,
            value: Property[idx]?.REAValuation * (item.Percentage / 100),
          };
        } else {
          return null;
        }
      });
    } else {
      if (GS?.SOT?.Equity?.properties.length) {
        EquityGainList = GS?.SOT?.Equity?.properties?.map((item, idx) => {
          return {
            PropertyName: item.PropertyName,
            Percentage: 5,
            startDate: new Date(),
            endDate: new Date(new Date().getTime() + 31536000000),
            noOfMonths: 12,
            value: item?.REAValuation * 0.05,
          };
        });
      }
    }

    let PPR = EquityGainsDB?.filter((item) => item.PropertyName === "PPR");
    if (PPR?.length > 0) {
      EquityGainList.unshift({
        PropertyName: "PPR",
        Percentage: PPR[0].Percentage,
        startDate: PPR[0].startDate,
        endDate: PPR[0].endDate,
        noOfMonths: PPR[0].noOfMonths,
        value: GS?.SOT?.Equity?.PPR?.DomainAutoVal * (PPR[0].Percentage / 100),
      });
    } else {
      EquityGainList.unshift({
        PropertyName: "PPR",
        Percentage: 5,
        startDate: new Date(),
        endDate: new Date(new Date().getTime() + 31536000000),
        noOfMonths: 12,
        value: GS?.SOT?.Equity?.PPR?.DomainAutoVal * 0.05,
      });
    }

    setEquityGains([...EquityGainList]);
    CalculatePrinciple();
  }, []);

  let accountType = sessionStorage.getItem("accountType");

  let [CreditCards, setCreditCards] = useState(
    GS?.SOT?.BorrowingCalculator?.CreditCards || []
  );
  const [currentColumn, setCurrentColumn] = useState(0);
  const inputCalculateAnnualMonthlyAmmount = (term, ammount) =>
    term === "Weekly"
      ? (ammount / 52) * 12
      : term === "Annually"
      ? ammount * 12
      : term === "Fortnightly"
      ? (ammount / 26) * 12
      : ammount;
  const calculateAnnualMonthlyAmmount = (term, ammount) =>
    term === "Weekly"
      ? (ammount * 52) / 12
      : term === "Annually"
      ? ammount / 12
      : term === "Fortnightly"
      ? (ammount * 26) / 12
      : ammount || 0.0;

  const [Editable, setEditable] = useState("");

  const [balanceSheet, setBalanceSheet] = useState(
    GS?.SOT?.StrategicSpending?.balanceSheet || 0.0
  );

  const [cashOnHand, setCashOnHand] = useState(
    GS?.SOT?.StrategicSpending?.cashOnHand || 0.0
  );
  const [accountYour, setAccountYour] = useState(
    GS?.SOT?.StrategicSpending?.accountYour || 0.0
  );
  const [accountPartner, setAccountPartner] = useState(
    GS?.SOT?.StrategicSpending?.accountPartner || 0.0
  );
  const [EquityGain, setEquityGain] = useState(
    GS?.SOT?.Equity?.PPR?.totalEquity || 0.0
  );
  const [ExtraCash, setExtraCash] = useState(
    GS?.SOT?.StrategicSpending?.ExtraCash || []
  );
  const [liabilities, setLiabilities] = useState(
    GS?.SOT?.StrategicSpending?.liabilities || 0.0
  );
  const [creditCardYours, setCreditCardYours] = useState(
    GS?.SOT?.BorrowingCalculator?.CreditCardMinimumPayment || 0.0
  );
  const [creditCardPartner, setCreditCardPartner] = useState(
    GS?.SOT?.BorrowingCalculator?.CreditCardMinimumPayment_Partner || 0.0
  );
  const [monthlyCashflow, setMonthlyCashflow] = useState(
    GS?.SOT?.StrategicSpending?.monthlyCashflow || 0.0
  );

  const [income, setIncome] = useState(GS?.SOT?.StrategicSpending?.income);
  const [netSalaryYour, setNetSalaryYour] = useState(
    calculateAnnualMonthlyAmmount(
      GS?.SOT?.BorrowingCalculator?.salary_after_tax_term,
      GS?.SOT?.BorrowingCalculator?.salary_after_tax
    )
  );
  const [netSalaryPartner, setNetSalaryPartner] = useState(
    calculateAnnualMonthlyAmmount(
      GS?.SOT?.BorrowingCalculator?.partner_salary_after_tax_term,
      GS?.SOT?.BorrowingCalculator?.partner_salary_after_tax
    )
  );
  const [rentalIncome, setRentalIncome] = useState(
    GS?.SOT?.BorrowingCalculator?.NetRentalIncome / 12 || 0.0
  );
  const [OtherIncome, setOtherIncome] = useState(
    calculateAnnualMonthlyAmmount(
      GS?.SOT?.BorrowingCalculator?.other_income_net_term,
      GS?.SOT?.BorrowingCalculator?.other_income_net || 0.0
    )
  );
  const [expenses, setExpenses] = useState(
    GS?.SOT?.StrategicSpending?.expenses || 0.0
  );
  const [BillsUtilities, setBillsUtilities] = useState(
    (GS?.SOT?.BorrowingCalculator?.NetRentalIncome * (32.5 / 100)) / 12
  );
  const [Education, setEducation] = useState(
    GS?.SOT?.StrategicSpending?.Education || 0.0
  );
  const [Entertainment, setEntertainment] = useState(
    GS?.SOT?.StrategicSpending?.Entertainment || 0.0
  );
  const [
    HigherEducationandVocationalTraining,
    setHigherEducationandVocationalTraining,
  ] = useState(
    GS?.SOT?.StrategicSpending?.HigherEducationandVocationalTraining || 0.0
  );
  const [Childcare, setChildcare] = useState(
    GS?.SOT?.StrategicSpending?.Childcare || 0.0
  );
  const [PrimaryResidence, setPrimaryResidence] = useState(
    GS?.SOT?.Equity?.PPR?.HomeLoanRepayments * (25 / 100) || 0.0
  );
  const [FoodDining, setFoodDining] = useState(
    GS?.SOT?.StrategicSpending?.FoodDining || 0.0
  );
  const [RecreationHolidays, setRecreationHolidays] = useState(
    GS?.SOT?.StrategicSpending?.RecreationHolidays || 0.0
  );
  const [HealthFitness, setHealthFitness] = useState(
    GS?.SOT?.StrategicSpending?.HealthFitness || 0.0
  );
  const [ScrollPosition, setScrollPosition] = useState(0);
  const [ScrollPosition2, setScrollPosition2] = useState(0);

  const [GeneralInsurance, setGeneralInsurance] = useState(
    GS?.SOT?.StrategicSpending?.GeneralInsurance || 0.0
  );
  const [LoansCreditCards, setLoansCreditCards] = useState(
    GS?.SOT?.StrategicSpending?.LoansCreditCards || 0.0
  );
  const [RentMortgageProperty, setRentMortgageProperty] = useState(0.0);
  const [Shopping, setShopping] = useState(
    GS?.SOT?.StrategicSpending?.Shopping || 0.0
  );
  const [Travel, setTravel] = useState(
    GS?.SOT?.StrategicSpending?.Travel || 0.0
  );
  const [CarLoan, setCarLoan] = useState(
    calculateAnnualMonthlyAmmount(
      GS?.SOT?.BorrowingCalculator?.car_loan_payment_2_term,
      GS?.SOT?.BorrowingCalculator?.car_loan_payment_2
    ) || 0.0
  );

  const [HECSPayments, setHECSPayments] = useState(
    GS?.SOT?.BorrowingCalculator?.HECSPayments?.balance
  );
  const getotherexpenses = () => {
    if (GS?.SOT?.BorrowingCalculator?.other_payments_2_term === "Annually") {
      return GS?.SOT?.StrategicSpending?.OtherExpenses / 12;
    } else if (
      GS?.SOT?.BorrowingCalculator?.other_payments_2_term === "Monthly"
    ) {
      return GS?.SOT?.StrategicSpending?.OtherExpenses;
    } else if (
      GS?.SOT?.BorrowingCalculator?.other_payments_2_term === "Weekly"
    ) {
      return (GS?.SOT?.StrategicSpending?.OtherExpenses * 52) / 12;
    } else {
      return (GS?.SOT?.StrategicSpending?.OtherExpenses * 26) / 12;
    }
  };
  const [OtherExpenses, setOtherExpenses] = useState(getotherexpenses());

  let [uaaae, setUaaae] = useState(
    GS?.SOT?.BorrowingCalculator?.averageAustralianAnnual || false
  );

  useEffect(() => {
    let value = 0;
    GS?.SOT?.Equity?.properties?.map((property) => {
      value += property?.MortgagePayments?.value;
      return null;
    });
    setRentMortgageProperty(value / 12 || 0.0);
  }, []);

  // const [CreditCardPayments, setCreditCardPayments] = useState(
  //   GS?.SOT?.BorrowingCalculator?.credit_card_current_debt / 12 || 0.0
  // );

  const [PersonalExpenses, setPersonalExpenses] = useState(
    GS?.SOT?.StrategicSpending?.PersonalExpenses || 0.0
  );
  const [Utilities, setUtilities] = useState(
    GS?.SOT?.StrategicSpending?.Utilities || 0.0
  );
  const [InvestmentExpenses, setInvestmentExpenses] = useState(
    GS?.SOT?.StrategicSpending?.InvestmentExpenses || 0.0
  );

  const [Data, setData] = useState([]);
  const [FilterBy, setFilterBy] = useState("");

  const TableRef = useRef();
  useEffect(() => {
    let value = CreditCards?.reduce(
      (partialSum, nextItem) => partialSum + nextItem?.minimumPayment,
      0
    );
    let pprloanvalue =
      GS.SOT.Equity.PPR.HomeLoanRepaymentType === "Monthly"
        ? GS.SOT.Equity.PPR.HomeLoanRepayments
        : GS.SOT.Equity.PPR.HomeLoanRepaymentType === "Weekly"
        ? (GS.SOT.Equity.PPR.HomeLoanRepayments * 52) / 12
        : GS.SOT.Equity.PPR.HomeLoanRepayments / 12;
    let iploanvalue = 0;
    GS.SOT.Equity.properties.map((item, index) => {
      let v =
        item.LoanRepaymentsType === "Monthly"
          ? item.LoanRepayments
          : item.LoanRepaymentsType === "Weekly"
          ? (item.LoanRepayments * 52) / 12
          : item.LoanRepayments / 12;
      iploanvalue = iploanvalue + v;
      return null;
    });
    setLiabilities(value + pprloanvalue + iploanvalue);
  }, [CreditCards]);

  useEffect(() => {
    let value =
      netSalaryYour + netSalaryPartner + rentalIncome + OtherIncome || 0.0;
    setIncome(value);
  }, [netSalaryYour, netSalaryPartner, rentalIncome, OtherIncome]);
  // useEffect(() => {
  //   let v = 0;
  // if (EquityGains.length > 0) {
  //   EquityGains.map((item, index) => {
  //     let v1 = item.value / item.noOfMonths;

  //     v = v + v1;
  //     return null;
  //   });
  // }
  // let p1 = 0;
  // if (PrincipleData.length > 0) {
  //   PrincipleData.map((item, index) => {
  //     let p2 = item.PrincipleValue;

  //     p1 = p1 + p2;
  //     return null;
  //   });
  // }
  // let value = v + p1 + EquityGain || 0.0;

  // setBalanceSheet(value);
  // }, [cashOnHand, liabilities, EquityGain, EquityGains, PrincipleData]);

  useEffect(() => {
    let value = uaaae
      ? CarLoan +
        OtherExpenses +
        GS?.SOT?.BorrowingCalculator?.annual_expenses_2 / 12
      : Education +
        Entertainment +
        HigherEducationandVocationalTraining +
        Childcare +
        PrimaryResidence +
        FoodDining +
        RecreationHolidays +
        HealthFitness +
        GeneralInsurance +
        LoansCreditCards +
        Shopping +
        Travel +
        CarLoan +
        Utilities +
        OtherExpenses +
        HECSPayments;

    setPersonalExpenses(value);
    GS.SOT.StrategicSpending.Utilities = Utilities;
  }, [
    Education,
    Entertainment,
    HigherEducationandVocationalTraining,
    Childcare,
    PrimaryResidence,
    FoodDining,
    RecreationHolidays,
    HealthFitness,
    GeneralInsurance,
    LoansCreditCards,
    Shopping,
    Travel,
    CarLoan,
    OtherExpenses,
    HECSPayments,
    Utilities,
    uaaae,
  ]);
  useEffect(() => {
    let value = BillsUtilities + RentMortgageProperty;
    setInvestmentExpenses(value);
  }, [BillsUtilities, RentMortgageProperty, uaaae]);

  useEffect(() => {
    let value = PersonalExpenses + InvestmentExpenses;
    setExpenses(value);
  }, [PersonalExpenses, InvestmentExpenses]);

  useEffect(() => {
    let value = income - (PersonalExpenses + liabilities) || 0.0;
    setMonthlyCashflow(value);
  }, [income, PersonalExpenses, liabilities]);

  useEffect(() => {
    let TotalExtraCash = ExtraCash.reduce(
      (partialSum, nextItem) => (partialSum += nextItem.value),
      0
    );
    let value =
      accountYour +
        accountPartner +
        monthlyCashflow +
        TotalExtraCash +
        GS?.SOT?.Equity.Cash?.value || 0.0;
    setCashOnHand(value);
  }, [accountYour, accountPartner, monthlyCashflow, ExtraCash]);

  let generateData = async () => {
    let userId = "610a0f1dc61edfcf62d62c52";
    let res = await API.get(`settings?office_id=${userId}`);
    let CPI = res.data[0].settings.CPI;

    let Today = moment();
    let NextMonth = moment();
    NextMonth.add(1, "month");

    let no_of_months = 7 * 12;
    const months = [];
    const dateStart = moment();
    const dateEnd = moment().add(no_of_months, "month");
    while (dateEnd.diff(dateStart, "months") >= 0) {
      months.push(dateStart.format("MMM-YYYY"));
      dateStart.add(1, "month");
    }
    let prevCashOnHand = 0;
    let prevExpenses = 0;
    let prevBillsUtilities = 0;
    let prevEducation = 0;
    let prevEntertainment = 0;
    let prevHigherEducationandVocationalTraining = 0;
    let prevChildcare = 0;
    let prevPrimaryResidence = 0;
    let prevFoodDining = 0;
    let prevRecreationHolidays = 0;
    let prevHealthFitness = 0;
    let prevGeneralInsurance = 0;
    let prevLoansCreditCards = 0;
    let prevRentMortgageProperty = 0;
    let prevShopping = 0;
    let prevTravel = 0;
    let prevCarLoan = 0;
    let prevOtherExpenses = 0;
    let prevHECSPayments = 0;
    let prevYear = Today.format("YYYY");
    let prevPersonalExpenses = 0;
    let prevEquityGain = 0;

    let data = months?.map((date) => {
      const d = new Date(date);

      let cashOnHandValue =
        date === Today.format("MMM-YYYY")
          ? 0
          : date === NextMonth.format("MMM-YYYY")
          ? cashOnHand
          : prevCashOnHand +
            (accountYour - accountYour + (accountPartner - accountPartner)) +
            monthlyCashflow;

      // let balanceSheetValue =
      //   date === Today.format("MMM-YYYY")
      //     ? balanceSheet
      //     : cashOnHandValue - liabilities;

      let v = 0;
      if (EquityGains.length > 0) {
        EquityGains.map((item, index) => {
          let v1 = item.value / item.noOfMonths;

          v = v + v1;
          return null;
        });
      }
      let p1 = 0;
      if (PrincipleData.length > 0) {
        PrincipleData.map((item, index) => {
          let p2 = item.PrincipleValue;

          p1 = p1 + p2;
          return null;
        });
      }
      let value = v + p1 + EquityGain || 0.0;
      let balanceSheetValue =
        date === Today.format("MMM-YYYY") ? EquityGain : value;

      let EquityGainValue =
        date === Today.format("MMM-YYYY")
          ? EquityGain
          : date === NextMonth.format("MMM-YYYY")
          ? EquityGain +
            PrincipleData[PrincipleData?.length - 1]?.PrincipleValue
          : prevEquityGain +
            PrincipleData[PrincipleData?.length - 1]?.PrincipleValue;

      let expensesValue =
        date === Today.format("MMM-YYYY")
          ? expenses
          : d.getFullYear() !== prevYear
          ? prevExpenses + (CPI / 100) * expenses
          : prevExpenses;

      let BillsUtilitiesValue =
        date === Today.format("MMM-YYYY")
          ? BillsUtilities
          : d.getFullYear() !== prevYear
          ? prevBillsUtilities + (CPI / 100) * BillsUtilities
          : prevBillsUtilities;

      let EducationValue =
        date === Today.format("MMM-YYYY")
          ? Education
          : d.getFullYear() !== prevYear
          ? prevEducation + (CPI / 100) * Education
          : prevEducation;

      let EntertainmentValue =
        date === Today.format("MMM-YYYY")
          ? Entertainment
          : d.getFullYear() !== prevYear
          ? prevEntertainment + (CPI / 100) * Entertainment
          : prevEntertainment;

      let HigherEducationandVocationalTrainingValue =
        date === Today.format("MMM-YYYY")
          ? HigherEducationandVocationalTraining
          : d.getFullYear() !== prevYear
          ? prevHigherEducationandVocationalTraining +
            (CPI / 100) * HigherEducationandVocationalTraining
          : prevHigherEducationandVocationalTraining;

      let ChildcareValue =
        date === Today.format("MMM-YYYY")
          ? Childcare
          : d.getFullYear() !== prevYear
          ? prevChildcare + (CPI / 100) * Childcare
          : prevChildcare;

      let PrimaryResidenceValue =
        date === Today.format("MMM-YYYY")
          ? PrimaryResidence
          : d.getFullYear() !== prevYear
          ? prevPrimaryResidence + (CPI / 100) * PrimaryResidence
          : prevPrimaryResidence;

      let FoodDiningValue =
        date === Today.format("MMM-YYYY")
          ? FoodDining
          : d.getFullYear() !== prevYear
          ? prevFoodDining + (CPI / 100) * FoodDining
          : prevFoodDining;

      let RecreationHolidaysValue =
        date === Today.format("MMM-YYYY")
          ? RecreationHolidays
          : d.getFullYear() !== prevYear
          ? prevRecreationHolidays + (CPI / 100) * RecreationHolidays
          : prevRecreationHolidays;

      let HealthFitnessValue =
        date === Today.format("MMM-YYYY")
          ? HealthFitness
          : d.getFullYear() !== prevYear
          ? prevHealthFitness + (CPI / 100) * HealthFitness
          : prevHealthFitness;

      let GeneralInsuranceValue =
        date === Today.format("MMM-YYYY")
          ? GeneralInsurance
          : d.getFullYear() !== prevYear
          ? prevGeneralInsurance + (CPI / 100) * GeneralInsurance
          : prevGeneralInsurance;

      let LoansCreditCardsValue =
        date === Today.format("MMM-YYYY")
          ? LoansCreditCards
          : d.getFullYear() !== prevYear
          ? prevLoansCreditCards + (CPI / 100) * LoansCreditCards
          : prevLoansCreditCards;

      let RentMortgagePropertyValue =
        date === Today.format("MMM-YYYY")
          ? RentMortgageProperty
          : d.getFullYear() !== prevYear
          ? prevRentMortgageProperty + (CPI / 100) * RentMortgageProperty
          : prevRentMortgageProperty;

      let ShoppingValue =
        date === Today.format("MMM-YYYY")
          ? Shopping
          : d.getFullYear() !== prevYear
          ? prevShopping + (CPI / 100) * Shopping
          : prevShopping;

      let TravelValue =
        date === Today.format("MMM-YYYY")
          ? Travel
          : d.getFullYear() !== prevYear
          ? prevTravel + (CPI / 100) * Travel
          : prevTravel;

      let HECSPaymentsValue =
        date === Today.format("MMM-YYYY")
          ? HECSPayments
          : d.getFullYear() !== prevYear
          ? prevHECSPayments + (CPI / 100) * HECSPayments
          : prevHECSPayments;

      let CarLoanValue =
        d.getFullYear() === Today.format("YYYY")
          ? CarLoan
          : d.getFullYear() !== prevYear
          ? prevCarLoan + (CPI / 100) * CarLoan
          : prevCarLoan;

      let OtherExpensesValue =
        date === Today.format("MMM-YYYY")
          ? OtherExpenses
          : d.getFullYear() !== prevYear
          ? prevOtherExpenses + (CPI / 100) * OtherExpenses
          : prevOtherExpenses;

      let PersonalExpensesValue =
        date === Today.format("MMM-YYYY")
          ? PersonalExpenses
          : d.getFullYear() !== prevYear
          ? prevPersonalExpenses + (CPI / 100) * PersonalExpenses
          : prevPersonalExpenses;

      prevCashOnHand = cashOnHandValue;
      prevPersonalExpenses = PersonalExpensesValue;
      prevExpenses = expensesValue;
      prevBillsUtilities = BillsUtilitiesValue;
      prevEducation = EducationValue;
      prevEntertainment = EntertainmentValue;
      prevHigherEducationandVocationalTraining =
        HigherEducationandVocationalTrainingValue;
      prevChildcare = ChildcareValue;
      prevPrimaryResidence = PrimaryResidenceValue;
      prevFoodDining = FoodDiningValue;
      prevRecreationHolidays = RecreationHolidaysValue;
      prevHealthFitness = HealthFitnessValue;
      prevGeneralInsurance = GeneralInsuranceValue;
      prevLoansCreditCards = LoansCreditCardsValue;
      prevRentMortgageProperty = RentMortgagePropertyValue;
      prevShopping = ShoppingValue;
      prevTravel = TravelValue;
      prevCarLoan = CarLoanValue;
      prevOtherExpenses = OtherExpensesValue;
      prevHECSPayments = HECSPaymentsValue;
      prevEquityGain = EquityGainValue;

      prevYear = d.getFullYear();

      return {
        date,
        balanceSheet: {
          total: balanceSheetValue,
          cashOnHand: {
            total: cashOnHandValue,
            accountYour,
            accountPartner,
          },
          EquityGain: EquityGainValue,
          liabilities: {
            total: liabilities,
            creditCardPartner,
            creditCardYours,
          },
        },
        monthlyCashflow: {
          total: monthlyCashflow,
          expenses: {
            total: expensesValue,
            PersonalExpenses: PersonalExpensesValue,
            InvestmentExpenses,
            BillsUtilities: BillsUtilitiesValue,
            Education: EducationValue,
            Entertainment: EntertainmentValue,
            HigherEducationandVocationalTraining:
              HigherEducationandVocationalTrainingValue,
            Childcare: ChildcareValue,
            PrimaryResidence: PrimaryResidenceValue,
            FoodDining: FoodDiningValue,
            RecreationHolidays: RecreationHolidaysValue,
            HealthFitness: HealthFitnessValue,
            GeneralInsurance: GeneralInsuranceValue,
            LoansCreditCards: LoansCreditCardsValue,
            RentMortgageProperty: RentMortgagePropertyValue,
            Shopping: ShoppingValue,
            Travel: TravelValue,
            HECSPayments: HECSPaymentsValue,
            CarLoan: CarLoanValue,
            OtherExpenses: OtherExpensesValue,
          },
          income: {
            total: income,
            netSalaryYour,
            netSalaryPartner,
            rentalIncome,
            OtherIncome,
          },
        },
      };
    });

    setData([...data]);
  };

  useEffect(() => {
    GS.SOT.StrategicSpending.balanceSheet = balanceSheet;
    GS.SOT.StrategicSpending.cashOnHand = cashOnHand;
    GS.SOT.StrategicSpending.accountYour = accountYour;
    GS.SOT.StrategicSpending.accountPartner = accountPartner;
    GS.SOT.StrategicSpending.liabilities = liabilities;
    GS.SOT.StrategicSpending.creditCardYours = creditCardYours;
    GS.SOT.StrategicSpending.creditCardPartner = creditCardPartner;
    GS.SOT.StrategicSpending.monthlyCashflow = monthlyCashflow;
    GS.SOT.StrategicSpending.income = income;
    GS.SOT.StrategicSpending.OtherIncome = OtherIncome;
    GS.SOT.StrategicSpending.netSalaryYour = netSalaryYour;
    GS.SOT.StrategicSpending.netSalaryPartner = netSalaryPartner;
    GS.SOT.StrategicSpending.rentalIncome = rentalIncome;
    GS.SOT.StrategicSpending.expenses = expenses;
    GS.SOT.StrategicSpending.BillsUtilities = BillsUtilities;
    GS.SOT.StrategicSpending.Education = Education;
    GS.SOT.StrategicSpending.Entertainment = Entertainment;
    GS.SOT.StrategicSpending.HigherEducationandVocationalTraining =
      HigherEducationandVocationalTraining;

    GS.SOT.StrategicSpending.Childcare = Childcare;
    GS.SOT.StrategicSpending.PrimaryResidence = PrimaryResidence;
    GS.SOT.StrategicSpending.FoodDining = FoodDining;
    GS.SOT.StrategicSpending.RecreationHolidays = RecreationHolidays;
    GS.SOT.StrategicSpending.HealthFitness = HealthFitness;
    GS.SOT.StrategicSpending.GeneralInsurance = GeneralInsurance;
    GS.SOT.StrategicSpending.LoansCreditCards = LoansCreditCards;
    GS.SOT.StrategicSpending.RentMortgageProperty = RentMortgageProperty;
    GS.SOT.StrategicSpending.Shopping = Shopping;
    GS.SOT.StrategicSpending.Travel = Travel;
    GS.SOT.StrategicSpending.CarLoan = CarLoan;
    GS.SOT.StrategicSpending.Data = Data;
    GS.SOT.BorrowingCalculator.averageAustralianAnnual = uaaae;

    GS.SOT.BorrowingCalculator.salary_after_tax =
      inputCalculateAnnualMonthlyAmmount(
        GS?.SOT?.BorrowingCalculator?.salary_after_tax_term,
        netSalaryYour
      );
    GS.SOT.BorrowingCalculator.partner_salary_after_tax =
      inputCalculateAnnualMonthlyAmmount(
        GS?.SOT?.BorrowingCalculator?.partner_salary_after_tax_term,
        netSalaryPartner
      );
    GS.SOT.BorrowingCalculator.NetRentalIncome =
      inputCalculateAnnualMonthlyAmmount(
        GS?.SOT?.BorrowingCalculator?.NetRentalIncome_term,
        rentalIncome
      );
    GS.SOT.BorrowingCalculator.car_loan_payment_2 =
      inputCalculateAnnualMonthlyAmmount(
        GS?.SOT?.BorrowingCalculator?.car_loan_payment_2_term,
        CarLoan
      );
    GS.SOT.BorrowingCalculator.other_payments_2 =
      inputCalculateAnnualMonthlyAmmount(
        GS?.SOT?.BorrowingCalculator?.other_payments_2_term,
        OtherExpenses
      );

    GS.SOT.BorrowingCalculator.HECSPayments.balance =
      inputCalculateAnnualMonthlyAmmount(
        GS?.SOT?.BorrowingCalculator?.HECSPayments?.type,
        HECSPayments
      );
    GS.SOT.StrategicSpending.ExtraCash = ExtraCash;
    GS.SOT.Equity.EquityGains = EquityGains;
    GS.SOT.BorrowingCalculator.CreditCards = CreditCards;
    GS.SOT.PropertyGenerators[0].netIncome = income;
    GS.SOT.PropertyGenerators[0].cashAvailable =
      Data[0]?.balanceSheet.cashOnHand.total;
    GS.SOT.PropertyGenerators[0].equityGain =
      Data[0]?.balanceSheet.EquityGain +
      EquityGains.reduce(
        (prevValue, currentValue) =>
          (prevValue += currentValue.value / currentValue.noOfMonths),
        0
      );

    GS.SOT.StrategicSpending.totalMonth =
      ((Data?.length > 0 && Data[0]?.balanceSheet.cashOnHand.total) ||
        cashOnHand) +
      ((Data?.length > 0 && Data[0]?.balanceSheet.EquityGain) || EquityGain) +
      EquityGains.reduce(
        (prevValue, currentValue) =>
          (prevValue += currentValue.value / currentValue.noOfMonths),
        0
      );

    API.AutoSave(window.location.href);
  });

  let CashColumns = [
    { title: "Cash-on hand", classes: "sidebar-cell cashOnHand-primary" },
    { title: "Account (yours)", classes: "sidebar-cell cashOnHand-secondary" },
    {
      title: "Account (partners)",
      classes: "sidebar-cell cashOnHand-secondary",
    },
    {
      title: "Extra Cash",
      classes: "sidebar-cell cashOnHand-secondary",
    },
  ];

  let PrincipleColumns = [];

  PrincipleData?.map((principle) => {
    PrincipleColumns.push({
      title: `${principle.PropertyName} - Principal`,
      classes: "sidebar-cell cashOnHand-secondary",
    });
    return null;
  });

  let LiabilitiesColumns = [
    { title: "Liabilities", classes: "sidebar-cell liabilities-primary" },
  ];

  EquityGains.map((item) => {
    CashColumns.push({
      title: `${item?.PropertyName} - Capital Growth Equity`,
      classes: "sidebar-cell cashOnHand-secondary",
    });
    return null;
  });

  CashColumns.push({
    title: "Equity Gain",
    classes: "sidebar-cell cashOnHand-secondary",
  });

  CreditCards.map((cardItem) => {
    LiabilitiesColumns.push({
      title: `${cardItem?.type} (${cardItem?.name}) (${cardItem?.owner})`,
      classes: "sidebar-cell liabilities-secondary",
    });
    return null;
  });

  let MonthlyCashFlowColumns = [
    { title: "MONTHLY SAVINGS", classes: "sidebar-cell monthlyCashFlow" },
  ];
  let PPRLoanColumns = [
    { title: "PPR Monthly Mortgage", classes: "sidebar-cell income-secondary" },
  ];
  let IncomeColums = [
    { title: "Income", classes: "sidebar-cell income-primary" },
    { title: "Net Salary (yours)", classes: "sidebar-cell income-secondary" },
    {
      title: "Net Salary (partners)",
      classes: "sidebar-cell income-secondary",
    },
    { title: "Rental Income", classes: "sidebar-cell income-secondary" },
    { title: "Other Income", classes: "sidebar-cell income-secondary" },
  ];

  let ExpensesColumns = [
    { title: "Expenses", classes: "sidebar-cell expense-primary" },
    { title: "Primary Residence", classes: "sidebar-cell expense-secondary" },
    {
      title: "Phone Internet Media",
      classes: "sidebar-cell expense-secondary",
    },
    {
      title: "Food & Groceries",
      classes: "sidebar-cell expense-secondary",
    },
    {
      title: "Recreation & Holidays",
      classes: "sidebar-cell expense-secondary",
    },
    {
      title: "Clothing And Personal Care",
      classes: "sidebar-cell expense-secondary",
    },
    { title: "Medical & Health", classes: "sidebar-cell expense-secondary" },
    {
      title: "Transport",
      classes: "sidebar-cell expense-secondary",
    },
    { title: "Public Education", classes: "sidebar-cell expense-secondary" },
    {
      title: "Higher Education and Vocational Training",
      classes: "sidebar-cell expense-secondary",
    },
    {
      title: "Childcare",
      classes: "sidebar-cell expense-secondary",
    },

    {
      title: "General Insurance",
      classes: "sidebar-cell expense-secondary",
    },
    { title: "Loans", classes: "sidebar-cell expense-secondary" },
    { title: "Car Loan", classes: "sidebar-cell expense-secondary" },
    { title: "Other", classes: "sidebar-cell expense-secondary" },
  ];

  let AsutralianAverageExpensesColumn = [
    { title: "Expenses", classes: "sidebar-cell expense-primary" },

    { title: "Other", classes: "sidebar-cell expense-secondary" },
  ];

  let BalanceSheetColumns = [
    { title: "Balance Sheet", classes: "sidebar-cell balanceSheet" },
  ];

  let handleLowerScroll = (e) => {
    setScrollPosition(e.target.scrollLeft);
  };

  let nextBtnNavigation = () => {
    TableRef.current.scrollLeft += 140;
    setScrollPosition(TableRef.current.scrollLeft);
    setCurrentColumn((prevColumn) => (prevColumn >= 84 ? 84 : prevColumn + 1));
  };

  let prevBtnNavigation = () => {
    TableRef.current.scrollLeft -= 140;
    setScrollPosition(TableRef.current.scrollLeft);
    setCurrentColumn((prevColumn) => (prevColumn <= 0 ? 0 : prevColumn - 1));
  };

  let ApplyFilter = (value = "ByMonth") => {
    setFilterBy(() => value);
    generateData();
  };

  useEffect(() => {
    setFilterBy(() => "ByMonth");
    generateData();
  }, [balanceSheet]); // eslint-disable-line react-hooks/exhaustive-deps

  let Navigate = useNavigate();
  const dispatch = useDispatch();
  const updateStrategicSpending = async () => {
    GS.SOT.Progress = { steps: 5, completed: 2, current: 3 };
    await API.AutoSave(window.location.href);
  };

  const updateCreditCard = (updatable_id, key, value) => {
    const updated_list = CreditCards.map((item, idx) => {
      if (updatable_id === idx) {
        item[key] = value;
      }
      return item;
    });
    setCreditCards([...updated_list]);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition2(position);
  };

  const GetEquityGainsTotalVal = () => {
    let value = 0;
    
    EquityGains.map((item) => {
      var condition = moment(new Date()).isBetween(
        item.startDate,
        item.endDate
      );
      if (condition) {
        let vk = item.value / item.noOfMonths;
        value = value + vk;
      }
    });
    return value;
  };
  

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const addExtraCash = () => {
    setExtraCash((prevState) => [...prevState, { date: new Date(), value: 0 }]);
  };

  const updateExtraCash = (updatable_id, key, value) => {
    const updated_list = ExtraCash?.map((item, idx) => {
      if (updatable_id === idx) {
        item[key] = value;
      }
      return item;
    });
    setExtraCash([...updated_list]);
  };

  const removeExtraCash = (e, removal_id) => {
    const updated_list = ExtraCash.filter((_, idx) => removal_id !== idx);
    setExtraCash([...updated_list]);
  };

  const updateEquityGains = (updatable_id, key, value) => {
    const updated_list = EquityGains?.map((item, idx) => {
      if (updatable_id === idx) {
        item[key] = value;
      }
      return item;
    });
    if (key === "Percentage" || key === "remove") {
      if (EquityGains[updatable_id].PropertyName.includes("Property")) {
        GS?.SOT?.Equity?.properties?.map((item) => {
          if (EquityGains[updatable_id].PropertName === item.PropertName) {
            updated_list[updatable_id].value = parseFloat(
              (item?.REAValuation * (value / 100)).toFixed(2)
            );

            updated_list[updatable_id].Percentage = value;
          }
          return null;
        });
      } else {
        updated_list[0].Percentage = value;

        updated_list[0].value = parseFloat(
          (GS?.SOT?.Equity?.PPR?.DomainAutoVal * (value / 100)).toFixed(2)
        );
      }
    }
    if (key === "endDate") {
      let startDate = moment(EquityGains[updatable_id].startDate);
      let noOfMonths = Math.abs(startDate.diff(value, "months"));
      EquityGains[updatable_id].noOfMonths = noOfMonths;
    }
    if (key === "startDate") {
      EquityGains[updatable_id].endDate = new Date(
        new Date(value).getTime() + 31536000000
      );
      let startDate = moment(value);
      let noOfMonths = Math.abs(
        startDate.diff(
          new Date(new Date(value).getTime() + 31536000000),
          "months"
        )
      );
      EquityGains[updatable_id].noOfMonths = noOfMonths;
    }

    setEquityGains([...updated_list]);
  };
  useEffect(() => {
    let accountType = sessionStorage.getItem("accountType");
    if (accountType === "Contacts") {
      setOverLay(true);
    } else {
      setOverLay(false);
    }
  }, []);
  return (
    <>
      {OverLay == true && (
        <div className="SpendingContainer">
          {OverLay == true && (
            <div className="Pageoverlay">
              Premium Access Only. Please contact{" "}
              <a className="Contactlink">info@investlogic.com.au</a> to unlock
              these features.
              <div className="overlaybutton-container">
                <GradientButton
                  onClick={async () => {
                    await updateStrategicSpending();
                    GS.SOT.Progress = { steps: 4, completed: 2, current: 3 };
                    const updateData = [...state];
                    const i = updateData.findIndex(
                      (vendor) => vendor._id === vendorid
                    );
                    updateData[i] = {
                      ...updateData[i],
                      Progress: [GS.SOT.Progress],
                    };
                    dispatch(Vendors(updateData));
                    setActiveIndex(3);
                    if (accountType != "Contacts") {
                      dispatch(setGenericData({ ActiveProperty: null }));
                    }
                    Navigate(`/Clients/Summary?id=${vendorid}`);
                  }}
                />
              </div>
            </div>
          )}
          <div style={{ marginTop: "30px" }}>
            <Form className="mt-3">
              <CollapseCard title={"Balance Sheet"}>
                <div className="d-flex bg-white align-items-center gap-3">
                  <FormGroup className="mb-3" controlId="cash">
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `${CurrencyFormatter.format(
                          balanceSheet
                        )}`)
                      }
                      value={balanceSheet}
                      placeholder="Balance"
                      onChange={(e) => {
                        setBalanceSheet(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="border-none bg-light rounded-0"
                      className="input-field mr-3 mb-1"
                    />
                  </FormGroup>
                </div>
                <Row>
                  <Col sm={12} md={12} lg={3}>
                    <FormGroup className="mb-3" controlId="cash">
                      <p className="input-label mb-2">Cash On Hand:</p>
                      <InputNumber
                        onFocus={(e) => (e.target.value = "")}
                        onBlur={(e) =>
                          (e.target.value = `${CurrencyFormatter.format(
                            cashOnHand
                          )}`)
                        }
                        value={cashOnHand}
                        onChange={(e) => {
                          setCashOnHand(e.value);
                          OnChange();
                        }}
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minfractiondigits={1}
                        className="input-field mr-3 mb-1"
                        inputClassName="border-none cashOnHand-primary rounded-0"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={12} lg={3}>
                    <FormGroup className="mb-3" controlId="equityValue">
                      <p className="input-label mb-2">Account (Yours)</p>
                      <InputNumber
                        onFocus={(e) => (e.target.value = "")}
                        onBlur={(e) =>
                          (e.target.value = `${CurrencyFormatter.format(
                            accountYour
                          )}`)
                        }
                        value={accountYour}
                        onChange={(e) => {
                          setAccountYour(e.value);
                          OnChange();
                        }}
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minfractiondigits={1}
                        className="input-field mr-3 mb-1"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={12} lg={3}>
                    <FormGroup className="mb-3" controlId="equityValue">
                      <p className="input-label mb-2">Account (Partner)</p>
                      <InputNumber
                        onFocus={(e) => (e.target.value = "")}
                        onBlur={(e) =>
                          (e.target.value = `${CurrencyFormatter.format(
                            accountPartner
                          )}`)
                        }
                        value={accountPartner}
                        onChange={(e) => {
                          setAccountPartner(e.value);
                          OnChange();
                        }}
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minfractiondigits={1}
                        className="input-field mr-3 mb-1"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={12} lg={3}>
                    <FormGroup className="mb-3" controlId="equityValue">
                      <p className="input-label mb-2">Equity Gain</p>
                      <InputNumber
                        onFocus={(e) => (e.target.value = "")}
                        onBlur={(e) =>
                          (e.target.value = `${CurrencyFormatter.format(
                            EquityGain
                          )}`)
                        }
                        value={EquityGain}
                        onChange={(e) => {
                          setEquityGain(e.value);
                          OnChange();
                        }}
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minfractiondigits={1}
                        className="input-field mr-3 mb-1"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={10} xs={10} lg={3} md={5} className="pr-5">
                    {ExtraCash.length > 0 ? (
                      <Table bordered className="ExtraCashTable">
                        <thead>
                          <tr>
                            <th>
                              <p className="input-label">Extra Cash</p>
                            </th>
                            <th>
                              <p className="input-label">Date</p>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {ExtraCash?.map((item, idx) => {
                            return (
                              <tr key={idx}>
                                <td className="py-1">
                                  <InputNumber
                                    onFocus={(e) => (e.target.value = "")}
                                    onBlur={(e) =>
                                      (e.target.value = `${CurrencyFormatter.format(
                                        item?.value || 0.0
                                      )}`)
                                    }
                                    value={item?.value}
                                    onChange={(e) => {
                                      updateExtraCash(idx, "value", e.value);
                                    }}
                                    mode="currency"
                                    currency="USD"
                                    locale="en-US"
                                    minfractiondigits={1}
                                    inputClassName="extraCash-input-field"
                                    className="border-0 extraCash-input-field"
                                  />
                                </td>

                                <td className="py-1">
                                  <Calendar
                                    minDate={new Date(moment())}
                                    maxDate={
                                      new Date(moment().add(84, "month"))
                                    }
                                    value={new Date(item?.date)}
                                    dateFormat="dd-M-yy"
                                    onChange={(e) =>
                                      updateExtraCash(idx, "date", e.value)
                                    }
                                    inputClassName="border-0 extraCash-input-field-calender"
                                    className="extraCash-input-field-calender"
                                  />
                                </td>
                                <td className="ExtraCashActionTD" hover>
                                  <span
                                    onClick={(e) => {
                                      removeExtraCash(e, idx);
                                    }}
                                    style={{
                                      fontSize: "18px",
                                      cursor: "pointer",
                                      color: "#323232",
                                      padding: "0px 2px",
                                    }}
                                  >
                                    x
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                          <tr
                            onMouseEnter={(e) =>
                              (e.target.style.background = "#F7F9FA")
                            }
                            onMouseOut={(e) =>
                              (e.target.style.background = "#fff")
                            }
                          >
                            <td
                              className="text-center"
                              onClick={addExtraCash}
                              style={{ cursor: "pointer", fontSize: "14px" }}
                              colSpan={7}
                            >
                              Add New
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    ) : (
                      <Table bordered hover>
                        <thead>
                          <tr>
                            <th>
                              <p className="input-label">Extra Cash</p>
                            </th>
                            <th>
                              <p className="input-label">Date</p>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              className="text-center "
                              onClick={addExtraCash}
                              style={{ cursor: "pointer", fontSize: "14px" }}
                              colSpan={6}
                            >
                              No Extra Cash
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12} lg={3}>
                    <FormGroup className="mb-3" controlId="CapitalGrowthRate">
                      <p className="input-label mb-2">Capital Growth Rate:</p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={10} xs={10} lg={5} md={5} className="pr-5">
                    {EquityGains.length > 0 && (
                      <Table bordered className="EquityGainsTable">
                        <thead>
                          <tr>
                            <th>
                              <p className="input-label">Property Name</p>
                            </th>
                            {/* <th>
                              <p className="input-label">Equity Gain</p>
                            </th> */}
                            <th>
                              <p className="input-label">Percentage</p>
                            </th>
                            <th>
                              <p className="input-label">Start Date</p>
                            </th>
                            <th>
                              <p className="input-label">End Date</p>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {EquityGains?.map((item, idx) => {
                            return (
                              <tr key={idx}>
                                <td className="py-1 ">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <InputText
                                      value={item?.PropertyName || ""}
                                      onChange={(e) => {
                                        updateEquityGains(
                                          idx,
                                          "PropertyName",
                                          e.value
                                        );
                                      }}
                                      mode="currency"
                                      currency="USD"
                                      locale="en-US"
                                      minfractiondigits={1}
                                      className="border-0 EquityGains-field"
                                      readOnly
                                    />
                                  </div>
                                </td>
                                {/* <td className="py-1 ">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <InputNumber
                                      value={item.value}
                                      onChange={(e) => {
                                        updateEquityGains(
                                          idx,
                                          "value",
                                          e.value
                                        );
                                      }}
                                      mode="currency"
                                      currency="USD"
                                      locale="en-US"
                                      minfractiondigits={1}
                                      className="border-0 EquityGains-field"
                                      readOnly
                                    />
                                  </div>
                                </td> */}
                                <td className="py-1 ">
                                  <div
                                    style={{ width: 64 }}
                                    className="d-flex align-items-center justify-content-between"
                                  >
                                    <InputNumber
                                      style={{ width: 64 }}
                                      onFocus={(e) => (e.target.value = "")}
                                      onBlur={(e) =>
                                        (e.target.value = `${item.Percentage}%`)
                                      }
                                      value={item.Percentage}
                                      onChange={(e) => {
                                        updateEquityGains(
                                          idx,
                                          "Percentage",
                                          e.value
                                        );
                                      }}
                                      mode="decimal"
                                      minFractionDigits={0}
                                      maxFractionDigits={2}
                                      suffix="%"
                                      className="border-0 EquityGains-fields"
                                    />
                                  </div>
                                </td>
                                <td className="py-1">
                                  <Calendar
                                    minDate={new Date(moment())}
                                    maxDate={
                                      new Date(moment().add(84, "month"))
                                    }
                                    value={new Date(item.startDate)}
                                    onChange={(e) =>
                                      updateEquityGains(
                                        idx,
                                        "startDate",
                                        e.value
                                      )
                                    }
                                    dateFormat="dd-M-yy"
                                    inputClassName="border-0 EquityGains-name"
                                  />
                                </td>
                                <td className="py-1">
                                  <Calendar
                                    minDate={new Date(moment())}
                                    maxDate={
                                      new Date(moment().add(84, "month"))
                                    }
                                    value={new Date(item.endDate)}
                                    onChange={(e) =>
                                      updateEquityGains(idx, "endDate", e.value)
                                    }
                                    dateFormat="dd-M-yy"
                                    inputClassName="border-0 EquityGains-name"
                                  />
                                </td>

                                <td className="ExtraCashActionTD" hover>
                                  <span
                                    onClick={(e) => {
                                      updateEquityGains(idx, "remove", 0);
                                    }}
                                    style={{
                                      fontSize: "18px",
                                      cursor: "pointer",
                                      color: "#323232",
                                      padding: "0px 2px",
                                    }}
                                  >
                                    x
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12} lg={3}>
                    <FormGroup className="mb-3" controlId="equityValue">
                      <p className="input-label mb-2">Liabilities:</p>
                      <InputNumber
                        onFocus={(e) => (e.target.value = "")}
                        onBlur={(e) =>
                          (e.target.value = `${CurrencyFormatter.format(
                            liabilities
                          )}`)
                        }
                        value={liabilities}
                        onChange={(e) => {
                          setLiabilities(e.value);
                          OnChange();
                        }}
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minfractiondigits={1}
                        className="input-field mr-3 mb-1"
                        inputClassName="border-none liabilities-primary rounded-0"
                      />
                    </FormGroup>
                  </Col>
                  {CreditCards?.map((cardItem, idx) => {
                    return (
                      <Col key={idx} sm={12} md={12} lg={3}>
                        <FormGroup className="mb-3" controlId="equityValue">
                          <p className="input-label mb-2">
                            {cardItem?.type}{" "}
                            {`${
                              cardItem?.name ? "(" + cardItem?.name + ")" : ""
                            }`}{" "}
                            {`${
                              cardItem?.owner ? "(" + cardItem?.owner + ")" : ""
                            }`}
                          </p>
                          <InputNumber
                            onFocus={(e) => (e.target.value = "")}
                            onBlur={(e) =>
                              (e.target.value = `${CurrencyFormatter.format(
                                cardItem?.minimumPayment
                              )}`)
                            }
                            value={cardItem?.minimumPayment}
                            onChange={(e) => {
                              updateCreditCard(idx, "minimumPayment", e.value);
                            }}
                            mode="currency"
                            currency="USD"
                            locale="en-US"
                            minfractiondigits={1}
                            className="input-field mr-3 mb-1"
                          />
                        </FormGroup>
                      </Col>
                    );
                  })}
                </Row>
              </CollapseCard>
              <CollapseCard title={"Monthly Savings"}>
                <div className="d-flex flex-wrap align-items-center gap-3">
                  <FormGroup className="mb-3" controlId="equityValue">
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `${CurrencyFormatter.format(
                          monthlyCashflow
                        )}`)
                      }
                      value={monthlyCashflow}
                      onChange={(e) => {
                        setMonthlyCashflow(e.value);
                        OnChange();
                      }}
                      placeholder="Equity Value"
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      className="input-field mr-3 mb-1"
                      inputClassName="border-none bg-light rounded-0"
                    />
                  </FormGroup>
                </div>
                <div className="d-flex flex-wrap align-items-center gap-3">
                  <FormGroup className="mb-3" controlId="equityValue">
                    <p className="input-label mb-2">Income:</p>
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `${CurrencyFormatter.format(income)}`)
                      }
                      value={income}
                      placeholder="Equity Value"
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      className="input-field mr-3 mb-1"
                      inputClassName="border-none income-primary rounded-0 text-white"
                    />
                  </FormGroup>
                  <FormGroup className="mb-3" controlId="equityValue">
                    <p className="input-label mb-2">Net Salary (yours)</p>
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `${CurrencyFormatter.format(
                          netSalaryYour
                        )}`)
                      }
                      className="input-field mr-3 mb-1"
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      placeholder="Equity Value"
                      value={netSalaryYour}
                      onChange={(e) => {
                        setNetSalaryYour(e.value);
                        OnChange();
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-3" controlId="equityValue">
                    <p className="input-label mb-2">Net Salary (partners)</p>
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `${CurrencyFormatter.format(
                          netSalaryPartner
                        )}`)
                      }
                      className="input-field mr-3 mb-1"
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      placeholder="Equity Value"
                      value={netSalaryPartner}
                      onChange={(e) => {
                        setNetSalaryPartner(e.value);
                        OnChange();
                      }}
                    />
                  </FormGroup>
                </div>
                <div className="d-flex flex-wrap align-items-center gap-3">
                  <FormGroup className="mb-3" controlId="equityValue">
                    <p className="input-label mb-2">Rental Income</p>
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `${CurrencyFormatter.format(
                          rentalIncome
                        )}`)
                      }
                      className="input-field mr-3 mb-1"
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      placeholder="Equity Value"
                      value={rentalIncome || 0}
                      onChange={(e) => {
                        setRentalIncome(e.value);
                        OnChange();
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-3" controlId="OtherIncome">
                    <p className="input-label mb-2">Other Income</p>
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `${CurrencyFormatter.format(
                          OtherIncome
                        )}`)
                      }
                      className="input-field mr-3 mb-1"
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      placeholder="Other Income"
                      value={OtherIncome}
                      onChange={(e) => {
                        setOtherIncome(e.value);
                        OnChange();
                      }}
                    />
                  </FormGroup>
                </div>

                <div className="d-flex flex-wrap align-items-center gap-3">
                  <FormGroup className="mb-3" controlId="equityValue">
                    <p className="input-label mb-2">Expenses:</p>
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `${CurrencyFormatter.format(
                          PersonalExpenses
                        )}`)
                      }
                      value={PersonalExpenses}
                      placeholder="Equity Value"
                      onChange={(e) => {
                        setPersonalExpenses(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="border-none expense-primary rounded-0 text-white"
                      className="input-field mr-3 mb-1"
                    />
                  </FormGroup>
                  <div className="d-flex align-items-center">
                    <Checkbox
                      inputId="UseAverageAustralianAnnual"
                      checked={uaaae}
                      onChange={(e) => {
                        setUaaae(e.checked);
                      }}
                    />
                    <label
                      className="checkbox-label mx-2"
                      htmlFor="UseAverageAustralianAnnual"
                    >
                      Use average Australian annual
                    </label>
                  </div>
                </div>
                {!uaaae && (
                  <>
                    <div className="d-flex flex-wrap align-items-center gap-3">
                      <FormGroup className="mb-3" controlId="PrimaryResidence">
                        <p className="input-label mb-2">Primary Residence</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              PrimaryResidence
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="PrimaryResidence"
                          value={PrimaryResidence}
                          onChange={(e) => {
                            setPrimaryResidence(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="equityValue">
                        <p className="input-label mb-2">Phone Internet Media</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              Entertainment
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Phone Internet Media"
                          value={Entertainment}
                          onChange={(e) => {
                            setEntertainment(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="FoodDining">
                        <p className="input-label mb-2">Food & Groceries</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              FoodDining
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Food & Groceries"
                          value={FoodDining}
                          onChange={(e) => {
                            setFoodDining(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                    </div>
                    <div className="d-flex flex-wrap align-items-center gap-3">
                      <FormGroup
                        className="mb-3"
                        controlId="RecreationHolidays"
                      >
                        <p className="input-label mb-2">
                          Recreation & Holidays
                        </p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              RecreationHolidays
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Recreation And Holidays"
                          value={RecreationHolidays}
                          onChange={(e) => {
                            setRecreationHolidays(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup
                        className="mb-3"
                        controlId="Clothing And Personal Care"
                      >
                        <p className="input-label mb-2">
                          Clothing And Personal Care
                        </p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              Shopping
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Clothing And Personal Care"
                          value={Shopping}
                          onChange={(e) => {
                            setShopping(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="HealthFitness">
                        <p className="input-label mb-2">Medical & Health</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              HealthFitness
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Medical & Health"
                          value={HealthFitness}
                          onChange={(e) => {
                            setHealthFitness(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                    </div>
                    <div className="d-flex flex-wrap align-items-center gap-3">
                      <FormGroup className="mb-3" controlId="Transport">
                        <p className="input-label mb-2">Transport</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              Travel
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Transport"
                          value={Travel}
                          onChange={(e) => {
                            setTravel(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="Public Education">
                        <p className="input-label mb-2">Public Education</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              Education
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Public Education"
                          value={Education}
                          onChange={(e) => {
                            setEducation(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup
                        className="mb-3"
                        controlId="HigherEducationandVocationalTraining"
                      >
                        <p className="input-label mb-2">
                          Higher Education and Vocational Training
                        </p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              HigherEducationandVocationalTraining
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Higher Education and Vocational Training"
                          value={HigherEducationandVocationalTraining}
                          onChange={(e) => {
                            setHigherEducationandVocationalTraining(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                    </div>
                  </>
                )}
                {!uaaae && (
                  <>
                    <div className="d-flex flex-wrap align-items-center gap-3">
                      <FormGroup className="mb-3" controlId="Childcare">
                        <p className="input-label mb-2">Childcare</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              Childcare
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Childcare"
                          value={Childcare}
                          onChange={(e) => {
                            setChildcare(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="GeneralInsurance">
                        <p className="input-label mb-2">General Insurance</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              GeneralInsurance
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="General Insurance"
                          value={GeneralInsurance}
                          onChange={(e) => {
                            setGeneralInsurance(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="LoansCreditCards">
                        <p className="input-label mb-2">Loans</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              LoansCreditCards
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Loans & Credit Cards"
                          value={LoansCreditCards}
                          onChange={(e) => {
                            setLoansCreditCards(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                    </div>
                  </>
                )}
                {!uaaae && (
                  <>
                    <div className="d-flex flex-wrap align-items-center gap-3">
                      <FormGroup className="mb-3" controlId="CarLoan">
                        <p className="input-label mb-2">Car Loan</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              CarLoan
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Car Loan"
                          value={CarLoan}
                          onChange={(e) => {
                            setCarLoan(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="OtherExpenses">
                        <p className="input-label mb-2">Other</p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              OtherExpenses
                            )}`)
                          }
                          className="input-field mr-3 mb-1"
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          placeholder="Other"
                          value={OtherExpenses}
                          onChange={(e) => {
                            setOtherExpenses(e.value);
                            OnChange();
                          }}
                        />
                      </FormGroup>
                    </div>
                  </>
                )}
                {uaaae && (
                  <div className="d-flex flex-wrap align-items-center gap-3">
                    <FormGroup className="mb-3" controlId="OtherExpenses">
                      <p className="input-label mb-2">Other</p>
                      <InputNumber
                        onFocus={(e) => (e.target.value = "")}
                        onBlur={(e) =>
                          (e.target.value = `${CurrencyFormatter.format(
                            OtherExpenses
                          )}`)
                        }
                        className="input-field mr-3 mb-1"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minfractiondigits={1}
                        placeholder="Other"
                        value={OtherExpenses}
                        onChange={(e) => {
                          setOtherExpenses(e.value);
                          OnChange();
                        }}
                      />
                    </FormGroup>
                  </div>
                )}
              </CollapseCard>
            </Form>
            <div className="filters-container my-3 d-flex flex-wrap justify-content-end align-items-center">
              <span
                onClick={prevBtnNavigation}
                className={`prevNavigationBtn ${
                  ScrollPosition >= 180 ? "active-img" : ""
                }`}
              >
                <img src={ArrowIcon} alt="" />
              </span>
              <div
                onClick={() => ApplyFilter("ByMonth")}
                className={FilterBy === "ByMonth" ? "filter-active" : ""}
              >
                By Month <img src={FilterIcon} alt="" />
              </div>
              <div
                onClick={() => ApplyFilter("Expenses")}
                className={FilterBy === "Expenses" ? "filter-active" : ""}
              >
                Expenses <img src={FilterIcon} alt="" />
              </div>
              <div
                onClick={() => ApplyFilter("Income")}
                className={FilterBy === "Income" ? "filter-active" : ""}
              >
                Income <img src={FilterIcon} alt="" />
              </div>
              <div
                onClick={() => ApplyFilter("Liabilties")}
                className={FilterBy === "Liabilties" ? "filter-active" : ""}
              >
                Liabilties <img src={FilterIcon} alt="" />
              </div>
              <div
                onClick={() => ApplyFilter("Cash")}
                className={FilterBy === "Cash" ? "filter-active" : ""}
              >
                Cash
                <img src={FilterIcon} alt="" />
              </div>
              <span
                onClick={nextBtnNavigation}
                className={`navigationBtn ${
                  ScrollPosition <= TableRef?.current?.scrollWidth
                    ? "active-img"
                    : ""
                }`}
              >
                <img src={ArrowIcon} alt="" />
              </span>
            </div>

            {Data?.length > 0 && (
              <>
                <div className="d-flex">
                  <div
                    className={`table-containers ${
                      ScrollPosition >= 120 ? "leftPadding" : ""
                    }`}
                    ref={TableRef}
                    onScroll={handleLowerScroll}
                  >
                    <StrategicSpendingTableColumn
                      ShowPrinciples={ShowPrinciples}
                      setShowPrinciples={setShowPrinciples}
                      TableRef={TableRef}
                      ScrollPosition={ScrollPosition}
                      isSideBar={true}
                      FilterBy={FilterBy}
                      LiabilitiesColumns={LiabilitiesColumns}
                      MonthlyCashFlowColumns={MonthlyCashFlowColumns}
                      IncomeColums={IncomeColums}
                      ExpensesColumns={ExpensesColumns}
                      BalanceSheetColumns={BalanceSheetColumns}
                      CashColumns={CashColumns}
                      PrincipleColumns={PrincipleColumns}
                      AsutralianAverageExpensesColumn={
                        AsutralianAverageExpensesColumn
                      }
                      uaaae={uaaae}
                      Editable={Editable}
                      setEditable={setEditable}
                      OnChange={() => OnChange()}
                    />

                    {Data &&
                      Data?.map((data, idx) => (
                        <StrategicSpendingTableColumn
                          ShowPrinciples={ShowPrinciples}
                          setShowPrinciples={setShowPrinciples}
                          PrincipleData={PrincipleData}
                          EquityGains={EquityGains}
                          updateEquityGains={updateEquityGains}
                          HECSPayments={HECSPayments}
                          setHECSPayments={setHECSPayments}
                          ScrollPosition={ScrollPosition}
                          uaaae={uaaae}
                          FilterBy={FilterBy}
                          Editable={Editable}
                          setEditable={setEditable}
                          balanceSheet={balanceSheet}
                          ExtraCash={ExtraCash}
                          updateExtraCash={updateExtraCash}
                          setBalanceSheet={setBalanceSheet}
                          setCashOnHand={setCashOnHand}
                          cashOnHand={cashOnHand}
                          accountYour={accountYour}
                          setAccountYour={setAccountYour}
                          accountPartner={accountPartner}
                          setAccountPartner={setAccountPartner}
                          liabilities={liabilities}
                          setLiabilities={setLiabilities}
                          creditCardYours={creditCardYours}
                          setCreditCardYours={setCreditCardYours}
                          creditCardPartner={creditCardPartner}
                          setCreditCardPartner={setCreditCardPartner}
                          monthlyCashflow={monthlyCashflow}
                          PPRLoanColumns={PPRLoanColumns}
                          setMonthlyCashflow={setMonthlyCashflow}
                          income={income}
                          setIncome={setIncome}
                          setOtherIncome={setOtherIncome}
                          OtherIncome={OtherIncome}
                          netSalaryYour={netSalaryYour}
                          setNetSalaryYour={setNetSalaryYour}
                          netSalaryPartner={netSalaryPartner}
                          setNetSalaryPartner={setNetSalaryPartner}
                          rentalIncome={rentalIncome}
                          setRentalIncome={setRentalIncome}
                          expenses={expenses}
                          setExpenses={setExpenses}
                          BillsUtilities={BillsUtilities}
                          setBillsUtilities={setBillsUtilities}
                          Education={Education}
                          setEducation={setEducation}
                          Entertainment={Entertainment}
                          setEntertainment={setEntertainment}
                          HigherEducationandVocationalTraining={
                            HigherEducationandVocationalTraining
                          }
                          setHigherEducationandVocationalTraining={
                            setHigherEducationandVocationalTraining
                          }
                          Childcare={Childcare}
                          setChildcare={setChildcare}
                          PrimaryResidence={PrimaryResidence}
                          setPrimaryResidence={setPrimaryResidence}
                          FoodDining={FoodDining}
                          setFoodDining={setFoodDining}
                          RecreationHolidays={RecreationHolidays}
                          setRecreationHolidays={setRecreationHolidays}
                          HealthFitness={HealthFitness}
                          setHealthFitness={setHealthFitness}
                          GeneralInsurance={GeneralInsurance}
                          setGeneralInsurance={setGeneralInsurance}
                          LoansCreditCards={LoansCreditCards}
                          setLoansCreditCards={setLoansCreditCards}
                          RentMortgageProperty={RentMortgageProperty}
                          setRentMortgageProperty={setRentMortgageProperty}
                          Shopping={Shopping}
                          setShopping={setShopping}
                          Travel={Travel}
                          setTravel={setTravel}
                          CarLoan={CarLoan}
                          setCarLoan={setCarLoan}
                          setOtherExpenses={setOtherExpenses}
                          OtherExpenses={OtherExpenses}
                          PersonalExpenses={PersonalExpenses}
                          setPersonalExpenses={setPersonalExpenses}
                          InvestmentExpenses={InvestmentExpenses}
                          setInvestmentExpenses={setInvestmentExpenses}
                          EquityGain={EquityGain}
                          setEquityGain={setEquityGain}
                          CreditCards={CreditCards}
                          updateCreditCard={updateCreditCard}
                          key={idx}
                          data={data}
                          OnChange={() => OnChange()}
                        />
                      ))}
                  </div>
                  <FloatingBox
                    style={{
                      top: ScrollPosition2,
                      marginTop: "-63px",
                      minWidth: "20%",
                    }}
                  >
                    <p className={`${classes.itemTitle} mt-4`}>Cash on Hand</p>
                    <p className="itemValue">
                      {CurrencyFormatter.format(
                        (Data?.length > 0 &&
                          Data[currentColumn < 0 ? 0 : currentColumn]
                            ?.balanceSheet.cashOnHand.total) ||
                          cashOnHand
                      )}{" "}
                      -{" "}
                      {(Data?.length > 0 &&
                        Data[
                          currentColumn < 0 ? 0 : currentColumn
                        ]?.date.replace(/-/g, " ")) ||
                        ""}
                    </p>
                    <p className={`${classes.itemTitle} mt-4`}>Equity Gained</p>
                    <p className="itemValue">
                      {CurrencyFormatter.format(
                        (Data?.length > 0 &&
                          Data[currentColumn < 0 ? 0 : currentColumn]
                            ?.balanceSheet.EquityGain) ||
                          EquityGain
                      )}{" "}
                      -{" "}
                      {(Data?.length > 0 &&
                        Data[
                          currentColumn < 0 ? 0 : currentColumn
                        ]?.date.replace(/-/g, " ")) ||
                        ""}
                    </p>
                    <div className={`mt-4 ${classes.ResultBox}`}>
                      <p className={`${classes.itemTitle}`}>
                        Cash on Hand + Equity Gained1
                      </p>
                      <p className="itemValue">
                      {CurrencyFormatter.format(
                        (Data?.length > 0 &&
                          Data[currentColumn < 0 ? 0 : currentColumn]
                            ?.balanceSheet.cashOnHand.total) ||
                          cashOnHand +
                            ((Data?.length > 0 &&
                              Data[currentColumn < 0 ? 0 : currentColumn]
                                ?.balanceSheet.EquityGain) ||
                              EquityGain) 
                      )}
                      </p>
                    </div>
                  </FloatingBox>
                </div>

                <Row className="mt-4 Gradient-Box w-80 d-flex justify-content-between">
                  <Col md={4} lg={4}>
                    <div className="d-flex align-items-end justify-content-between">
                      <div className="text-left">
                        <p className="strong-text my-1"></p>
                        <p className="my-1">Total Income</p>
                        <p className="my-1">Total Liabilities</p>
                        <p className="my-1">Total Expenses</p>
                        <p className="my-1">Net Personal Income (Savings)</p>
                      </div>
                      <div className="text-right">
                        <p className="my-1">
                          {CurrencyFormatter.format(income)}
                        </p>
                        <p className="my-1">
                          {CurrencyFormatter.format(liabilities)}
                        </p>
                        <p className="my-1">
                          {CurrencyFormatter.format(expenses)}
                        </p>
                        <p className="my-1">
                          {CurrencyFormatter.format(
                            income - (liabilities + expenses)
                          )}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col
                    className="net-personal-icome-saving-container"
                    md={4}
                    lg={4}
                  >
                    <Row className="net-personal-icome-saving bg-white">
                      <Col xs={8} sm={8} md={8} lg={8}>
                        <div>
                          <p className="strong-text">Cash On Hand</p>
                        </div>
                      </Col>
                      <Col xs={4} sm={4} md={4} lg={4}>
                        <div className="text-right">
                          <p>{CurrencyFormatter.format(cashOnHand)}</p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="button-container">
                  <GradientButton
                    onClick={async () => {
                      await updateStrategicSpending();
                      GS.SOT.Progress = { steps: 4, completed: 2, current: 3 };
                      const updateData = [...state];
                      const i = updateData.findIndex(
                        (vendor) => vendor._id === vendorid
                      );
                      updateData[i] = {
                        ...updateData[i],
                        Progress: [GS.SOT.Progress],
                      };
                      dispatch(Vendors(updateData));
                      setActiveIndex(3);
                      if (accountType != "Contacts") {
                        dispatch(setGenericData({ ActiveProperty: null }));
                      }
                      Navigate(`/Clients/Summary?id=${vendorid}`);
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {OverLay == false && (
        <div style={{ marginTop: "30px" }}>
          <Form className="mt-3">
            <CollapseCard title={"Balance Sheet"}>
              <div className="d-flex bg-white align-items-center gap-3">
                <FormGroup className="mb-3" controlId="cash">
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `${CurrencyFormatter.format(
                        balanceSheet
                      )}`)
                    }
                    value={balanceSheet}
                    placeholder="Balance"
                    onChange={(e) => {
                      setBalanceSheet(e.value);
                      OnChange();
                    }}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    inputClassName="border-none bg-light rounded-0"
                    className="input-field mr-3 mb-1"
                  />
                </FormGroup>
              </div>
              <Row>
                <Col sm={12} md={12} lg={3}>
                  <FormGroup className="mb-3" controlId="cash">
                    <p className="input-label mb-2">Cash On Hand:</p>
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `${CurrencyFormatter.format(
                          cashOnHand
                        )}`)
                      }
                      value={cashOnHand}
                      onChange={(e) => {
                        setCashOnHand(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      className="input-field mr-3 mb-1"
                      inputClassName="border-none cashOnHand-primary rounded-0"
                    />
                  </FormGroup>
                </Col>
                <Col sm={12} md={12} lg={3}>
                  <FormGroup className="mb-3" controlId="equityValue">
                    <p className="input-label mb-2">Account (Yours)</p>
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `${CurrencyFormatter.format(
                          accountYour
                        )}`)
                      }
                      value={accountYour}
                      onChange={(e) => {
                        setAccountYour(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      className="input-field mr-3 mb-1"
                    />
                  </FormGroup>
                </Col>
                <Col sm={12} md={12} lg={3}>
                  <FormGroup className="mb-3" controlId="equityValue">
                    <p className="input-label mb-2">Account (Partner)</p>
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `${CurrencyFormatter.format(
                          accountPartner
                        )}`)
                      }
                      value={accountPartner}
                      onChange={(e) => {
                        setAccountPartner(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      className="input-field mr-3 mb-1"
                    />
                  </FormGroup>
                </Col>
                <Col sm={12} md={12} lg={3}>
                  <FormGroup className="mb-3" controlId="equityValue">
                    <p className="input-label mb-2">Equity Gain</p>
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `${CurrencyFormatter.format(
                          EquityGain
                        )}`)
                      }
                      value={EquityGain}
                      onChange={(e) => {
                        setEquityGain(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      className="input-field mr-3 mb-1"
                    />
                  </FormGroup>
                </Col>
                <Col sm={10} xs={10} lg={3} md={5} className="pr-5">
                  {ExtraCash.length > 0 ? (
                    <Table bordered className="ExtraCashTable">
                      <thead>
                        <tr>
                          <th>
                            <p className="input-label">Extra Cash</p>
                          </th>
                          <th>
                            <p className="input-label">Date</p>
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {ExtraCash?.map((item, idx) => {
                          return (
                            <tr key={idx}>
                              <td className="py-1">
                                <InputNumber
                                  onFocus={(e) => (e.target.value = "")}
                                  onBlur={(e) =>
                                    (e.target.value = `${CurrencyFormatter.format(
                                      item?.value || 0.0
                                    )}`)
                                  }
                                  value={item?.value}
                                  onChange={(e) => {
                                    updateExtraCash(idx, "value", e.value);
                                  }}
                                  mode="currency"
                                  currency="USD"
                                  locale="en-US"
                                  minfractiondigits={1}
                                  inputClassName="extraCash-input-field"
                                  className="border-0 extraCash-input-field"
                                />
                              </td>

                              <td className="py-1">
                                <Calendar
                                  minDate={new Date(moment())}
                                  maxDate={new Date(moment().add(84, "month"))}
                                  value={new Date(item?.date)}
                                  dateFormat="dd-M-yy"
                                  onChange={(e) =>
                                    updateExtraCash(idx, "date", e.value)
                                  }
                                  inputClassName="border-0 extraCash-input-field-calender"
                                  className="extraCash-input-field-calender"
                                />
                              </td>
                              <td className="ExtraCashActionTD" hover>
                                <span
                                  onClick={(e) => {
                                    removeExtraCash(e, idx);
                                  }}
                                  style={{
                                    fontSize: "18px",
                                    cursor: "pointer",
                                    color: "#323232",
                                    padding: "0px 2px",
                                  }}
                                >
                                  x
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                        <tr
                          onMouseEnter={(e) =>
                            (e.target.style.background = "#F7F9FA")
                          }
                          onMouseOut={(e) =>
                            (e.target.style.background = "#fff")
                          }
                        >
                          <td
                            className="text-center"
                            onClick={addExtraCash}
                            style={{ cursor: "pointer", fontSize: "14px" }}
                            colSpan={7}
                          >
                            Add New
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  ) : (
                    <Table bordered hover>
                      <thead>
                        <tr>
                          <th>
                            <p className="input-label">Extra Cash</p>
                          </th>
                          <th>
                            <p className="input-label">Date</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            className="text-center "
                            onClick={addExtraCash}
                            style={{ cursor: "pointer", fontSize: "14px" }}
                            colSpan={6}
                          >
                            No Extra Cash
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={3}>
                  <FormGroup className="mb-3" controlId="CapitalGrowthRate">
                    <p className="input-label mb-2">Capital Growth Rate:</p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={10} xs={10} lg={5} md={5} className="pr-5">
                  {EquityGains.length > 0 && (
                    <Table bordered className="EquityGainsTable">
                      <thead>
                        <tr>
                          <th>
                            <p className="input-label">Property Name</p>
                          </th>
                          {/* <th>
                            <p className="input-label">Equity Gain</p>
                          </th> */}
                          <th>
                            <p className="input-label">Percentage</p>
                          </th>
                          <th>
                            <p className="input-label">Start Date</p>
                          </th>
                          <th>
                            <p className="input-label">End Date</p>
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {EquityGains?.map((item, idx) => {
                          return (
                            <tr key={idx}>
                              <td className="py-1 ">
                                <div className="d-flex align-items-center justify-content-between">
                                  <InputText
                                    value={item?.PropertyName || ""}
                                    onChange={(e) => {
                                      updateEquityGains(
                                        idx,
                                        "PropertyName",
                                        e.value
                                      );
                                    }}
                                    mode="currency"
                                    currency="USD"
                                    locale="en-US"
                                    minfractiondigits={1}
                                    className="border-0 EquityGains-field"
                                    readOnly
                                  />
                                </div>
                              </td>
                              {/* <td className="py-1 ">
                                <div className="d-flex align-items-center justify-content-between">
                                  <InputNumber
                                    value={item.value}
                                    onChange={(e) => {
                                      updateEquityGains(idx, "value", e.value);
                                    }}
                                    mode="currency"
                                    currency="USD"
                                    locale="en-US"
                                    minfractiondigits={1}
                                    className="border-0 EquityGains-field"
                                    readOnly
                                  />
                                </div>
                              </td> */}
                              <td className="py-1 ">
                                <div
                                  style={{ width: 64 }}
                                  className="d-flex align-items-center justify-content-between"
                                >
                                  <InputNumber
                                    style={{ width: 64 }}
                                    onFocus={(e) => (e.target.value = "")}
                                    onBlur={(e) =>
                                      (e.target.value = `${item.Percentage}%`)
                                    }
                                    value={item.Percentage}
                                    onChange={(e) => {
                                      updateEquityGains(
                                        idx,
                                        "Percentage",
                                        e.value
                                      );
                                    }}
                                    mode="decimal"
                                    minFractionDigits={0}
                                    maxFractionDigits={2}
                                    suffix="%"
                                    className="border-0 EquityGains-fields"
                                  />
                                </div>
                              </td>
                              <td className="py-1">
                                <Calendar
                                  minDate={new Date(moment().add(1,"month").startOf('month'))}
                                  maxDate={new Date(moment().add(84, "month"))}
                                  value={new Date(item.startDate)}
                                  onChange={(e) =>
                                    updateEquityGains(idx, "startDate", e.value)
                                  }
                                  dateFormat="dd-M-yy"
                                  inputClassName="border-0 EquityGains-name"
                                />
                              </td>
                              <td className="py-1">
                                <Calendar
                                  minDate={new Date(moment().add(1,"month").startOf('month'))}
                                  maxDate={new Date(moment().add(84, "month"))}
                                  value={new Date(item.endDate)}
                                  onChange={(e) =>
                                    updateEquityGains(idx, "endDate", e.value)
                                  }
                                  dateFormat="dd-M-yy"
                                  inputClassName="border-0 EquityGains-name"
                                />
                              </td>

                              <td className="ExtraCashActionTD" hover>
                                <span
                                  onClick={(e) => {
                                    updateEquityGains(idx, "remove", 0);
                                  }}
                                  style={{
                                    fontSize: "18px",
                                    cursor: "pointer",
                                    color: "#323232",
                                    padding: "0px 2px",
                                  }}
                                >
                                  x
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={3}>
                  <FormGroup className="mb-3" controlId="equityValue">
                    <p className="input-label mb-2">Liabilities:</p>
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `${CurrencyFormatter.format(
                          liabilities
                        )}`)
                      }
                      value={liabilities}
                      onChange={(e) => {
                        setLiabilities(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      className="input-field mr-3 mb-1"
                      inputClassName="border-none liabilities-primary rounded-0"
                    />
                  </FormGroup>
                </Col>
                {CreditCards?.map((cardItem, idx) => {
                  return (
                    <Col key={idx} sm={12} md={12} lg={3}>
                      <FormGroup className="mb-3" controlId="equityValue">
                        <p className="input-label mb-2">
                          {cardItem?.type}{" "}
                          {`${
                            cardItem?.name ? "(" + cardItem?.name + ")" : ""
                          }`}{" "}
                          {`${
                            cardItem?.owner ? "(" + cardItem?.owner + ")" : ""
                          }`}
                        </p>
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `${CurrencyFormatter.format(
                              cardItem?.minimumPayment
                            )}`)
                          }
                          value={cardItem?.minimumPayment}
                          onChange={(e) => {
                            updateCreditCard(idx, "minimumPayment", e.value);
                          }}
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          className="input-field mr-3 mb-1"
                        />
                      </FormGroup>
                    </Col>
                  );
                })}
              </Row>
            </CollapseCard>
            <CollapseCard title={"Monthly Savings"}>
              <div className="d-flex flex-wrap align-items-center gap-3">
                <FormGroup className="mb-3" controlId="equityValue">
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `${CurrencyFormatter.format(
                        monthlyCashflow
                      )}`)
                    }
                    value={monthlyCashflow}
                    onChange={(e) => {
                      setMonthlyCashflow(e.value);
                      OnChange();
                    }}
                    placeholder="Equity Value"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    className="input-field mr-3 mb-1"
                    inputClassName="border-none bg-light rounded-0"
                  />
                </FormGroup>
              </div>
              <div className="d-flex flex-wrap align-items-center gap-3">
                <FormGroup className="mb-3" controlId="equityValue">
                  <p className="input-label mb-2">Income:</p>
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `${CurrencyFormatter.format(income)}`)
                    }
                    value={income}
                    placeholder="Equity Value"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    className="input-field mr-3 mb-1"
                    inputClassName="border-none income-primary rounded-0 text-white"
                  />
                </FormGroup>
                <FormGroup className="mb-3" controlId="equityValue">
                  <p className="input-label mb-2">Net Salary (yours)</p>
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `${CurrencyFormatter.format(
                        netSalaryYour
                      )}`)
                    }
                    className="input-field mr-3 mb-1"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    placeholder="Equity Value"
                    value={netSalaryYour}
                    onChange={(e) => {
                      setNetSalaryYour(e.value);
                      OnChange();
                    }}
                  />
                </FormGroup>
                <FormGroup className="mb-3" controlId="equityValue">
                  <p className="input-label mb-2">Net Salary (partners)</p>
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `${CurrencyFormatter.format(
                        netSalaryPartner
                      )}`)
                    }
                    className="input-field mr-3 mb-1"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    placeholder="Equity Value"
                    value={netSalaryPartner}
                    onChange={(e) => {
                      setNetSalaryPartner(e.value);
                      OnChange();
                    }}
                  />
                </FormGroup>
              </div>
              <div className="d-flex flex-wrap align-items-center gap-3">
                <FormGroup className="mb-3" controlId="equityValue">
                  <p className="input-label mb-2">Rental Income</p>
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `${CurrencyFormatter.format(
                        rentalIncome
                      )}`)
                    }
                    className="input-field mr-3 mb-1"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    placeholder="Equity Value"
                    value={rentalIncome || 0}
                    onChange={(e) => {
                      setRentalIncome(e.value);
                      OnChange();
                    }}
                  />
                </FormGroup>
                <FormGroup className="mb-3" controlId="OtherIncome">
                  <p className="input-label mb-2">Other Income</p>
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `${CurrencyFormatter.format(
                        OtherIncome
                      )}`)
                    }
                    className="input-field mr-3 mb-1"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    placeholder="Other Income"
                    value={OtherIncome}
                    onChange={(e) => {
                      setOtherIncome(e.value);
                      OnChange();
                    }}
                  />
                </FormGroup>
              </div>

              <div className="d-flex flex-wrap align-items-center gap-3">
                <FormGroup className="mb-3" controlId="equityValue">
                  <p className="input-label mb-2">Expenses:</p>
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `${CurrencyFormatter.format(
                        PersonalExpenses
                      )}`)
                    }
                    value={PersonalExpenses}
                    placeholder="Equity Value"
                    onChange={(e) => {
                      setPersonalExpenses(e.value);
                      OnChange();
                    }}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    inputClassName="border-none expense-primary rounded-0 text-white"
                    className="input-field mr-3 mb-1"
                  />
                </FormGroup>
                <div className="d-flex align-items-center">
                  <Checkbox
                    inputId="UseAverageAustralianAnnual"
                    checked={uaaae}
                    onChange={(e) => {
                      setUaaae(e.checked);
                    }}
                  />
                  <label
                    className="checkbox-label mx-2"
                    htmlFor="UseAverageAustralianAnnual"
                  >
                    Use average Australian annual
                  </label>
                </div>
              </div>
              {!uaaae && (
                <>
                  <div className="d-flex flex-wrap align-items-center gap-3">
                    <FormGroup className="mb-3" controlId="PrimaryResidence">
                      <p className="input-label mb-2">Primary Residence</p>
                      <InputNumber
                        onFocus={(e) => (e.target.value = "")}
                        onBlur={(e) =>
                          (e.target.value = `${CurrencyFormatter.format(
                            PrimaryResidence
                          )}`)
                        }
                        className="input-field mr-3 mb-1"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minfractiondigits={1}
                        placeholder="PrimaryResidence"
                        value={PrimaryResidence}
                        onChange={(e) => {
                          setPrimaryResidence(e.value);
                          OnChange();
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3" controlId="equityValue">
                      <p className="input-label mb-2">Phone Internet Media</p>
                      <InputNumber
                        onFocus={(e) => (e.target.value = "")}
                        onBlur={(e) =>
                          (e.target.value = `${CurrencyFormatter.format(
                            Entertainment
                          )}`)
                        }
                        className="input-field mr-3 mb-1"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minfractiondigits={1}
                        placeholder="Phone Internet Media"
                        value={Entertainment}
                        onChange={(e) => {
                          setEntertainment(e.value);
                          OnChange();
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3" controlId="FoodDining">
                      <p className="input-label mb-2">Food & Groceries</p>
                      <InputNumber
                        onFocus={(e) => (e.target.value = "")}
                        onBlur={(e) =>
                          (e.target.value = `${CurrencyFormatter.format(
                            FoodDining
                          )}`)
                        }
                        className="input-field mr-3 mb-1"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minfractiondigits={1}
                        placeholder="Food & Groceries"
                        value={FoodDining}
                        onChange={(e) => {
                          setFoodDining(e.value);
                          OnChange();
                        }}
                      />
                    </FormGroup>
                  </div>
                  <div className="d-flex flex-wrap align-items-center gap-3">
                    <FormGroup className="mb-3" controlId="RecreationHolidays">
                      <p className="input-label mb-2">Recreation & Holidays</p>
                      <InputNumber
                        onFocus={(e) => (e.target.value = "")}
                        onBlur={(e) =>
                          (e.target.value = `${CurrencyFormatter.format(
                            RecreationHolidays
                          )}`)
                        }
                        className="input-field mr-3 mb-1"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minfractiondigits={1}
                        placeholder="Recreation And Holidays"
                        value={RecreationHolidays}
                        onChange={(e) => {
                          setRecreationHolidays(e.value);
                          OnChange();
                        }}
                      />
                    </FormGroup>
                    <FormGroup
                      className="mb-3"
                      controlId="Clothing And Personal Care"
                    >
                      <p className="input-label mb-2">
                        Clothing And Personal Care
                      </p>
                      <InputNumber
                        onFocus={(e) => (e.target.value = "")}
                        onBlur={(e) =>
                          (e.target.value = `${CurrencyFormatter.format(
                            Shopping
                          )}`)
                        }
                        className="input-field mr-3 mb-1"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minfractiondigits={1}
                        placeholder="Clothing And Personal Care"
                        value={Shopping}
                        onChange={(e) => {
                          setShopping(e.value);
                          OnChange();
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3" controlId="HealthFitness">
                      <p className="input-label mb-2">Medical & Health</p>
                      <InputNumber
                        onFocus={(e) => (e.target.value = "")}
                        onBlur={(e) =>
                          (e.target.value = `${CurrencyFormatter.format(
                            HealthFitness
                          )}`)
                        }
                        className="input-field mr-3 mb-1"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minfractiondigits={1}
                        placeholder="Medical & Health"
                        value={HealthFitness}
                        onChange={(e) => {
                          setHealthFitness(e.value);
                          OnChange();
                        }}
                      />
                    </FormGroup>
                  </div>
                  <div className="d-flex flex-wrap align-items-center gap-3">
                    <FormGroup className="mb-3" controlId="Transport">
                      <p className="input-label mb-2">Transport</p>
                      <InputNumber
                        onFocus={(e) => (e.target.value = "")}
                        onBlur={(e) =>
                          (e.target.value = `${CurrencyFormatter.format(
                            Travel
                          )}`)
                        }
                        className="input-field mr-3 mb-1"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minfractiondigits={1}
                        placeholder="Transport"
                        value={Travel}
                        onChange={(e) => {
                          setTravel(e.value);
                          OnChange();
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3" controlId="Public Education">
                      <p className="input-label mb-2">Public Education</p>
                      <InputNumber
                        onFocus={(e) => (e.target.value = "")}
                        onBlur={(e) =>
                          (e.target.value = `${CurrencyFormatter.format(
                            Education
                          )}`)
                        }
                        className="input-field mr-3 mb-1"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minfractiondigits={1}
                        placeholder="Public Education"
                        value={Education}
                        onChange={(e) => {
                          setEducation(e.value);
                          OnChange();
                        }}
                      />
                    </FormGroup>
                    <FormGroup
                      className="mb-3"
                      controlId="HigherEducationandVocationalTraining"
                    >
                      <p className="input-label mb-2">
                        Higher Education and Vocational Training
                      </p>
                      <InputNumber
                        onFocus={(e) => (e.target.value = "")}
                        onBlur={(e) =>
                          (e.target.value = `${CurrencyFormatter.format(
                            HigherEducationandVocationalTraining
                          )}`)
                        }
                        className="input-field mr-3 mb-1"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minfractiondigits={1}
                        placeholder="Higher Education and Vocational Training"
                        value={HigherEducationandVocationalTraining}
                        onChange={(e) => {
                          setHigherEducationandVocationalTraining(e.value);
                          OnChange();
                        }}
                      />
                    </FormGroup>
                  </div>
                </>
              )}
              {!uaaae && (
                <>
                  <div className="d-flex flex-wrap align-items-center gap-3">
                    <FormGroup className="mb-3" controlId="Childcare">
                      <p className="input-label mb-2">Childcare</p>
                      <InputNumber
                        onFocus={(e) => (e.target.value = "")}
                        onBlur={(e) =>
                          (e.target.value = `${CurrencyFormatter.format(
                            Childcare
                          )}`)
                        }
                        className="input-field mr-3 mb-1"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minfractiondigits={1}
                        placeholder="Childcare"
                        value={Childcare}
                        onChange={(e) => {
                          setChildcare(e.value);
                          OnChange();
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3" controlId="GeneralInsurance">
                      <p className="input-label mb-2">General Insurance</p>
                      <InputNumber
                        onFocus={(e) => (e.target.value = "")}
                        onBlur={(e) =>
                          (e.target.value = `${CurrencyFormatter.format(
                            GeneralInsurance
                          )}`)
                        }
                        className="input-field mr-3 mb-1"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minfractiondigits={1}
                        placeholder="General Insurance"
                        value={GeneralInsurance}
                        onChange={(e) => {
                          setGeneralInsurance(e.value);
                          OnChange();
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3" controlId="LoansCreditCards">
                      <p className="input-label mb-2">Loans</p>
                      <InputNumber
                        onFocus={(e) => (e.target.value = "")}
                        onBlur={(e) =>
                          (e.target.value = `${CurrencyFormatter.format(
                            LoansCreditCards
                          )}`)
                        }
                        className="input-field mr-3 mb-1"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minfractiondigits={1}
                        placeholder="Loans & Credit Cards"
                        value={LoansCreditCards}
                        onChange={(e) => {
                          setLoansCreditCards(e.value);
                          OnChange();
                        }}
                      />
                    </FormGroup>
                  </div>
                </>
              )}
              {!uaaae && (
                <>
                  <div className="d-flex flex-wrap align-items-center gap-3">
                    <FormGroup className="mb-3" controlId="CarLoan">
                      <p className="input-label mb-2">Car Loan</p>
                      <InputNumber
                        onFocus={(e) => (e.target.value = "")}
                        onBlur={(e) =>
                          (e.target.value = `${CurrencyFormatter.format(
                            CarLoan
                          )}`)
                        }
                        className="input-field mr-3 mb-1"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minfractiondigits={1}
                        placeholder="Car Loan"
                        value={CarLoan}
                        onChange={(e) => {
                          setCarLoan(e.value);
                          OnChange();
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3" controlId="OtherExpenses">
                      <p className="input-label mb-2">Other</p>
                      <InputNumber
                        onFocus={(e) => (e.target.value = "")}
                        onBlur={(e) =>
                          (e.target.value = `${CurrencyFormatter.format(
                            OtherExpenses
                          )}`)
                        }
                        className="input-field mr-3 mb-1"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minfractiondigits={1}
                        placeholder="Other"
                        value={OtherExpenses}
                        onChange={(e) => {
                          setOtherExpenses(e.value);
                          OnChange();
                        }}
                      />
                    </FormGroup>
                  </div>
                </>
              )}
              {uaaae && (
                <div className="d-flex flex-wrap align-items-center gap-3">
                  <FormGroup className="mb-3" controlId="OtherExpenses">
                    <p className="input-label mb-2">Other</p>
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `${CurrencyFormatter.format(
                          OtherExpenses
                        )}`)
                      }
                      className="input-field mr-3 mb-1"
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      placeholder="Other"
                      value={OtherExpenses}
                      onChange={(e) => {
                        setOtherExpenses(e.value);
                        OnChange();
                      }}
                    />
                  </FormGroup>
                </div>
              )}
            </CollapseCard>
          </Form>
          <div className="filters-container my-3 d-flex flex-wrap justify-content-end align-items-center">
            <span
              onClick={prevBtnNavigation}
              className={`prevNavigationBtn ${
                ScrollPosition >= 180 ? "active-img" : ""
              }`}
            >
              <img src={ArrowIcon} alt="" />
            </span>
            <div
              onClick={() => ApplyFilter("ByMonth")}
              className={FilterBy === "ByMonth" ? "filter-active" : ""}
            >
              By Month <img src={FilterIcon} alt="" />
            </div>
            <div
              onClick={() => ApplyFilter("Expenses")}
              className={FilterBy === "Expenses" ? "filter-active" : ""}
            >
              Expenses <img src={FilterIcon} alt="" />
            </div>
            <div
              onClick={() => ApplyFilter("Income")}
              className={FilterBy === "Income" ? "filter-active" : ""}
            >
              Income <img src={FilterIcon} alt="" />
            </div>
            <div
              onClick={() => ApplyFilter("Liabilties")}
              className={FilterBy === "Liabilties" ? "filter-active" : ""}
            >
              Liabilties <img src={FilterIcon} alt="" />
            </div>
            <div
              onClick={() => ApplyFilter("Cash")}
              className={FilterBy === "Cash" ? "filter-active" : ""}
            >
              Cash
              <img src={FilterIcon} alt="" />
            </div>
            <span
              onClick={nextBtnNavigation}
              className={`navigationBtn ${
                ScrollPosition <= TableRef?.current?.scrollWidth
                  ? "active-img"
                  : ""
              }`}
            >
              <img src={ArrowIcon} alt="" />
            </span>
          </div>

          {Data?.length > 0 && (
            <>
              <div className="d-flex">
                <div
                  className={`table-containers ${
                    ScrollPosition >= 120 ? "leftPadding" : ""
                  }`}
                  ref={TableRef}
                  onScroll={handleLowerScroll}
                >
                  <StrategicSpendingTableColumn
                    ShowPrinciples={ShowPrinciples}
                    setShowPrinciples={setShowPrinciples}
                    TableRef={TableRef}
                    ScrollPosition={ScrollPosition}
                    isSideBar={true}
                    FilterBy={FilterBy}
                    LiabilitiesColumns={LiabilitiesColumns}
                    MonthlyCashFlowColumns={MonthlyCashFlowColumns}
                    IncomeColums={IncomeColums}
                    ExpensesColumns={ExpensesColumns}
                    BalanceSheetColumns={BalanceSheetColumns}
                    CashColumns={CashColumns}
                    PrincipleColumns={PrincipleColumns}
                    AsutralianAverageExpensesColumn={
                      AsutralianAverageExpensesColumn
                    }
                    uaaae={uaaae}
                    Editable={Editable}
                    setEditable={setEditable}
                    OnChange={() => OnChange()}
                  />

                  {Data &&
                    Data?.map((data, idx) => (
                      <StrategicSpendingTableColumn
                        ShowPrinciples={ShowPrinciples}
                        setShowPrinciples={setShowPrinciples}
                        PrincipleData={PrincipleData}
                        EquityGains={EquityGains}
                        updateEquityGains={updateEquityGains}
                        HECSPayments={HECSPayments}
                        setHECSPayments={setHECSPayments}
                        ScrollPosition={ScrollPosition}
                        uaaae={uaaae}
                        FilterBy={FilterBy}
                        Editable={Editable}
                        setEditable={setEditable}
                        balanceSheet={balanceSheet}
                        ExtraCash={ExtraCash}
                        updateExtraCash={updateExtraCash}
                        setBalanceSheet={setBalanceSheet}
                        setCashOnHand={setCashOnHand}
                        cashOnHand={cashOnHand}
                        accountYour={accountYour}
                        setAccountYour={setAccountYour}
                        accountPartner={accountPartner}
                        setAccountPartner={setAccountPartner}
                        liabilities={liabilities}
                        setLiabilities={setLiabilities}
                        creditCardYours={creditCardYours}
                        setCreditCardYours={setCreditCardYours}
                        creditCardPartner={creditCardPartner}
                        setCreditCardPartner={setCreditCardPartner}
                        monthlyCashflow={monthlyCashflow}
                        PPRLoanColumns={PPRLoanColumns}
                        setMonthlyCashflow={setMonthlyCashflow}
                        income={income}
                        setIncome={setIncome}
                        setOtherIncome={setOtherIncome}
                        OtherIncome={OtherIncome}
                        netSalaryYour={netSalaryYour}
                        setNetSalaryYour={setNetSalaryYour}
                        netSalaryPartner={netSalaryPartner}
                        setNetSalaryPartner={setNetSalaryPartner}
                        rentalIncome={rentalIncome}
                        setRentalIncome={setRentalIncome}
                        expenses={expenses}
                        setExpenses={setExpenses}
                        BillsUtilities={BillsUtilities}
                        setBillsUtilities={setBillsUtilities}
                        Education={Education}
                        setEducation={setEducation}
                        Entertainment={Entertainment}
                        setEntertainment={setEntertainment}
                        HigherEducationandVocationalTraining={
                          HigherEducationandVocationalTraining
                        }
                        setHigherEducationandVocationalTraining={
                          setHigherEducationandVocationalTraining
                        }
                        Childcare={Childcare}
                        setChildcare={setChildcare}
                        PrimaryResidence={PrimaryResidence}
                        setPrimaryResidence={setPrimaryResidence}
                        FoodDining={FoodDining}
                        setFoodDining={setFoodDining}
                        RecreationHolidays={RecreationHolidays}
                        setRecreationHolidays={setRecreationHolidays}
                        HealthFitness={HealthFitness}
                        setHealthFitness={setHealthFitness}
                        GeneralInsurance={GeneralInsurance}
                        setGeneralInsurance={setGeneralInsurance}
                        LoansCreditCards={LoansCreditCards}
                        setLoansCreditCards={setLoansCreditCards}
                        RentMortgageProperty={RentMortgageProperty}
                        setRentMortgageProperty={setRentMortgageProperty}
                        Shopping={Shopping}
                        setShopping={setShopping}
                        Travel={Travel}
                        setTravel={setTravel}
                        CarLoan={CarLoan}
                        setCarLoan={setCarLoan}
                        setOtherExpenses={setOtherExpenses}
                        OtherExpenses={OtherExpenses}
                        PersonalExpenses={PersonalExpenses}
                        setPersonalExpenses={setPersonalExpenses}
                        InvestmentExpenses={InvestmentExpenses}
                        setInvestmentExpenses={setInvestmentExpenses}
                        EquityGain={EquityGain}
                        setEquityGain={setEquityGain}
                        CreditCards={CreditCards}
                        updateCreditCard={updateCreditCard}
                        key={idx}
                        data={data}
                        OnChange={() => OnChange()}
                      />
                    ))}
                </div>
                <FloatingBox
                  style={{
                    top: ScrollPosition2,
                    marginTop: "-63px",
                    minWidth: "20%",
                  }}
                >
                  <p className={`${classes.itemTitle} mt-4`}>Cash on Hand</p>
                  <p className="itemValue">
                    {CurrencyFormatter.format(
                      (Data?.length > 0 &&
                        Data[currentColumn < 0 ? 0 : currentColumn]
                          ?.balanceSheet.cashOnHand.total) ||
                        cashOnHand
                    )}{" "}
                    -{" "}
                    {(Data?.length > 0 &&
                      Data[currentColumn < 0 ? 0 : currentColumn]?.date.replace(
                        /-/g,
                        " "
                      )) ||
                      ""}
                  </p>
                  <p className={`${classes.itemTitle} mt-4`}>Equity Gained</p>
                  <p className="itemValue">
                    {CurrencyFormatter.format(
                      (Data?.length > 0 &&
                        Data[currentColumn < 0 ? 0 : currentColumn]
                          ?.balanceSheet.EquityGain) ||
                        EquityGain
                    )}{" "}
                    -{" "}
                    {(Data?.length > 0 &&
                      Data[currentColumn < 0 ? 0 : currentColumn]?.date.replace(
                        /-/g,
                        " "
                      )) ||
                      ""}
                  </p>
                  <div className={`mt-4 ${classes.ResultBox}`}>
                    <p className={`${classes.itemTitle}`}>
                      Cash on Hand + Equity Gained
                    </p>
                    <p className="itemValue">
                      {CurrencyFormatter.format(
                        (Data?.length > 0 &&
                          Data[currentColumn < 0 ? 0 : currentColumn]
                            ?.balanceSheet.cashOnHand.total) ||
                          cashOnHand +
                            ((Data?.length > 0 &&
                              Data[currentColumn < 0 ? 0 : currentColumn]
                                ?.balanceSheet.EquityGain) ||
                              EquityGain) 
                      )}
                    </p>
                  </div>
                </FloatingBox>
              </div>

              <Row className="mt-4 Gradient-Box w-80 d-flex justify-content-between">
                <Col md={4} lg={4}>
                  <div className="d-flex align-items-end justify-content-between">
                    <div className="text-left">
                      <p className="strong-text my-1"></p>
                      <p className="my-1">Total Income</p>
                      <p className="my-1">Total Liabilities</p>
                      <p className="my-1">Total Expenses</p>
                      <p className="my-1">Net Personal Income (Savings)</p>
                    </div>
                    <div className="text-right">
                      <p className="my-1">{CurrencyFormatter.format(income)}</p>
                      <p className="my-1">
                        {CurrencyFormatter.format(liabilities)}
                      </p>
                      <p className="my-1">
                        {CurrencyFormatter.format(expenses)}
                      </p>
                      <p className="my-1">
                        {CurrencyFormatter.format(
                          income - (liabilities + expenses)
                        )}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col
                  className="net-personal-icome-saving-container"
                  md={4}
                  lg={4}
                >
                  <Row className="net-personal-icome-saving bg-white">
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <div>
                        <p className="strong-text">Cash On Hand</p>
                      </div>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <div className="text-right">
                        <p>{CurrencyFormatter.format(cashOnHand)}</p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* {window.scrollY > 340 && (
        <GradientButton
          txt="Next"
          style={{
            position: "fixed",
            bottom: "10px",
            right: "28px",
           
          }}
          // onClick={updateProfile}
        />
      )} */}
              <div className="button-container">
                <GradientButton
                  style={{
                    position: "fixed",
                    bottom: "10px",
                    right: "10px",
                  }}
                  onClick={async () => {
                    await updateStrategicSpending();
                    GS.SOT.Progress = { steps: 4, completed: 2, current: 3 };
                    const updateData = [...state];
                    const i = updateData.findIndex(
                      (vendor) => vendor._id === vendorid
                    );
                    updateData[i] = {
                      ...updateData[i],
                      Progress: [GS.SOT.Progress],
                    };
                    dispatch(Vendors(updateData));
                    setActiveIndex(3);
                    if (accountType != "Contacts") {
                      dispatch(setGenericData({ ActiveProperty: null }));
                    }
                    Navigate(`/Clients/Summary?id=${vendorid}`);
                  }}
                />
                <GradientButton
                  style={{
                    position: "fixed",
                    bottom: "10px",
                    right: "170px",
                  }}
                  onClick={() => {
                    // Navigate(-1);
                    onBack();
                  }}
                  txt="Back"
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default StrategicSpending;
