import { useState, useEffect, useRef } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { Card } from "primereact/card";
import { Slider } from "primereact/slider";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { GS, isWhatPercentOf, publish } from "../../services/global";
import "./css.css";
import { API } from "../../services/api";
import GradientButton from "../Button/GradientButton";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getPropertyData,
  setLockedProperties,
  SetPropertyData,
} from "../../redux/actions";
import { commaSeparators, CurrencyFormatter, getRate } from "../../utils";
import { InputNumbers, MySlider } from "../Equity/Equity.style";
import { Toast } from "primereact/toast";
import moment from "moment";
import Loading from "../Loading";

let isloaded = false;

function PropertyGenerator(props: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast: any = useRef();
  const lockedPropeties = useSelector((state: any) => state.PropertyLocks);

  const [cityName] = useState(
    GS.SOT.PortfolioSummary.Properties[
      GS.SOT.PropertyGenerators[props.number].medianPropertyType
    ].length === 0
      ? ""
      : GS.SOT.PortfolioSummary.Properties[
          GS.SOT.PropertyGenerators[props.number].medianPropertyType
        ].name
  );
  const [loading, setLoading] = useState(false);
  const showError = () => {
    toast.current.show({
      severity: "error",
      summary: "Error Message",
      detail: "Your property LVR should be greater than or equal to 80",
      life: 3000,
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    publish(`fixedtop`, { fixedtop: true });
  }, []);
  if (!isloaded) {
    isloaded = true;
  }

  const { data } = useSelector((state: any) => state.Domain);

  const [liquidAssets, setLiquidAssets] = useState(props?.Data?.liquidAssets);
  const [extraCash, setExtraCash] = useState(props?.Data?.extraCash);
  const [cashAvailable, setCashAvailable] = useState(
    props.number === 0
      ? isNaN(GS.SOT.StrategicSpending.cashOnHand)
        ? 0
        : GS.SOT.StrategicSpending.cashOnHand
      : props?.Data?.cashAvailable
  );
  const [useEquity, setUseEquity] = useState(props?.Data?.useEquity);
  const [percentageEquity, setPercentageEquity] = useState(
    props?.Data?.percentageEquity
  );
  const [equityAvailable, setEquityAvailable] = useState(
    props?.Data?.equityGain
  );
  const [totalAvailableFunds, setTotalAvailableFunds] = useState(
    props.number === 0
      ? props?.Data?.equityAvailable +
          props?.Data?.liquidAssets +
          (isNaN(GS.SOT.StrategicSpending.cashOnHand)
            ? 0
            : GS.SOT.StrategicSpending.cashOnHand) +
          parseInt(
            GS.SOT.StrategicSpending?.ExtraCash?.reduce((a: any, b: any) => {
              return (a += b.value);
            }, 0)
          )
      : props?.Data?.equityAvailable + props?.Data?.liquidAssets + cashAvailable
  );
  const [useCash, setUseCash] = useState(props?.Data?.useCash);
  const [slider1, setSlider1] = useState(props?.Data?.slider1);
  const [percentageCash, setPercentageCash] = useState(
    !isNaN(props?.Data?.percentageCash) ? props?.Data?.percentageCash : 0
  );

  const [useLiquidAssets, setUseLiquidAssets] = useState(
    props?.Data?.useLiquidAssets
  );

  const [targetYieldReturn, setTargetYieldReturn] = useState(
    GS.SOT.PropertyGenerators[props.number]?.B54
  );

  const [useExtraCash, setUseExtraCash] = useState(props?.Data?.useExtraCash);
  const [mortgageInsuranceApply, setMortgageInsuranceApply] = useState(
    GS?.SOT?.PropertyGenerators[props.number]?.summaryData?.LMI == 0 ? "N" : "Y"
  );

  const [A45, setA45] = useState(props?.Data?.A45);
  const [B45, setB45] = useState(props?.Data?.B45);
  const [A46, setA46] = useState(props?.Data?.A46);
  const [B46, setB46] = useState(props?.Data?.B46);
  const [B47, setB47] = useState(props?.Data?.B47);
  const [B48, setB48] = useState(props?.Data?.B48);
  const [B49, setB49] = useState(props?.Data?.B49);
  const [B50, setB50] = useState(props?.Data?.B50);
  const [B51, setB51] = useState(props?.Data?.B51);
  const [C51, setC51] = useState(props?.Data?.C51);
  const [B52, setB52] = useState(props?.Data?.B52);
  const [B53, setB53] = useState(props?.Data?.B53);
  const [B54, setB54] = useState(GS.SOT.PropertyGenerators[props.number]?.B54);
  const [B55, setB55] = useState(props?.Data?.B55);
  const [B56, setB56] = useState(props?.Data?.B56);
  const [B57, setB57] = useState(props?.Data?.B57);
  const [A58, setA58] = useState(props?.Data?.A58);
  const [B58, setB58] = useState(props?.Data?.B58);
  const [B59, setB59] = useState(props?.Data?.B59);
  const [C59, setC59] = useState(props?.Data?.C59);
  const [B60, setB60] = useState(props?.Data?.B60);
  const [B61, setB61] = useState(props?.Data?.B61);
  const [A62, setA62] = useState(props?.Data?.A62);
  const [B62, setB62] = useState(props?.Data?.B62);
  const [A63, setA63] = useState(props?.Data?.A63);
  const [B63, setB63] = useState(props?.Data?.B63);
  const [A64, setA64] = useState(props?.Data?.A64);
  const [B64, setB64] = useState(props?.Data?.B64);

  const [PurchasePrice, setPurchasePrice] = useState(
    props?.Data?.summaryData?.PurchasePrice
  );

  const [Deposit, setDeposit] = useState(props?.Data?.summaryData?.Deposit);
  const [StampDuty, setStampDuty] = useState(
    props?.Data?.summaryData?.StampDuty
  );
  const [AcquisitionCost, setAcquisitionCost] = useState(
    props?.Data?.summaryData?.AcquisitionCost
  );

  const [TotalDepositRequired, setTotalDepositRequired] = useState(
    props?.Data?.summaryData?.TotalDepositRequired
  );

  const [TotalPurchaseCosts, setTotalPurchaseCosts] = useState(
    props?.Data?.summaryData?.TotalPurchaseCosts
  );

  const [LoantoValueRatioLVR, setLoantoValueRatioLVR] = useState(
    props?.Data?.summaryData?.LoantoValueRatioLVR
  );
  const [TotalDebt, setTotalDebt] = useState(
    props?.Data?.summaryData?.TotalDebt
  );

  const [TotalDepositEquity, setTotalDepositEquity] = useState(
    props?.Data?.summaryData?.TotalDepositEquity
  );

  const [TotalDepositCash, setTotalDepositCash] = useState(
    props?.Data?.summaryData?.TotalDepositCash
  );

  const [AppliedInterestRate, setAppliedInterestRate] = useState(
    props?.Data?.summaryData?.AppliedInterestRate
  );
  const [EquityInterestRate, setEquityInterestRate] = useState(
    props?.Data?.summaryData?.EquityInterestRate || 0
  );
  const [DebtInterestRate, setDebtInterestRate] = useState(
    props?.Data?.summaryData?.DebtInterestRate || 0
  );
  const [PropertyRentIncomeYear1, setPropertyRentIncomeYear1] = useState(
    props?.Data?.summaryData?.PropertyRentIncomeYear1
  );
  const [AppliedGrowthRateannualPERC, setAppliedGrowthRateannualPERC] =
    useState(props?.Data?.summaryData?.AppliedGrowthRateannualPERC);

  const [TotalCostofLending, setTotalCostofLending] = useState(
    props?.Data?.summaryData?.TotalCostofLending +
      GS.SOT.Equity.PPR.totalEquity * (AppliedGrowthRateannualPERC / 100)
  );

  const [CashflowAnnual, setCashflowAnnual] = useState(
    props?.Data?.summaryData?.CashflowAnnual
  );

  const [CashflowMonthly, setCashflowMonthly] = useState(
    props?.Data?.summaryData?.CashflowMonthly
  );

  const [ReturnonEquityCOC, setReturnonEquityCOC] = useState(
    props?.Data?.summaryData?.ReturnonEquityCOC
  );
  const [availableExtraCash, setAvailableExtraCash] = useState(
    parseInt(
      GS.SOT.StrategicSpending?.ExtraCash?.reduce((a: any, b: any) => {
        return (a += b.value);
      }, 0)
    )
  );
  const [LMI, setLMI] = useState(props?.Data?.summaryData?.LMI);

  const [EquityGainPERCorDOLLAR, setEquityGainPERCorDOLLAR] = useState<any>(
    props?.Data?.summaryData?.EquityGainPERCorDOLLAR
  );

  const [CashGainDOLLAR, setCashGainDOLLAR] = useState(
    props?.Data?.summaryData?.CashGainDOLLAR
  );

  const [propertytype, setPropertytype] = useState(
    props?.Data?.summaryData?.propertyType
  );
  const calculateTotalDepositLeft = (
    equityAvailable: any,
    cashAvailable: any,
    extraCash: any,
    useLiquid: any,
    useEquity: any,
    useCash: any,
    TotalDepositRequired: any
  ) => {
    const propeties = [...GS.SOT.PropertyGenerators];
    let availableLiquidAssets =
      props.number === 0
        ? liquidAssets
        : parseInt(GS.SOT.Equity.LiquidAssets.value) -
          parseInt(
            propeties.splice(0, props.number - 1).reduce((a: any, b: any) => {
              return (a += b.liquidAssets);
            }, 0)
          );
    availableLiquidAssets = useLiquid === "Y" ? availableLiquidAssets : 0;
    let totalDepositLeft = TotalDepositRequired;
    equityAvailable = useEquity === "N" ? 0 : equityAvailable;
    cashAvailable = useCash === "N" ? 0 : cashAvailable;
    if (
      equityAvailable + cashAvailable + extraCash + availableLiquidAssets ==
      0
    ) {
      return `${CurrencyFormatter.format(
        totalDepositLeft
      )} Extra Cash Required`;
    }

    if (totalDepositLeft > equityAvailable) {
      if (equityAvailable > 0 && useEquity === "Y") {
        totalDepositLeft = totalDepositLeft - equityAvailable;
        if (cashAvailable + extraCash === 0 && liquidAssets === "N") {
          return `${CurrencyFormatter.format(
            totalDepositLeft
          )} Extra Cash Required`;
        }
      }
      if (totalDepositLeft > cashAvailable) {
        if (cashAvailable > 0 && useCash === "Y") {
          totalDepositLeft = totalDepositLeft - cashAvailable;

          if (equityAvailable + extraCash === 0 && liquidAssets === "N") {
            return `${CurrencyFormatter.format(
              totalDepositLeft
            )} Extra Cash Required`;
          }
        }
        if (totalDepositLeft > availableLiquidAssets) {
          if (availableLiquidAssets > 0 && useLiquid === "Y") {
            totalDepositLeft = totalDepositLeft - availableLiquidAssets;
          }
          if (totalDepositLeft > extraCash) {
            if (extraCash > 0 && useExtraCash == "Y") {
              totalDepositLeft = totalDepositLeft - extraCash;

              return `${CurrencyFormatter.format(
                totalDepositLeft
              )} Extra Cash Required`;
            } else {
              return `${CurrencyFormatter.format(
                totalDepositLeft
              )} Extra Cash Required`;
            }
          } else if (totalDepositLeft === extraCash && extraCash > 0) {
            totalDepositLeft = 0;
            return "Extra Cash Covered";
          } else {
            totalDepositLeft = 0;
            return "Extra Cash Covered";
          }
        } else if (
          totalDepositLeft === availableLiquidAssets &&
          useLiquid === "Y"
        ) {
          totalDepositLeft = 0;
          return "Liquid Assets Covered";
        } else if (
          totalDepositLeft < availableLiquidAssets &&
          useLiquid === "Y"
        ) {
          totalDepositLeft = 0;
          return "Liquid Assets Covered";
        }
      } else if (totalDepositLeft === cashAvailable) {
        totalDepositLeft = 0;
        return "Cash Covered!";
      } else {
        return "Cash Covered!";
      }
    } else if (totalDepositLeft === equityAvailable) {
      totalDepositLeft = 0;
      return "The equity can cover the initial amount!";
    } else {
      totalDepositLeft = 0;
      return "The equity can cover the initial amount!";
    }
  };

  const [notice, setNotice] = useState("");

  function c(number: number, maximumFractionDigits: number = 0) {
    if (isNaN(number)) {
      return "$0";
    }
    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: maximumFractionDigits,
    }).format(number);
  }
  function cp(number: number) {
    if (isNaN(number)) {
      return "0%";
    }
    return (
      Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(number) +
      "%"
    );
  }
  const blendedUpdateDeposit = (cash: any, equity: any) => {
    let purchasePrice = (cash + equity) * 5;
    let totalDebt = purchasePrice * LoantoValueRatioLVR;
    let stampDuty = purchasePrice * 0.055;
    let acquisitionCost = purchasePrice * 0.015;
    let Deposit = purchasePrice - totalDebt - stampDuty - acquisitionCost;
    let TotalDepositReq = Deposit + stampDuty + acquisitionCost;
    setPurchasePrice(purchasePrice);
    setTotalDebt(totalDebt);
    setDeposit(purchasePrice - totalDebt - stampDuty - stampDuty);
    setTotalDepositEquity(TotalDepositReq * 0.8);
    setTotalDepositCash(TotalDepositReq - TotalDepositReq * 0.8);
    setTotalPurchaseCosts(purchasePrice + stampDuty + acquisitionCost);
    let v = GS.SOT.Equity.PPR.totalEquity * (AppliedGrowthRateannualPERC / 100);
    let costofDebt = totalDebt * (AppliedInterestRate / 100);
    let costofEquity =
      TotalDepositReq * 0.8 * (AppliedGrowthRateannualPERC / 100);
    let totalCostofLending = costofDebt + costofEquity + v;
    setTotalCostofLending(totalCostofLending);

    setCashflowAnnual(PropertyRentIncomeYear1 - totalCostofLending);
    setCashflowMonthly(PropertyRentIncomeYear1 - totalCostofLending / 12);

    setReturnonEquityCOC(
      (PropertyRentIncomeYear1 - totalCostofLending / TotalDepositReq) * 100
    );
    setTotalDepositRequired(TotalDepositReq);
  };
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const [showDialog, setShowDialog] = useState(false);

  const getLMI = (mortgageInsuranceApply: any) => {
    if (mortgageInsuranceApply === "Y") {
      let totalmortgage = TotalDebt; //630000;
      let purchasePrice = TotalPurchaseCosts; //700000;
      let lvr = (totalmortgage / purchasePrice) * 100;

      let lmiRate: any = getRate(totalmortgage, LoantoValueRatioLVR * 100);
      let lmiPremium: any = totalmortgage * (lmiRate / 100);
      let stampDutyRate: any = 0;
      if (cityName === "NSW") {
        stampDutyRate = 9 / 100;
      } else if (cityName === "VIC") {
        stampDutyRate = 10 / 100;
      } else if (cityName === "QLD") {
        stampDutyRate = 9 / 100;
      } else if (cityName === "SA") {
        stampDutyRate = 11 / 100;
      } else if (cityName === "WA") {
        stampDutyRate = 10 / 100;
      } else if (cityName === "ACT") {
        stampDutyRate = 6 / 100;
      } else if (cityName === "NT") {
        stampDutyRate = 10 / 100;
      } else if (cityName === "TAS") {
        stampDutyRate = 10 / 100;
      } else {
        stampDutyRate = 9 / 100;
      }

      let stampDutyVal = lmiPremium * stampDutyRate;
      let totalLmi = stampDutyVal + lmiPremium;
      lvr = LoantoValueRatioLVR * 100;

      if (lvr >= 80) {
        return totalLmi;
      } else {
        showError();
        setMortgageInsuranceApply("N");
      }
    } else {
      if (LMI > 0) {
        return LMI;
      }
    }
  };
  const [initalLmi, setinitialLmi] = useState(true);

  useEffect(() => {
    if (initalLmi) {
      setinitialLmi(false);
    } else {
      updateTargetYield(
        targetYieldReturn,
        AppliedGrowthRateannualPERC,
        AppliedInterestRate
      );
    }
  }, [mortgageInsuranceApply]);

  useEffect(() => {
    let Notice = String(
      calculateTotalDepositLeft(
        equityAvailable,
        cashAvailable,
        extraCash,
        useLiquidAssets,
        useEquity,
        useCash,
        TotalDepositRequired
      )
    );
    setNotice(Notice);
  }, [TotalDepositRequired]);

  const updateTargetYield = (
    targetYield: any,
    equityInterestRate: any,
    AppliedInterestRate: any
  ) => {
    let MEDIANRENTPRICE_RentIncome = 0;
    let thePurchasePrice = 0;
    let TotalDebt = 0;
    let TotalDepositReq = 0;
    let totalCostOfLending = 0;
    let lmivalue = 0;
    if (mortgageInsuranceApply == "Y") {
      lmivalue = getLMI(mortgageInsuranceApply)
        ? getLMI(mortgageInsuranceApply)
        : 0;
    }
    let ResidentialMedianPrice =
      GS?.SOT?.PortfolioSummary?.Properties["residential"][props.number]
        ?.price *
      0.68 *
      52;
    let ComercialMedianPrice =
      GS?.SOT?.PortfolioSummary?.Properties["office"][props.number]?.price;
    //  *100;
    let RetailMedianPrice =
      GS?.SOT?.PortfolioSummary?.Properties["retail"][props.number]?.price;
    // *      100;
    if (props.number === 0) {
      if (GS?.SOT?.PropertyGenerators[0]?.initialPropertyType === "Blended") {
        thePurchasePrice = cashAvailable + equityAvailable;
      } else if (targetYield < 6) {
        thePurchasePrice = (ResidentialMedianPrice / targetYield) * 100;
      } else if (targetYield < 6.99) {
        thePurchasePrice = (RetailMedianPrice / targetYield) * 100;
      } else {
        thePurchasePrice = (ComercialMedianPrice / targetYield) * 100;
      }
      if (targetYield < 6) {
        MEDIANRENTPRICE_RentIncome = ResidentialMedianPrice;
      } else if (targetYield < 6.99) {
        MEDIANRENTPRICE_RentIncome = RetailMedianPrice;
      } else {
        MEDIANRENTPRICE_RentIncome = ComercialMedianPrice;
      }
      TotalDebt = thePurchasePrice * LoantoValueRatioLVR;
      if (mortgageInsuranceApply === "Y") {
        TotalDebt = TotalDebt + lmivalue;
      } else {
        if (lmivalue > 0) {
          TotalDebt = TotalDebt - lmivalue;
        }
      }
      TotalDepositReq = Deposit + StampDuty + AcquisitionCost;
      totalCostOfLending =
        ((TotalDebt + TotalDepositReq * 0.8) / 100) * AppliedInterestRate;
    } else {
      if (targetYield < 6) {
        thePurchasePrice = (ResidentialMedianPrice / targetYield) * 100;
        MEDIANRENTPRICE_RentIncome = ResidentialMedianPrice;
      } else if (targetYield <= 6.99) {
        thePurchasePrice = (RetailMedianPrice / targetYield) * 100;
        MEDIANRENTPRICE_RentIncome = RetailMedianPrice;
      } else {
        thePurchasePrice = (ComercialMedianPrice / targetYield) * 100;
        MEDIANRENTPRICE_RentIncome = ComercialMedianPrice;
      }
      TotalDebt = thePurchasePrice * LoantoValueRatioLVR;
      if (mortgageInsuranceApply === "Y") {
        TotalDebt = TotalDebt + lmivalue;
      } else {
        TotalDebt = TotalDebt - lmivalue;
        if (lmivalue > 0) {
          TotalDebt = TotalDebt - lmivalue;
        }
      }
      TotalDepositReq = Deposit + StampDuty + AcquisitionCost;
      totalCostOfLending =
        ((TotalDebt + TotalDepositReq * 0.8) / 100) * AppliedInterestRate;
    }
    setLMI(lmivalue);
    setPurchasePrice(thePurchasePrice);
    setPropertyRentIncomeYear1(MEDIANRENTPRICE_RentIncome);
    setTotalDebt(TotalDebt);
    setDeposit(thePurchasePrice - TotalDebt);
    setTotalDepositRequired(TotalDepositReq);
    setTotalPurchaseCosts(thePurchasePrice + StampDuty + AcquisitionCost);
    setTotalDepositEquity(TotalDepositReq * 0.8);
    setTotalDepositCash(TotalDepositReq - TotalDepositReq * 0.8);
    let v = GS.SOT.Equity.PPR.totalEquity * (equityInterestRate / 100);
    let costofDebt = TotalDebt * (AppliedInterestRate / 100);
    let costofEquity = TotalDepositEquity * (equityInterestRate / 100);
    setTotalCostofLending(costofDebt + costofEquity + v);
    setCashflowAnnual(MEDIANRENTPRICE_RentIncome - totalCostOfLending);
    setCashflowMonthly(MEDIANRENTPRICE_RentIncome - totalCostOfLending / 12);
    setReturnonEquityCOC(
      (MEDIANRENTPRICE_RentIncome - totalCostOfLending / TotalDepositReq) * 100
    );
  };

  const reCalculateNextProperties = (
    growthHacking: {
      equityGainGrowth: 0;
      cashGainGrowth: 0;
      AnnualGrowth: 0;
    }[],
    startAt: any = 1,
    endAt: any = 20
  ) => {
    if (growthHacking.length == 0)
      growthHacking.push({
        equityGainGrowth: 0,
        cashGainGrowth: 0,
        AnnualGrowth: 0,
      });
    let Externals = {
      RISKProfilingCommercialE7: 900000,
      EquityCapacityH14: 856837,
      RISKProfilingCommercialM7: 720000,
      RISKProfilingCommercialL7: 288000,
      RISKProfilingCommercialT7: 720000,
      RISKProfilingCommercialS7: 108000,
      ClientInputsC35: GS?.SOT?.PropertyGenerators[0]?.targetYieldReturn,
      RemoveTabCalculateF5: 1000000,
      RemoveTabCalculateF48: 3.7,
      RemoveTabCalculateG49: GS.SOT.BorrowingCalculator.can_borrow1,
      RemoveTabCalculateF49: GS.SOT.BorrowingCalculator.can_borrow1,
      MedianPrices: data,
    };

    if (!GS?.SOT.PortfolioSummary.hasOwnProperty("Properties")) {
      GS.SOT.PortfolioSummary.Properties = [];
    }

    if (GS?.SOT.PortfolioSummary.Properties.length === 0) {
      // for (let zi = 0; zi < 201; zi++) {
      //   GS.SOT.PortfolioSummary.Properties.push(GS?.getRandMedianPrice(data));
      // }
      // let ndata = GS.shuffle(data);
      for (let i = 0; i < data.length; i++) {
        GS.SOT.PortfolioSummary.Properties.push({
          suburb: data[i].Suburb_Name,
          name: data[i].State,
          price: data[i].Median_Rental_Price,
        });
      }
      GS?.SOT.PortfolioSummary.Properties.sort(() => Math.random() - 0.5);
    }

    GS.generateData();

    GS.SOT.PropertyGenerators = [
      ...GS.SOT.PropertyGenerators.splice(0, startAt),
    ];

    let totalcashAvailable =
      GS?.SOT?.PropertyGenerators[0]?.F3 +
      GS?.SOT?.PropertyGenerators[0]?.F4 +
      GS?.SOT?.PropertyGenerators[0]?.equityGain;
    if (
      totalcashAvailable <
      GS?.SOT?.PropertyGenerators[0]?.summaryData?.TotalDepositRequired
    ) {
      return false;
    }

    let desireIncomeGoal = GS?.SOT?.BorrowingCalculator?.income_goal || 0;
    let currentIncomeGoal =
      GS?.SOT?.PropertyGenerators[0]?.summaryData?.CashflowAnnual || 0;
    let financialYear: any;
    if (currentIncomeGoal < desireIncomeGoal) {
      if (
        GS.SOT.PortfolioSummary.Properties[0] === undefined ||
        GS.SOT.PortfolioSummary.Properties[0] === null
      ) {
        GS.SOT.PropertyGenerators[0].summaryData.State =
          GS?.SOT?.PortfolioSummary?.Properties[0]?.city;
      }

      let ResidentialMedianPrice =
        GS?.SOT?.PortfolioSummary?.Properties[0]?.price * 0.68 * 52;
      let ComercialMedianPrice =
        GS?.SOT?.PortfolioSummary?.Properties[0]?.price * 100;
      let RiskFactoredYieldReturn = 0;
      if (GS?.SOT?.PropertyGenerators[0]?.targetYieldReturn !== null) {
        RiskFactoredYieldReturn = parseFloat(
          GS?.SOT?.PropertyGenerators[0]?.targetYieldReturn
            .toString()
            .replace("%", "")
        );
      }

      let thePurchasePrice = 0;
      let MEDIANRENTPRICE_RentIncome = 0;
      const rules = {
        rule1: [5, 7, 7, 6, 5],
        rule2: [7, 5, 6, 6, 5],
        rule3: [5.5, 7, 5, 6, 5],
        rule4: [6.5, 5, 7, 5, 6],
      };

      const nY = parseInt(
        GS?.SOT?.RiskProfile?.NetYieldonProperty.slice(0, -1)
      );

      let oldproperty_pTotalCashRequired =
        GS?.SOT?.PropertyGenerators[0]?.summaryData?.TotalDepositRequired;

      for (let i = startAt < 1 ? 1 : startAt; i <= endAt; i++) {
        ResidentialMedianPrice =
          GS?.SOT?.PortfolioSummary?.Properties[i]?.price * 0.68 * 52;
        ComercialMedianPrice =
          GS?.SOT?.PortfolioSummary?.Properties[i]?.price * 100;

        let propertyno = i;

        GS.generateDataForProperty(moment());

        if (GS?.SOT?.RiskProfile?.initialPropertyType === "Blended") {
          if (GS?.SOT?.RiskProfile?.NetYieldonProperty) {
            const rule =
              nY <= 5.5
                ? "rule1"
                : nY <= 6
                ? "rule3"
                : nY <= 6.5
                ? "rule4"
                : Math.round(nY) <= 7
                ? "rule2"
                : 0;

            if (rule !== 0) {
              const arrayDuplicates = Math.floor(
                propertyno / rules[rule].length
              );
              if (arrayDuplicates > 0) {
                for (let zz = 0; zz < arrayDuplicates; zz++) {
                  rules[rule].push(...rules[rule]);
                }
              }

              RiskFactoredYieldReturn = rules[rule][propertyno];
            }
          }
        } else if (
          GS?.SOT?.RiskProfile?.initialPropertyType === "Commercial - Retail" ||
          GS?.SOT?.RiskProfile?.initialPropertyType === "Commercial - Office"
        ) {
          RiskFactoredYieldReturn = parseFloat(
            GS?.SOT?.PropertyGenerators[0]?.targetYieldReturn
              .toString()
              .replace("%", "")
          );
        } else {
          RiskFactoredYieldReturn = parseFloat(
            GS?.SOT?.PropertyGenerators[0]?.targetYieldReturn
              .toString()
              .replace("%", "")
          );
        }

        if (RiskFactoredYieldReturn < 6) {
          thePurchasePrice =
            (ResidentialMedianPrice / RiskFactoredYieldReturn) * 100;
          MEDIANRENTPRICE_RentIncome = ResidentialMedianPrice;
        } else {
          thePurchasePrice =
            (ComercialMedianPrice / RiskFactoredYieldReturn) * 100;
          MEDIANRENTPRICE_RentIncome = ComercialMedianPrice;
        }

        GS.SOT.PropertyGenerators.push({
          liquidAssets: GS.SOT.PropertyGenerators[i - 1]?.liquidAssets,
          netIncome: GS?.Data[0][1]?.monthlyCashflow?.income?.total,
          extraCash: GS.SOT.PropertyGenerators[i - 1]?.extraCash,
          cashAvailable: GS?.Data[0][1]?.balanceSheet?.cashOnHand?.total,
          useEquity: "Y",
          equityAvailable:
            GS.SOT.PropertyGenerators[i - 1].tableData
              .ReturnonEquitywitha5capitalgrowthrate *
            (GS.SOT.PropertyGenerators[i - 1].tableData.PurchasePrice / 100),
          percentageEquity: GS.SOT.PropertyGenerators[i - 1].percentageEquity,
          totalAvailableFunds: 0,
          useCash: "Y",
          slider1: GS.SOT.PropertyGenerators[i - 1].slider1,
          percentageCash: GS.SOT.PropertyGenerators[i - 1].percentageCash,
          totalAcquisitionlabel: 0,
          useLiquidAssets: "N",
          totalAcquisition: 0,
          targetYieldReturn: 0,
          useExtraCash: "N",
          slider2: 0,
          totalAssetsRequiredLeveragedLabel: 0,
          totalAssetsRequiredLeveraged: 0,
          cashRequiredLeveragedLabel: 0,
          cashRequiredLeveraged: 0,
          methodforPurchasePrice: "Maximum borrowing capacity + 20% LVR",
          tableData: {
            PurchasePrice: thePurchasePrice,
            Depositassumingleverage20: 0,
            StampDuty: 0,
            SolicitorValuation: 0,
            BuildingReport: 0,
            LoanArrangementFee: 0,
            TotalCashRequired: 0,
            TotalPurchaseCosts: 0,
            NetAnnualCashflowReturnYear1: 0,
            NetYieldonPropertyYear1: RiskFactoredYieldReturn,
            NetYieldonTotalPurchaseCostsYear1: 0,
            CashonCashReturn: 0,
            TotalCashRequired20Costs: 0,
            CostofLoan5paonleverage: 0,
            Cashflowaftermortagecosts: 0,
            CashflowaftermortgageMonthly: 0,
            ReturnonEquitypurecashflowreturn: 0,
            ReturnonEquitywitha5capitalgrowthrate: 0,
            ReturnonEquitywitha7capitalgrowthrate: 0,
            ReturnonEquitywitha10capitalgrowthrate: 0,
          },
          summaryData: {
            PropertyType:
              RiskFactoredYieldReturn < 6 ? "Residential" : "Commercial",
            PurchasePrice: thePurchasePrice,
            Deposit: 0,
            StampDuty: 0,
            TotalDepositRequired: 0,
            AcquisitionCost: 0,
            TotalPurchaseCosts: 0,
            LoantoValueRatioLVR: 0,
            TotalDebt: 0,
            TotalDepositEquity: 0,
            TotalDepositCash: 0,
            AppliedInterestRate: 0,
            PropertyRentIncomeYear1: MEDIANRENTPRICE_RentIncome,
            TotalCostofLending: 0,
            CashflowAnnual: 0,
            CashflowMonthly: 0,
            ReturnonEquityCOC: 0,
            AppliedGrowthRateannualPERC: growthHacking[i]?.AnnualGrowth
              ? growthHacking[i].AnnualGrowth
              : 0,
            EquityGainPERCorDOLLAR: growthHacking[i]?.equityGainGrowth
              ? growthHacking[i].equityGainGrowth
              : 0,
            CashGainDOLLAR: growthHacking[i]?.cashGainGrowth
              ? growthHacking[i].cashGainGrowth
              : 0,
            LMI: 0,
          },
          G3: "",
          A44: 0,
          F44: 0,
          C44: 0,
          A45: "",
          B45: 0,
          A46: 0,
          B46: 0,
          B47: 0,
          B48: 0,
          B49: 0,
          B50: 0,
          B51: 0,
          C51: 0,
          B52: 0,
          B53: 0,
          B54: 0,
          B55: 0,
          B56: 0,
          B57: 0,
          A58: 0,
          B58: 0,
          B59: 0,
          C59: 0,
          B60: 0,
          B61: 0,
          A62: 0,
          B62: 0,
          A63: 0,
          B63: 0,
          A64: 0,
          B64: 0,
        });

        GS.SOT.PropertyGenerators[i].summaryData.State =
          GS?.SOT?.PortfolioSummary?.Properties[i]?.city;
        let totalDepositLeft =
          thePurchasePrice * 0.2 +
          thePurchasePrice * 0.055 +
          thePurchasePrice * 0.015;
        const percentageEquityUsed = isWhatPercentOf(
          totalDepositLeft,
          GS.SOT.PropertyGenerators[i].equityAvailable
        );
        const availableExtraCash =
          parseInt(
            GS.SOT.StrategicSpending.ExtraCash.reduce((a: any, b: any) => {
              return (a += b.value);
            }, 0)
          ) -
          parseInt(
            GS.SOT.PropertyGenerators.reduce((a: any, b: any) => {
              return (a += b.extraCash);
            }, 0)
          );
        const availableLiquidAssets =
          parseInt(GS.SOT.Equity.LiquidAssets.value) -
          parseInt(
            GS.SOT.PropertyGenerators.reduce((a: any, b: any) => {
              return (a += b.liquidAssets);
            }, 0)
          );
        const cashAvailable = GS.SOT.PropertyGenerators[i].cashAvailable;
        const equityAvailable = GS.SOT.PropertyGenerators[i].equityAvailable;
        if (totalDepositLeft > equityAvailable) {
          if (equityAvailable > 0) {
            GS.SOT.PropertyGenerators[i].useEquity = "Y";
            GS.SOT.PropertyGenerators[i].percentageEquity = 100;
            totalDepositLeft = totalDepositLeft - equityAvailable;
          } else {
            GS.SOT.PropertyGenerators[i].useEquity = "N";
            GS.SOT.PropertyGenerators[i].percentageEquity = 0;
          }
          if (totalDepositLeft > cashAvailable) {
            if (cashAvailable > 0) {
              GS.SOT.PropertyGenerators[i].useCash = "Y";
              GS.SOT.PropertyGenerators[i].percentageCash = 100;
              totalDepositLeft = totalDepositLeft - cashAvailable;
            } else {
              GS.SOT.PropertyGenerators[i].useCash = "N";
              GS.SOT.PropertyGenerators[i].percentageCash = 0;
            }
            if (totalDepositLeft > availableLiquidAssets) {
              if (availableLiquidAssets > 0) {
                GS.SOT.PropertyGenerators[i].useLiquidAssets = "Y";
                GS.SOT.PropertyGenerators[i].liquidAssets =
                  availableLiquidAssets;
                totalDepositLeft = totalDepositLeft - availableLiquidAssets;
              } else {
                GS.SOT.PropertyGenerators[i].useLiquidAssets = "N";
                GS.SOT.PropertyGenerators[i].liquidAssets = 0;
              }
              if (totalDepositLeft >= availableExtraCash) {
                if (availableExtraCash > 0) {
                  GS.SOT.PropertyGenerators[i].useExtraCash = "Y";
                  GS.SOT.PropertyGenerators[i].extraCash = availableExtraCash;
                  totalDepositLeft = totalDepositLeft - availableExtraCash;
                } else {
                  GS.SOT.PropertyGenerators[i].useExtraCash = "N";
                  GS.SOT.PropertyGenerators[i].extraCash = 0;
                }
              } else {
                if (totalDepositLeft > 0) {
                  GS.SOT.PropertyGenerators[i].useExtraCash = "Y";
                  GS.SOT.PropertyGenerators[i].extraCash =
                    availableExtraCash - totalDepositLeft;
                  totalDepositLeft = 0;
                } else {
                  GS.SOT.PropertyGenerators[i].useExtraCash = "N";
                  GS.SOT.PropertyGenerators[i].extraCash = 0;
                }
              }
            } else if (totalDepositLeft === availableLiquidAssets) {
              GS.SOT.PropertyGenerators[i].useLiquidAssets = "Y";
              GS.SOT.PropertyGenerators[i].liquidAssets = availableLiquidAssets;
              GS.SOT.PropertyGenerators[i].useExtraCash = "N";
              GS.SOT.PropertyGenerators[i].extraCash = 0;
            } else {
              if (totalDepositLeft > 0) {
                GS.SOT.PropertyGenerators[i].useLiquidAssets = "Y";
                GS.SOT.PropertyGenerators[i].liquidAssets =
                  availableLiquidAssets - totalDepositLeft;
                totalDepositLeft = 0;
              } else {
                GS.SOT.PropertyGenerators[i].useLiquidAssets = "N";
                GS.SOT.PropertyGenerators[i].liquidAssets = 0;
              }
              GS.SOT.PropertyGenerators[i].useExtraCash = "N";
              GS.SOT.PropertyGenerators[i].extraCash = 0;
            }
          } else if (totalDepositLeft === cashAvailable) {
            totalDepositLeft = 0;
            GS.SOT.PropertyGenerators[i].useCash = "Y";
            GS.SOT.PropertyGenerators[i].percentageCash = 100;
            GS.SOT.PropertyGenerators[i].useExtraCash = "N";
            GS.SOT.PropertyGenerators[i].useLiquidAssets = "N";
          } else {
            const percentageCashUsed = isWhatPercentOf(
              totalDepositLeft,
              cashAvailable
            );
            totalDepositLeft = 0;
            GS.SOT.PropertyGenerators[i].useCash = "Y";
            GS.SOT.PropertyGenerators[i].percentageCash =
              percentageCashUsed > 100 ? 100 : percentageCashUsed;
            GS.SOT.PropertyGenerators[i].useExtraCash = "N";
            GS.SOT.PropertyGenerators[i].useLiquidAssets = "N";
          }
        } else if (totalDepositLeft === equityAvailable) {
          GS.SOT.PropertyGenerators[i].useEquity = "Y";
          GS.SOT.PropertyGenerators[i].percentageEquity = 100;
          totalDepositLeft = 0;
          GS.SOT.PropertyGenerators[i].useCash = "N";
          GS.SOT.PropertyGenerators[i].percentageCash = 0;
          GS.SOT.PropertyGenerators[i].useExtraCash = "N";
          GS.SOT.PropertyGenerators[i].useLiquidAssets = "N";
        } else {
          GS.SOT.PropertyGenerators[i].useEquity = "Y";
          GS.SOT.PropertyGenerators[i].percentageEquity =
            percentageEquityUsed > 100 ? 100 : percentageEquityUsed;
          totalDepositLeft = 0;
          GS.SOT.PropertyGenerators[i].useCash = "N";
          GS.SOT.PropertyGenerators[i].percentageCash = 0;
          GS.SOT.PropertyGenerators[i].useExtraCash = "N";
          GS.SOT.PropertyGenerators[i].useLiquidAssets = "N";
        }
        GS.calculatePropertyGenerator(
          {
            Data: {
              ...Externals,
              ClientInputsC35: RiskFactoredYieldReturn,
              ...GS.SOT.PropertyGenerators[i],
              ...growthHacking[i],
            },
          },
          GS.SOT.PropertyGenerators.length - 1
        );

        oldproperty_pTotalCashRequired +=
          GS?.SOT?.PropertyGenerators[i]?.summaryData?.TotalDepositRequired;
        let pTotalCashRequired = oldproperty_pTotalCashRequired;
        let nextDate: any = null;
        let LResult: any = null;

        const equityGainFromProperties =
          GS.SOT.PropertyGenerators[0].equityGain;
        const equityGains = GS.SOT.Equity.EquityGains.reduce(
          (prevValue: any, currentValue: any) =>
            (prevValue += currentValue.value / currentValue.noOfMonths),
          0
        );
        for (const pdata of GS?.Data[0]) {
          if (
            pdata?.balanceSheet?.cashOnHand?.total +
              equityGainFromProperties +
              equityGains +
              (growthHacking[i - 1]?.equityGainGrowth
                ? growthHacking[i - 1]?.equityGainGrowth
                : 0) +
              (growthHacking[i - 1]?.cashGainGrowth
                ? growthHacking[i - 1]?.cashGainGrowth
                : 0) >=
            pTotalCashRequired
          ) {
            if (nextDate) break;
            if (!nextDate) {
              LResult =
                pdata?.balanceSheet?.cashOnHand?.total +
                (growthHacking[i - 1]?.equityGainGrowth
                  ? growthHacking[i - 1]?.equityGainGrowth
                  : 0) +
                (growthHacking[i - 1]?.cashGainGrowth
                  ? growthHacking[i - 1]?.cashGainGrowth
                  : 0);
              nextDate = pdata?.date;
            }
          }
        }

        GS.SOT.PropertyGenerators[i].cashAvailable = LResult;
        GS.SOT.PropertyGenerators[i] = {
          ...GS.SOT.PropertyGenerators[i],
          nextDate: nextDate,
        };

        GS.calculatePropertyGenerator(
          {
            Data: {
              ...Externals,
              ClientInputsC35: RiskFactoredYieldReturn,
              ...GS.SOT.PropertyGenerators[i],
              ...growthHacking[i],
            },
          },
          GS.SOT.PropertyGenerators.length - 1
        );

        if (GS.SOT.PropertyGenerators[i].summaryData?.PurchasePrice < 350000) {
          GS.SOT.BorrowingCalculator.FinancialYear = parseInt(
            nextDate?.split("-")[1]
          );
          props.setIncomeGoal({
            ...props.IncomeGoal,
            year: parseInt(nextDate?.split("-")[1]),
          });
          financialYear = parseInt(nextDate?.split("-")[1]);
          i = 999999999;
        } else {
          currentIncomeGoal +=
            GS?.SOT?.PropertyGenerators[i]?.summaryData?.CashflowAnnual;
          if (currentIncomeGoal >= desireIncomeGoal) {
            if (nextDate) {
              GS.SOT.BorrowingCalculator.FinancialYear = parseInt(
                nextDate?.split("-")[1]
              );
              financialYear = parseInt(nextDate?.split("-")[1]);
              props.setIncomeGoal({
                ...props.IncomeGoal,
                year: parseInt(nextDate?.split("-")[1]),
              });
            }
            i = 9999999;
          }
        }
      }
    }

    let diff = financialYear - new Date().getUTCFullYear();

    publish(
      "setgoalprogress",
      isWhatPercentOf(currentIncomeGoal, desireIncomeGoal)
    );
    API.AutoSave();
  };

  const lockSummary = () => {
    let data = JSON.parse(JSON.stringify(GS?.SOT?.PortfolioSummary?.tableData));
    let properties = JSON.parse(JSON.stringify(GS?.SOT?.PropertyGenerators));
    properties[props.number].B54 = targetYieldReturn;
    properties[props.number].slider1 = slider1;
    properties[props.number].extraCash = extraCash;
    properties[props.number].useExtraCash = extraCash > 0 ? "Y" : "N";
    properties[props.number].useLiquidAssets = useLiquidAssets;
    properties[props.number].percentageCash = percentageCash;
    properties[props.number].percentageEquity = percentageEquity;
    properties[props.number].useEquity = useEquity;
    properties[props.number].useCash = useCash;

    data[0][props.number + 1] = propertytype;
    data[2][props.number + 1] = PurchasePrice;
    data[3][props.number + 1] = PurchasePrice - TotalDebt; // Deposit
    data[6][props.number + 1] = LMI; //LMI
    data[7][props.number + 1] = TotalDepositRequired;
    data[8][props.number + 1] = PurchasePrice + StampDuty + AcquisitionCost;
    data[10][props.number + 1] = LoantoValueRatioLVR * 100;
    data[11][props.number + 1] = TotalDebt;
    data[12][props.number + 1] = TotalDepositRequired * 0.8;
    data[13][props.number + 1] =
      TotalDepositRequired - TotalDepositRequired * 0.8;
    data[16][props.number + 1] = PropertyRentIncomeYear1;
    data[17][props.number + 1] = TotalCostofLending;
    data[18][props.number + 1] = PropertyRentIncomeYear1 - TotalCostofLending;
    data[19][props.number + 1] =
      (PropertyRentIncomeYear1 - TotalCostofLending) / 12;
    data[20][props.number + 1] = ReturnonEquityCOC;

    properties[props.number].summaryData.propertyType = propertytype;
    properties[props.number].medianPropertyType =
      propertytype === "Residential"
        ? "residential"
        : propertytype === "Commercial Office"
        ? "office"
        : propertytype === "Commercial Retail"
        ? "retail"
        : propertytype === "Other"
        ? "other"
        : "industry";
    properties[props.number].summaryData.PurchasePrice = PurchasePrice;
    properties[props.number].summaryData.LoantoValueRatioLVR =
      LoantoValueRatioLVR;
    properties[props.number].tableData.NetYieldonPropertyYear1 =
      targetYieldReturn;
    properties[props.number].summaryData.LMI = LMI;
    properties[props.number].summaryData.PropertyIncomeYear1 =
      PropertyRentIncomeYear1;
    properties[props.number].summaryData.Deposit = Deposit;
    properties[props.number].summaryData.TotalDepositRequired =
      TotalDepositRequired;
    properties[props.number].summaryData.TotalCostofLending =
      TotalCostofLending;
    properties[props.number].summaryData.AppliedInterestRate =
      AppliedInterestRate;
    properties[props.number].summaryData.ReturnonEquityCOC = ReturnonEquityCOC;
    properties[props.number].summaryData.TotalDebt = TotalDebt;
    properties[props.number].isLocked = true;
    // GS.SOT.PropertyGenerators[props.number].B54 = targetYieldReturn;
    // GS.SOT.PropertyGenerators[props.number].slider1 = slider1;
    // GS.SOT.PropertyGenerators[props.number].extraCash = extraCash;
    // GS.SOT.PropertyGenerators[props.number].useExtraCash =
    //   extraCash > 0 ? "Y" : "N";
    // GS.SOT.PropertyGenerators[props.number].useLiquidAssets = useLiquidAssets;
    // GS.SOT.PropertyGenerators[props.number].percentageCash = percentageCash;
    // GS.SOT.PropertyGenerators[props.number].percentageEquity = percentageEquity;
    // GS.SOT.PropertyGenerators[props.number].useEquity = useEquity;
    // GS.SOT.PropertyGenerators[props.number].useCash = useCash;

    // GS.SOT.PortfolioSummary.tableData[0][props.number + 1] = propertytype;
    // GS.SOT.PortfolioSummary.tableData[2][props.number + 1] = PurchasePrice;
    // GS.SOT.PortfolioSummary.tableData[3][props.number + 1] =
    //   PurchasePrice - TotalDebt; // Deposit
    // GS.SOT.PortfolioSummary.tableData[6][props.number + 1] = LMI; //LMI
    // GS.SOT.PortfolioSummary.tableData[7][props.number + 1] =
    //   TotalDepositRequired;
    // GS.SOT.PortfolioSummary.tableData[8][props.number + 1] =
    //   PurchasePrice + StampDuty + AcquisitionCost;
    // GS.SOT.PortfolioSummary.tableData[11][props.number + 1] =
    //   LoantoValueRatioLVR;
    // GS.SOT.PortfolioSummary.tableData[11][props.number + 1] = TotalDebt;
    // GS.SOT.PortfolioSummary.tableData[12][props.number + 1] =
    //   TotalDepositRequired * 0.8;
    // GS.SOT.PortfolioSummary.tableData[13][props.number + 1] =
    //   TotalDepositRequired - TotalDepositRequired * 0.8;
    // GS.SOT.PortfolioSummary.tableData[16][props.number + 1] =
    //   PropertyRentIncomeYear1;
    // GS.SOT.PortfolioSummary.tableData[17][props.number + 1] =
    //   TotalCostofLending;
    // GS.SOT.PortfolioSummary.tableData[18][props.number + 1] =
    //   PropertyRentIncomeYear1 - TotalCostofLending;
    // GS.SOT.PortfolioSummary.tableData[19][props.number + 1] =
    //   (PropertyRentIncomeYear1 - TotalCostofLending) / 12;
    // GS.SOT.PortfolioSummary.tableData[22][props.number + 1] =
    //   AppliedGrowthRateannualPERC;
    // GS.SOT.PortfolioSummary.tableData[23][props.number + 1] =
    //   EquityGainPERCorDOLLAR;

    // GS.SOT.PropertyGenerators[props.number].summaryData.propertyType =
    //   propertytype;
    // GS.SOT.PropertyGenerators[props.number].summaryData.PurchasePrice =
    //   PurchasePrice;
    // GS.SOT.PropertyGenerators[props.number].summaryData.LoantoValueRatioLVR =
    //   LoantoValueRatioLVR;
    // GS.SOT.PropertyGenerators[props.number].tableData.NetYieldonPropertyYear1 =
    //   targetYieldReturn;
    // GS.SOT.PropertyGenerators[props.number].summaryData.LMI = LMI;
    // GS.SOT.PropertyGenerators[props.number].summaryData.PropertyIncomeYear1 =
    //   PropertyRentIncomeYear1;
    // GS.SOT.PropertyGenerators[props.number].summaryData.Deposit = Deposit;
    // GS.SOT.PropertyGenerators[props.number].summaryData.TotalDepositRequired =
    //   TotalDepositRequired;
    // GS.SOT.PropertyGenerators[props.number].summaryData.TotalCostofLending =
    //   TotalCostofLending;
    // GS.SOT.PropertyGenerators[props.number].summaryData.AppliedInterestRate =
    //   AppliedInterestRate;
    // GS.SOT.PropertyGenerators[props.number].summaryData.TotalDebt = TotalDebt;
    // GS.SOT.PropertyGenerators[
    //   props.number
    // ].summaryData.AppliedGrowthRateannualPERC = AppliedGrowthRateannualPERC;
    // GS.SOT.PropertyGenerators[props.number].summaryData.EquityGainPERCorDOLLAR =
    //   EquityGainPERCorDOLLAR;
    // GS.SOT.PropertyGenerators[props.number].isLocked = true;
    let arr: any = [];
    data[22].slice(1).forEach((item: any, index: any) => {
      arr.push({
        AnnualGrowth: item,
        equityGainGrowth: data[23][index + 1],
        cashGainGrowth: data[24][index + 1],
      });
    });
    dispatch(
      setLockedProperties({
        tableData: data,
        PropertyGenerators: properties,
      })
    );
    // API.AutoSave();
    // reCalculateNextProperties(arr, props.number + 1, 20);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        showHeader={false}
        visible={showDialog}
        style={{ width: "25vw" }}
        onHide={() => setShowDialog(false)}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label style={{ fontWeight: "bold", marginBottom: "5px" }}>
            Available Extra Cash &nbsp;
            {commaSeparators(availableExtraCash)}
          </label>

          <label style={{ fontWeight: "bold", marginBottom: "5px" }}>
            Extra Cash
          </label>
          <InputNumbers
            mode="currency"
            currency="USD"
            locale="en-US"
            minfractiondigits={0}
            onFocus={(e: any) => (e.target.value = "")}
            onBlur={(e: any) => (e.target.value = commaSeparators(extraCash))}
            value={extraCash}
            onChange={(e: any) => {
              let Notice = String(
                calculateTotalDepositLeft(
                  useEquity === "Y" ? equityAvailable : 0,
                  useCash === "Y" ? cashAvailable : 0,
                  e.value,
                  useLiquidAssets,
                  useEquity,
                  useCash,
                  TotalDepositRequired
                )
              );
              setNotice(Notice);
              setExtraCash(e.value);
            }}
          />
          <div className="mt-3 grid gap-2">
            <GradientButton
              txt={"Cancel "}
              onClick={() => {
                setShowDialog(false);
                setUseExtraCash("N");
              }}
            />

            <GradientButton
              txt={"Add"}
              onClick={() => {
                setShowDialog(false);
              }}
            />
          </div>
        </div>
      </Dialog>

      <div>
        <div className="boxs-container">
          <div className="boxs">
            <Row
              lg={12}
              className={`${scrollPosition > 300 ? "grid fixed" : "grid"}`}
            >
              <Col lg={3} className="col">
                <p>Accessible Equity Available</p>
                <p>{c(equityAvailable, 2)}</p>
              </Col>
              <Col lg={3} className="col">
                <p>Cash On Hand</p>
                <p>{c(cashAvailable, 2)}</p>
              </Col>
              <Col lg={3} className="col">
                <p>Total Available Funds</p>
                <p>{c(totalAvailableFunds, 2)}</p>
              </Col>
              <Col lg={3} className="col">
                <p>Year Goal Achieved</p>
                <p>{props?.Data?.nextDate}</p>
              </Col>
            </Row>
          </div>
        </div>
        <div className="grid px-2 ">
          <div
            className="col"
            style={{ backgroundColor: "#ebf2fa", padding: "52px 52px" }}
          >
            <p className="heading">Property #{props.number + 1}</p>
            <table className="propertytable">
              <thead>
                <tr>
                  <th colSpan={1}>
                    <span className="heading2">FEASIBILITY MARKERS</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <span>Property Type</span>
                  </td>
                  <td>
                    <span>{propertytype}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Purchase Price</span>
                  </td>
                  <td>
                    <span>
                      {commaSeparators(Number(PurchasePrice).toFixed(2))}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Deposit</span>
                  </td>
                  <td>
                    <span>{commaSeparators(Deposit)}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Stamp Duty</span>
                  </td>
                  <td>
                    <span>{commaSeparators(StampDuty)}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Acquisition Cost</span>
                  </td>
                  <td>
                    <span>{commaSeparators(AcquisitionCost)}</span>
                  </td>
                </tr>
                {mortgageInsuranceApply === "Y" && (
                  <tr>
                    <td>
                      <span>Total LMI</span>
                    </td>
                    <td>
                      <span>{commaSeparators(LMI)}</span>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <span className="total">Total Deposit Required</span>
                  </td>
                  <td>
                    <span>{commaSeparators(TotalDepositRequired)} </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Total Purchase Costs</span>
                  </td>
                  <td>
                    <span>{commaSeparators(TotalPurchaseCosts)}</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={1}>
                    <span className="heading2">FINANCE</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Loan To Value Ratio(LVR)</span>
                  </td>
                  <td>
                    <span>{(LoantoValueRatioLVR * 100).toFixed(2)}%</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Total Debt</span>
                  </td>
                  <td>
                    <span>{commaSeparators(TotalDebt)}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Total Deposit Equity</span>
                  </td>
                  <td>
                    <span>{commaSeparators(TotalDepositEquity)}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Total Deposit Cash</span>
                  </td>
                  <td>
                    <span>{commaSeparators(TotalDepositCash)}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Applied Interest Rate</span>
                  </td>
                  <td>
                    <span>{parseFloat(AppliedInterestRate)}%</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={1}>
                    <span className="heading2">CASHFLOW</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Property Rent Income (Year 1)</span>
                  </td>
                  <td>
                    <span>{commaSeparators(PropertyRentIncomeYear1)}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Total Cost of Lending</span>
                  </td>
                  <td>
                    <span>{commaSeparators(TotalCostofLending)}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Cashflow (Annual)</span>
                  </td>
                  <td>
                    <span>{commaSeparators(CashflowAnnual)}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Cashflow (Monthly)</span>
                  </td>
                  <td>
                    <span>{commaSeparators(CashflowMonthly)}</span>
                  </td>
                </tr>

                <tr>
                  <td colSpan={1}>
                    <span className="heading2">GROWTH HACKING</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Applied Growth Rate (annual) (%)</span>
                  </td>
                  <td>
                    <span>{AppliedGrowthRateannualPERC}%</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Equity Gain ($)</span>
                  </td>
                  <td>
                    <span>{commaSeparators(EquityGainPERCorDOLLAR)}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Cash Gain ($)</span>
                  </td>
                  <td>
                    <span>{commaSeparators(CashGainDOLLAR)}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className="col"
            style={{
              backgroundColor: "var(--white)",
              padding: "0 52px 52px 52px",
            }}
          >
            <div>
              <p className="lbl notice">{notice}</p>
              <div className="mb-3">
                <span className="lbl">Property Type</span>
                <div className="d-flex">
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="propertytype1"
                      name="propertytype"
                      value="Residential"
                      onChange={(e) => setPropertytype(e.value)}
                      checked={propertytype === "Residential"}
                    />
                    <label htmlFor="propertytype1">Residential</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="propertytype2"
                      name="propertytype"
                      value="Commercia Retail"
                      onChange={(e) => setPropertytype(e.value)}
                      checked={propertytype === "Commercial Retail"}
                    />
                    <label htmlFor="propertytype2">Retail</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="propertytype3"
                      name="propertytype"
                      value="Commercial Office"
                      onChange={(e) => setPropertytype(e.value)}
                      checked={propertytype === "Commercial Office"}
                    />
                    <label htmlFor="propertytype2">Office</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="propertytype4"
                      name="propertytype"
                      value="Industrial"
                      onChange={(e) => setPropertytype(e.value)}
                      checked={propertytype === "Industrial"}
                    />
                    <label htmlFor="propertytype2">Industrial</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="propertytype5"
                      name="propertytype"
                      value="Other"
                      onChange={(e) => setPropertytype(e.value)}
                      checked={propertytype === "Other"}
                    />
                    <label htmlFor="propertytype2">Other</label>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <span className="lbl">Use Equity?</span>
                <div className="d-flex">
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="seEquity1"
                      name="seEquity"
                      value="Y"
                      onChange={(e) => {
                        setUseEquity(e.value);
                        setPercentageEquity(
                          isWhatPercentOf(
                            TotalDepositRequired,
                            equityAvailable
                          ) >= 100
                            ? 100
                            : isWhatPercentOf(
                                TotalDepositRequired,
                                equityAvailable
                              )
                        );
                        if (
                          GS.SOT.PropertyGenerators[0].initialPropertyType ===
                            "Blended" &&
                          props.number === 0
                        ) {
                          blendedUpdateDeposit(
                            useCash === "N" ? 0 : cashAvailable,
                            equityAvailable
                          );
                        } else {
                          let Notice = String(
                            calculateTotalDepositLeft(
                              equityAvailable,
                              useCash === "N" ? 0 : cashAvailable,
                              useExtraCash === "N" ? 0 : extraCash,
                              useLiquidAssets,
                              e.value,
                              useCash,
                              TotalDepositRequired
                            )
                          );
                          setNotice(Notice);
                        }
                      }}
                      checked={useEquity === "Y"}
                    />
                    <label htmlFor="seEquity1">Yes</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="seEquity2"
                      name="seEquity"
                      value="N"
                      onChange={(e) => {
                        setUseEquity(e.value);
                        setPercentageEquity(0);
                        if (
                          GS.SOT.PropertyGenerators[0].initialPropertyType ===
                            "Blended" &&
                          props.number === 0
                        ) {
                          blendedUpdateDeposit(
                            useCash === "N" ? 0 : cashAvailable,
                            0
                          );
                        } else {
                          let Notice = String(
                            calculateTotalDepositLeft(
                              0,
                              useCash === "N" ? 0 : cashAvailable,
                              useExtraCash === "N" ? 0 : extraCash,
                              useLiquidAssets,
                              e.value,
                              useCash,
                              TotalDepositRequired
                            )
                          );
                          setNotice(Notice);
                        }
                      }}
                      checked={useEquity === "N"}
                    />
                    <label htmlFor="seEquity2">No</label>
                  </div>
                </div>
                <div className="w-100 p-1 mb-3">
                  <InputNumber
                    suffix="%"
                    minFractionDigits={0}
                    maxFractionDigits={1}
                    max={100}
                    min={0}
                    value={percentageEquity}
                    className="w-100"
                    placeholder="Percentage Cash"
                    onChange={(e: any) => {
                      setPercentageEquity(e?.value);
                      setUseEquity(e?.value > 0 ? "Y" : "N");

                      if (
                        GS.SOT.PropertyGenerators[0].initialPropertyType ===
                          "Blended" &&
                        props.number === 0
                      ) {
                        blendedUpdateDeposit(
                          useCash === "N" ? 0 : cashAvailable,
                          equityAvailable * (e.value / 100)
                        );
                      } else {
                        let Notice = String(
                          calculateTotalDepositLeft(
                            equityAvailable * (e.value / 100),
                            useCash === "N" ? 0 : cashAvailable,
                            useExtraCash === "N" ? 0 : extraCash,
                            useLiquidAssets,
                            e?.value > 0 ? "Y" : "N",
                            useCash,
                            TotalDepositRequired
                          )
                        );
                        setNotice(Notice);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <span className="lbl">Use Cash</span>
                <div className="d-flex">
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="useCash1"
                      name="useCash"
                      value="Y"
                      onChange={(e) => {
                        setUseCash(e.value);
                        setPercentageCash(
                          isWhatPercentOf(
                            TotalDepositRequired,
                            cashAvailable
                          ) >= 100
                            ? 100
                            : isWhatPercentOf(
                                TotalDepositRequired,
                                cashAvailable
                              )
                        );
                        if (
                          GS.SOT.PropertyGenerators[0].initialPropertyType ===
                            "Blended" &&
                          props.number === 0
                        ) {
                          blendedUpdateDeposit(
                            cashAvailable,
                            useEquity === "N" ? 0 : equityAvailable
                          );
                        } else {
                          let Notice = String(
                            calculateTotalDepositLeft(
                              useEquity === "N" ? 0 : equityAvailable,
                              cashAvailable,
                              useExtraCash === "N" ? 0 : extraCash,
                              useLiquidAssets,
                              useEquity,
                              e.value,
                              TotalDepositRequired
                            )
                          );
                          setNotice(Notice);
                        }
                      }}
                      checked={useCash === "Y"}
                    />
                    <label htmlFor="useCash1">Yes</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="useCash2"
                      name="useCash"
                      value="N"
                      onChange={(e) => {
                        setUseCash(e.value);
                        setPercentageCash(0);
                        if (
                          GS.SOT.PropertyGenerators[0].initialPropertyType ===
                            "Blended" &&
                          props.number === 0
                        ) {
                          blendedUpdateDeposit(
                            0,
                            useEquity === "N" ? 0 : equityAvailable
                          );
                        } else {
                          let Notice = String(
                            calculateTotalDepositLeft(
                              useEquity === "N" ? 0 : equityAvailable,
                              0,
                              useExtraCash === "N" ? 0 : extraCash,
                              useLiquidAssets,
                              useEquity,
                              e.value,
                              TotalDepositRequired
                            )
                          );
                          setNotice(Notice);
                        }
                      }}
                      checked={useCash === "N"}
                    />
                    <label htmlFor="useCash2">No</label>
                  </div>
                </div>
                <div className="w-100 p-1 mb-3">
                  <InputNumber
                    suffix="%"
                    minFractionDigits={0}
                    maxFractionDigits={1}
                    max={100}
                    value={percentageCash}
                    className="w-100"
                    placeholder="Percentage Equity"
                    onChange={(e: any) => {
                      setPercentageCash(e?.value);
                      setUseCash(e.value > 0 ? "Y" : "N");
                      if (
                        GS.SOT.PropertyGenerators[0].initialPropertyType ===
                          "Blended" &&
                        props.number === 0
                      ) {
                        const cash = cashAvailable * (e.value / 100);
                        blendedUpdateDeposit(
                          cash,
                          useEquity === "N" ? 0 : equityAvailable
                        );
                      } else {
                        let Notice = String(
                          calculateTotalDepositLeft(
                            useEquity === "N" ? 0 : equityAvailable,
                            cashAvailable * (e.value / 100),
                            useExtraCash === "N" ? 0 : extraCash,
                            useLiquidAssets,
                            useEquity,
                            e.value > 0 ? "Y" : "N",
                            TotalDepositRequired
                          )
                        );
                        setNotice(Notice);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <span className="lbl">Use Liquid Assets</span>
                <div className="d-flex">
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="useLiquidAssets1"
                      name="useLiquidAssets"
                      value="Y"
                      onChange={(e) => {
                        setUseLiquidAssets(e.value);
                        let Notice = String(
                          calculateTotalDepositLeft(
                            useEquity === "N" ? 0 : equityAvailable,
                            useCash === "N" ? 0 : cashAvailable,
                            useExtraCash === "N" ? 0 : extraCash,
                            e.value,
                            useEquity,
                            useCash,
                            TotalDepositRequired
                          )
                        );
                        setNotice(Notice);
                      }}
                      checked={useLiquidAssets === "Y"}
                    />
                    <label htmlFor="useLiquidAssets1">Yes</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="useLiquidAssets2"
                      name="useLiquidAssets"
                      value="N"
                      onChange={(e) => {
                        setUseLiquidAssets(e.value);
                        let Notice = String(
                          calculateTotalDepositLeft(
                            useEquity === "N" ? 0 : equityAvailable,
                            useCash === "N" ? 0 : cashAvailable,
                            useExtraCash === "N" ? 0 : extraCash,
                            e.value,
                            useEquity,
                            useCash,
                            TotalDepositRequired
                          )
                        );
                        setNotice(Notice);
                      }}
                      checked={useLiquidAssets === "N"}
                    />
                    <label htmlFor="useLiquidAssets2">No</label>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <span className="lbl">Use Extra Cash</span>
                <div className="d-flex">
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="useExtraCash1"
                      name="useExtraCash"
                      value="Y"
                      onChange={(e) => {
                        setUseExtraCash(e.value);
                        setShowDialog(true);
                        let Notice = String(
                          calculateTotalDepositLeft(
                            useEquity === "Y" ? equityAvailable : 0,
                            useCash === "Y" ? cashAvailable : 0,
                            extraCash,
                            useLiquidAssets,
                            useEquity,
                            useCash,
                            TotalDepositRequired
                          )
                        );
                        setNotice(Notice);
                      }}
                      checked={useExtraCash === "Y"}
                    />
                    <label htmlFor="useExtraCash1">Yes</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="useExtraCash2"
                      name="useExtraCash"
                      value="N"
                      onChange={(e) => {
                        setUseExtraCash(e.value);
                        setExtraCash(0);
                        let Notice = String(
                          calculateTotalDepositLeft(
                            useEquity === "Y" ? equityAvailable : 0,
                            useCash === "Y" ? cashAvailable : 0,
                            0,
                            useLiquidAssets,
                            useEquity,
                            useCash,
                            TotalDepositRequired
                          )
                        );
                        setNotice(Notice);
                      }}
                      checked={useExtraCash === "N"}
                    />
                    <label htmlFor="useExtraCash2">No</label>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <p className="lbl mb-3">
                  Total Acquisition @ {cp(slider1)} LVR
                </p>
                <MySlider
                  value={slider1}
                  onChange={(e: any) => {
                    if (e.value < 80) {
                      setMortgageInsuranceApply("N");
                    }

                    setSlider1(e.value);
                    setLoantoValueRatioLVR(e.value / 100);
                    const newDebt =
                      parseFloat(PurchasePrice) *
                      parseFloat(String(e.value / 100));
                    setTotalDebt(newDebt);
                    const newDeposit = parseFloat(PurchasePrice) - newDebt;
                    const totalDepositReq =
                      newDeposit +
                      parseFloat(AcquisitionCost) +
                      parseFloat(StampDuty);
                    setTotalDepositRequired(totalDepositReq);
                    setDeposit(newDeposit);
                  }}
                />
                <div className="d-flex">
                  <span className=" mt-4">{slider1}%</span>
                  <InputText
                    style={{ width: "5rem" }}
                    className="ml-auto mt-4"
                    value={slider1}
                    disabled
                    onChange={(e: any) => {
                      setSlider1(e?.target?.value);
                    }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <p className="lbl mb-3">Risk Factored Yield Return</p>
                <MySlider
                  min={5}
                  max={7}
                  step={0.01}
                  value={targetYieldReturn}
                  onChange={(e: any) => {
                    setTargetYieldReturn(e.value);
                    updateTargetYield(
                      e.value,
                      AppliedGrowthRateannualPERC,
                      AppliedInterestRate
                    );
                    if (e.value >= 5 && e.value < 6) {
                      setPropertytype("Residential");
                    } else if (e.value >= 6 && e.value < 7) {
                      setPropertytype("Commercial Retail");
                    } else {
                      setPropertytype("Commercial Office");
                    }
                  }}
                />
                <div className="d-flex">
                  <span className=" mt-4">
                    {targetYieldReturn?.toFixed(2)}%
                  </span>
                  <InputText
                    disabled
                    style={{ width: "5rem" }}
                    className="ml-auto mt-4"
                    value={targetYieldReturn?.toFixed(2)}
                    onChange={(e: any) => {
                      setTargetYieldReturn(e?.target?.value);
                    }}
                  />
                </div>
                <div className="d-flex">
                  <div className="eclipse"></div>
                  <span className="fixed-percentage">Fixed percentage</span>
                </div>
              </div>
              <div className="mb-3">
                <p className="lbl mb-3">Debt Interest Rate</p>
                <MySlider
                  step={1}
                  min={0}
                  max={10}
                  value={AppliedInterestRate}
                  onChange={(e: any) => {
                    updateTargetYield(
                      targetYieldReturn,
                      AppliedGrowthRateannualPERC,
                      e.value
                    );
                    setAppliedInterestRate(e.value);
                  }}
                />
                <div className="d-flex">
                  <span className=" mt-4">
                    {AppliedInterestRate?.toFixed(2)}%
                  </span>
                  <InputNumbers
                    mode="decimal"
                    minFractionDigits={1}
                    style={{ width: "5rem" }}
                    className="ml-auto mt-4"
                    onFocus={(e: any) => (e.target.value = "")}
                    value={AppliedInterestRate?.toFixed(2)}
                    onChange={(e: any) => {
                      updateTargetYield(
                        targetYieldReturn,
                        AppliedGrowthRateannualPERC,
                        e.value
                      );
                      setAppliedInterestRate(e.value);
                    }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <p className="lbl mb-3">Equity Interest Rate </p>
                <MySlider
                  step={1}
                  min={0}
                  max={10}
                  value={AppliedGrowthRateannualPERC}
                  onChange={(e: any) => {
                    updateTargetYield(
                      targetYieldReturn,
                      e.value,
                      AppliedInterestRate
                    );
                    setAppliedGrowthRateannualPERC(e.value);
                  }}
                />
                <div className="d-flex">
                  <span className=" mt-4">
                    {AppliedGrowthRateannualPERC?.toFixed(2)}%
                  </span>
                  <InputNumbers
                    onFocus={(e: any) => (e.target.value = "")}
                    onBlur={(e: any) =>
                      (e.target.value = AppliedGrowthRateannualPERC?.toFixed(2))
                    }
                    minFractionDigits={1}
                    style={{ width: "5rem" }}
                    className="ml-auto mt-4"
                    value={AppliedGrowthRateannualPERC?.toFixed(2)}
                    onChange={(e: any) => {
                      updateTargetYield(
                        targetYieldReturn,
                        e.value,
                        AppliedInterestRate
                      );
                      setAppliedGrowthRateannualPERC(e.value);
                      const equityGain =
                        (PurchasePrice / 100) * parseInt(String(e.value));
                      setEquityGainPERCorDOLLAR(equityGain);
                    }}
                  />
                </div>
                <div className="d-flex">
                  <div className="eclipse"></div>
                  <span className="fixed-percentage">Fixed percentage</span>
                </div>
              </div>
              <div className="mb-4">
                <span className="lbl">Mortgage Insurance apply?</span>
                <div className="d-flex">
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="mortgageInsuranceApply1"
                      name="mortgageInsuranceApply"
                      value="Y"
                      onChange={(e) => setMortgageInsuranceApply(e.value)}
                      checked={mortgageInsuranceApply === "Y"}
                    />
                    <label htmlFor="useExtraCash1">Yes</label>
                  </div>
                  <div className="field-radiobutton radiomargin">
                    <RadioButton
                      inputId="mortgageInsuranceApply2"
                      name="mortgageInsuranceApply"
                      value="N"
                      onChange={(e) => setMortgageInsuranceApply(e.value)}
                      checked={mortgageInsuranceApply === "N"}
                    />
                    <label htmlFor="useExtraCash2">No</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center">
              {lockedPropeties.PropertyGenerators?.length > 0 &&
              lockedPropeties?.PropertyGenerators[props.number]?.isLocked ===
                true ? (
                <GradientButton txt={"Updates Locked"} onClick={() => {}} />
              ) : (
                <GradientButton
                  txt={"Lock Updates"}
                  onClick={() => {
                    setLoading(true);
                    lockSummary();
                    setTimeout(() => {
                      setLoading(false);
                    }, 2000);
                  }}
                />
              )}
              {loading == true && (
                <div className="px-1">
                  <Loading height="0" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div style={{ display: "none" }} className="propertygenerator">
          <table className="w-50" style={{ border: "2px solid black" }}>
            <thead>
              <tr>
                <th
                  colSpan={1}
                  className="text-center"
                  style={{ border: "2px solid black" }}
                >
                  <p>
                    <span>1 x Commercial Property Portfolio</span>
                  </p>
                </th>
              </tr>
              <tr style={{ backgroundColor: "#1638e0", color: "white" }}>
                <th>
                  <p>
                    <span>Feasibility Markers</span>
                  </p>
                </th>
                <th>
                  <p>
                    <span>Property 1</span>
                  </p>
                </th>
                <th style={{ borderLeft: "2px solid black" }}>
                  <p>
                    <span>TOTAL</span>
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>
                    <span>{A45} </span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{B45}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{B45}</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>{A46}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{B46}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>&nbsp;</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>Stamp Duty</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{B47}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>&nbsp;</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>Solicitor + Valuation</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{B48}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>&nbsp;</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>Building Report*</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{B49}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>&nbsp;</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>Loan Arrangement Fee *</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{B50}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>&nbsp;</span>
                  </p>
                </td>
              </tr>
              <tr style={{ backgroundColor: "#ffd26d" }}>
                <td>
                  <p>
                    <span>Total Cash Required</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{B51}</span>
                  </p>
                </td>
                <td style={{ backgroundColor: "#02ae02", color: "white" }}>
                  <p>
                    <span>{C51}</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>Total Purchase Costs</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{B52}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>&nbsp;</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>Net Annual Cashflow Return (Year 1)</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{B53}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>&nbsp;</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>Net Yield on Property (Year 1)</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{B54}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>&nbsp;</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>Net Yield on Total Purchase Costs (Year 1)</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{B55}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>&nbsp;</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td
                  colSpan={1}
                  style={{ backgroundColor: "#1638e0", color: "white" }}
                >
                  <p>
                    <span>Cash on Cash Return</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>&nbsp;</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>Total Cash Required  (20% + Costs)</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{B57}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>&nbsp;</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>cost of loan @{A58}%pa on leverage</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{B58}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>&nbsp;</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>Cashflow after mortage costs</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{B59}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{C59}</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>Cashflow after mortgage (Monthly)</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{B60}</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>&nbsp;</span>
                  </p>
                </td>
              </tr>
              <tr style={{ backgroundColor: "#ffd26d" }}>
                <td>
                  <p>
                    <span>Return on Equity (pure cashflow return)</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{B61}</span>
                  </p>
                </td>
                <td style={{ backgroundColor: "var(--white)" }}>
                  <p>
                    <span>&nbsp;</span>
                  </p>
                </td>
              </tr>
              <tr style={{ backgroundColor: "#ffd26d" }}>
                <td>
                  <p>
                    <span>
                      Return on Equity with a {A62}.00% capital growth rate
                    </span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{B62}</span>
                  </p>
                </td>
                <td style={{ backgroundColor: "var(--white)" }}>
                  <p>
                    <span>&nbsp;</span>
                  </p>
                </td>
              </tr>
              <tr style={{ backgroundColor: "#ffd26d" }}>
                <td>
                  <p>
                    <span>
                      Return on Equity with a {A63}.00% capital growth rate
                    </span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{B63}</span>
                  </p>
                </td>
                <td style={{ backgroundColor: "var(--white)" }}>
                  <p>
                    <span>&nbsp;</span>
                  </p>
                </td>
              </tr>
              <tr style={{ backgroundColor: "#ffd26d" }}>
                <td>
                  <p>
                    <span>
                      Return on Equity with a {A64}.00% capital growth rate
                    </span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>{B64}</span>
                  </p>
                </td>
                <td style={{ backgroundColor: "var(--white)" }}>
                  <p>
                    <span>&nbsp;</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="button-container">
        <div>
          <GradientButton
            txt={"Next"}
            onClick={() => {
              publish("PropertyActivate", null);
              window.scroll(0, 200);
            }}
          />
          <GradientButton
            style={{
              position: "fixed",
              bottom: "24px",
              right: "180px",
            }}
            onClick={() => {
              // Navigate(-1);
              props.onBack();
            }}
            txt="Back"
          />
        </div>
      </div>
    </>
  );
}
export default PropertyGenerator;
