const initialState = {
  open: {
    Vendors: false,
    "Portfolio Summary": false,
    Contacts: false,
    "Agency Profile": false,
    "Lifestyle Goals": false,
    Settings: false,
    "Pinder 2.0": false,
  },
};

const SubSidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SUBSIDEBAR":
      let updated = action.payload;
      return { ...state, open: updated };

    default:
      return state;
  }
};
export default SubSidebarReducer;
