import styled from "styled-components";
export const VendorsListContainer = styled.div`
  margin: 51px 32px 59px;
  padding: 48px 0px 48px 0px;
  background-color: var(--white);
  box-shadow: 0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02),
    0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02),
    0 19px 122px 0 rgba(7, 62, 132, 0.04);
  border-radius: 8px;
  box-sizing: border-box;
  margin-left: 1rem;
  margin-right: 1rem;
  .profile-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap:15px;
    align-items: center;
    margin-bottom: 38px;
    padding-right: 48px;
    padding-left: 26px;
    h1 { 
      font-family: Inter;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--grey-dark);
    }
  }
  .pagination-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 32px;
  }
  a {
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--grey-dark);
    text-decoration: none;
  }
  table {
    thead {
      tr {
        th {
          font-family: Inter;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--grey-dark);
        }
        th: last-child{
          width:20px !important;
          padding-right:24px;
        }
        .name {
          padding-left: 81px;
        }
      }
    }
    tbody {
      tr {
        td {
          font-family: Inter;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          box-sizing: border-box;
          color: var(--grey-dark);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border-radius:unset;
          cursor: pointer;
          height: 60px;
          vertical-align: middle;
        }
        td: last-child{
          // max-width:35px !important;
          // text-align:right !important;
        }
        .name {

          font-weight: 600;
          padding-left: 32px;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 18px;
        }
        .progress {
          display: flex;
          height:40px;
          width:35px;
          background-color:#fff;
        }
        }
        .avatar-column {
          display: flex;
          align-items: center;
          gap: 18px;
          padding-left: 16px;
          font-weight: 600;
        }
      }
    }

`;
