
import { GS } from "../services/global";
import { API } from "../services/api";

const SeparateCamelCase = (text = "") => {
  return text?.replace(/([a-z])([A-Z])/g, "$1 $2");
};

const parseErrors = (errObj) => {
  try {
    let message = "";
    switch (errObj.status) {
      case 401:
        if (!window.location.href.includes("login")) {
          sessionStorage.removeItem("Token");
          window.location.href = "/";
        }
        return {
          success: false,
          message:
            errObj.data && errObj.data.message
              ? errObj.data.message
              : "You are not authorized. Please login",
        };
      case 403:
      case 404:
      case 409:
      case 422:
        if (errObj.data && errObj.data.message) {
          message = errObj.data.message;
        } else if (errObj.message) {
          message = errObj.message;
        } else {
          message = "An error occured while processing your request.";
        }
        return {
          success: false,
          message,
        };

      default:
        return {
          success: false,
          message: "An error occured while processing your request.",
        };
    }
  } catch (error) {
    return {
      success: false,
      message: "An error occured while processing your request.",
    };
  }
};



let CurrencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
const ValidateName = (name) => {
  if (name.length === 0) {
    return false;
  } else {
    return true;
  }
};
const ValidateEmail = (mail) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) { //eslint-disable-line
    return true;
  }

  return false;
};

const validateImage = (img) => {
  const base64regex = new RegExp("/[A-Za-z0-9+/=]/");
  if (base64regex.test(img)) {
    return true;
  } else {
    return false;
  }
};

const commaSeparators = (num) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
  }).format(num)
};

const _commaSeparators = (num) => {
  num = num ? num : 0;
  let numParts = num.toString().split(".");
  numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const result = numParts.join(".");
  return result.includes(".")
    ? `$${result.split(".")[0]}.${result.split(".")[1].substring(0, 2)}`
    : `$${result}.00`;
};
const ChangeStatus = async () => {
  if (GS.SOT.Progress.completed === 5 || GS.SOT.Progress.completed === 6) {
    GS.SOT.Progress.completed = 4;
  }
  await API.AutoSave(window.location.href);
  return GS.SOT.Progress.completed;
};
const PMT = (rate, nper, pv, fv, type) => {
  if (!fv) fv = 0;
  if (!type) type = 0;

  if (rate === 0) return -(pv + fv) / nper;

  var pvif = Math.pow(1 + rate, nper);
  var pmt = (rate / (pvif - 1)) * -(pv * pvif + fv);

  if (type === 1) {
    pmt /= 1 + rate;
  }

  return pmt;
};
const CalculateIMPT = (rate, per, nper, pv, fv, type) => {
  if (per < 1 || per >= nper + 1) return null;
  var pmt = PMT(rate, nper, pv, fv, type);
  var ipmt = IPMT(pv, pmt, rate, per - 1);
  return ipmt;
};
const IPMT = (pv, pmt, rate, per) => {
  var tmp = Math.pow(1 + rate, per);
  return 0 - (pv * tmp * rate + pmt * (tmp - 1));
};
const PPMT = (rate, per, nper, pv, fv, type) => {
  if (per < 1 || per >= nper + 1) return null;
  var pmt = PMT(rate, nper, pv, fv, type);
  var ipmt = IPMT(pv, pmt, rate, per - 1);
  return pmt - ipmt;
};
const PMTCalculate = (rate, per, nper, pv, fv, type) => {
  var pmt = PMT(rate, nper, pv, fv, type);
  return pmt;
};
const getRate = (loan, LVR) => {
  if (loan < 300000) {
    if (LVR >= 80 && LVR <= 81) {
      return 0.48;
    } else if (LVR > 81 && LVR <= 82) {
      return 0.49;
    } else if (LVR > 82 && LVR <= 83) {
      return 0.6;
    } else if (LVR > 83 && LVR <= 84) {
      return 0.66;
    } else if (LVR > 84 && LVR <= 85) {
      return 0.73;
    } else if (LVR > 85 && LVR <= 86) {
      return 0.88;
    } else if (LVR > 86 && LVR <= 87) {
      return 0.93;
    } else if (LVR > 87 && LVR <= 88) {
      return 1.06;
    } else if (LVR > 88 && LVR <= 89) {
      return 1.3;
    } else if (LVR > 89 && LVR <= 90) {
      return 1.46;
    } else if (LVR > 90 && LVR <= 91) {
      return 2.01;
    } else if (LVR > 91 && LVR <= 92) {
      return 2.01;
    } else if (LVR > 92 && LVR <= 93) {
      return 2.33;
    } else if (LVR > 93 && LVR <= 94) {
      return 2.38;
    } else if (LVR > 94 && LVR <= 95) {
      return 2.61;
    }
  }
  else if (loan === 300000) {
    if (LVR >= 80 && LVR <= 81) {
      return 0.48;
    } else if (LVR > 81 && LVR <= 82) {
      return 0.49;
    } else if (LVR > 82 && LVR <= 83) {
      return 0.6;
    } else if (LVR > 83 && LVR <= 84) {
      return 0.66;
    } else if (LVR > 84 && LVR <= 85) {
      return 0.73;
    } else if (LVR > 85 && LVR <= 86) {
      return 0.88;
    } else if (LVR > 86 && LVR <= 87) {
      return 0.93;
    } else if (LVR > 87 && LVR <= 88) {
      return 1.06;
    } else if (LVR > 88 && LVR <= 89) {
      return 1.3;
    } else if (LVR > 89 && LVR <= 90) {
      return 1.46;
    } else if (LVR > 90 && LVR <= 91) {
      return 2.01;
    } else if (LVR > 91 && LVR <= 92) {
      return 2.01;
    } else if (LVR > 92 && LVR <= 93) {
      return 2.33;
    } else if (LVR > 93 && LVR <= 94) {
      return 2.38;
    } else if (LVR > 94 && LVR <= 95) {
      return 2.61;
    }
  } else if (loan > 300000 && loan <= 500000) {
    if (LVR >= 80 && LVR <= 81) {
      return 0.57;
    } else if (LVR > 81 && LVR <= 82) {
      return 0.57;
    } else if (LVR > 82 && LVR <= 83) {
      return 0.7;
    } else if (LVR > 83 && LVR <= 84) {
      return 0.83;
    } else if (LVR > 84 && LVR <= 85) {
      return 0.97;
    } else if (LVR > 85 && LVR <= 86) {
      return 1.08;
    } else if (LVR > 86 && LVR <= 87) {
      return 1.15;
    } else if (LVR > 87 && LVR <= 88) {
      return 1.31;
    } else if (LVR > 88 && LVR <= 89) {
      return 1.62;
    } else if (LVR > 89 && LVR <= 90) {
      return 1.87;
    } else if (LVR > 90 && LVR <= 91) {
      return 2.62;
    } else if (LVR > 91 && LVR <= 92) {
      return 2.67;
    } else if (LVR > 92 && LVR <= 93) {
      return 3.03;
    } else if (LVR > 93 && LVR <= 94) {
      return 3.03;
    } else if (LVR > 94 && LVR <= 95) {
      return 3.35;
    }
  } else if (loan > 500000 && loan <= 600000) {
    if (LVR >= 80 && LVR <= 81) {
      return 0.9;
    } else if (LVR > 81 && LVR <= 82) {
      return 0.9;
    } else if (LVR > 82 && LVR <= 83) {
      return 0.93;
    } else if (LVR > 83 && LVR <= 84) {
      return 0.96;
    } else if (LVR > 84 && LVR <= 85) {
      return 1.17;
    } else if (LVR > 85 && LVR <= 86) {
      return 1.26;
    } else if (LVR > 86 && LVR <= 87) {
      return 1.41;
    } else if (LVR > 87 && LVR <= 88) {
      return 1.46;
    } else if (LVR > 88 && LVR <= 89) {
      return 1.95;
    } else if (LVR > 89 && LVR <= 90) {
      return 2.18;
    } else if (LVR > 90 && LVR <= 91) {
      return 3.51;
    } else if (LVR > 91 && LVR <= 92) {
      return 3.57;
    } else if (LVR > 92 && LVR <= 93) {
      return 3.8;
    } else if (LVR > 93 && LVR <= 94) {
      return 3.8;
    } else if (LVR > 94 && LVR <= 95) {
      return 4.0;
    }
  } else if (loan > 600000 && loan <= 750000) {
    if (LVR >= 80 && LVR <= 81) {
      return 0.9;
    } else if (LVR > 81 && LVR <= 82) {
      return 0.9;
    } else if (LVR > 82 && LVR <= 83) {
      return 1.09;
    } else if (LVR > 83 && LVR <= 84) {
      return 1.09;
    } else if (LVR > 84 && LVR <= 85) {
      return 1.33;
    } else if (LVR > 85 && LVR <= 86) {
      return 1.41;
    } else if (LVR > 86 && LVR <= 87) {
      return 1.63;
    } else if (LVR > 87 && LVR <= 88) {
      return 1.63;
    } else if (LVR > 88 && LVR <= 89) {
      return 2.22;
    } else if (LVR > 89 && LVR <= 90) {
      return 2.37;
    } else if (LVR > 90 && LVR <= 91) {
      return 3.78;
    } else if (LVR > 91 && LVR <= 92) {
      return 3.87;
    } else if (LVR > 92 && LVR <= 93) {
      return 4.08;
    } else if (LVR > 93 && LVR <= 94) {
      return 4.29;
    } else if (LVR > 94 && LVR <= 95) {
      return 4.61;
    }
  } else if (loan > 750000 && loan <= 1000000) {
    if (LVR >= 80 && LVR <= 81) {
      return 0.91;
    } else if (LVR > 81 && LVR <= 82) {
      return 0.91;
    } else if (LVR > 82 && LVR <= 83) {
      return 1.11;
    } else if (LVR > 83 && LVR <= 84) {
      return 1.15;
    } else if (LVR > 84 && LVR <= 85) {
      return 1.41;
    } else if (LVR > 85 && LVR <= 86) {
      return 1.46;
    } else if (LVR > 86 && LVR <= 87) {
      return 1.73;
    } else if (LVR > 87 && LVR <= 88) {
      return 1.75;
    } else if (LVR > 88 && LVR <= 89) {
      return 2.4;
    } else if (LVR > 89 && LVR <= 90) {
      return 2.52;
    } else if (LVR > 90 && LVR <= 91) {
      return 3.82;
    } else if (LVR > 91 && LVR <= 92) {
      return 3.93;
    } else if (LVR > 92 && LVR <= 93) {
      return 4.16;
    } else if (LVR > 93 && LVR <= 94) {
      return 4.32;
    } else if (LVR > 94 && LVR <= 95) {
      return 4.6;
    }
  }else if ( loan > 1000000) {
    if (LVR >= 80 && LVR <= 81) {
      return 0.91;
    } else if (LVR > 81 && LVR <= 82) {
      return 0.91;
    } else if (LVR > 82 && LVR <= 83) {
      return 1.11;
    } else if (LVR > 83 && LVR <= 84) {
      return 1.15;
    } else if (LVR > 84 && LVR <= 85) {
      return 1.41;
    } else if (LVR > 85 && LVR <= 86) {
      return 1.46;
    } else if (LVR > 86 && LVR <= 87) {
      return 1.73;
    } else if (LVR > 87 && LVR <= 88) {
      return 1.75;
    } else if (LVR > 88 && LVR <= 89) {
      return 2.4;
    } else if (LVR > 89 && LVR <= 90) {
      return 2.52;
    } else if (LVR > 90 && LVR <= 91) {
      return 3.82;
    } else if (LVR > 91 && LVR <= 92) {
      return 3.93;
    } else if (LVR > 92 && LVR <= 93) {
      return 4.16;
    } else if (LVR > 93 && LVR <= 94) {
      return 4.32;
    } else if (LVR > 94 && LVR <= 95) {
      return 4.6;
    }
  }
   
};

export {
  CurrencyFormatter,
  SeparateCamelCase,
  commaSeparators,
  parseErrors,
  ValidateEmail,
  validateImage,
  ValidateName,
  PMTCalculate,
  PPMT,
  CalculateIMPT,
  ChangeStatus,
  getRate,
};
