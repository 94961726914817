/* eslint no-eval: 0 */
import moment from "moment";
import { API } from "./api";
class GlobalService {
  public title = "PropVest";
  public isFetchedSOT = false;
  public PortfolioSummarytableData: any = [
    ["Purchase Price"],
    ["Deposit assuming leverage @ 20.00%"],
    ["Stamp Duty"],
    ["Solicitor + Valuation"],
    ["Building Report*"],
    ["Loan Arrangement Fee *"],
    ["Total Cash Required"],
    ["Total Purchase Costs"],
    ["Net Annual Cashflow Return (Year 1)"],
    ["Net Yield on Property (Year 1)"],
    ["Net Yield on Total Purchase Costs (Year 1)"],
    ["Cash on Cash Return"],
    ["Total Cash Required  (20% + Costs)"],
    ["Cost of Loan @ 3.5%pa on leverage"],
    ["Cashflow after mortage costs"],
    ["Cashflow after mortgage (Monthly)"],
    ["Return on Equity (pure cashflow return)"],
    ["Return on Equity with a 5.0% capital growth rate"],
    ["Return on Equity with a 7.0% capital growth rate"],
    ["Return on Equity with a 10.0% capital growth rate"],
  ];
  public update_user: any = {
    first_name: "",
    last_name: "",
    email: "",

    mobile_no: "",
    telephone_no: "",
    contact_address: "",
    enquiry_source: "",
    contact_type: "",
  };
  public user_id: any = "";
  public TotalMortgage: any = 0;

  public SOT: any = {
    VendorId: null,
    Sheets: {},
    ai_matrix: {
      Scenario: [1, 3, 3, 2, 1, 1, 3, 3, 2, 1],
      Dependant: 7000,
      BaseExpense: [17800, 26040],

      BuildingReport: 1200,
      PurchasePriceDeviation: 5.3,
      RiskPercentage: { 1: 5, 2: 6, 3: 7 },
    },
    PortfolioSummary: {
      isLocked: false,
      tableData: [],
      Properties: {
        retail: [],
        office: [],
        residential: [],
        other: [],
        industry: [],
      },
    },
    PropertyGenerators: [],

    BorrowingCalculator: {
      income_goal: 0,
      FinancialYear: 0,
      salary_after_tax: 0,
      salary_after_tax_term: "Annually",
      NetRentalIncome_term: "Annually",
      other_income_net: 0,
      other_income_net_term: "Annually",
      partner_salary_after_tax: 0,
      partner_salary_after_tax_term: "Annually",
      dependants: 0,
      car_loan_payment: 0,
      car_loan_payment_2: 0,
      car_loan_payment_2_term: "Monthly",
      annual_expenses: 0,
      annual_expenses_2: 0,
      other_payments: 0,
      other_payments_2: 0,
      other_payments_2_term: "Monthly",
      uaaae: true,
      credit_card_limit: 0,
      credit_card_limit_2: 0,
      credit_card_limit_2_term: "Annually",
      existing_home_loan: 0,
      existing_home_loan_2_term: "Monthly",
      interest_rate: 0,
      loan_term: 0,
      uirb: false,
      base_expanse: 0,
      dependant_base_expanse: 0,
      servicable_income_factor: 0,
      default_bp_interest_rate: 0,
      can_borrow1: 0,
      monthly_repayment: 0,
      can_borrow2: 0,
      monthly_repayment2: 0,
      period: "Annually",
      value1: 0,
      value2: 0,
      Checked: false,
      InterestOnly: false,
      PrincipalInterest: false,
      IncomeType: "Net",
      IncomeGoalStatus: false,
      HECSPayments: {
        type: "Annualy",
        balance: 0,
      },
      HECS: 0,
    },
    Equity: {
      AnnualInterestRate: "5%",
      InterestRateBuffer: 0,
      TequityGain: 0,
      Cash: {
        type: "Net",
        value: 0,
      },
      LiquidAssets: {
        type: "Net",
        value: 0,
      },
      PPR: {
        Address: "",
        PPRMortgage: {
          type: "Annually",
          value: 0,
        },
        PPRInterestRate: "0",
        PurchaseDate: "",
        RemainingLoanTerm: 0,
        LoanTerm: 30,
        DomainAutoVal: "",
        HomeLoanRepayments: 0,
        HomeLoanRepaymentType: "Monthly",
        interest: false,
        PrincipleInterest: true,
        totalEquity: 0,
        PPREquity: 0,
        totalAvailableEquity: 0,
      },
      properties: [],
    },
    StrategicSpending: {
      balanceSheet: 0,
      cashOnHand: 0,
      accountYour: 0,
      accountPartner: 0,
      liabilities: 0,
      creditCardYours: 0,
      creditCardPartner: 0,
      monthlyCashflow: 0,
      income: 0,
      netSalaryYour: 0,
      netSalaryPartner: 0,
      rentalIncome: 0,
      expenses: 0,
      AutoTransport: 0,
      BillsUtilities: 0,
      Education: 0,
      Entertainment: 0,
      Equipment: 0,
      FeesCharges: 0,
      FinancialAccounting: 0,
      FoodDining: 0,
      GiftsDonations: 0,
      HealthFitness: 0,
      HealthInsurance: 0,
      LoansCreditCards: 0,
      Misc: 0,
      RentMortgageProperty: 0,
      Shopping: 0,
      Travel: 0,
      CarLoan: 0,
      Data: [],
    },
    Progress: {
      steps: 5,
      completed: 0,
      current: 1,
    },
    RiskProfile: {
      NetYieldonProperty: "5%",
      initialPropertyType: "Residential",
    },
  };

  public initPortfolioTable() {
    this.SOT.PropertyGenerators = [];
    this.SOT.PortfolioSummary.isLocked = false;
    this.SOT.PortfolioSummary.tableData = [...this.PortfolioSummarytableData];
    for (let i = 1; i <= 1; i++) {
      this.SOT.PropertyGenerators.push({
        liquidAssets: 0,
        netIncome: 0,
        extraCash: 0,
        cashAvailable: 0,
        useEquity: "Y",
        equityAvailable: 0,
        percentageEquity: 80,
        totalAvailableFunds: 0,
        useCash: "Y",
        slider1: 80,
        percentageCash: 100,
        totalAcquisitionlabel: 0,
        useLiquidAssets: "N",
        totalAcquisition: 0,
        targetYieldReturn: 5,
        useExtraCash: "N",
        slider2: 0,
        totalAssetsRequiredLeveragedLabel: 0,
        totalAssetsRequiredLeveraged: 0,
        cashRequiredLeveragedLabel: 0,
        cashRequiredLeveraged: 0,
        hidden: false,
        // methodforPurchasePrice: "Target estimator",
        methodforPurchasePrice: "Maximum borrowing capacity + 20% LVR",
        tableData: {
          PurchasePrice: 0,
          Depositassumingleverage20: 0,
          StampDuty: 0,
          SolicitorValuation: 0,
          BuildingReport: 0,
          LoanArrangementFee: 0,
          TotalCashRequired: 0,
          TotalPurchaseCosts: 0,
          NetAnnualCashflowReturnYear1: 0,
          NetYieldonPropertyYear1: 0,
          NetYieldonTotalPurchaseCostsYear1: 0,
          CashonCashReturn: 0,
          TotalCashRequired20Costs: 0,
          CostofLoan5paonleverage: 0,
          Cashflowaftermortagecosts: 0,
          CashflowaftermortgageMonthly: 0,
          ReturnonEquitypurecashflowreturn: 0,
          ReturnonEquitywitha5capitalgrowthrate: 0,
          ReturnonEquitywitha7capitalgrowthrate: 0,
          ReturnonEquitywitha10capitalgrowthrate: 0,
        },
        summaryData: {
          PurchasePrice: 0,
          Depositassumingleverage20: 0,
          AcquisitionCost: 0,
          TotalEquityRequired: 0,
          CurrentEquity: 0,
          TotalPurchaseCosts: 0,
          NetAnnualCashflowReturnYear1: 0,
          NetYieldonPropertyYear1: 0,
          CashonCashReturn: 0,
          TotalCashRequired20Costs: 0,
          CostofLoan5paonleverage: 0,
          Cashflowaftermortagecosts: 0,
          CashflowaftermortgageMonthly: 0,
          ReturnonEquitypurecashflowreturn: 0,
        },
        G3: "",
        A44: 0,
        F44: 0,
        C44: 0,
        A45: "",
        B45: 0,
        A46: 0,
        B46: 0,
        B47: 0,
        B48: 0,
        B49: 0,
        B50: 0,
        B51: 0,
        C51: 0,
        B52: 0,
        B53: 0,
        B54: 0,
        B55: 0,
        B56: 0,
        B57: 0,
        A58: 0,
        B58: 0,
        B59: 0,
        C59: 0,
        B60: 0,
        B61: 0,
        A62: 0,
        B62: 0,
        A63: 0,
        B63: 0,
        A64: 0,
        B64: 0,
      });
    }
  }

  private _lookforkey = (thekey: any, data: any) => {
    let itmIdxByName = null;
    for (let i = 0; i < data.length; i++) {
      if (data[i][0] === thekey) {
        itmIdxByName = i;
        i = 9999999;
      }
    }
    return itmIdxByName;
  };
  private _generatePortfolioSummaryData = () => {
    let PortfolioSummarytableData = [
      ["Property Type"],
      ["Region"],
      ["Purchase Price"],
      ["Deposit"],
      ["Stamp Duty(5.5%)"],
      ["Acquisition Cost"],
      ["LMI"],
      ["Total Deposit Required"],
      ["Total Purchase Costs"],
      ["FINANCE"],
      ["Loan to Value Ratio(LVR)"],
      ["Total Debt"],
      ["Total Deposit Equity"],
      ["Total Deposit Cash"],
      ["Applied Interest Rate"],
      ["CASHFLOW"],
      ["Property Rent Income (Year 1)"],
      ["Total Cost of Lending"],
      ["Cashflow (Annual)"],
      ["Cashflow (Monthly)"],
      ["Return on Equity (COC)"],
      ["GROWTH HACKING"],
      ["Applied Growth Rate (annual)(%)"],
      ["Equity Gain($)"],
      ["Cash Gain($)"],
    ];
    const pGenerators: any = this.SOT.PropertyGenerators;

    this.SOT.PortfolioSummary.tableData = [...PortfolioSummarytableData];

    for (let i = 0; i <= this.SOT.PortfolioSummary.tableData.length - 1; i++) {
      if (i === 11) {
        for (let z = 0; z <= this.SOT.PropertyGenerators.length; z++) {
          this.SOT.PortfolioSummary.tableData[i].push("");
        }
      } else {
        for (let z = 0; z <= this.SOT.PropertyGenerators.length; z++) {
          this.SOT.PortfolioSummary.tableData[i].push(0);
        }
      }
    }
    let tempTableData: any = [...this.SOT.PortfolioSummary.tableData];
    for (let i = 0; i <= this.SOT.PropertyGenerators.length - 1; i++) {
      for (let propidx = 0; propidx < pGenerators.length; propidx++) {
        let itmIdx = this._lookforkey("Property Type", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["propertyType"];
        }
        itmIdx = itmIdx = this._lookforkey("Region", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["region"];
        }

        itmIdx = itmIdx = this._lookforkey("Purchase Price", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["PurchasePrice"];
        }

        itmIdx = itmIdx = this._lookforkey("Deposit", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["Deposit"];
        }

        itmIdx = itmIdx = this._lookforkey("Stamp Duty(5.5%)", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["StampDuty"];
        }

        itmIdx = itmIdx = this._lookforkey("Acquisition Cost", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["AcquisitionCost"];
        }

        itmIdx = itmIdx = this._lookforkey("LMI", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["LMI"];
        }
        itmIdx = itmIdx = this._lookforkey(
          "Total Deposit Required",
          tempTableData
        );
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["TotalDepositRequired"];
        }

        itmIdx = itmIdx = this._lookforkey(
          "Total Purchase Costs",
          tempTableData
        );
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["TotalPurchaseCosts"];
        }

        itmIdx = itmIdx = this._lookforkey(
          "Loan to Value Ratio(LVR)",
          tempTableData
        );
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["LoantoValueRatioLVR"] * 100;
        }

        itmIdx = itmIdx = this._lookforkey("Total Debt", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["TotalDebt"];
        }

        itmIdx = itmIdx = this._lookforkey(
          "Total Deposit Equity",
          tempTableData
        );
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["TotalDepositEquity"];
        }

        itmIdx = itmIdx = this._lookforkey("Total Deposit Cash", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["TotalDepositCash"];
        }

        itmIdx = itmIdx = this._lookforkey(
          "Applied Interest Rate",
          tempTableData
        );
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["AppliedInterestRate"];
        }

        itmIdx = itmIdx = this._lookforkey(
          "Property Rent Income (Year 1)",
          tempTableData
        );
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["PropertyRentIncomeYear1"];
        }

        itmIdx = itmIdx = this._lookforkey(
          "Total Cost of Lending",
          tempTableData
        );
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["TotalCostofLending"];
        }

        itmIdx = itmIdx = this._lookforkey("Cashflow (Annual)", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["CashflowAnnual"];
        }

        itmIdx = itmIdx = this._lookforkey("Cashflow (Monthly)", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["CashflowMonthly"];
        }

        itmIdx = itmIdx = this._lookforkey(
          "Return on Equity (COC)",
          tempTableData
        );
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["ReturnonEquityCOC"];
        }

        itmIdx = itmIdx = this._lookforkey(
          "Applied Growth Rate (annual)(%)",
          tempTableData
        );
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["AppliedGrowthRateannualPERC"];
        }

        itmIdx = itmIdx = this._lookforkey("Equity Gain($)", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["EquityGainPERCorDOLLAR"];
        }

        itmIdx = itmIdx = this._lookforkey("Cash Gain($)", tempTableData);
        if (itmIdx !== null) {
          tempTableData[itmIdx][propidx + 1] =
            pGenerators[propidx].summaryData["CashGainDOLLAR"];
        }
      }

      let tmpT: any = [];
      try {
        for (let idx = 1; idx <= tempTableData.length; idx++) {
          let row = tempTableData[idx];
          let col = 0;
          tmpT[idx] = 0;

          for (let i = 0; i < this.SOT.PropertyGenerators.length; i++) {
            col = tempTableData[idx][1 + i];
            if (!isNaN(col)) {
              tmpT[idx] += col * 1;
            }
          }
          tempTableData[idx][row.length - 1] = tmpT[idx];
        }
      } catch (e) {}
    }
    this.SOT.PortfolioSummary.tableData = tempTableData;
    publish("rendersummary", {});
  };
  public get generatePortfolioSummaryData() {
    return this._generatePortfolioSummaryData;
  }
  public set generatePortfolioSummaryData(value) {
    this._generatePortfolioSummaryData = value;
  }

  getCountries() {
    return fetch("/data/countries.json")
      .then((res) => res.json())
      .then((d) => d.data);
  }

  public Dates: any = [];
  public Data: any = [];
  public generateDates = (years: any = 100) => {
    let no_of_months = years * 12;
    const months = [];
    const dateStart = moment();
    const dateEnd = moment().add(no_of_months, "month");
    while (dateEnd.diff(dateStart, "months") >= 0) {
      months.push(dateStart.format("MMM-YYYY"));
      dateStart.add(1, "month");
    }
    this.Dates = [...months];
  };

  public generateData = (startDate: any = moment(), propno: any = 0) => {
    this.Data = [];
    var SS = { ...this.SOT.StrategicSpending };
    SS.income =
      SS.netSalaryYour + SS.netSalaryPartner + parseFloat(SS.rentalIncome);
    SS.monthlyCashflow = SS.income - SS.expenses;

    SS.balanceSheet = SS.cashOnHand - SS.liabilities;
    let Today = startDate;

    this.generateDates(1000);

    let prevCashOnHand = 0;

    let data = this.Dates?.map((date: any) => {
      let cashOnHandValue =
        date === Today.format("MMM-YYYY")
          ? SS.cashOnHand
          : prevCashOnHand +
            (SS.accountYour -
              SS.accountYour +
              (SS.accountPartner - SS.accountPartner)) +
            SS.monthlyCashflow;

      let balanceSheetValue =
        date === Today.format("MMM-YYYY")
          ? SS.balanceSheet
          : cashOnHandValue - SS.liabilities;

      prevCashOnHand = cashOnHandValue;

      return {
        thedate: Today,
        date,
        balanceSheet: {
          total: balanceSheetValue,
          cashOnHand: {
            total: cashOnHandValue,
            accountYour: SS.accountYour,
            accountPartner: SS.accountPartner,
          },
          liabilities: {
            total: SS.liabilities,
            creditCardPartner: SS.creditCardPartner,
            creditCardYours: SS.creditCardYours,
          },
        },
        monthlyCashflow: {
          total: SS.monthlyCashflow,
          expenses: {
            total: SS.expenses,
            AutoTransport: SS.AutoTransport,
            BillsUtilities: SS.BillsUtilities,
            Education: SS.Education,
            Entertainment: SS.Entertainment,
            Equipment: SS.Equipment,
            FeesCharges: SS.FeesCharges,
            FinancialAccounting: SS.FinancialAccounting,
            FoodDining: SS.FoodDining,
            GiftsDonations: SS.GiftsDonations,
            HealthFitness: SS.HealthFitness,
            HealthInsurance: SS.HealthInsurance,
            LoansCreditCards: SS.LoansCreditCards,
            Misc: SS.Misc,
            RentMortgageProperty: SS.RentMortgageProperty,
            Shopping: SS.Shopping,
            Travel: SS.Travel,
            CarLoan: SS.CarLoan,
          },
          income: {
            total: SS.income,
            netSalaryYour: SS.netSalaryYour,
            netSalaryPartner: SS.netSalaryPartner,
            rentalIncome: SS.rentalIncome,
          },
          static: {
            incomeIdentifier: 1,
            expenseIdentifier: 1,
            addCashFlow: "Yes",
          },
        },
      };
    });

    this.Data.push([...data]);
  };

  public generateDataForProperty = (
    startDate: any = moment(),
    propno: any = 0
  ) => {
    var SS = { ...this.SOT.StrategicSpending };
    SS.income =
      SS.netSalaryYour + SS.netSalaryPartner + parseFloat(SS.rentalIncome);
    SS.monthlyCashflow = SS.income - SS.expenses;
    SS.cashOnHand = SS.accountYour + SS.accountPartner + SS.monthlyCashflow;
    SS.balanceSheet = SS.cashOnHand - SS.liabilities;

    let Today = startDate;

    this.generateDates(1000);

    let prevCashOnHand = 0;

    let data = this.Dates?.map((date: any, idx: any) => {
      let cashOnHandValue = 0;
      let balanceSheetValue = 0;
      SS.income =
        SS.netSalaryYour +
        SS.netSalaryPartner +
        (parseFloat(SS.rentalIncome) +
          parseFloat(
            this.SOT.PropertyGenerators[propno].tableData
              .CashflowaftermortgageMonthly
          ));
      SS.monthlyCashflow = SS.income - (SS.expenses + SS.liabilities);
      SS.cashOnHand = SS.accountYour + SS.accountPartner + SS.monthlyCashflow;
      SS.balanceSheet = SS.cashOnHand - SS.liabilities;
      // debugger;
      cashOnHandValue =
        date === Today.format("MMM-YYYY")
          ? this.SOT.StrategicSpending.cashOnHand
          : prevCashOnHand +
            (SS.accountYour -
              SS.accountYour +
              (SS.accountPartner - SS.accountPartner)) +
            SS.monthlyCashflow;

      balanceSheetValue =
        date === Today.format("MMM-YYYY")
          ? SS.balanceSheet
          : cashOnHandValue - SS.liabilities;

      prevCashOnHand = cashOnHandValue;

      SS.accountYour = 0;
      SS.accountPartner = 0;

      return {
        thedate: Today,
        date,
        balanceSheet: {
          total: balanceSheetValue,
          cashOnHand: {
            total: cashOnHandValue,
            accountYour: SS.accountYour,
            accountPartner: SS.accountPartner,
          },
          liabilities: {
            total: SS.liabilities,
            creditCardPartner: SS.creditCardPartner,
            creditCardYours: SS.creditCardYours,
          },
        },
        monthlyCashflow: {
          total: SS.monthlyCashflow,
          expenses: {
            total: SS.expenses,
            AutoTransport: SS.AutoTransport,
            BillsUtilities: SS.BillsUtilities,
            Education: SS.Education,
            Entertainment: SS.Entertainment,
            Equipment: SS.Equipment,
            FeesCharges: SS.FeesCharges,
            FinancialAccounting: SS.FinancialAccounting,
            FoodDining: SS.FoodDining,
            GiftsDonations: SS.GiftsDonations,
            HealthFitness: SS.HealthFitness,
            HealthInsurance: SS.HealthInsurance,
            LoansCreditCards: SS.LoansCreditCards,
            Misc: SS.Misc,
            RentMortgageProperty: SS.RentMortgageProperty,
            Shopping: SS.Shopping,
            Travel: SS.Travel,
            CarLoan: SS.CarLoan,
          },
          income: {
            total: SS.income,
            netSalaryYour: SS.netSalaryYour,
            netSalaryPartner: SS.netSalaryPartner,
            rentalIncome: SS.rentalIncome,
          },
          static: {
            incomeIdentifier: 1,
            expenseIdentifier: 1,
            addCashFlow: "Yes",
          },
        },
      };
    });

    this.Data.push([...data]);
  };

  public calculatePropertyGenerator(props: any, propertyno: any = 0) {
    let RISKProfilingCommercialE7 = props?.Data?.RISKProfilingCommercialE7;
    let MedianPrices = props?.Data.MedianPrices;
    let RISKProfilingCommercialM7 = props?.Data?.RISKProfilingCommercialM7;
    let RISKProfilingCommercialL7 = props?.Data?.RISKProfilingCommercialL7;
    let RISKProfilingCommercialT7 = props?.Data?.RISKProfilingCommercialT7;
    let RISKProfilingCommercialS7 = props?.Data?.RISKProfilingCommercialS7;
    let ClientInputsC35 = props?.Data?.ClientInputsC35;
    let RemoveTabCalculateG49 = props?.Data?.RemoveTabCalculateG49;
    let liquidAssets = props?.Data?.liquidAssets;
    let netIncome = props?.Data?.netIncome;
    let extraCash = props?.Data?.extraCash;
    let cashAvailable = props?.Data?.cashAvailable;
    let useEquity = props?.Data?.useEquity;
    let equityGain = props?.Data?.equityGain || 0;
    let equityGainGrowth = props?.Data?.equityGainGrowth || 0;
    let cashGainGrowth = props?.Data?.cashGainGrowth || 0;
    let annualGrowth = props?.Data?.AnnualGrowth || 0;
    let percentageEquity = props?.Data?.percentageEquity;
    let equityAvailable = props?.Data?.equityAvailable;
    let totalAvailableFunds = props?.Data?.totalAvailableFunds;
    let useCash = props?.Data?.useCash;
    let slider1 = props?.Data?.slider1;
    let percentageCash = props?.Data?.percentageCash;
    let totalAcquisitionlabel = props?.Data?.totalAcquisitionlabel;
    let useLiquidAssets = props?.Data?.useLiquidAssets;
    let totalAcquisition = props?.Data?.totalAcquisition;
    let targetYieldReturn = props?.Data?.targetYieldReturn;
    let initialPropertyType = props?.Data?.initialPropertyType;
    let useExtraCash = props?.Data?.useExtraCash;
    let slider2 = props?.Data?.slider2;
    let totalAssetsRequiredLeveragedLabel =
      props?.Data?.totalAssetsRequiredLeveragedLabel;
    let totalAssetsRequiredLeveraged =
      props?.Data?.totalAssetsRequiredLeveraged;
    let cashRequiredLeveragedLabel = props?.Data?.cashRequiredLeveragedLabel;
    let cashRequiredLeveraged = props?.Data?.cashRequiredLeveraged;
    let methodforPurchasePrice = props?.Data?.methodforPurchasePrice;

    let G3 = props?.Data?.G3;
    let A44 = props?.Data?.A44;
    let F44 = props?.Data?.F44;
    let C44 = props?.Data?.C44;
    let A45 = props?.Data?.A45;
    let B45 = props?.Data?.B45;
    let A46 = props?.Data?.A46;
    let B46 = props?.Data?.B46;
    let B47 = props?.Data?.B47;
    let B48 = props?.Data?.B48;
    let B49 = props?.Data?.B49;
    let B50 = props?.Data?.B50;
    let B51 = props?.Data?.B51;
    let C51 = props?.Data?.C51;
    let B52 = props?.Data?.B52;
    let B53 = props?.Data?.B53;
    let B54 = props?.Data?.B54;
    let B55 = props?.Data?.B55;
    let B56 = props?.Data?.B56;
    let B57 = props?.Data?.B57;
    let A58 = props?.Data?.A58;
    let B58 = props?.Data?.B58;
    let B59 = props?.Data?.B59;
    let C59 = props?.Data?.C59;
    let B60 = props?.Data?.B60;
    let B61 = props?.Data?.B61;
    let A62 = props?.Data?.A62;
    let B62 = props?.Data?.B62;
    let A63 = props?.Data?.A63;
    let B63 = props?.Data?.B63;
    let A64 = props?.Data?.A64;
    let B64 = props?.Data?.B64;

    let PurchasePrice = props?.Data?.summaryData?.PurchasePrice;
    let Deposit = props?.Data?.summaryData?.Deposit;
    let StampDuty = props?.Data?.summaryData?.StampDuty;
    let AcquisitionCost = props?.Data?.summaryData?.AcquisitionCost;
    let TotalDepositRequired = props?.Data?.summaryData?.TotalDepositRequired;
    let TotalPurchaseCosts = props?.Data?.summaryData?.TotalPurchaseCosts;
    let LoantoValueRatioLVR = props?.Data?.summaryData?.LoantoValueRatioLVR;
    let TotalDebt = props?.Data?.summaryData?.TotalDebt;
    let TotalDepositEquity = props?.Data?.summaryData?.TotalDepositEquity;
    let TotalDepositCash = props?.Data?.summaryData?.TotalDepositCash;
    let AppliedInterestRate = props?.Data?.summaryData?.AppliedInterestRate;
    let PropertyRentIncomeYear1 =
      props?.Data?.summaryData?.PropertyRentIncomeYear1;
    let TotalCostofLending = props?.Data?.summaryData?.TotalCostofLending;
    let CashflowAnnual = props?.Data?.summaryData?.CashflowAnnual;
    let CashflowMonthly = props?.Data?.summaryData?.CashflowMonthly;
    let ReturnonEquityCOC = props?.Data?.summaryData?.ReturnonEquityCOC;
    let AppliedGrowthRateannualPERC =
      props?.Data?.summaryData?.AppliedGrowthRateannualPERC;
    let EquityGainPERCorDOLLAR =
      props?.Data?.summaryData?.EquityGainPERCorDOLLAR;
    let CashGainDOLLAR = props?.Data?.summaryData?.CashGainDOLLAR;
    let LMI = props?.Data?.summaryData?.LMI;
    let propertytype = props?.Data?.propertyType;

    function theforumla(
      d: any = {
        liquidAssets: null,
        netIncome: null,
        extraCash: null,
        cashAvailable: null,
        useEquity: null,
        equityAvailable: null,
        percentageEquity: null,
        totalAvailableFunds: null,
        useCash: null,
        slider1: null,
        percentageCash: null,
        totalAcquisitionlabel: null,
        useLiquidAssets: null,
        totalAcquisition: null,
        targetYieldReturn: null,
        initialPropertyType: null,
        useExtraCash: null,
        slider2: null,
        totalAssetsRequiredLeveragedLabel: null,
        totalAssetsRequiredLeveraged: null,
        cashRequiredLeveragedLabel: null,
        cashRequiredLeveraged: null,
        methodforPurchasePrice: null,
        propertyType: null,
        hidden: false,
        G3: null,
        A44: null,
        F44: null,
        C44: null,
        A45: null,
        B45: null,
        A46: null,
        B46: null,
        B47: null,
        B48: null,
        B49: null,
        B50: null,
        B51: null,
        C51: null,
        B52: null,
        B53: null,
        B54: null,
        B55: null,
        B56: null,
        B57: null,
        A58: null,
        B58: null,
        B59: null,
        C59: null,
        B60: null,
        B61: null,
        A62: null,
        B62: null,
        A63: null,
        B63: null,
        A64: null,
        B64: null,
      }
    ) {
      let vlookup: any = {};

      let F1 = 0;
      let C2 = liquidAssets;
      let E2 = extraCash;
      let F2 = 0;
      let B3 = cashAvailable;
      let F3 = 0;
      let G3 = "";
      let I3 = useEquity;
      let K3 = 900000;
      let F4 = 0;
      let I4 = percentageEquity;
      let K4 = 432000;
      let B4 = 0;
      if (propertyno === 0) {
        B4 = (equityAvailable / 100) * I4;
      } else {
        B4 = equityAvailable;
      }

      let B5 = totalAvailableFunds;
      let F5 = 0;
      let I5 = useCash;
      let K5 = 612000;
      let F6 = 0;
      let I6 = percentageCash;
      // let K6 = 432000;
      let A7 = 0;
      // let B7 = 0;
      let D7 = slider1;
      let F7 = 1;
      // let G7 = 1;
      let I7 = useLiquidAssets;
      // let K7 = 522000;
      let F8 = 3321772.14;
      // let H8 = "";
      let I8 = 0;
      // let K8 = 648000;
      let B9 = targetYieldReturn;
      let I9 = useExtraCash;
      // let A11 = 0;
      // let B11 = 0;
      let A12 = 0;
      // let B12 = 0;
      let F13 = -12.55;

      let F44: any = 0;

      let A45 = "Purchase Price";
      let B45 = 0;
      // let C45 = 0;
      let A46 = 0;
      let B46 = 0;
      let B47 = 0;
      let B48 = 0;
      // let F48 = 0;
      let B49 = 0;
      // let F49 = 0;
      let B50 = 0;
      // let F50 = 0;
      let B51 = 0;
      let C51 = 0;
      let B52 = 0;
      let B53 = 0;
      let B54 = 0;
      let B55 = 0;
      let B57 = 0;
      let A58 = 3.5;
      let B58 = 0;
      let B59 = 0;
      let C59 = 0;
      let B60 = 0;
      let B61 = 0;
      let A62 = 5;
      let B62 = 0;
      let B63 = 0;
      let A63 = 7;
      let B64 = 0;
      let A64 = 10;
      let C12 = 0;
      let C11 = 0;

      let C13 = 0;
      let C14 = 0;
      let C15 = 0;
      let C16 = 0;
      let C17 = 0;
      let C18 = 0;
      let C19 = 0;
      let C20 = 0;
      let C21 = 0;
      let C22 = 0;

      let propertytype = "Residential";

      vlookup[1] = RISKProfilingCommercialE7;
      vlookup[2] = RISKProfilingCommercialM7 - RISKProfilingCommercialL7;
      vlookup[3] = RISKProfilingCommercialT7 - RISKProfilingCommercialS7;
      vlookup[4] = K4;
      vlookup[5] = (K4 + K5) / 2;
      vlookup[6] = (K3 + K4 + K5) / 3;

      F1 = I9 === "Y" ? E2 : 0;

      F2 = I7 === "Y" ? (I8 === 0 ? C2 : I8 * C2) : 0;

      F3 = I5 === "Y" ? (I6 === 0 ? B3 : I6 * (B3 / 100)) : 0;

      K3 = RISKProfilingCommercialE7;

      F4 = I3 === "Y" ? (I4 < 1 ? B4 : I4 * (B4 / 100)) : 0;

      K4 = RISKProfilingCommercialM7 - RISKProfilingCommercialL7;

      B5 = B3 + B4;

      F5 = F1 + F2 + F3 + F4;

      K5 = RISKProfilingCommercialT7 - RISKProfilingCommercialS7;

      // K6 = K4;

      A7 = D7 / 100;

      F7 =
        F3 >= RISKProfilingCommercialS7 && F3 < RISKProfilingCommercialT7
          ? F3 >= RISKProfilingCommercialL7 && F3 < RISKProfilingCommercialM7
            ? F3 >= RISKProfilingCommercialE7
              ? 6
              : 5
            : 3
          : 0;

      // B7 = F5 / A7 - (F5 / A7) * (A7 - 0.13);

      // G7 = vlookup[F7] * 1.1 > F3 && vlookup[F7] / 1.1 ? 1 : 2;

      // K7 = (K4 + K5) / 2;

      F8 = B5 / (D7 / 100) - (B5 / (D7 / 100)) * (D7 / 100 - 0.13);

      // H8 = I7 === "Y" ? "Percentage Liquid Assets?" : "";

      // K8 = (K3 + K4 + K5) / 3;

      B9 = ClientInputsC35;

      // B11 = B7;
      A12 = A7;
      // A11 = 1 - A12;
      // B12 = B11 * A12 + B11 * 0.06;

      A45 = "Purchase Price ";

      F44 = RemoveTabCalculateG49 + " + " + A7 * RemoveTabCalculateG49;

      // F48 = F4 + B3;

      // F50 =
      // F48 / A7 -
      // (F48 / A7 / 100) * (A7 * 100 + GS.SOT.ai_matrix.PurchasePriceDeviation);
      // let cashnequity = B3 + F4 + F3;

      let cashnequity = cashAvailable;
      let medianPropertyType = propertytype;

      if (ClientInputsC35 <= 5) {
        medianPropertyType = "residential";
      } else if (ClientInputsC35 > 5 && ClientInputsC35 < 6.9) {
        medianPropertyType = "retail";
      } else if (ClientInputsC35 <= 7.5) {
        medianPropertyType = "office";
      } else {
        medianPropertyType = "other";
      }

      if (!GS?.SOT.PortfolioSummary.hasOwnProperty("Properties")) {
        GS.SOT.PortfolioSummary.Properties = {
          retail: [],
          office: [],
          residential: [],
          other: [],
          industry: [],
        };
      }
      if (GS?.SOT.PortfolioSummary.Properties["office"].length === 0) {
        for (let i = 0; i < 25; i++) {
          GS.SOT.PortfolioSummary.Properties["office"].push({
            suburb: MedianPrices["office"][i].Suburb_Name,
            name: MedianPrices["office"][i].State,
            price: MedianPrices["office"][i].Median_Rental_Price,
          });

          GS.SOT.PortfolioSummary.Properties["residential"].push({
            suburb: MedianPrices["residential"][i].Suburb_Name,
            name: MedianPrices["residential"][i].State,
            price: MedianPrices["residential"][i].Median_Rental_Price,
          });

          GS.SOT.PortfolioSummary.Properties["industry"].push({
            suburb: MedianPrices["industry"][i].Suburb_Name,
            name: MedianPrices["industry"][i].State,
            price: MedianPrices["industry"][i].Median_Rental_Price,
          });

          GS.SOT.PortfolioSummary.Properties["other"].push({
            suburb: MedianPrices["other"][i].Suburb_Name,
            name: MedianPrices["other"][i].State,
            price: MedianPrices["other"][i].Median_Rental_Price,
          });

          GS.SOT.PortfolioSummary.Properties["retail"].push({
            suburb: MedianPrices["retail"][i].Suburb_Name,
            name: MedianPrices["retail"][i].State,
            price: MedianPrices["retail"][i].Median_Rental_Price,
          });
        }
      }
      // GS?.SOT.PortfolioSummary.Properties.sort(() => Math.random() - 0.5);
      // }

      // if (GS?.SOT.PortfolioSummary.Properties.length === 0) {

      //   for (let i = 0; i < MedianPrices.length; i++) {
      //     GS.SOT.PortfolioSummary.Properties.push({
      //       suburb: MedianPrices[i].Suburb_Name,
      //       name: MedianPrices[i].State,
      //       price: MedianPrices[i].Median_Rental_Price,
      //     });
      //   }
      //   GS?.SOT.PortfolioSummary.Properties.sort(() => Math.random() - 0.5);
      // }
      let RiskFactoredYieldReturn = 0;
      let MEDIANRENTPRICE_RentIncome = 0;

      let ResidentialMedianPrice =
        GS?.SOT?.PortfolioSummary?.Properties["residential"][propertyno]
          ?.price *
        0.68 *
        52;
      let ComercialMedianPrice =
        GS?.SOT?.PortfolioSummary?.Properties["office"][propertyno]?.price;
      // *
      // 100;
      let RetailMedianPrice =
        GS?.SOT?.PortfolioSummary?.Properties["retail"][propertyno]?.price;
      let IndustryMedianPrice =
        GS?.SOT?.PortfolioSummary?.Properties["industry"][propertyno]?.price;
      let OtherMedianPrice =
        GS?.SOT?.PortfolioSummary?.Properties["other"][propertyno]?.price;
      // *
      // 100;
      if (propertyno === 0) {
        if (ClientInputsC35 !== null) {
          RiskFactoredYieldReturn = parseFloat(
            ClientInputsC35.toString().replace("%", "")
          );
        }

        if (GS?.SOT?.RiskProfile?.initialPropertyType === "Blended") {
          B45 = cashnequity + equityGain;
        } else if (
          GS?.SOT?.RiskProfile?.initialPropertyType === "Residential"
        ) {
          B45 = (ResidentialMedianPrice / RiskFactoredYieldReturn) * 100;
        } else if (
          GS?.SOT?.RiskProfile?.initialPropertyType === "Commercial - Office"
        ) {
          B45 = (ComercialMedianPrice / RiskFactoredYieldReturn) * 100;
        } else if (
          GS?.SOT?.RiskProfile?.initialPropertyType === "Commercial - Retail"
        ) {
          B45 = (RetailMedianPrice / RiskFactoredYieldReturn) * 100;
        }
        RiskFactoredYieldReturn = ((ClientInputsC35 * (B45 / 100)) / B45) * 100;
        if (RiskFactoredYieldReturn < 5.99) {
          MEDIANRENTPRICE_RentIncome = ResidentialMedianPrice;
        } else if (RiskFactoredYieldReturn < 6.99) {
          MEDIANRENTPRICE_RentIncome = RetailMedianPrice;
        } else {
          MEDIANRENTPRICE_RentIncome = ComercialMedianPrice;
        }
      } else {
        let thePurchasePrice = 0;
        if (ClientInputsC35 !== null) {
          RiskFactoredYieldReturn = parseFloat(
            ClientInputsC35.toString().replace("%", "")
          );
        }
        if (RiskFactoredYieldReturn < 5.99) {
          thePurchasePrice =
            (ResidentialMedianPrice / RiskFactoredYieldReturn) * 100;
          MEDIANRENTPRICE_RentIncome = ResidentialMedianPrice;
        } else if (RiskFactoredYieldReturn < 6.99) {
          thePurchasePrice =
            (RetailMedianPrice / RiskFactoredYieldReturn) * 100;
          MEDIANRENTPRICE_RentIncome = RetailMedianPrice;
        } else {
          thePurchasePrice =
            (ComercialMedianPrice / RiskFactoredYieldReturn) * 100;
          MEDIANRENTPRICE_RentIncome = ComercialMedianPrice;
        }
        B45 = thePurchasePrice;
      }

      if (RiskFactoredYieldReturn <= 5) {
        propertytype = "Residential";
      } else if (RiskFactoredYieldReturn > 5 && RiskFactoredYieldReturn <= 6) {
        propertytype = "Commercial Retail";
      } else if (RiskFactoredYieldReturn > 6 && RiskFactoredYieldReturn <= 8) {
        propertytype = "Commercial Office";
      } else {
        propertytype = "Commercial";
      }

      // C45 = B45;

      A46 = A12;

      B46 = B45 / 5;

      B47 = B45 * 0.055;
      B48 = B45 * 0.0045;

      B49 = GS.SOT.ai_matrix.BuildingReport;

      // F49 = F48 / A7;

      B50 = (B45 - B46) * 0.0075;

      B51 = B46 + B47 + B48 + B49 + B50;

      F6 = F5 - B51;

      C51 = F5 - F6;

      F13 = ((B51 - F5) / B3) * 100;

      G3 =
        F13 > 0
          ? `You still need  ${
              isFinite(round_up(F13, 2)) ? round_up(F13, 2) + "%" : "more cash"
            } from your cash amount!`
          : I5 === "N" && I9 === "N"
          ? "The equity can cover the initial amount!"
          : "Cash covered!";

      B52 = B47 + B47 + B48 + B49 + B50 + B45;

      B53 = B9 * (B45 / 100);

      B54 = (B53 / B45) * 100;

      B55 = (B53 / B52) * 100;

      B57 = B51;

      B58 = ((B45 - B46 + B4) / 100) * A58;

      B59 = B53 - B58;

      C59 = B59;

      B60 = B59 / 12;

      B61 = (B59 / B51) * 100;

      B62 = (((B45 / 100) * (100 + A62) - B45 + B59) / B57) * 100;

      B63 = (((B45 / 100) * (100 + A63) - B45 + B59) / B57) * 100;

      B64 = (((B45 / 100) * (100 + A64) - B45 + B59) / B57) * 100;
      C11 = B45 * 0.015;
      C12 = B46 + B47 + C11;
      C13 = 80;
      C14 = (B45 * C13) / 100;
      C15 = C12 * 0.8;
      C16 = C12 - C12 * 0.8;
      C17 = 5;
      C18 = B47 + 1300;
      C19 = (C14 + C15) * C17;
      C20 = C18 - C19;
      C21 = C20 / 12;
      C22 = C20 / C12;
      
      if (
        GS.SOT.RiskProfile.initialPropertyType === "Blended" &&
        propertyno === 0
      ) {
        PurchasePrice = B45 * 5;
        StampDuty = PurchasePrice * 0.055;
        AcquisitionCost = PurchasePrice * 0.015;
        TotalDebt = PurchasePrice * A7;
        Deposit = PurchasePrice - TotalDebt - StampDuty - AcquisitionCost;
        TotalDepositRequired = Deposit + StampDuty + AcquisitionCost;
      } else {
        PurchasePrice = B45;
        StampDuty = B45 * 0.055;
        AcquisitionCost = B45 * 0.015;
        TotalDebt = B45 * A7;
        Deposit = B45 - TotalDebt;
        TotalDepositRequired = Deposit + StampDuty + AcquisitionCost;
      }

      LoantoValueRatioLVR = A7;
      TotalPurchaseCosts = B45 + StampDuty + AcquisitionCost;

      TotalDepositEquity = TotalDepositRequired * 0.8;
      TotalDepositCash = TotalDepositRequired - TotalDepositEquity;
      AppliedInterestRate = AppliedInterestRate || 5;
      PropertyRentIncomeYear1 = MEDIANRENTPRICE_RentIncome;
      TotalCostofLending =
        ((TotalDebt + TotalDepositEquity) / 100) * AppliedInterestRate;
      CashflowAnnual = PropertyRentIncomeYear1 - TotalCostofLending;
      CashflowMonthly = CashflowAnnual / 12;
      ReturnonEquityCOC = (CashflowAnnual / TotalDepositRequired) * 100;

      AppliedGrowthRateannualPERC = annualGrowth;
      EquityGainPERCorDOLLAR = equityGainGrowth;
      // B45 * (AppliedGrowthRateannualPERC / 100);
      CashGainDOLLAR = cashGainGrowth;
      LMI = 0;
      // equityGain = equityGain;

      return {
        liquidAssets: liquidAssets,
        netIncome: netIncome,
        extraCash: extraCash,
        cashAvailable: cashAvailable,
        useEquity: useEquity,
        equityGain: equityGain,
        equityAvailable: equityAvailable,
        percentageEquity: percentageEquity,
        totalAvailableFunds: totalAvailableFunds,
        useCash: useCash,
        slider1: slider1,
        percentageCash: percentageCash,
        totalAcquisitionlabel: totalAcquisitionlabel,
        useLiquidAssets: useLiquidAssets,
        totalAcquisition: totalAcquisition,
        targetYieldReturn: targetYieldReturn,
        initialPropertyType: initialPropertyType,
        useExtraCash: useExtraCash,
        slider2: slider2,
        totalAssetsRequiredLeveragedLabel: totalAssetsRequiredLeveragedLabel,
        totalAssetsRequiredLeveraged: totalAssetsRequiredLeveraged,
        cashRequiredLeveragedLabel: cashRequiredLeveragedLabel,
        cashRequiredLeveraged: cashRequiredLeveraged,
        methodforPurchasePrice: methodforPurchasePrice,
        nextDate: props?.Data?.nextDate,
        propertyType: propertytype,
        medianPropertyType: medianPropertyType,
        hidden: false,
        isLocked: false,
        tableData: {
          PurchasePrice: B45,
          Depositassumingleverage20: B46,
          StampDuty: B47,
          SolicitorValuation: B48,
          BuildingReport: B49,
          LoanArrangementFee: B50,
          TotalCashRequired: B51,
          TotalPurchaseCosts: B52,
          NetAnnualCashflowReturnYear1: B53,
          NetYieldonPropertyYear1: B54,
          NetYieldonTotalPurchaseCostsYear1: B55,
          CashonCashReturn: B56,
          TotalCashRequired20Costs: B57,
          CostofLoan5paonleverage: B58,
          Cashflowaftermortagecosts: B59,
          CashflowaftermortgageMonthly: B60,
          ReturnonEquitypurecashflowreturn: B61,
          ReturnonEquitywitha5capitalgrowthrate: B62,
          ReturnonEquitywitha7capitalgrowthrate: B63,
          ReturnonEquitywitha10capitalgrowthrate: B64,
        },
        summaryData: {
          propertyType: propertytype,
          region:
            GS?.SOT?.PortfolioSummary?.Properties[medianPropertyType][
              propertyno
            ]?.suburb +
            " (" +
            GS?.SOT?.PortfolioSummary?.Properties[medianPropertyType][
              propertyno
            ]?.name +
            ")",
          PurchasePrice: PurchasePrice,
          Deposit: Deposit,
          StampDuty: StampDuty,
          TotalDepositRequired: TotalDepositRequired,
          AcquisitionCost: AcquisitionCost,
          LMI: LMI,
          TotalPurchaseCosts: TotalPurchaseCosts,
          LoantoValueRatioLVR: LoantoValueRatioLVR,
          TotalDebt: TotalDebt,
          TotalDepositEquity: TotalDepositEquity,
          TotalDepositCash: TotalDepositCash,
          AppliedInterestRate: AppliedInterestRate,
          PropertyRentIncomeYear1: PropertyRentIncomeYear1,
          TotalCostofLending: TotalCostofLending,
          CashflowAnnual: CashflowAnnual,
          CashflowMonthly: CashflowMonthly,
          ReturnonEquityCOC: ReturnonEquityCOC,
          AppliedGrowthRateannualPERC: AppliedGrowthRateannualPERC,
          EquityGainPERCorDOLLAR: EquityGainPERCorDOLLAR,
          CashGainDOLLAR: CashGainDOLLAR,
        },
        G3: G3,
        A44: A44,
        F44: F44,
        C44: C44,
        A45: A45,
        B45: B45,
        A46: A46,
        B46: B46,
        B47: B47,
        B48: B48,
        B49: B49,
        B50: B50,
        B51: B51,
        C51: C51,
        B52: B52,
        B53: B53,
        B54: B54,
        B55: B55,
        B56: B56,
        B57: B57,
        A58: A58,
        B58: B58,
        B59: B59,
        C59: C59,
        B60: B60,
        B61: B61,
        A62: A62,
        B62: B62,
        A63: A63,
        B63: B63,
        A64: A64,
        B64: B64,
        F1: F1,
        F2: F2,
        F3: F3,
        F4: F4,
        F5: F5,
        F6: F6,
        F7: F7,
        F8: F8,
      };
    }

    function round_up(value: any, precision: any = 0) {
      const power = Math.pow(10, precision);
      let result = Math.ceil(value * power) / power;

      return result;
    }
    // function conv_number(expr: any, decplaces: any) {
    //   var str = "" + Math.round(eval(expr) * Math.pow(10, decplaces));
    //   while (str.length <= decplaces) {
    //     str = "0" + str;
    //   }

    //   var decpoint = str.length - decplaces;
    //   return (
    //     str.substring(0, decpoint) + "." + str.substring(decpoint, str.length)
    //   );
    // }

    // function pv(rate: any, nper: any, pmt: any, fv: any) {
    //   let pv_value, x, y;

    //   rate = parseFloat(rate);
    //   nper = parseFloat(nper);
    //   pmt = parseFloat(pmt);
    //   fv = parseFloat(fv);
    //   if (nper === 0) {
    //     alert("Why do you want to test me with zeros?");
    //     return 0;
    //   }
    //   if (rate === 0) {

    //     pv_value = -(fv + pmt * nper);
    //   } else {
    //     x = Math.pow(1 + rate, -nper);
    //     y = Math.pow(1 + rate, nper);
    //     pv_value = -(x * (fv * rate - pmt + y * pmt)) / rate;
    //   }
    //   pv_value = conv_number(pv_value, 2);
    //   return pv_value;
    // }

    // function fv(rate: any, nper: any, pmt: any, pv: any) {
    //   let fv_value, x, y;
    //   rate = parseFloat(rate);
    //   nper = parseFloat(nper);
    //   pmt = parseFloat(pmt);
    //   pv = parseFloat(pv);
    //   if (nper === 0) {

    //     return 0;
    //   }
    //   if (rate === 0) {

    //     fv_value = -(pv + pmt * nper);
    //   } else {
    //     x = Math.pow(1 + rate, nper);
    //     fv_value = -(-pmt + x * pmt + rate * x * pv) / rate;
    //   }
    //   fv_value = conv_number(fv_value, 2);
    //   return fv_value;
    // }
    // function PV(rate: any, nper: any, pmt: any, fv: any = 0) {
    //   return (pmt / rate) * (1 - Math.pow(1 + rate, -nper));
    // }

    GS.SOT.PropertyGenerators[propertyno] = {
      liquidAssets: liquidAssets,
      netIncome: netIncome,
      extraCash: extraCash,
      cashAvailable: cashAvailable,
      useEquity: useEquity,
      equityAvailable: equityAvailable,
      percentageEquity: percentageEquity,
      totalAvailableFunds: totalAvailableFunds,
      useCash: useCash,
      slider1: slider1,
      percentageCash: percentageCash,
      totalAcquisitionlabel: totalAcquisitionlabel,
      useLiquidAssets: useLiquidAssets,
      totalAcquisition: totalAcquisition,
      targetYieldReturn: targetYieldReturn,
      initialPropertyType: initialPropertyType,
      useExtraCash: useExtraCash,
      slider2: slider2,
      totalAssetsRequiredLeveragedLabel: totalAssetsRequiredLeveragedLabel,
      totalAssetsRequiredLeveraged: totalAssetsRequiredLeveraged,
      cashRequiredLeveragedLabel: cashRequiredLeveragedLabel,
      cashRequiredLeveraged: cashRequiredLeveraged,
      methodforPurchasePrice: methodforPurchasePrice,
      nextDate: GS?.SOT?.PropertyGenerators[propertyno]?.nextDate,
      propertyType: propertytype,
      hidden: false,
      isLocked: false,
      tableData: {
        PurchasePrice: B45,
        Depositassumingleverage20: B46,
        StampDuty: B47,
        SolicitorValuation: B48,
        BuildingReport: B49,
        LoanArrangementFee: B50,
        TotalCashRequired: B51,
        TotalPurchaseCosts: B52,
        NetAnnualCashflowReturnYear1: B53,
        NetYieldonPropertyYear1: B54,
        NetYieldonTotalPurchaseCostsYear1: B55,
        CashonCashReturn: B56,
        TotalCashRequired20Costs: B57,
        CostofLoan5paonleverage: B58,
        Cashflowaftermortagecosts: B59,
        CashflowaftermortgageMonthly: B60,
        ReturnonEquitypurecashflowreturn: B61,
        ReturnonEquitywitha5capitalgrowthrate: B62,
        ReturnonEquitywitha7capitalgrowthrate: B63,
        ReturnonEquitywitha10capitalgrowthrate: B64,
      },

      summaryData: {
        propertyType: propertytype,
        PurchasePrice: PurchasePrice,
        Deposit: Deposit,
        StampDuty: StampDuty,
        TotalDepositRequired: TotalDepositRequired,
        AcquisitionCost: AcquisitionCost,
        LMI: LMI,
        TotalPurchaseCosts: TotalPurchaseCosts,
        LoantoValueRatioLVR: LoantoValueRatioLVR,
        TotalDebt: TotalDebt,
        TotalDepositEquity: TotalDepositEquity,
        TotalDepositCash: TotalDepositCash,
        AppliedInterestRate: AppliedInterestRate,
        PropertyRentIncomeYear1: PropertyRentIncomeYear1,
        TotalCostofLending: TotalCostofLending,
        CashflowAnnual: CashflowAnnual,
        CashflowMonthly: CashflowMonthly,
        ReturnonEquityCOC: ReturnonEquityCOC,
        AppliedGrowthRateannualPERC: AppliedGrowthRateannualPERC,
        EquityGainPERCorDOLLAR: EquityGainPERCorDOLLAR,
        CashGainDOLLAR: CashGainDOLLAR,
      },
      G3: G3,
      A44: A44,
      F44: F44,
      C44: C44,
      A45: A45,
      B45: B45,
      A46: A46,
      B46: B46,
      B47: B47,
      B48: B48,
      B49: B49,
      B50: B50,
      B51: B51,
      C51: C51,
      B52: B52,
      B53: B53,
      B54: B54,
      B55: B55,
      B56: B56,
      B57: B57,
      A58: A58,
      B58: B58,
      B59: B59,
      C59: C59,
      B60: B60,
      B61: B61,
      A62: A62,
      B62: B62,
      A63: A63,
      B63: B63,
      A64: A64,
      B64: B64,
    };

    let values = GS.SOT.PropertyGenerators[propertyno];
    let rs = theforumla(values);
    GS.SOT.PropertyGenerators[propertyno] = rs;

    GS.generatePortfolioSummaryData();

    API.AutoSave(window.location.href);
  }

  public medianChart: any = {};
  public getRandMedianPriceChart() {
    let states = ["NSW", "QLD", "VIC"];
    let items = [
      {
        city: "Sydney",
        data: {
          auctionedDate: "2022-11-12",
          lastModifiedDateTime: "2022-11-15T10:09:35.347Z",
          adjClearanceRate: 0.5508317929759704,
          median: 1397000,
          numberAuctioned: 541,
          numberListedForAuction: 669,
          numberSold: 298,
          numberUnreported: 3,
          numberWithdrawn: 111,
          totalSales: 287351500,
        },
        _id: "637365805e133609dca921bb",
      },
      {
        city: "Melbourne",
        data: {
          auctionedDate: "2022-11-12",
          lastModifiedDateTime: "2022-11-15T10:09:35.347Z",
          adjClearanceRate: 0.592391304347826,
          median: 947750,
          numberAuctioned: 736,
          numberListedForAuction: 822,
          numberSold: 436,
          numberUnreported: 11,
          numberWithdrawn: 71,
          totalSales: 335780769,
        },
        _id: "637365805e133609dca921bc",
      },
      {
        city: "Brisbane",
        data: {
          auctionedDate: "2022-11-12",
          lastModifiedDateTime: "2022-11-15T10:09:35.347Z",
          adjClearanceRate: 0.34328358208955223,
          median: 1030000,
          numberAuctioned: 67,
          numberListedForAuction: 81,
          numberSold: 23,
          numberUnreported: 2,
          numberWithdrawn: 7,
          totalSales: 12770751,
        },
        _id: "637365805e133609dca921bd",
      },
      {
        city: "Adelaide",
        data: {
          auctionedDate: "2022-11-12",
          lastModifiedDateTime: "2022-11-15T10:09:35.347Z",
          adjClearanceRate: 0.6739130434782609,
          median: 772500,
          numberAuctioned: 92,
          numberListedForAuction: 111,
          numberSold: 62,
          numberUnreported: 4,
          numberWithdrawn: 2,
          totalSales: 40390500,
        },
        _id: "637365805e133609dca921be",
      },
      {
        city: "Canberra",
        data: {
          auctionedDate: "2022-11-12",
          lastModifiedDateTime: "2022-11-15T10:09:35.347Z",
          adjClearanceRate: 0.527027027027027,
          median: 1181250,
          numberAuctioned: 74,
          numberListedForAuction: 78,
          numberSold: 39,
          numberUnreported: 2,
          numberWithdrawn: 8,
          totalSales: 36240500,
        },
        _id: "637365805e133609dca921bf",
      },
    ];

    this.medianChart = {};
    for (let idx = 0; idx <= states.length - 1; idx++) {
      let nkey = states[idx];
      this.medianChart[`${nkey}`] = { "0": 0 };
      for (let i = 5; i <= 8; i = i + 0.01) {
        let index = Math.floor(Math.random() * (items.length - 0) + 0);

        this.medianChart[`${nkey}`][`${i.toFixed(2)}`] = {
          name: nkey,
          price: (items[index].data.median / 100) * 0.15,
          listingData: {},
        };
      }
    }
  }

  public shuffle(array: any) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  public getRandMedianPrice(items: any) {
    let index = Math.floor(Math.random() * (items.length - 0) + 0);
    if (GS?.SOT?.PortfolioSummary?.Properties?.length < 1) {
      return {
        suburb: items[2].Suburb_Name,
        name: items[2].State,
        price: items[2].Median_Rental_Price,
      };
    } else {
      return {
        suburb: items[index].Suburb_Name,
        name: items[index].State,
        price: items[index].Median_Rental_Price,
      };
    }
  }
  public Round(val: any, num: any) {
    var coef = Math.pow(10, num);
    return Math.round(val * coef) / coef;
  }
  public PMT(ir: any, np: any, pv: any, fv: any = 0) {
    var presentValueInterstFector = Math.pow(1 + ir, np);
    var pmt =
      (ir * pv * (presentValueInterstFector + fv)) /
      (presentValueInterstFector - 1);
    return pmt;
  }
  public round_up(value: any, precision: any = 0) {
    const power = Math.pow(10, precision);
    return Math.ceil(value * power) / power;
  }
  public round_down(value: any, precision: any = 0) {
    const power = Math.pow(10, precision);
    return Math.floor(value * power) / power;
  }

  public conv_number(expr: any, decplaces: any) {
    var str = "" + Math.round(eval(expr) * Math.pow(10, decplaces));
    while (str.length <= decplaces) {
      str = "0" + str;
    }

    var decpoint = str.length - decplaces;
    return (
      str.substring(0, decpoint) + "." + str.substring(decpoint, str.length)
    );
  }

  public pv(rate: any, nper: any, pmt: any, fv: any) {
    let pv_value, x, y;

    rate = parseFloat(rate);
    nper = parseFloat(nper);
    pmt = parseFloat(pmt);
    fv = parseFloat(fv);
    if (nper === 0) {
      alert("Why do you want to test me with zeros?");
      return 0;
    }
    if (rate === 0) {
      pv_value = -(fv + pmt * nper);
    } else {
      x = Math.pow(1 + rate, -nper);
      y = Math.pow(1 + rate, nper);
      pv_value = -(x * (fv * rate - pmt + y * pmt)) / rate;
    }
    pv_value = this.conv_number(pv_value, 2);
    return pv_value;
  }

  public fv(rate: any, nper: any, pmt: any, pv: any) {
    let fv_value, x;
    rate = parseFloat(rate);
    nper = parseFloat(nper);
    pmt = parseFloat(pmt);
    pv = parseFloat(pv);
    if (nper === 0) {
      return 0;
    }
    if (rate === 0) {
      fv_value = -(pv + pmt * nper);
    } else {
      x = Math.pow(1 + rate, nper);
      fv_value = -(-pmt + x * pmt + rate * x * pv) / rate;
    }
    fv_value = this.conv_number(fv_value, 2);
    return fv_value;
  }
  public PV(rate: any, nper: any, pmt: any, fv: any = 0) {
    return (pmt / rate) * (1 - Math.pow(1 + rate, -nper));
  }
}

let GS = new GlobalService();

function subscribe(eventName: any, listener: any) {
  document.addEventListener(eventName, listener);
}

function unsubscribe(eventName: any, listener: any) {
  document.removeEventListener(eventName, listener);
}

function publish(eventName: any, data: any) {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
}

function isWhatPercentOf(numA: any, numB: any) {
  let percentage = (numA / numB) * 100;
  return percentage;
}

function getPercentageIncrease(numA: any, numB: any) {
  return ((numA - numB) / numB) * 100;
}

export {
  GS,
  publish,
  subscribe,
  unsubscribe,
  isWhatPercentOf,
  getPercentageIncrease,
};
