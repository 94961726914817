const initialState = {
  UseableEquityAndCash: 0,
};

const FinancesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FINANCES":
      state = action.payload;
      return state;
    case "UPDATE_FINANCES":
      state = { ...state, ...action.payload };
      return state;
    case "GET_FINANCES":
      return state;
    default:
      return state;
  }
};
export default FinancesReducer;
