import styled from "styled-components";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Slider } from "primereact/slider";

import Sliderimg from "../../assets/images/PurpleCircle.png"
export const MySlider=styled(Slider)`

.p-slider-handle{
  background: url('${Sliderimg}') !important;
  width: 20px;
  height: 20px;
  /* background:url("../../assets/images/SliderCirlce.png") !important; */
  /* https://media.geeksforgeeks.org/wp-content/uploads/rk.png */
  
}

`

export const InputNumbers=styled( InputNumber)`

.p-inputtext:enabled:focus{
  border-image-source: linear-gradient(to right, var(--gredient-blue-1) 0%, var(--gredient-blue-2) 100%) !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-image-slice: 1;
  border-radius: 4px !important;
}
.p-inputnumber-input{
  width:${({width})=>width?width:"320px"}  !important;
  border: 1px solid #cccccc;
   border-radius: 4px !important;
 background-color: ${({bgColor})=>bgColor?bgColor:"white"} !important;
line-height: normal;
letter-spacing: normal;

font-family: "Inter" !important;
font-size: 12px !important;
font-weight: normal !important;
font-stretch: normal !important;
font-style: normal !important;
height:36px;
padding:15px 0px 14px 16.8px;
color: var(--black-main-color);
overflow: hidden;
}
`
export const InputTexts=styled( InputText)`
width:${({width})=>width?width:"320px !important"};
  border: 1px solid #cccccc;
   border-radius: 4px !important;
 
line-height: normal;
letter-spacing: normal;

font-family: "Inter" !important;
font-size: 12px !important;
font-weight: normal !important;
font-stretch: normal !important;
font-style: normal !important;
height:36px;
padding:15px 0px 14px 16.8px;
color: var(--black-main-color);
overflow: hidden;
&::placeholder{
  font-family: "Inter" !important;
font-size: 12px !important;
}
&:focus{
  border:1px solid blue !important;
  
}

`
export const EquityContainer = styled.div`
  padding: 20px 0px 0px 0px;


 
`;
export const CashContainer = styled.div`
  border-radius: 8px;
  box-shadow: 0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02),
    0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02),
    0 19px 122px 0 rgba(7, 62, 132, 0.04);
  background-color: var(--white);
  padding-top: 1.5rem;
  padding-left: 32px;
  padding-bottom: 1.5rem;

  .contact-input-group-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 1.5rem;
    .contact-group-column1 {
      width: 500px;
    }
  }
`;
export const ContactColumn = styled.div`
  width: ${(props) => props.width};
`;
export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  padding-top: 25px;
`;
export const PropertiesContainer = styled.div``;

export const GradientContainer=styled.div`
margin-top: 20px;

.text-right{
  margin-left: 40px;
}
`