import styled from "styled-components";
import ProgessIndicator from "../SvgComponents/ProgessIndicator";
import { Slider } from "primereact/slider";
import { GS } from "../../services/global";
import { API } from "../../services/api";
const SliderComponent = ({
  value,
  setSliderVal,
  min,
  max,
  step,
  RiskVal,
  setRiskVal,
  setProperty,
  progressColor,
  setProgessColor,
  setPropertyType,
  propertyType,
  OnChange,
}) => {
  function setNetYieldonProperty(val, propertyType) {
    GS.SOT.PropertyGenerators.forEach((n, idx) => {
      if (val) {
        GS.SOT.PropertyGenerators[idx].targetYieldReturn = val;
      }
      if (propertyType) {
        GS.SOT.PropertyGenerators[idx].initialPropertyType = propertyType;
      }
    });
  }
  const SetRisk = (val) => {
    if (val >= 17.5 && val <= 87.5) {
      setSliderVal(val);
      const property = val < 52.5 ? 5 : val < 87.5 ? 6 : 7;
      setProgessColor(
        property < 6 ? "#00a779" : property < 7 ? "#fee468" : "#cd4a5e"
      );
      setProperty(
        property < 6 ? "Conservative" : property < 7 ? "Moderate" : "Aggressive"
      );
      let minimumVal = property === 5 ? 17.5 : property === 6 ? 52.5 : 87.5;
      let riskVal = (val - minimumVal) / 3.5;
      riskVal = `${property}.${riskVal}`;
      riskVal = parseFloat(riskVal);
      setRiskVal(riskVal);
      GS.SOT.RiskProfile.NetYieldonProperty = `${riskVal}%`;
      setNetYieldonProperty(riskVal);
      if (riskVal === 5) {
        if (propertyType !== "Blended") {
          setPropertyType("Residential");
          GS.SOT.RiskProfile.initialPropertyType = "Residential";
          setNetYieldonProperty(riskVal, "Residential");
        }
      } else if (riskVal > 5 && propertyType === "Residential") {
        setPropertyType("Blended");
        GS.SOT.RiskProfile.initialPropertyType = "Blended";
        setNetYieldonProperty(riskVal, "Blended");
      } else if (riskVal < 6 && propertyType === "Commercial - Retail") {
        setPropertyType("Blended");
        GS.SOT.RiskProfile.initialPropertyType = "Blended";
        setNetYieldonProperty(riskVal, "Blended");
      } else if (
        riskVal < 7 &&
        (propertyType === "Commercial - Office" ||
          propertyType === "Commercial - Retail")
      ) {
        setPropertyType("Blended");
        GS.SOT.RiskProfile.initialPropertyType = "Blended";
        setNetYieldonProperty(riskVal, "Blended");
      } else {
        setNetYieldonProperty(riskVal);
      }
    }
    API.AutoSave(window.location.href);
  };
  return (
    <Container>
      <IndicatorContainer>
        <ProgessIndicator
          value={RiskVal}
          progressColor={progressColor}
          txtColor={"white"}
          min={min}
          max={max}
          step={step}
          setSliderVal={setSliderVal}
          SetRisk={SetRisk}
          Sliderval={value}
          onChange={(e) => {
            setSliderVal(e.value);
            SetRisk(e.value);
            OnChange();
          }}
          disabled={true}
        />
      </IndicatorContainer>
      <BarContainer
        defaultValue={value > 87.5 ? 87.5 : value < 17.6 ? 0 : value}
        progressColor={progressColor}
      >
        <div className="line-hider"></div>
        <div className="line-hider1"></div>
        <span className="bar">
          <span className="fill"></span>
        </span>
      </BarContainer>

      <MySlider
        className="slider"
        min={min}
        max={max}
        step={step}
        progressColor={progressColor}
        value={value > 87.5 ? 87.5 : value < 17.5 ? 17.5 : value}
        onChange={(e) => {
          setSliderVal(e.value);
          SetRisk(e.value);
          OnChange();
        }}
      />
    </Container>
  );
};

export default SliderComponent;
const IndicatorContainer = styled.div`
  position: relative;
`;
const BarContainer = styled.div`
  cursor: pointer;
  position: relative;
  .line-hider1 {
    width: 12.8vw;
    height: 30px;
    position: absolute;
    left: -62px;
    top: -10px;
    background-color: white;
    z-index: 10;
  }
  .line-hider {
    width: 10.8vw;
    @media (max-width: 1920px) {
      width: 11vw;
    }
    height: 30px;
    position: absolute;
    right: -75px;
    top: -10px;
    background-color: white;
    z-index: 10;
  }
  .bar {
    position: absolute;
    z-index: 1;
    left: 2px;
    top: 0px;
    width: 100%;
    height: 3px;
    border-radius: 5px;
    background-color: #ebf2fa;
    overflow: hidden;
    transition: all 0.2s ease;
    .fill {
      transition: all 0.2s ease;
      display: block;
      width: ${({ defaultValue }) =>
        defaultValue ? `${defaultValue}%` : "0%"};
      height: 100%;
      background-color: ${({ progressColor }) => progressColor};
    }
  }
`;

const Container = styled.div`
  position: relative;
`;
const MySlider = styled(Slider)`
  position: relative;
  z-index: 2;
  cursor: pointer;
  width: 100%;
  height: 4px !important;
  border-radius: 5px !important;
  -webkit-appearance: none !important;
  outline: none;
  background-color: transparent !important;
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: -10px;
    width: 10px;
    height: 10px;
    background-color: ${({ progressColor }) => progressColor};
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    box-shadow: 0 0 0 0 rgba(98, 0, 238, 0.1);
    transition: 0.2s ease-in-out;
    position: relative;
    z-index: 2;
  }
  .p-slider-range {
    background-color: transparent !important;
  }
  .p-slider-handle {
    margin-top: -6px !important;
    width: 10px !important;
    height: 10px !important;
    color: green !important;
    background-color: ${({ progressColor }) =>
      progressColor ? progressColor : "orange"} !important;
    border-radius: 50% !important;
    cursor: pointer !important;
    background-image: none !important;
  }
  .p-slider-handle:focus {
    outline: none !important;
    border: none !important;
  }
`;
