const initialState = {
  name: "PropVestingF",
  first_name: "",
  last_name: "",
  email: "",
  mobile_no: "",
  telephone_no: "",
  logos: "",
  password: "",
  oldpassword: "",
};

const UserProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PROFILE":
      state = action.payload;
      return state;
    case "UPDATE_PROFILE":
      state = { ...state, ...action.payload };
      return state;
    case "GET_PROFILE":
      return state;
    default:
      return state;
  }
};
export default UserProfileReducer;
