import React, {  useState } from "react";
import { Link,  } from "react-router-dom";
import { API } from "../../services/api";
import Avatar from "../Avatar/Avatar";
import Loading from "../Loading";
import {
  SearchbarText,
  SearchbarContainer,
  SearchResultContainer,
} from "./Searchbar.style";

const Searchbar = ({
  SearchQuery = "",
  setSearchQuery = () => {},
  placeholder,
  FromHeader = false,
}) => {
  const [VendorsData, setVendorsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [HeaderSearchQuery, setHeaderSearchQuery] = useState("");
  const [ShowSuggestion, setShowSuggestion] = useState(false);

  const getVendors = async (query) => {
    let user_id = "610a0f1dc61edfcf62d62c52";
    let response = await API.get(
      `contacts?office_id=${user_id}${query !== "" ? `&search=${query}` : ""}`
    );
    setVendorsData(response?.data || []);
    setLoading(false);
  };

  let onSearch = (query) => {
    setHeaderSearchQuery(() => query);
    setShowSuggestion(true);
    setLoading(true);
    let wait = setTimeout(() => getVendors(query), 250);
    return () => {
      clearTimeout(wait);
    };
  };

  return (
    <>
      <SearchbarContainer>
        <i className="pi pi-search search-icon" />
        <SearchbarText
          value={FromHeader ? HeaderSearchQuery : SearchQuery}
          onChange={(e) =>
            FromHeader
              ? onSearch(e.target.value)
              : setSearchQuery(() => e?.target?.value)
          }
          placeholder={placeholder}
        />
       {SearchQuery.length>0 &&  <i className="pi pi-times" style={{marginRight:"10px",cursor:"pointer",color: "#999"}} onClick={()=>setSearchQuery("")}></i>}   
       {HeaderSearchQuery.length>0 && FromHeader===true? <i className="pi pi-times" style={{marginRight:"10px",cursor:"pointer",color: "#999"}} onClick={()=>setHeaderSearchQuery("")}></i>:<></>}   
      
  
      </SearchbarContainer>
      {FromHeader && ShowSuggestion && HeaderSearchQuery !== "" && (
        <SearchResultContainer>
          {loading ? (
            <Loading height="150px" />
          ) : (
            <>
              {VendorsData.length > 0 ? (
                VendorsData?.map((item, idx) => {
                  return (
                    <Link to={`/Clients/Vendor/?id=${item._id}`}>
                      <div
                        key={idx}
                        className={`d-flex flex-row align-items-center py-3 ${
                          idx < VendorsData.length && "border-bottom"
                        }`}
                      >
                        <span>
                          <Avatar
                            width="30px"
                            height="30px"
                            imgSrc={item.photo}
                            fontSize="8.8px"
                            textColor="#fefefe"
                            text={item.full_name}
                          />
                        </span>
                        <span className="ml-3">
                          <div>{item.full_name}</div>
                          <div>{item.email}</div>
                        </span>
                      </div>
                    </Link>
                  );
                })
              ) : (
                <div
                  style={{ height: "150px",color:"#999" }}
                  className="d-flex justify-content-center align-items-center"
                >
                  No Result Found...
                </div>
              )}
            </>
          )}
        </SearchResultContainer>
      )}
    </>
  );
};

export default Searchbar;
