import React, { useState, useRef, useEffect } from "react";
import { ContactContainer, ContactDropDown } from "./Contact.style";
import Button from "../Button/Button";
import Avatar from "../Avatar/Avatar";
import { API } from "../../services/api";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { ValidateEmail, ValidateName } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { Vendors } from "../../redux/actions";
import { GS } from "../../services/global";
import { useNavigate } from "react-router-dom";
import ContactUser from "../SvgComponents/ContactUser";
const ContactCreate = ({ data }) => {
  const toast = useRef(null);
  const [alldata, setalldata] = useState([]);
  const navigate = useNavigate();

  let dispatch = useDispatch();
  const mystate = useSelector((state) => state.Vendors);
  let newuserId = "";
  const [showPass, setShowPass] = useState(true);

  let MySOT = {
    VendorId: null,
    Sheets: {},
    ai_matrix: {
      Scenario: [1, 3, 3, 2, 1, 1, 3, 3, 2, 1],
      Dependant: 7000,
      BaseExpense: [17800, 26040],

      BuildingReport: 1200,
      PurchasePriceDeviation: 5.3,
      RiskPercentage: { 1: 5, 2: 6, 3: 7 },
    },
    PortfolioSummary: {
      tableData: [],
      Properties: {
        retail: [],
        office: [],
        residential: [],
        other: [],
        industry: [],
      },
      isLocked: false,
    },
    PropertyGenerators: [],
    BorrowingCalculator: {
      income_goal: 0,
      FinancialYear: 0,
      salary_after_tax: 0,
      salary_after_tax_term: "Annually",
      other_income_net: 0,
      other_income_net_term: "Annually",
      partner_salary_after_tax: 0,
      partner_salary_after_tax_term: "Annually",
      dependants: 0,
      car_loan_payment: 0,
      car_loan_payment_2: 0,
      car_loan_payment_2_term: "Monthly",
      annual_expenses: 0,
      annual_expenses_2: 0,
      other_payments: 0,
      other_payments_2: 0,
      other_payments_2_term: "Monthly",
      uaaae: true,
      credit_card_limit: 0,
      credit_card_limit_2: 0,
      credit_card_limit_2_term: "Annually",
      existing_home_loan: 0,
      existing_home_loan_2_term: "Monthly",
      interest_rate: 0,
      loan_term: 0,
      uirb: false,
      base_expanse: 0,
      dependant_base_expanse: 0,
      servicable_income_factor: 0,
      default_bp_interest_rate: 0,
      can_borrow1: 0,
      monthly_repayment: 0,
      can_borrow2: 0,
      monthly_repayment2: 0,
      period: "Annually",
      value1: 0,
      value2: 0,
      Checked: false,
      InterestOnly: false,
      PrincipalInterest: false,
      IncomeType: "Net",
      IncomeGoalStatus: false,
    },
    Equity: {
      Cash: {
        type: "Net",
        value: 0,
      },
      LiquidAssets: {
        type: "Net",
        value: 0,
      },
      PPRInterestRate: "",
      PPRMortage: "",
      HomeLoanPayment: {
        type: "Yearly",
        value: 0,
      },
      interest: false,
      PrincipleInterest: false,
      properties: [],
    },
    StrategicSpending: {
      balanceSheet: 0,
      cashOnHand: 0,
      accountYour: 0,
      accountPartner: 0,
      liabilities: 0,
      creditCardYours: 0,
      creditCardPartner: 0,
      monthlyCashflow: 0,
      income: 0,
      netSalaryYour: 0,
      netSalaryPartner: 0,
      rentalIncome: 0,
      expenses: 0,
      AutoTransport: 0,
      BillsUtilities: 0,
      Education: 0,
      Entertainment: 0,
      Equipment: 0,
      FeesCharges: 0,
      OtherExpenses: 0,
      FinancialAccounting: 0,
      FoodDining: 0,
      GiftsDonations: 0,
      HealthFitness: 0,
      HealthInsurance: 0,
      LoansCreditCards: 0,
      Misc: 0,
      RentMortgageProperty: 0,
      Shopping: 0,
      Travel: 0,
      CarLoan: 0,
      Data: [],
    },
    Progress: {
      steps: 5,
      completed: 0,
      current: 1,
    },
    RiskProfile: {
      NetYieldonProperty: "5%",
      initialPropertyType: "Residential",
    },
  };

  const [userdata, setuserdata] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "",
    telephone_no: "",
    contact_type: "",
    password: "",
    agent: data.full_name,

    preferred_contact_method: "",
    photo: "",
    role_id: "6193a50669fff80382457d27",
    office_id: "610a0f1dc61edfcf62d62c52",
  });

  const [address, setaddress] = useState("");

  const ContactTypes = [
    { label: "Vendor", value: "Vendor" },
    { label: "Contact", value: "Contact" },
  ];

  const handleChange = (e) => {
    if (e.target.name === "mobile_no") {
      const formatNumber = formatPhoneNumber(e.target.value);
      setuserdata({ ...userdata, [e.target.name]: formatNumber });
    } else if (e.target.name === "telephone_no") {
      const formatNumber = formatTelephoneNumber(e.target.value);
      setuserdata({ ...userdata, [e.target.name]: formatNumber });
    } else {
      setuserdata({ ...userdata, [e.target.name]: e.target.value });
    }
  };

  const formatPhoneNumber = (num) => {
    if (!num) return num;
    const phoneNumber = num.replace(/[^\d]/g, "");
    if (phoneNumber.length < 4) return phoneNumber;
    if (phoneNumber.length < 7) {
      return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(
        4,
        phoneNumber.length
      )}`;
    }
    return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(
      4,
      7
    )} ${phoneNumber.slice(7, phoneNumber.length)}`;
  };
  const formatTelephoneNumber = (num) => {
    if (!num) return num;
    const phoneNumber = num.replace(/[^\d]/g, "");
    if (phoneNumber.length < 2) return phoneNumber;
    if (phoneNumber.length < 6) {
      return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
        2,
        phoneNumber.length
      )}`;
    }
    return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
      2,
      6
    )} ${phoneNumber.slice(6, phoneNumber.length)}`;
  };

  const saveUser = async () => {
    GS.SOT = MySOT;
    GS.SOT.Progress = { steps: 4, completed: 0, current: 0 };
    let response = await API.post("/contacts", {
      ...userdata,
      contact_address: address,
    });

    if (response.insertedId === null) {
      newuserId = response.insertedId;
      showError(response.message);
    } else {
      newuserId = response.insertedId;
      UpdateData(newuserId);
      showSuccess();
    }
  };
  let UpdateData = async (VendorId) => {
    let user_id = "610a0f1dc61edfcf62d62c52";
    let response = await API.get(`contacts?office_id=${user_id}`);
    GS.SOT.VendorId = VendorId;
    await API.AutoSave(window.location.href);

    dispatch(Vendors(response?.data || []));
  };
  const checkEmailExistance = (email) => {
    let check = false;
    mystate.map((item) => {
      if (item.email === email) {
        check = true;
      }
    });
    alldata.map((item) => {
      let offices = item.offices.data;
      if (offices.length > 0) {
        offices.map((item) => {
          if (item.email == email) {
            check = true;
          }
        });
      }
    });
    return check;
  };
  const handleAdd = async () => {
    if (userdata.first_name === "") {
      showError("First name should not be empty");
    } else if (userdata.last_name === "") {
      showError("Last name should not be empty");
    } else if (userdata.email === "") {
      showError("Email should not be empty");
    } else if (userdata.password === "") {
      showError("Password should not be empty");
    } else {
      ValidateName(userdata.first_name) === false
        ? showError("Name contain atleast 3 characters")
        : ValidateName(userdata.last_name) === false
        ? showError("Name contain atleast 3 characters")
        : ValidateEmail(userdata.email) === false
        ? showError("Invalid Email format ")
        : checkEmailExistance(userdata.email) === true
        ? showError(" Email Already Exist ")
        : saveUser();
    }
  };
  const clear = () => {
    toast.current.clear();
  };

  useEffect(() => {
    const getAdminData = async () => {
      let response = await API.get(`users?role=610a0f1dc61edfcf62d62bb1`);
      setalldata(response.data);
      console.log(response.data);
    };
    getAdminData();
  }, []);
  const showError = (Message) => {
    toast.current.show({
      severity: "success",
      sticky: true,
      content: (
        <div className="flex flex-column p-5" style={{ flex: "1" }}>
          <div className="text-center py-1">
            <p className="popup-error-text">{Message}</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <span
              className="toast-anchor toast-no"
              style={{ marginTop: "15px" }}
              onClick={clear}
            >
              Ok
            </span>
          </div>
        </div>
      ),
    });
  };

  const showSuccess = (Message) => {
    toast.current.show({
      severity: "success",
      sticky: true,
      content: (
        <div
          className="flex flex-column "
          style={{ paddingTop: "55px", paddingBottom: "55px" }}
        >
          <div className="text-center ">
            <div>
              <ContactUser />
            </div>
            <p className="popup-success-text">
              Contact is created, would you like to view the Profile?
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "32px",
            }}
          >
            <span
              className="toast-anchor toast-yes"
              onClick={() => navigate(`/Clients/Profile?id=${newuserId}`)}
            >
              Yes
            </span>
            <span className="toast-anchor toast-no" onClick={clear}>
              No
            </span>
          </div>
        </div>
      ),
    });
  };

  const inputRef = useRef();
  const autoCompleteRef = useRef();

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      setaddress(place.formatted_address);
    });
  }, []);

  return (
    <ContactContainer>
      <Toast
        ref={toast}
        position="center"
        style={{ zIndex: "2", opacity: "1" }}
      />
      <div className="profile-container">
        <h1>Profile</h1>
        <Button onClick={handleAdd} txt="Create Contact" paddingTop />
      </div>
      <div className="avatar-container pb-3">
        <Avatar
          text="CH"
          width="80px"
          height="80px"
          fontSize="23px"
          textColor="#fefefe"
          imgSrc={userdata.photo}
          setuserdata={setuserdata}
          data={userdata}
        />

        <div className="avatar-text-container">
          <p className="main-text">
            {userdata.first_name}&nbsp;{userdata.last_name}
          </p>
          <p className="sub-text">{userdata.contact_type}</p>
        </div>
      </div>
      <div>
        <div
          className="py-3"
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            gap: "25px",
          }}
        >
          <div>
            <p className="input-label mb-2">First name</p>
            <InputText
              style={{ width: "320px" }}
              type={"text"}
              name="first_name"
              className=" edit-input-field"
              value={userdata.first_name}
              onChange={handleChange}
            />
          </div>
          <div>
            <p className="input-label mb-2">Last name</p>
            <InputText
              style={{ width: "320px" }}
              type={"email"}
              className=" edit-input-field"
              name="last_name"
              value={userdata.last_name}
              onChange={handleChange}
            />
          </div>

          <div>
            <p className="input-label mb-2">Email</p>
            <InputText
              style={{ width: "320px" }}
              type={"email"}
              name="email"
              value={userdata.email}
              onChange={handleChange}
              className=" edit-input-field"
            />
          </div>
        </div>
        <div
          className="py-3"
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            gap: "25px",
          }}
        >
          <div>
            <p className="input-label mb-2">Mobile No</p>
            <InputText
              style={{ width: "320px" }}
              type={"tel"}
              name="mobile_no"
              value={userdata.mobile_no}
              onChange={handleChange}
              className=" edit-input-field"
              format
            />
          </div>
          <div>
            <p className="input-label mb-2">Phone Number</p>
            <InputText
              style={{ width: "320px" }}
              type={"text"}
              name="telephone_no"
              className=" edit-input-field"
              value={userdata.telephone_no}
              onChange={handleChange}
            />
          </div>

          <div>
            <p className="input-label mb-2">Contact Type</p>
            <ContactDropDown
              width="320px !important"
              value={userdata.contact_type}
              onChange={(e) =>
                setuserdata({ ...userdata, contact_type: e.value })
              }
              options={ContactTypes}
            />
          </div>
        </div>
        <div
          className="py-3"
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            gap: "25px",
          }}
        >
          <div>
            <p className="input-label mb-2">Residential Address</p>
            <InputText
              style={{ width: "320px" }}
              onFocus={(e) => (e.target.value = "")}
              placeholder="Enter Residential Address"
              ref={inputRef}
              name="contact_address"
              onChange={(e) => setaddress(e.target.value)}
              className=" edit-input-field"
            />
          </div>

          <div>
            <p className="input-label mb-2">Password</p>
            <span className="p-input-icon-right">
              {showPass === false ? (
                <i
                  className="pi pi-eye-slash"
                  style={{ cursor: "pointer", paddingTop: "3px" }}
                  onClick={() => setShowPass(!showPass)}
                ></i>
              ) : (
                <i
                  className="pi pi-eye-slash pi-eye"
                  style={{ cursor: "pointer", paddingTop: "3px" }}
                  onClick={() => setShowPass(!showPass)}
                ></i>
              )}
              <InputText
                style={{ width: "320px" }}
                type={showPass === false ? "password" : "text"}
                name="password"
                value={userdata.password}
                onChange={handleChange}
                autoComplete={false}
              />
            </span>
          </div>
        </div>
      </div>
    </ContactContainer>
  );
};

export default ContactCreate;
