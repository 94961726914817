import { useState } from "react";
import { Dropdown } from "primereact/dropdown";

import classes from "./PinderPage.module.css";
import ImportCSV from "./ImportCSV";
import useWindowDimensions from "../../hooks";
import DomainResidential from "./DomainResidential";
import DomainCommercial from "./DomainCommerical";
import { useParams } from "react-router-dom";

export default function PinderPage() {
const {q}=useParams()
  const [dataType, setDataType] = useState(q);
  const [ApiType, setApiType] = useState("Commerical");
  const [apiTypeOnlineOffline, setApiTypeOnlineOffline] = useState("Online");

  const { width } = useWindowDimensions();

  const ApiTypeSelectItems = [
    { label: "Residential", value: "Residential" },
    { label: "Commerical", value: "Commerical" },
  ];

  const ApiTypeOnlineOfline = [
    { label: "Live Domain", value: "Online" },
    { label: "Local Database", value: "Offline" },
  ];
  

  const DataTypeSelectItems = [
    { label: "Domain Results", value: "domain" },
    { label: "CSV Results", value: "csv" },
  ];


  return (
    <div
      style={{ width: width - 290 + "px" }}
      className={`pinderContainer ${classes.PinderPageContainer}`}
    >
      <div className="d-flex flex-wrap  justify-content-between mt-4">
        <div>
          <h2 className={classes.pageTitle}>Pinder 2.0</h2>
        </div>
        <div>
          <span className={classes.dropdownLabel}>Dashboards</span>
        
          <Dropdown
            className={classes.dropdown1}
            value={dataType}
            options={DataTypeSelectItems}
            onChange={(e) => {
              setDataType(e.value);
            }}
          />
          {dataType === "domain" && (
            <>
            <Dropdown
              className={classes.dropdown}
              value={ApiType}
              options={ApiTypeSelectItems}
              onChange={(e) => {
                setApiType(e.value);
              }}
            />
        
            <Dropdown
            style={{marginLeft:20}}
              className={classes.dropdown}
              value={apiTypeOnlineOffline}
              options={ApiTypeOnlineOfline}
              onChange={(e) => {
                setApiTypeOnlineOffline(e.value);
              }}
            />
            </>
          )}
        </div>
      </div>
      {dataType === "domain" ? (
        ApiType === "Residential" ? (
          <DomainResidential apiTypeOnlineOffline={apiTypeOnlineOffline} />
        ) : (
          <DomainCommercial apiTypeOnlineOffline={apiTypeOnlineOffline} ApiType={ApiType} />
        )
      ) : (
        <ImportCSV />
      )}
    </div>
  );
}
