import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import PinderFilterForCommercial from "./PinderFilterForCommercial";
import PinderSummary from "./PinderSummary";
import PinderTable from "./PinderTable";
import { API } from "../../services/api";
import Loading from "../Loading";

export default function DomainResidential({ ApiType, apiTypeOnlineOffline }) {
  const [TableData, setTableData] = useState([]);
  const [total, setTotal] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState(null);
  const [Filters, setFilters] = useState({ pageNumber: 1, pageSize: 500 });

  const GetTableData = async (filter) => {
    const QueryUrl =
      apiTypeOnlineOffline == "Online"
        ? "/listing/domain/comm?limit=100&skip=0&sort=-listing_id"
        : "listing/domain-cache/comm?limit=100&skip=0";
    setIsLoading(true);
    const selectedFilter = filter ? filter : Filters;
    let res = await API.post(QueryUrl, selectedFilter);
    setTotal(res.totals);
    if (res?.status !== 400) {
      let result = !res.data.length
        ? []
        : res.data.map((v) => {
            let Converted = v["propertyArea"]?.replaceAll("m²", "");
            Converted = Converted?.replaceAll(",", "");
            let index = Converted?.indexOf("-");
            Converted = Converted?.slice(index + 1, Converted?.length - 1) || 0;
            return {
              yeild: null,
              income: null,
              ...v,
              propertyArea: Number(Converted) || 0,
            };
          });
      setErrorMessage(null);
      setTableData(result);
      setIsLoading(false);
    } else {
      setErrorMessage(res?.message);
      setIsLoading(false);
    }
  };

  const [ActiveColumnFilter, setActiveColumnFilter] = useState({
    by: "",
    type: "",
  });

  useEffect(() => {
    // let filtered = TableData?.filter((item) => item == item);
    // setTableData([...filtered]);
    console.log("ActiveColumnFilter", ActiveColumnFilter);
  }, [ActiveColumnFilter]);

  const ApplyFilter = (localFilter) => {
    setFilters({ ...Filters, ...localFilter });
    GetTableData({ ...Filters, ...localFilter });
  };
  // const headers = [
  //   "Street Address",
  //   "Property Type",
  //   "Sale or Lease",
  //   "Primary Listing Agent",
  //   "Property Name",
  //   "Floor Area",
  //   "Land Area",
  //   "Total Car Spaces",
  //   "Parking Comments",
  //   "Zoning",
  //   "Tenancy",
  //   "Contact Details",
  //   "Price",
  //   "Return on Investment",
  //   "Net Income",
  //   "Current Occupancy Rate",
  //   "Current Number of Tenants",
  //   "Tenant Details",
  //   "Headline",
  //   "Descriptions",
  //   "Auction",
  // ];
  // const headers = [
  //   { name: "Street Address", key: "" },
  //   { name: "Property Type", key: "propertyType" },
  //   { name: "Sale or Lease", key: "link" },
  //   { name: "Primary Listing Agent", key: "displayFullName" },
  //   { name: "Property Name", key: "name" },
  //   { name: "Floor Area", key: "" },
  //   { name: "Land Area", key: "propertyArea" },
  //   { name: "Total Car Spaces", key: "carspaceCount" },
  //   { name: "Parking Comments", key: "" },
  //   { name: "Zoning", key: "" },
  //   { name: "Tenancy", key: "" },
  //   { name: "Contact Details", key: "" },
  //   { name: "Price", key: "price" },
  //   { name: "Return on Investment", key: "" },
  //   { name: "Net Income", key: "" },
  //   { name: "Current Occupancy Rate", key: "" },
  //   { name: "Current Number of Tenants", key: "" },
  //   { name: "Tenant Details", key: "" },
  //   { name: "Headline", key: "headline" },
  //   { name: "Descriptions", key: "" },
  //   { name: "Auction", key: "" },
  // ];

  const headers = [
    { name: "Days Active", key: "days_active" },
    { name: "Address", key: "address" },
    { name: "Link", key: "ad.url" },
    { name: "Price", key: "price" },
    { name: "Estimated Price", key: "est_price" },
    { name: "Floor Area", key: "propertyArea" },
    { name: "Price per m2", key: "name" },
    { name: "Land Area", key: "" },
    { name: "Income", key: "income" },
    { name: "Yield", key: "yeild" },
    { name: "Invest", key: "" },
    { name: "Tenure Type", key: "tenure_type" },
    { name: "Tenant Details", key: "tenants_type" },
    { name: "Property Type", key: "propertyType" },
    { name: "# of Tenants", key: "number_of_tenants" },
    { name: "Description", key: "headline" },
    { name: "Auction", key: "sale_auction" },
  ];

  return (
    <div>
      <Row className="mt-4">
        <Col sm={12} md={12} lg={3}>
          <PinderFilterForCommercial
            ErrorMessage={ErrorMessage}
            ApplyFilter={ApplyFilter}
          />
        </Col>
        <Col sm={12} md={12} lg={4}></Col>
        <Col sm={12} md={12} lg={5}>
          <PinderSummary totals={total} />
        </Col>
      </Row>
      {isLoading ? (
        <Loading />
      ) : TableData.length > 0 ? (
        <PinderTable
          ApiType={ApiType}
          headers={headers}
          tableData={TableData}
          ActiveColumnFilter={ActiveColumnFilter}
          setActiveColumnFilter={setActiveColumnFilter}
        />
      ) : (
        ""
      )}
    </div>
  );
}
