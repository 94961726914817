import React from "react";
import PreviousButton from "../SvgComponents/PreviousButton";
import NextButton from "../SvgComponents/NextButton";
import { Box, PaginationWrapper } from "./Pagination.style";
const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
  const endPage = pageNumbers[pageNumbers.length - 1];
  const onlypages = pageNumbers.slice(currentPage - 1, currentPage + 2);

  if (currentPage === endPage) {
    const p = onlypages[0];
    onlypages.unshift(p - 1);
    onlypages.unshift(p - 2);
  } else if (currentPage === endPage - 1) {
    const p = onlypages[0];
    onlypages.unshift(p - 1);
  }
  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  return (
    <nav>
      <PaginationWrapper>
        <PreviousButton onClick={prevPage} currentPage={currentPage} />
        {nPages > 1 ? (
          onlypages.map((pgNumber, idx) => (
            <>
              {pgNumber !== 0 && (
                <Box
                  key={idx}
                  active={currentPage === pgNumber ? true : false}
                  onClick={() => setCurrentPage(pgNumber)}
              >
                  <span>{pgNumber}</span>
                </Box>
              )}
            </>
          ))
        ) : (
          <Box active={true}>
            <span>1</span>
          </Box>
        )}

        <NextButton
          onClick={nextPage}
          currentPage={currentPage}
          endPage={endPage}
        />
      </PaginationWrapper>
    </nav>
  );
};

export default Pagination;
