import { Text, AvatarContainer } from "./Avatar.style";
import FileBase64 from "react-file-base64";
import EditIcon from "../../assets/images/Edit-icon.svg";
import { Image } from "react-bootstrap";

const Avatar = ({
  width,
  height,
  text,
  imgSrc,
  setImgSrc,
  fontSize,
  textColor,
  setuserdata,
  data,
  userProfile = false,
}) => {
  let textdata = [];
  let firstname = "";
  let l = "";
  let c = "";
  if (containsWhitespace(text)) {
    textdata = text.split(" ");
    firstname = textdata[0];
    l = textdata.length;
    let lastname = textdata[l - 1];
    c = firstname[0] + lastname[0];
  } else {
    firstname = text;
    c = firstname;
  }
  function containsWhitespace(str) {
    return /\s/.test(str);
  }

  return (
    <>
      <AvatarContainer width={width} height={height} imgSrc={imgSrc}>
        {imgSrc?.length > 0 ? (
          <>
            <Image
              className="rounded-circle"
              src={imgSrc}
              width={width}
              height={height}
            />
          </>
        ) : userProfile ? (
          <Text color={textColor} fontSize={fontSize}>
            {data?.first_name && data?.last_name
              ? `${data?.first_name.trim()[0]} ${data?.last_name.trim()[0]}`
              : ""}
          </Text>
        ) : (
          <Text color={textColor} fontSize={fontSize}>
            {c ? c : "ND"}
          </Text>
        )}
        {setuserdata && (
          <div className="file">
            <FileBase64
              onDone={({ base64 }) => {
                setuserdata({ ...data, logos: base64,photo:base64 });
                setImgSrc(base64);
              }}
            />
          </div>
        )}
        {setuserdata && (
          <div className="edit-icon-container">
            <Image src={EditIcon} />
          </div>
        )}
      </AvatarContainer>
    </>
  );
};

export default Avatar;
