import { useEffect, useRef, useState } from "react";
import { Image } from "react-bootstrap";
import PlusIcon from "../../assets/images/arrowdown.png";
import MinusIcon from "../../assets/images/arrowup.png";
import ArrowIcon from "../../assets/images/arrowW.png";
import classes from "./PinderPage.module.css";
import Pagination from "../Pagination/Pagination";
import { CurrencyFormatter } from "../../utils";
import ColumnFilter from "./ColumnFilter";
import { InputNumber } from "primereact/inputnumber";
import YieldBox from "./YieldBox";
import EstPrice from "./EstPrice";
import EstIncome from "./EstIncome";

export default function PinderTable({ tableData, headers, ApiType }) {
  const [TableData, setTableData] = useState(tableData);
  const [LocalData, setLocalData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ScrollPosition, setScrollPosition] = useState(0);
  const [, setCurrentColumn] = useState(0);
  const [ShowData, setShowData] = useState([]);
  const [FetchedData, setFetchedData] = useState(tableData);
  const [editValue, setEditValue] = useState(null);
  const [Reset, setReset] = useState([{ columnId: null }]);

  const TableRef = useRef();
  const [ActiveColumnFilter, setActiveColumnFilter] = useState({
    by: "",
    type: "",
  });

  const [selectedOptions, setSelectedOptions] = useState({
    by: "",
    value: [],
  });

  let AscByNumber = (Data) => {
    let ascArr = [...Data];
    ascArr.sort(function (a, b) {
      return b[ActiveColumnFilter?.by] - a[ActiveColumnFilter?.by];
    });
    setTableData([...ascArr]);
  };

  let DescByNumber = (Data) => {
    let descArr = [...Data];
    descArr.sort(function (a, b) {
      return a[ActiveColumnFilter?.by] - b[ActiveColumnFilter?.by];
    });
    setTableData([...descArr]);
  };

  let AscByString = (Data) => {
    let ascArr = [...Data];
    console.log(ascArr, "ascArr");
    ascArr.sort();
    setTableData([...ascArr]);
  };

  let DescByString = (Data) => {
    let descArr = [...Data];
    descArr.sort();
    descArr.reverse();
    setTableData([...descArr]);
  };

  let ApplyColumnFilter = () => { 
    if (ActiveColumnFilter?.by !== "" || selectedOptions.by !== "") {
      let FilterValues = selectedOptions?.value?.map((val) => val.name);

      let FilteredTableData = FetchedData.filter((item) =>
        FilterValues?.includes(item[selectedOptions?.by])
      );

      let Data =
        FilteredTableData?.length > 0 ? FilteredTableData : FetchedData;

      let nonNullValues = Data.filter(
        (item) => item[ActiveColumnFilter?.by] !== null
      );

      let typeOfColumn = typeof nonNullValues[0][ActiveColumnFilter?.by];

      if (ActiveColumnFilter?.type === "asc") {
        if (typeOfColumn === "number") {
          AscByNumber(Data);
        } else {
          AscByString(Data);
        }
      } else if (ActiveColumnFilter?.type === "desc") {
        if (typeOfColumn === "string") {
          DescByString(Data);
        } else {
          DescByNumber(Data);
        }
      } else {
        setTableData([...Data]);
      }
    }
  };

  useEffect(() => {
    ApplyColumnFilter();
  }, [ActiveColumnFilter, selectedOptions]);

  const stringManage = (id, str) => {
    if (str) {
      const data = ShowData.filter((v) => v === id).length ? 500 : 20;
      return str.length > data
        ? str.toString().substring(0, data) + "..."
        : str;
    } else {
      return str;
    }
  };

  let PageSize = 50;

  const nPages =
    TableData?.length > PageSize ? Math.ceil(TableData?.length / PageSize) : 1;

  function Paginate(data, page_size, page_number) {
    setLocalData(
      data?.slice((page_number - 1) * page_size, page_number * page_size)
    );
  }

  let nextBtnNavigation = () => {
    TableRef.current.scrollLeft += 250;
    setScrollPosition(TableRef.current.scrollLeft);
    setCurrentColumn((prevColumn) => (prevColumn >= 84 ? 84 : prevColumn + 1));
  };

  let prevBtnNavigation = () => {
    TableRef.current.scrollLeft -= 250;
    setScrollPosition(TableRef.current.scrollLeft);
    setCurrentColumn((prevColumn) => (prevColumn <= 0 ? 0 : prevColumn - 1));
  };

  useEffect(() => {
    Paginate(TableData, PageSize, currentPage);
  }, [currentPage, TableData]); // eslint-disable-line react-hooks/exhaustive-deps

  function extractContent(html) {
    return new DOMParser().parseFromString(html, "text/html").documentElement
      .textContent;
  }

  const showData = (idx) => {
    // ShowData.push(idx)

    if (ShowData.filter((v) => v === idx).length) {
      setShowData(ShowData.filter((v) => v !== idx));
    } else {
      setShowData([idx, ...ShowData]);
    }
  };

  useEffect(() => {
    if (editValue) {
      const { id, columnName, value } = editValue;
      // if(columnName=="yield")
      // LocalData.map((v,i)=>LocalData[i][columnName]=value)
      // else
      // LocalData[id][columnName]=[value]
      // console.log("ho gai update")
      // setLocalData([...LocalData])

      if (columnName == "yield")
        TableData.map((v, i) => {
          if (Reset?.filter((col) => col?.columnId === i)?.length === 0) {
            TableData[i][columnName] = value;
          } else {
            TableData[i][columnName] = undefined;
            TableData[i]["income"] = undefined;
          }
          if (
            TableData[i]?.price &&
            stringToFirstNumber(TableData[i]?.price) &&
            TableData[i].yield
          )
            TableData[i]["income"] =
              stringToFirstNumber(TableData[i]?.price) *
              (TableData[i]?.yield / 100);
        });
      else TableData[id][columnName] = [value];
      console.log("ho gai update");
      setTableData([...TableData]);
    }
  }, [editValue, Reset]);

  const [ClickedFilter, setClickedFilter] = useState("");
  const [ClickedColumn, setClickedColumn] = useState("");
  const stringToFirstNumber = (value) => {
    return value
      .toString()
      .split("-")[0]
      .replace(/[^0-9]/g, "");
  };
  return (
    <>
      <div className={`d-flex ${classes.TablesContainer} mt-5`}>
        <span
          onClick={prevBtnNavigation}
          className={`${classes.prevNavigationBtn} ${
            ScrollPosition >= 100 ? classes.activeImg : ""
          }`}
        >
          <img
            src={ArrowIcon}
            className={classes.prevNavigationBtnImg}
            alt="<"
          />
        </span>
        <span
          onClick={nextBtnNavigation}
          className={`${classes.navigationBtn} ${
            ScrollPosition <= TableRef?.current?.scrollWidth
              ? classes.activeImg
              : ""
          }`}
        >
          <img src={ArrowIcon} className={classes.navigationBtnImg} alt=">" />
        </span>
        <div
          ref={TableRef}
          className={`d-flex ${classes.TablesContainerInside}`}
        >
          <table className={classes.MainTable}>
            <thead>
              <tr>
                {headers.map((v, index) => (
                  <th key={index}>
                    <span className={classes.columnHeading}>{v?.name}</span>
                    <span onClick={() => setClickedFilter(v?.name)}>
                      <ColumnFilter
                        setTableData={setTableData}
                        tableData={tableData}
                        data={TableData}
                        columnTitle={v}
                        clickedFilter={ClickedFilter}
                        ActiveColumnFilter={ActiveColumnFilter}
                        setActiveColumnFilter={setActiveColumnFilter}
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                        setClickedFilter={setClickedFilter}
                        ApiType={ApiType}
                      />
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {LocalData?.map((row, idx) => {
                return ApiType === "Commerical" ? (
                  <tr
                    onClick={() => showData(idx)}
                    className={classes.cursorPointer}
                    key={idx}
                  >
                    <td>
                      <div className="d-flex align-items-center gap-1">
                        <Image
                          className={classes.upDownArrow}
                          src={
                            ShowData.filter((v) => v === idx).length
                              ? MinusIcon
                              : PlusIcon
                          }
                          onClick={() => showData(idx)}
                          alt=""
                        />
                        {stringManage(idx, row?.days_active)}
                      </div>
                    </td>

                    <td>{row?.address}</td>
                    <td>
                      <a href={row?.ad?.url} target={"_blank"}>
                        {stringManage(idx, "Link")}
                      </a>
                    </td>

                    <td>{row?.price}</td>
                    {/* estimated price */}
                    <td
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setClickedColumn(idx);
                      }}
                    >
                      <EstPrice
                        columnId={idx}
                        ClickedColumn={ClickedColumn}
                        setClickedColumn={setClickedColumn}
                        value={
                          row?.price && stringToFirstNumber(row?.price)
                            ? stringToFirstNumber(row?.price)
                            : row?.income && row?.yield
                            ? row?.income / (row?.yield / 100)
                            : null
                        }
                        setEditValue={setEditValue}
                        stringToFirstNumber={stringToFirstNumber}
                      />
                    </td>

                    <td>{stringManage(idx, row?.propertyArea)}m²</td>
                    <td>
                      {row?.price &&
                        row?.propertyArea &&
                        CurrencyFormatter.format(
                          stringToFirstNumber(row?.price) /
                            stringToFirstNumber(row?.propertyArea)
                        )}
                    </td>
                    <td></td>
                    <td
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setClickedColumn(idx);
                      }}
                    >
                      {/* { stringManage(idx, row?.est_income) } */}
                      {stringManage(idx, row?.est_income) ?? (
                        <EstIncome
                          columnId={idx}
                          ClickedColumn={ClickedColumn}
                          setClickedColumn={setClickedColumn}
                          value={
                            row?.price && row?.yield
                              ? CurrencyFormatter.format(
                                  stringToFirstNumber(row?.price) *
                                    (row?.yield / 100)
                                )
                              : null
                          }
                          setEditValue={setEditValue}
                          stringToFirstNumber={stringToFirstNumber}
                        />
                      )}
                    </td>

                    <td
                      onClick={(e) => {
                        console.log("click");
                        e.preventDefault();
                        e.stopPropagation();
                        setClickedColumn(idx);
                      }}
                    >
                      <YieldBox
                        columnId={idx}
                        ClickedColumn={ClickedColumn}
                        setClickedColumn={setClickedColumn}
                        setEditValue={setEditValue}
                        Reset={Reset}
                        setReset={setReset}
                        // setYield={setYield}
                        value={
                          row?.yield
                            ? row?.yield.toFixed(0) + "%"
                            : row?.price && row?.income
                            ? (
                                stringToFirstNumber(row?.price) / row.income
                              ).toFixed(2) + "%"
                            : null
                        }
                      />
                    </td>
                    <td>
                      {row?.yield &&
                        row?.income &&
                        CurrencyFormatter.format(
                          row?.income / (row?.yield / 100)
                        )}
                    </td>

                    <td>{row?.tenure_type}</td>
                    <td>{stringManage(idx, row?.tenants_type)}</td>

                    <td>{stringManage(idx, row?.propertyType)}</td>

                    <td>{stringManage(idx, row?.number_of_tenants)}</td>

                    <td>{stringManage(idx, row?.headline)}</td>

                    <td>{stringManage(idx, row?.sale_auction)}</td>
                  </tr>
                ) : ApiType === "CSV" ? (
                  <tr
                    onClick={() => showData(idx)}
                    className={classes.cursorPointer}
                    key={idx}
                  >
                    <td>
                      <div className="d-flex align-items-center gap-1">
                        <Image
                          className={classes.upDownArrow}
                          src={
                            ShowData.filter((v) => v === idx).length
                              ? MinusIcon
                              : PlusIcon
                          }
                          onClick={() => showData(idx)}
                          alt=""
                        />
                        {stringManage(idx, row?.days_active)}
                      </div>
                    </td>
                    <td></td>
                    <td>
                      <a href={row?.link[0]} target={"_blank"}>
                        {stringManage(idx, "Link")}
                      </a>
                    </td>
                    <td>{CurrencyFormatter.format(row?.price)}</td>
                    <td></td>
                    <td></td>
                    <td>{stringManage(idx, row?.land_area)}</td>
                    <td>{stringManage(idx, row?.est_income)}</td>
                    <td
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setClickedColumn(idx);
                      }}
                    >
                      <YieldBox
                        columnId={idx}
                        ClickedColumn={ClickedColumn}
                        setClickedColumn={setClickedColumn}
                        setEditValue={setEditValue}
                        Reset={Reset}
                        setReset={setReset}
                        value={
                          row?.yield
                            ? row?.yield.toFixed(0) + "%"
                            : row?.price && row?.income
                            ? (
                                stringToFirstNumber(row?.price) / row.income
                              ).toFixed(2) + "%"
                            : null
                        }
                      />
                    </td>
                    <td></td>
                    <td>{stringManage(idx, row?.tenure_type)}</td>
                    <td>{stringManage(idx, row?.type)}</td>
                    <td>{stringManage(idx, row?.number_of_tenants)}</td>
                    <td>{stringManage(idx, row?.description)}</td>
                    <td>{stringManage(idx, row?.sale_auction)}</td>
                  </tr>
                ) : (
                  // Residential work ===========================================
                  <tr
                    onClick={() => showData(idx)}
                    className={classes.cursorPointer}
                    key={idx}
                  >
                    <td>
                      <div className="d-flex align-items-center gap-1">
                        <Image
                          className={classes.upDownArrow}
                          src={
                            ShowData.filter((v) => v === idx).length
                              ? MinusIcon
                              : PlusIcon
                          }
                          onClick={() => showData(idx)}
                          alt=""
                        />
                        {/* days_active */}
                      </div>
                    </td>
                    <td>{row?.displayableAddress}</td>
                    <td>{/* New or Established	 */}</td>
                    <td>
                      <a
                        target={"_blank"}
                        rel="noreferrer"
                        href={`https://www.domain.com.au/${row?.listingSlug}`}
                      >
                        {stringManage(idx, "Link")}
                      </a>
                    </td>

                    <td
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setClickedColumn(idx);
                      }}
                    >
                      <EstPrice
                        columnId={idx}
                        ClickedColumn={ClickedColumn}
                        setClickedColumn={setClickedColumn}
                        value={
                          row?.price && stringToFirstNumber(row?.price)
                            ? stringToFirstNumber(row?.price)
                            : row?.income && row?.yield
                            ? row?.income / (row?.yield / 100)
                            : null
                        }
                        setEditValue={setEditValue}
                        stringToFirstNumber={stringToFirstNumber}
                      />
                    </td>

                    <td>{stringManage(idx, row?.landArea)}</td>
                    {/* Rental Income */}
                    <td>
                      <EstIncome
                        columnId={idx}
                        ClickedColumn={ClickedColumn}
                        setClickedColumn={setClickedColumn}
                        value={
                          row?.price && row?.yield
                            ? CurrencyFormatter.format(
                                stringToFirstNumber(row?.price) *
                                  (row?.yield / 100)
                              )
                            : null
                        }
                        setEditValue={setEditValue}
                        stringToFirstNumber={stringToFirstNumber}
                      />
                    </td>
                    {/* Yeild */}
                    <td
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setClickedColumn(idx);
                      }}
                    >
                      <YieldBox
                        columnId={idx}
                        ClickedColumn={ClickedColumn}
                        setClickedColumn={setClickedColumn}
                        setEditValue={setEditValue}
                        Reset={Reset}
                        setReset={setReset}
                        value={
                          row?.yield
                            ? row?.yield.toFixed(0) + "%"
                            : row?.price && row?.income
                            ? (
                                stringToFirstNumber(row?.price) / row.income
                              ).toFixed(2) + "%"
                            : null
                        }
                      />
                    </td>
                    {/* Inves */}
                    <td>
                      {row?.yield &&
                        row?.income &&
                        CurrencyFormatter.format(
                          row?.income / (row?.yield / 100)
                        )}
                    </td>
                    <td>{stringManage(idx, row?.propertyType)}</td>
                    <td>
                      {extractContent(
                        stringManage(idx, row?.summaryDescription)
                      )}
                    </td>
                    {/* Auction */}
                    <td></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className={`${classes.TotalCount} text-right pt-1`}>
        Results: {LocalData?.length || 0.0}
      </div>

      <div className="d-flex justify-content-center align-items-center mb-4">
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
}
