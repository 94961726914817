import {ButtonContainer} from "./Button.style"
import { Image } from 'react-bootstrap';
import EditIcon from "../../assets/images/Edit-icon.svg"

import PdfIcon from "../SvgComponents/PdfIcon"
const Button = ({onClick,txt,paddingTop,height}) => {
  return (
    <ButtonContainer onClick={onClick} paddingTop={paddingTop} height={height}>
       {txt==="Edit Contact" && <Image src={EditIcon} width="15px" height="15px" style={{color:"white"}}/>} 
       {txt==="Generate PDF" && <PdfIcon color="white"/>}
        <p>{txt}</p>
        
        </ButtonContainer>
  )
}

export default Button