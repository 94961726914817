
import Header from "../../components/header/Header";
import UserSidebar from "../../components/SideBar/UserSidebar";
import AssumptionSettings from "../../components/AssumptionSettings";
import { HomeContainer, OuterContainer } from "../Home/Home.styles";

export default function Settings() {
  return (
    <OuterContainer>
      <UserSidebar headerPath="Settings" />
      <HomeContainer>
        <Header headerPath="/Settings/Assumptions" />
        <AssumptionSettings />
      </HomeContainer>
    </OuterContainer>
  );
}
