import React from 'react'

const ContactUser = () => {
  return (
    <svg width="88" height="77" viewBox="0 0 88 77" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.117 35.964a7.498 7.498 0 1 0-7.498-7.498 7.508 7.508 0 0 0 7.498 7.498zm0-12.08a4.597 4.597 0 1 1 0 9.194 4.597 4.597 0 0 1 0-9.194zM23.116 38.735a11.252 11.252 0 0 0-8.15 3.408c-2.175 2.218-3.364 5.235-3.364 8.512.002.8.65 1.448 1.45 1.45H33.18c.8-.002 1.447-.65 1.45-1.45 0-3.277-1.19-6.294-3.365-8.512a11.25 11.25 0 0 0-8.15-3.408zm-8.512 10.47a8.871 8.871 0 0 1 2.436-5.032 8.543 8.543 0 0 1 12.152 0 8.913 8.913 0 0 1 2.437 5.032H14.603z" fill="#323232"/>
    <path d="M64.679 10.883H9.57a7.254 7.254 0 0 0-7.25 7.25V54.97a7.254 7.254 0 0 0 7.25 7.25H64.68a7.254 7.254 0 0 0 7.25-7.25V18.134a7.254 7.254 0 0 0-7.25-7.251zm4.35 44.086a4.363 4.363 0 0 1-4.35 4.35H9.57a4.363 4.363 0 0 1-4.35-4.35V18.134a4.363 4.363 0 0 1 4.35-4.35H64.68a4.363 4.363 0 0 1 4.35 4.35v36.835z" fill="#323232"/>
    <path d="M61.11 35.094H44.217a1.45 1.45 0 1 0 0 2.9H61.11a1.45 1.45 0 1 0 0-2.9zM61.11 44.375H44.217a1.45 1.45 0 1 0 0 2.9H61.11a1.45 1.45 0 1 0 0-2.9zM61.11 25.813H44.217a1.45 1.45 0 1 0 0 2.9H61.11a1.45 1.45 0 1 0 0-2.9z" fill="#323232"/>
    <rect x="55" y="44" width="33" height="33" rx="16.5" fill="#fff"/>
    <circle cx="71.5" cy="60.5" r="12.375" stroke="url(#wegsdewqma)" stroke-width="2.75"/>
    <path d="m66 60.5 3.806 3.806a.413.413 0 0 0 .608-.028L77 56.375" stroke="url(#gr9vfrw12b)" stroke-width="2.75" stroke-linecap="round"/>
    <defs>
        <linearGradient id="wegsdewqma" x1="80.25" y1="51.75" x2="62.75" y2="69.25" gradientUnits="userSpaceOnUse">
            <stop stop-color="#01C4FA"/>
            <stop offset="0" stop-color="#01BAF8"/>
            <stop offset="1" stop-color="#0269EB"/>
        </linearGradient>
        <linearGradient id="gr9vfrw12b" x1="75.389" y1="63.417" x2="69.789" y2="55.95" gradientUnits="userSpaceOnUse">
            <stop stop-color="#01C4FA"/>
            <stop offset="0" stop-color="#01BAF8"/>
            <stop offset="1" stop-color="#0269EB"/>
        </linearGradient>
    </defs>
</svg>

  )
}

export default ContactUser