import React from "react";
import { Col, Image, Row } from "react-bootstrap";

import SignInForm from "../../components/SignInForm";

import Logo from "../../assets/images/logo.png";
import Logo1 from "../../assets/images/invest-logic.png";
import SignInBg from "../../assets/images/signin-bg.png";
import SignUpBg from "../../assets/images/signup-bg.png";
import classes from "./stylesheet.module.css";
import { API } from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { getMedianPrice } from "./../../redux/actions";
import { Link } from "react-router-dom";

export default function SignIn() {
  const dispatch = useDispatch();

  (async () => {
    try {
      const { data, offices, other, retail, warehouse } = await API.get(
        "pricefinder/suburb/statistics"
      );

      const medianPrices = {
        office: offices,
        other: other,
        retail: retail,
        industry: warehouse,
        residential: data,
      };
      dispatch(getMedianPrice(medianPrices));
    } catch (err) {}
  })();
  const mystate = useSelector((state) => state.ThemePattern);
  return (
    <Row className={classes.mainContainer}>
      <Col xs={12} sm={12} md={6} lg={6} className={classes.vectorInfo}>
        {mystate == "Propvesting" && (
          <div className={classes.logoContainer}>
            <Image src={Logo} className={classes.logo} />
          </div>
        )}
        {mystate == "Investlogic" && (
          <div className={classes.logoContainer}>
            <div
              style={{
                background: "white",
                width: "200px",
                paddingLeft: "10px",
              }}
            >
              <Image src={Logo1} className={classes.logo} />
            </div>
          </div>
        )}
        <div className={classes.vectorImgContainer}>
          <Image
            src={mystate == "Propvesting" ? SignInBg : SignUpBg}
            className={classes.vectorImg}
          />
        </div>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6}>
        <div className={classes.formContainer}>
          <div>
            <h2 className={classes.greeting}>
              {mystate == "Propvesting"
                ? "WELCOME TO PROPVESTING"
                : "WELCOME TO INVESTLOGIC"}
            </h2>
          </div>
          <div className="my-4">
            <h3 className={classes.title}>Sign in</h3>
          </div>
          <SignInForm />
          <div className="d-flex justify-content-between">
            <div className={classes.link}>
              <Link to="/reset-password" className={classes.link}>
                Reset Password?
              </Link>
            </div>

            <div className={classes.link}>
              Don't have an account?
              <Link to="/signup" className={classes.linkSignup}>
                Signup
              </Link>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
