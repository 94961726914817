import { useEffect, useRef, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Toast } from "primereact/toast";
import { Navigate, useNavigate } from "react-router-dom";
import { API } from "../../services/api";
import classes from "./stylesheet.module.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

export default function ResetPasswordForm() {
  const navigation = useNavigate();
  const [ShowPassword, setShowPassword] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const toast = useRef(null);

  useEffect(() => {
    return () => {
      setOtp("");
      setEmail("");
      setPassword("");
      setOtpSent(false);
    };
  }, []);
  const handleSubmit = async () => {
    if (otpSent === false) {
      const params = { email };
      const res = await API.post("forgetpass", params);
      if (res.status === false) {
        NotificationManager.error(res.message);
      } else {
        return setOtpSent(true);
      }
    } else {
      const params = { otp, password };
      const res = await API.post("otp", params);
      if (res.status === false) {
        NotificationManager.error(res.message);
      } else {
        navigation("/signin");
      }
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Form.Group className="mb-3" controlId="signinForm.email">
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={classes.EmailInput}
            placeholder="Email Address"
            disabled={otpSent}
            required
          />
        </Form.Group>
        {Boolean(otpSent) && (
          <>
            <Form.Group className="mb-3" controlId="signinForm.password">
              <InputGroup className={classes.PasswordInputGroup}>
                <Form.Control
                  type={ShowPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className={classes.PasswordInput}
                  placeholder="New Password"
                  required
                />
                <InputGroup.Text className={classes.passwordShowHide}>
                  <span
                    onClick={() => setShowPassword(!ShowPassword)}
                    className={classes.showHideBtn}
                  >
                    {ShowPassword ? "Hide" : "Show"}
                  </span>
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="signinForm.email">
              <Form.Control
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className={classes.EmailInput}
                placeholder="OTP"
                required
              />
            </Form.Group>
          </>
        )}
        <Button className={classes.loginBtn} type="submit">
          Submit
        </Button>
      </Form>
      <NotificationContainer />
    </>
  );
}
