import classes from "./YieldBox.module.css";
import { useEffect, useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import GradientButton from "../Button/GradientButton";

export default function YieldBox({
  setClickedColumn,
  ClickedColumn,
  columnId,
  setEditValue,
  value,
  Reset,
  setReset,
}) {
  const [ShowYieldBox, setShowYieldBox] = useState(false);
  const [InputValue, setInputValue] = useState(null);

  useEffect(() => {
    if (ClickedColumn !== columnId) {
      setShowYieldBox(false);
    }
  }, [ClickedColumn]);

  const handleToggleBox = () => {
    setShowYieldBox(!ShowYieldBox);
  };

  return (
    <span className={classes.ColumnYieldBoxContainer}>
      <span
        onClick={handleToggleBox}
        className={`${classes.ToggleYieldBoxButton}`}
      >
        {value //&& Reset?.filter((col) => col?.columnId === columnId)?.length === 0
          ? value
          : "Set Yield"}
      </span>
      {ShowYieldBox && (
        <div className={classes.YieldBox}>
          <span className={`border-0 ${classes.columnTitle}`}>
            Set Yield %
            <i
              onClick={() => {
                setShowYieldBox(false);
                setClickedColumn(null);
              }}
              className={`pi pi-times`}
            ></i>
          </span>
          <div className={"d-flex justify-center align-center gap-2"}>
            <InputNumber
              onFocus={(e) => (e.target.value = "")}
              onBlur={(e) => (e.target.value = `${InputValue}%`)}
              value={InputValue || 0}
              onChange={(e) => {
                setInputValue(e.value);
              }}
              mode="decimal"
              minFractionDigits={1}
              maxFractionDigits={2}
              suffix="%"
              className="border-0"
              inputClassName={classes.InputValue}
            />
            {value && (
              <GradientButton
                txt={"Reset"}
                onClick={() => {
                  setClickedColumn("");
                  setShowYieldBox(false);
                  setReset([...Reset, { columnId }]);
                }}
                style={{
                  height: "32px",
                  width: "80px",
                  fontFamily: "Inter",
                  fontSize: "13px",
                }}
              />
            )}
            <GradientButton
              txt={"Set"}
              onClick={() => {
                setClickedColumn("");
                setShowYieldBox(false);
                setReset(false);
                let deleted = Reset?.filter(
                  (col) => col?.columnId !== columnId
                );
                setReset([...deleted]);
                setEditValue({
                  id: columnId,
                  columnName: "yield",
                  value: InputValue,
                });
              }}
              style={{
                height: "32px",
                width: "80px",
                fontFamily: "Inter",
                fontSize: "13px",
              }}
            />
          </div>
        </div>
      )}
    </span>
  );
}
