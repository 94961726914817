import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import PinderFilters from "./PinderFilters";
import PinderSummary from "./PinderSummary";
import PinderTable from "./PinderTable";
import { API } from "../../services/api";
import Loading from "../Loading";

export default function DomainResidential({ apiTypeOnlineOffline }) {
  const [TableData, setTableData] = useState([]);
  const [total, setTotal] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // /listing/domain-cache/resi

  const [Filters, setFilters] = useState({ pageNumber: 0, pageSize: 200 });

  const GetTableData = async (filter) => {
    const QueryUrl =
      apiTypeOnlineOffline == "Online"
        ? "/listing/domain/resi?limit=100&skip=0&sort=-listing_id"
        : "listing/domain-cache/resi?limit=100&skip=0";
    setIsLoading(true);
    const selectedFilter = filter ? filter : Filters;
    let res = await API.post(QueryUrl, selectedFilter);
    setTotal(res.totals);
    if (res?.status) {
      let StructuredData = !res?.data.length
        ? []
        : res?.data?.map((item) => {
            return {
              ...item?.listing,
              ...item?.listing?.priceDetails,
              ...item?.listing?.propertyDetails,
              ...item?.listing?.advertiser,
              price:item.listing.priceDetails.priceFrom ? item.listing.priceDetails.priceFrom:item.listing.priceDetails.price
            };
          });
      setTableData([...StructuredData]);
      setIsLoading(false);
    }
  };

  const ApplyFilter = (localFilter) => {
    setFilters({ ...Filters, ...localFilter });
    GetTableData({ ...Filters, ...localFilter });
  };

  const headers = [
    { name: "Days Active", key: "street" },
    { name: "Address", key: "headline" },
    { name: "New or Established", key: "summaryDescription" },
    { name: "Link", key: "" },
    { name: "Price", key: "price" },
    { name: "Land Area", key: "name" },
    { name: "Rental Income", key: "" },
    { name: "Yield", key: "" },
    { name: "Invest", key: "bathrooms" },
    { name: "Property Type", key: "bedrooms" },
    { name: "Description", key: "" },
    { name: "Auction", key: "carspaces" },
  ];

  return (
    <div>
      <Row className="mt-4">
        <Col sm={12} md={12} lg={3}>
          <PinderFilters ApplyFilter={ApplyFilter} />
        </Col>
        <Col sm={12} md={12} lg={4}></Col>
        <Col sm={12} md={12} lg={5}>
          <PinderSummary totals={total} />
        </Col>
      </Row>
      {isLoading ? (
        <Loading />
      ) : TableData?.length > 0 ? (
        <PinderTable headers={headers} tableData={TableData} />
      ) : (
        ""
      )}
    </div>
  );
}
