import { InputNumber } from "primereact/inputnumber";
import { Slider } from "primereact/slider";
import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { API } from "../../services/api";
import { CurrencyFormatter } from "../../utils";
import styles from "./stylesheet.module.css"

export default function SliderSettings() {
  const [CarLoanSlider, setCarLoanSlider] = useState(1000);
  const [CarLoanSliderValue, setCarLoanSliderValue] = useState(0);

  const [CreditCardLimitSlider, setCreditCardLimitSlider] = useState(1000);
  const [CreditCardLimitValue, setCreditCardLimitValue] = useState(0);

  const [HomeLoanRentalPaymentsSlider, setHomeLoanRentalPaymentsSlider] =
    useState(1000);
  const [HomeLoanRentalPaymentsValue, setHomeLoanRentalPaymentsValue] =
    useState(0);

  const [CurrentCreditCardDebtSlider, setCurrentCreditCardDebtSlider] =
    useState(1000);
  const [CurrentCreditCardDebtValue, setCurrentCreditCardDebtValue] =
    useState(0);

  const [AnnualExpensesSlider, setAnnualExpensesSlider] = useState(1000);
  const [AnnualExpensesValue, setAnnualExpensesValue] = useState(0);

  const [OtherPaymentsSlider, setOtherPaymentsSlider] = useState(1000);
  const [OtherPaymentsValue, setOtherPaymentsValue] = useState(0);

  const [SingleBaseExpenseSlider, setSingleBaseExpenseSlider] = useState(1000);
  const [SingleBaseExpenseValue, setSingleBaseExpenseValue] = useState(0);

  const [DependentBaseExpensiveSlider, setDependentBaseExpensiveSlider] =
    useState(1000);
  const [DependentBaseExpensiveValue, setDependentBaseExpensiveValue] =
    useState(0);

  const [
    ReadyGrossWeeklyRentalReceivedSlider,
    setReadyGrossWeeklyRentalReceivedSlider,
  ] = useState(1000);
  const [
    ReadyGrossWeeklyRentalReceivedValue,
    setReadyGrossWeeklyRentalReceivedValue,
  ] = useState(0);

  const [MortgagePaymentsSlider, setMortgagePaymentsSlider] = useState(1000);
  const [MortgagePaymentsValue, setMortgagePaymentsValue] = useState(0);

  const [isCall, setIsCall] = useState(false);

  let office_id = "610a0f1dc61edfcf62d62c52";

  const handleSliderData = async () => {
    setTimeout(async () => {
      let data = {
        name: "Slider Settings",
        slug: "slider-settings",
        settings: {
          CarLoanSlider,
          CreditCardLimitSlider,
          HomeLoanRentalPaymentsSlider,
          CurrentCreditCardDebtSlider,
          AnnualExpensesSlider,
          OtherPaymentsSlider,
          SingleBaseExpenseSlider,
          DependentBaseExpensiveSlider,
          ReadyGrossWeeklyRentalReceivedSlider,
          MortgagePaymentsSlider,
        },
        office_id: office_id,
      };
      await API.post(`settings`, data);
    }, 2000);
  };

  let getSliderData = async () => {
    let res = await API.get(
      `settings?office_id=${office_id}&slug=slider-settings`
    );
    setCarLoanSlider(res?.data?.settings?.CarLoanSlider || 1);
    setCreditCardLimitSlider(res?.data?.settings?.CreditCardLimitSlider || 1);
    setHomeLoanRentalPaymentsSlider(
      res?.data?.settings?.HomeLoanRentalPaymentsSlider || 1
    );
    setCurrentCreditCardDebtSlider(
      res?.data?.settings?.CurrentCreditCardDebtSlider || 1
    );
    setAnnualExpensesSlider(res?.data?.settings?.AnnualExpensesSlider || 1);
    setOtherPaymentsSlider(res?.data?.settings?.OtherPaymentsSlider || 1);
    setSingleBaseExpenseSlider(
      res?.data?.settings?.SingleBaseExpenseSlider || 1
    );
    setDependentBaseExpensiveSlider(
      res?.data?.settings?.DependentBaseExpensiveSlider || 1
    );
    setReadyGrossWeeklyRentalReceivedSlider(
      res?.data?.settings?.ReadyGrossWeeklyRentalReceivedSlider || 1
    );
    setIsCall(true);
  };

  useEffect(() => {
    getSliderData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    isCall && handleSliderData();
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    CarLoanSlider,
    CreditCardLimitSlider,
    HomeLoanRentalPaymentsSlider,
    CurrentCreditCardDebtSlider,
    AnnualExpensesSlider,
    OtherPaymentsSlider,
    SingleBaseExpenseSlider,
    DependentBaseExpensiveSlider,
    ReadyGrossWeeklyRentalReceivedSlider,
    MortgagePaymentsSlider,
  ]);

  return (
    <Card className={`card ${styles.container}`}>
      <h1 className="card-title mb-3">Slider</h1>
      <Row className="card-row">
        <Col sm={12} xs={12} md={12} lg={4}>
          <div>
            <p className="input-label mb-4">Car Loan Payments</p>
            <div className="d-flex flex-wrap">
              <div className="mb-4 mr-3">
                <Slider
                  value={CarLoanSliderValue}
                  onChange={(e) => setCarLoanSliderValue(e.value)}
                  className={"slider-field "}
                  step={CarLoanSlider}
                  min={0}
                  max={100000}
                />
                <div
                  className={`d-flex justify-content-between mt-3 ${styles.f12}`}
                >
                  {CurrencyFormatter.format(CarLoanSliderValue)}
                </div>
              </div>
              <span className="custom-input-field">
                <InputNumber
                  onFocus={(e) => (e.target.value = "")}
                  onBlur={(e) =>
                    (e.target.value = `${CurrencyFormatter.format(
                      CarLoanSlider
                    )}`)
                  }
                  value={CarLoanSlider}
                  onChange={(e) => {
                    setCarLoanSlider(e.value);
                  }}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  minfractiondigits={0}
                  inputClassName="w-90"
                />
                <span style={{ fontSize: "13px" }} className={`ml-2`}>
                  Increments
                </span>
              </span>
            </div>
          </div>
        </Col>
        <Col sm={12} xs={12} md={12} lg={4}>
          <div>
            <p className="input-label mb-4">Credit Card Limit</p>
            <div className="d-flex flex-wrap">
              <div className="mb-4 mr-3">
                <Slider
                  value={CreditCardLimitValue}
                  onChange={(e) => setCreditCardLimitValue(e.value)}
                  className={"slider-field "}
                  step={CreditCardLimitSlider}
                  min={0}
                  max={100000}
                />
                <div
                  className={`d-flex justify-content-between mt-3 ${styles.f12}`}
                >
                  {CurrencyFormatter.format(CreditCardLimitValue)}
                </div>
              </div>
              <span className="custom-input-field">
                <InputNumber
                  onFocus={(e) => (e.target.value = "")}
                  onBlur={(e) =>
                    (e.target.value = `${CurrencyFormatter.format(
                      CreditCardLimitSlider
                    )} `)
                  }
                  value={CreditCardLimitSlider}
                  onChange={(e) => {
                    setCreditCardLimitSlider(e.value);
                  }}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  minfractiondigits={0}
                  inputClassName="w-90"
                />
                <span style={{ fontSize: "13px" }} className={`ml-2`}>
                  Increments
                </span>
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="card-row">
        <Col sm={12} xs={12} md={12} lg={4}>
          <div>
            <p className="input-label mb-4">Current Credit Card Debt</p>
            <div className="d-flex flex-wrap">
              <div className="mb-4 mr-3">
                <Slider
                  value={CurrentCreditCardDebtValue}
                  onChange={(e) => setCurrentCreditCardDebtValue(e.value)}
                  className={"slider-field "}
                  step={CurrentCreditCardDebtSlider}
                  min={0}
                  max={100000}
                />
                <div
                  className={`d-flex justify-content-between mt-3 ${styles.f12}`}
                >
                  {CurrencyFormatter.format(CurrentCreditCardDebtValue)}
                </div>
              </div>
              <span className="custom-input-field">
                <InputNumber
                  onFocus={(e) => (e.target.value = "")}
                  onBlur={(e) =>
                    (e.target.value = `${CurrencyFormatter.format(
                      CurrentCreditCardDebtSlider
                    )} `)
                  }
                  value={CurrentCreditCardDebtSlider}
                  onChange={(e) => {
                    setCurrentCreditCardDebtSlider(e.value);
                  }}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  minfractiondigits={0}
                  inputClassName="w-90"
                />
                <span style={{ fontSize: "13px" }} className={`ml-2`}>
                  Increments
                </span>
              </span>
            </div>
          </div>
        </Col>
        <Col sm={12} xs={12} md={12} lg={4}>
          <div>
            <p className="input-label mb-4">Annual Expenses</p>
            <div className="d-flex flex-wrap">
              <div className="mb-4 mr-3">
                <Slider
                  value={AnnualExpensesValue}
                  onChange={(e) => setAnnualExpensesValue(e.value)}
                  className={"slider-field "}
                  step={AnnualExpensesSlider}
                  min={0}
                  max={100000}
                />
                <div
                  className={`d-flex justify-content-between mt-3 ${styles.f12}`}
                >
                  {CurrencyFormatter.format(AnnualExpensesValue)}
                </div>
              </div>
              <span className="custom-input-field">
                <InputNumber
                  onFocus={(e) => (e.target.value = "")}
                  onBlur={(e) =>
                    (e.target.value = `${CurrencyFormatter.format(
                      AnnualExpensesSlider
                    )} `)
                  }
                  value={AnnualExpensesSlider}
                  onChange={(e) => {
                    setAnnualExpensesSlider(e.value);
                  }}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  minfractiondigits={0}
                  inputClassName="w-90"
                />
                <span style={{ fontSize: "13px" }} className={`ml-2`}>
                  Increments
                </span>
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="card-row">
        <Col sm={12} xs={12} md={12} lg={4}>
          <div>
            <p className="input-label mb-4">Single Base Expense</p>
            <div className="d-flex flex-wrap">
              <div className="mb-4 mr-3">
                <Slider
                  value={SingleBaseExpenseValue}
                  onChange={(e) => setSingleBaseExpenseValue(e.value)}
                  className={"slider-field "}
                  step={SingleBaseExpenseSlider}
                  min={0}
                  max={100000}
                />
                <div
                  className={`d-flex justify-content-between mt-3 ${styles.f12}`}
                >
                  {CurrencyFormatter.format(SingleBaseExpenseValue)}
                </div>
              </div>
              <span className="custom-input-field">
                <InputNumber
                  onFocus={(e) => (e.target.value = "")}
                  onBlur={(e) =>
                    (e.target.value = `${CurrencyFormatter.format(
                      SingleBaseExpenseSlider
                    )} `)
                  }
                  value={SingleBaseExpenseSlider}
                  onChange={(e) => {
                    setSingleBaseExpenseSlider(e.value);
                  }}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  minfractiondigits={0}
                  inputClassName="w-90"
                />
                <span style={{ fontSize: "13px" }} className={`ml-2`}>
                  Increments
                </span>
              </span>
            </div>
          </div>
        </Col>
        <Col sm={12} xs={12} md={12} lg={4}>
          <div>
            <p className="input-label mb-4">Dependent Base Expensive</p>
            <div className="d-flex flex-wrap">
              <div className="mb-4 mr-3">
                <Slider
                  value={DependentBaseExpensiveValue}
                  onChange={(e) => setDependentBaseExpensiveValue(e.value)}
                  className={"slider-field "}
                  step={DependentBaseExpensiveSlider}
                  min={0}
                  max={100000}
                />
                <div
                  className={`d-flex justify-content-between mt-3 ${styles.f12}`}
                >
                  {CurrencyFormatter.format(DependentBaseExpensiveValue)}
                </div>
              </div>
              <span className="custom-input-field">
                <InputNumber
                  onFocus={(e) => (e.target.value = "")}
                  onBlur={(e) =>
                    (e.target.value = `${CurrencyFormatter.format(
                      DependentBaseExpensiveSlider
                    )} `)
                  }
                  value={DependentBaseExpensiveSlider}
                  onChange={(e) => {
                    setDependentBaseExpensiveSlider(e.value);
                  }}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  minfractiondigits={0}
                  inputClassName="w-90"
                />
                <span style={{ fontSize: "13px" }} className={`ml-2`}>
                  Increments
                </span>
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="card-row">
        <Col sm={12} xs={12} md={12} lg={4}>
          <div>
            <p className="input-label mb-4">Home Loan/Rental Payments</p>
            <div className="d-flex flex-wrap">
              <div className="mb-4 mr-3">
                <Slider
                  value={HomeLoanRentalPaymentsValue}
                  onChange={(e) => setHomeLoanRentalPaymentsValue(e.value)}
                  className={"slider-field "}
                  step={HomeLoanRentalPaymentsSlider}
                  min={0}
                  max={100000}
                />
                <div
                  className={`d-flex justify-content-between mt-3 ${styles.f12}`}
                >
                  {CurrencyFormatter.format(HomeLoanRentalPaymentsValue)}
                </div>
              </div>
              <span className="custom-input-field">
                <InputNumber
                  onFocus={(e) => (e.target.value = "")}
                  onBlur={(e) =>
                    (e.target.value = `${CurrencyFormatter.format(
                      HomeLoanRentalPaymentsSlider
                    )} `)
                  }
                  value={HomeLoanRentalPaymentsSlider}
                  onChange={(e) => {
                    setHomeLoanRentalPaymentsSlider(e.value);
                  }}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  minfractiondigits={0}
                  inputClassName="w-90"
                />
                <span style={{ fontSize: "13px" }} className={`ml-2`}>
                  Increments
                </span>
              </span>
            </div>
          </div>
        </Col>
        <Col sm={12} xs={12} md={12} lg={4}>
          <div>
            <p className="input-label mb-4">Other Payments</p>
            <div className="d-flex flex-wrap">
              <div className="mb-4 mr-3">
                <Slider
                  value={OtherPaymentsValue}
                  onChange={(e) => setOtherPaymentsValue(e.value)}
                  className={"slider-field "}
                  step={OtherPaymentsSlider}
                  min={0}
                  max={100000}
                />
                <div
                  className={`d-flex justify-content-between mt-3 ${styles.f12}`}
                >
                  {CurrencyFormatter.format(OtherPaymentsValue)}
                </div>
              </div>
              <span className="custom-input-field">
                <InputNumber
                  onFocus={(e) => (e.target.value = "")}
                  onBlur={(e) =>
                    (e.target.value = `${CurrencyFormatter.format(
                      OtherPaymentsSlider
                    )} `)
                  }
                  value={OtherPaymentsSlider}
                  onChange={(e) => {
                    setOtherPaymentsSlider(e.value);
                  }}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  minfractiondigits={0}
                  inputClassName="w-90"
                />
                <span style={{ fontSize: "13px" }} className={`ml-2`}>
                  Increments
                </span>
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="card-row">
        <Col sm={12} xs={12} md={12} lg={4}>
          <div>
            <p className="input-label mb-4">Mortgage Payments</p>
            <div className="d-flex flex-wrap">
              <div className="mb-4 mr-3">
                <Slider
                  value={MortgagePaymentsValue}
                  onChange={(e) => setMortgagePaymentsValue(e.value)}
                  className={"slider-field "}
                  step={MortgagePaymentsSlider}
                  min={0}
                  max={100000}
                />
                <div
                  className={`d-flex justify-content-between mt-3 ${styles.f12}`}
                >
                  {CurrencyFormatter.format(MortgagePaymentsValue)}
                </div>
              </div>
              <span className="custom-input-field">
                <InputNumber
                  onFocus={(e) => (e.target.value = "")}
                  onBlur={(e) =>
                    (e.target.value = `${CurrencyFormatter.format(
                      MortgagePaymentsSlider
                    )} `)
                  }
                  value={MortgagePaymentsSlider}
                  onChange={(e) => {
                    setMortgagePaymentsSlider(e.value);
                  }}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  minfractiondigits={0}
                  inputClassName="w-90"
                />
                <span style={{ fontSize: "13px" }} className={`ml-2`}>
                  Increments
                </span>
              </span>
            </div>
          </div>
        </Col>
        <Col sm={12} xs={12} md={12} lg={4}>
          <div>
            <p className="input-label mb-4">
              Ready Gross Weekly Rental Received
            </p>
            <div className="d-flex flex-wrap">
              <div className="mb-4 mr-3">
                <Slider
                  value={ReadyGrossWeeklyRentalReceivedValue}
                  onChange={(e) =>
                    setReadyGrossWeeklyRentalReceivedValue(e.value)
                  }
                  className={"slider-field "}
                  step={ReadyGrossWeeklyRentalReceivedSlider}
                  min={0}
                  max={100000}
                />
                <div
                  className={`d-flex justify-content-between mt-3 ${styles.f12}`}
                >
                  {CurrencyFormatter.format(
                    ReadyGrossWeeklyRentalReceivedValue
                  )}
                </div>
              </div>
              <span className="custom-input-field">
                <InputNumber
                  onFocus={(e) => (e.target.value = "")}
                  onBlur={(e) =>
                    (e.target.value = `${CurrencyFormatter.format(
                      ReadyGrossWeeklyRentalReceivedSlider
                    )} `)
                  }
                  value={ReadyGrossWeeklyRentalReceivedSlider}
                  onChange={(e) => {
                    setReadyGrossWeeklyRentalReceivedSlider(e.value);
                  }}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  minfractiondigits={0}
                  inputClassName="w-90"
                />
                <span style={{ fontSize: "13px" }} className={`ml-2`}>
                  Increments
                </span>
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
}
