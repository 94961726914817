import styled from "styled-components";

export const TimelineContainer = styled.div`
  padding: 165px 135px 48px;
  height: 240px;
  .timeline {
    height: 3px;
    margin: 15px 0 16px 0;
    flex-grow: 0;
    object-fit: contain;
    transform: rotate(-360deg);
    background-image: linear-gradient(
      to right,
      #cd4a5e 33%,
      #fee468 33%,
      #00a779 133%
    );
    position: relative;

    &::after {
      content: "";
      height: 3px;
      right: -17px;

      top: -7px;
      position: absolute;
      border: solid #00a779;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 7px;
      transform: rotate(-45deg);
    }

    .circle-1 {
      height: 25px;
      width: 3px;
      flex-grow: 0;
      object-fit: contain;
      background-color: #cd4a5e;
      position: absolute;
      bottom: -12px;
    }
    .circle-2 {
      height: 25px;
      width: 3px;
      flex-grow: 0;
      object-fit: contain;
      background-color: #fee468;
      position: absolute;
      bottom: -12px;
      left: 33%;
    }
    .circle-3 {
      height: 25px;
      width: 3px;
      flex-grow: 0;
      object-fit: contain;
      background-color: #00a779;
      position: absolute;
      bottom: -12px;
      left: 66%;
    }
    .circle-4 {
      height: 25px;
      width: 3px;
      flex-grow: 0;
      object-fit: contain;
      background-color: #0582f0;
      position: absolute; 
      bottom: -12px;
      left: 80%;
    }

    .timeline-card {
      width: 159px;
      height: 80px;
      flex-grow: 0;
      padding: 25px 0px;
      position: absolute;
      top: -100px;

      div {
        font-family: Inter;
        font-size: 13px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #323232;
      }
    }
    .card-1 {
      left: -3%;
    }
    .card-2 {
      left: 30%;
    }
    .card-3 {
      left: 63%;
    }
    .card-4 {
      left: 97%;
    }
  }
`;
