import React, { useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import Logo1 from "../../assets/images/invest-logic.png";
import DashboardIcon from "../../assets/images/icon-dashboard.png";
import UserIcon from "../../assets/images/icon-user.png";
import Contact from "../../assets/images/contact.png";
import SettingIcon from "../../assets/images/icon-setting.png";
import LogoutIcon from "../../assets/images/icon-logout.png";
import TwoPeople from "../../assets/images/two_people.svg";
import { useNavigate } from "react-router-dom";
import { SideBarContainer, LogoContainer } from "./sidebar.styles";
import PlusIcon from "../../assets/images/plus.svg";
import Sidebaritem from "./Sidebaritem";
import { useSelector } from "react-redux";
const UserSidebar = ({ headerPath }) => {
  const mystate = useSelector((state) => state.SidebarReducer);
  const themestate = useSelector((state) => state.ThemePattern);
  const generatedProp = useSelector((state) => state.PropertyGenerator);

  const navigate = useNavigate();

  const getSummaryProperties = () => {
    if (generatedProp.length === 0 || generatedProp.length === undefined) {
      return [
        {
          link: "/VendorProfile",
          name: "Properties 1",
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
      ];
    } else {
      let arr = [];
      for (let k = 1; k <= generatedProp.length; k++) {
        arr.push({
          link: "/VendorProfile",
          name: `Properties ${k}`,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        });
      }
      return arr;
    }
  };
  const [menuItem, setMenuItem] = useState([
    {
      link: "/",
      name: "Dashboard",
      icon: DashboardIcon,
      active: true,
      activeColor: "rgba(255, 255, 255, 0.15)",
    },
    {
      link: "/Clients",
      name: "Clients",
      icon: UserIcon,
      active: false,
      activeColor: "rgba(255, 255, 255, 0.15)",
      subIcon: PlusIcon,
      subItems: [
        {
          link: "/Clients/Profile",
          name: "Profile Details",
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: "/Clients/Finances",
          name: "Finances",
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: "/Clients/Spending",
          name: "Strategic Spending",
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: "/Clients/Summary",
          name: "Portfolio Summary",
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
          subIcon: PlusIcon,
          subItems: getSummaryProperties(),
        },
        {
          link: "/Clients/IncomePlan",
          name: "Portfolio Plan",
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
      ],
    },
    {
      link: "/ContactList",
      name: "Contacts",
      icon: Contact,
      active: false,
      activeColor: "rgba(255, 255, 255, 0.15)",
      subIcon: PlusIcon,
      subItems: [
        {
          link: "/Vendors",
          name: "Vendors",
          icon: UserIcon,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
      ],
    },
    {
      link: "/agency",
      name: "Agency Profile",
      icon: TwoPeople,
      active: false,
      activeColor: "rgba(255, 255, 255, 0.15)",
      subIcon: PlusIcon,
      subItems: [
        {
          link: "/agency/edit",
          name: "Agency edit page",
          icon: TwoPeople,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: "/agency/users",
          name: "Users",
          icon: TwoPeople,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: "/agency/access",
          name: "Permissions access",
          icon: TwoPeople,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
      ],
    },
    {
      link: "/settings",
      name: "Settings",
      icon: SettingIcon,
      active: false,
      activeColor: "rgba(255, 255, 255, 0.15)",
      subIcon: PlusIcon,
      subItems: [
        {
          link: "/settings/assumptions",
          name: "Assumptions",
          icon: TwoPeople,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: "/settings/slider",
          name: "Slider",
          icon: TwoPeople,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: "/settings/themes",
          name: "Themes",
          icon: TwoPeople,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: "/settings/median-prices",
          name: "Median Prices",
          icon: TwoPeople,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
      ],
    },
    {
      link: "/logout",
      name: "Logout",
      icon: LogoutIcon,
      active: false,
      activeColor: "rgba(255, 255, 255, 0.15)",
    },
  ]);
  useEffect(() => {
    let accountType = sessionStorage.getItem("accountType");
    let userId = sessionStorage.getItem("userId");
    if (accountType === "Contacts") {
      setMenuItem([
        {
          link: "/",
          name: "Dashboard",
          icon: DashboardIcon,
          active: true,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: `/Clients/Profile?id=${userId}`,
          name: "Lifestyle Goals",
          icon: UserIcon,
          active: true,
          activeColor: "rgba(255, 255, 255, 0.15)",
          subIcon: PlusIcon,
          subItems: [
            {
              link: `/Clients/Profile?id=${userId}`,
              name: "Profile Details",
              active: false,
              activeColor: "rgba(255, 255, 255, 0.15)",
            },
            {
              link: `/Clients/Finances?id=${userId}`,
              name: "Finances",
              active: false,
              activeColor: "rgba(255, 255, 255, 0.15)",
            },
            {
              link: `/Clients/Spending?id=${userId}`,
              name: "Strategic Spending",
              active: false,
              activeColor: "rgba(255, 255, 255, 0.15)",
            },
            {
              link: `/Clients/Summary?id=${userId}`,
              name: "Portfolio Summary",
              active: false,
              activeColor: "rgba(255, 255, 255, 0.15)",
              subIcon: PlusIcon,
              subItems: getSummaryProperties(),
              // subItems: [
              //   {
              //     link: "/VendorProfile",
              //     name: "Properties 1",
              //     active: false,
              //     activeColor: "rgba(255, 255, 255, 0.15)",
              //   },
              // ],
            },
            {
              link: `/Clients/IncomePlan?id=${userId}`,
              name: "Portfolio Plan",
              active: false,
              activeColor: "rgba(255, 255, 255, 0.15)",
            },
          ],
        },
        {
          link: "/logout",
          name: "Logout",
          icon: LogoutIcon,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
      ]);
    } else {
      setMenuItem([
        {
          link: "/",
          name: "Dashboard",
          icon: DashboardIcon,
          active: true,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
        {
          link: "/pinder/domain",
          name: "Pinder 2.0",
          icon: UserIcon,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
          subIcon: PlusIcon,
          subItems: [
            {
              link: "/pinder/domain",
              name: "Domian Results",
              active: false,
              activeColor: "rgba(255, 255, 255, 0.15)",
            },
            {
              link: "/pinder/csv",
              name: "CSV Results",
              active: false,
              activeColor: "rgba(255, 255, 255, 0.15)",
            },
          ],
        },
        {
          link: "/Clients",
          name: "Clients",
          icon: UserIcon,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
          subIcon: PlusIcon,
          subItems: [
            {
              link: "/Clients/Profile",
              name: "Profile Details",
              active: false,
              activeColor: "rgba(255, 255, 255, 0.15)",
            },
            {
              link: "/Clients/Finances",
              name: "Finances",
              active: false,
              activeColor: "rgba(255, 255, 255, 0.15)",
            },
            {
              link: "/Clients/Spending",
              name: "Strategic Spending",
              active: false,
              activeColor: "rgba(255, 255, 255, 0.15)",
            },
            {
              link: "/Clients/Summary",
              name: "Portfolio Summary",
              active: false,
              activeColor: "rgba(255, 255, 255, 0.15)",
              subIcon: PlusIcon,
              subItems: getSummaryProperties(),
              // subItems: [
              //   {
              //     link: "/VendorProfile",
              //     name: "Properties 1",
              //     active: false,
              //     activeColor: "rgba(255, 255, 255, 0.15)",
              //   },
              // ],
            },
            {
              link: "/Clients/IncomePlan",
              name: "Portfolio Plan",
              active: false,
              activeColor: "rgba(255, 255, 255, 0.15)",
            },
          ],
        },
        {
          link: "/ContactList",
          name: "Contacts",
          icon: Contact,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
          subIcon: PlusIcon,
          subItems: [
            {
              link: "/Vendors",
              name: "Vendors",
              icon: UserIcon,
              active: false,
              activeColor: "rgba(255, 255, 255, 0.15)",
            },
          ],
        },
        {
          link: "/agency",
          name: "Agency Profile",
          icon: TwoPeople,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
          subIcon: PlusIcon,
          subItems: [
            {
              link: "/agency/edit",
              name: "Agency edit page",
              icon: TwoPeople,
              active: false,
              activeColor: "rgba(255, 255, 255, 0.15)",
            },
            {
              link: "/agency/users",
              name: "Users",
              icon: TwoPeople,
              active: false,
              activeColor: "rgba(255, 255, 255, 0.15)",
            },
            {
              link: "/agency/access",
              name: "Permissions access",
              icon: TwoPeople,
              active: false,
              activeColor: "rgba(255, 255, 255, 0.15)",
            },
          ],
        },
        {
          link: "/settings",
          name: "Settings",
          icon: SettingIcon,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
          subIcon: PlusIcon,
          subItems: [
            {
              link: "/settings/assumptions",
              name: "Assumptions",
              icon: TwoPeople,
              active: false,
              activeColor: "rgba(255, 255, 255, 0.15)",
            },
            {
              link: "/settings/slider",
              name: "Slider",
              icon: TwoPeople,
              active: false,
              activeColor: "rgba(255, 255, 255, 0.15)",
            },
            {
              link: "/settings/themes",
              name: "Themes",
              icon: TwoPeople,
              active: false,
              activeColor: "rgba(255, 255, 255, 0.15)",
            },
            {
              link: "/settings/median-prices",
              name: "Median Prices",
              icon: TwoPeople,
              active: false,
              activeColor: "rgba(255, 255, 255, 0.15)",
            },
          ],
        },
        {
          link: "/logout",
          name: "Logout",
          icon: LogoutIcon,
          active: false,
          activeColor: "rgba(255, 255, 255, 0.15)",
        },
      ]);
    }
  }, [generatedProp]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <SideBarContainer sideBarOpen={mystate}>
      <LogoContainer
        onClick={() => navigate("/")}
        style={
          themestate == "Investlogic"
            ? {
                marginBottom: "20px",
                paddingLeft: "10px",
                paddingRight: "10px",
                backgroundColor: "white",
                height: "auto",
              }
            : { marginBottom: "70px" }
        }
      >
        {themestate == "Propvesting" && <Image src={Logo} width="120%" />}
        {themestate == "Investlogic" && (
          <div>
            <Image src={Logo1} width="100%" />
          </div>
        )}
      </LogoContainer>
      <div style={{ display: "flex", flexDirection: "column", gap: "7px" }}>
        {menuItem.map((item, index) => (
          <Sidebaritem item={item} key={index} activePath={headerPath} />
        ))}
      </div>
    </SideBarContainer>
  );
};

export default UserSidebar;
