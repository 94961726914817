import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Pagination from "../Pagination/Pagination";
import Avatar from "../Avatar/Avatar";
import { ToggleSubSidebar } from "../../redux/actions";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
export default function ListTable({ VendorsData, SearchQuery }) {
  let data = VendorsData || [];
  const { open } = useSelector((state) => state.SubSidebar);
  const dispatch=useDispatch();
  console.log(data,"datalenght")
  let PageSize = 50;
  const [currentPage, setCurrentPage] = useState(1);

  const nPages =
    data?.length > PageSize ? Math.ceil(data?.length / PageSize) : 1;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (SearchQuery !== "") {
      setCurrentPage(1);
    }
    return data?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data]);  // eslint-disable-line react-hooks/exhaustive-deps
  const opensidebar=(e)=>{
    e.preventDefault();
    let prop = "Clients";
    open[prop] = true;
    dispatch(ToggleSubSidebar({ ...open }));

  }
  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th className="name">Name</th>
            <th>Email</th>
            <th>Contact</th>
            <th>Address</th>
            <th>Progress</th>
          </tr>
        </thead>
        <tbody>
          {currentTableData?.map((item, idx) => {
            let percentage =
              (100 / 5) *
              parseInt(
                item.Progress && Boolean(item.Progress.length)
                  ? item.Progress[0].completed
                  : 0
              );
            return (
              <tr key={idx}>
                <td>
                  <div className="avatar-column"
                  onClick={opensidebar}
                  >
                    <Avatar
                      className="mb-4"
                      width="30px"
                      height="30px"
                      imgSrc={item.photo}
                      fontSize="8.8px"
                      textColor="#fefefe"
                      text={item.full_name}
                    />
                    <Link to={`/Clients/Profile/?id=${item._id}`}>
                      {item.full_name}
                    </Link>
                  </div>
                </td>
                <td onClick={opensidebar}>
                  <Link to={`/Clients/Profile/?id=${item._id}`}>
                    {item.email}
                  </Link>
                </td>
                <td onClick={opensidebar}>
                  <Link to={`/Clients/Profile/?id=${item._id}`}>
                    {item.mobile_no}
                  </Link>
                </td>
                <td onClick={opensidebar}>
                  <Link to={`/Clients/Profile/?id=${item._id}`}>
                    {item.contact_address}
                  </Link>
                </td>
                <td onClick={opensidebar}>
                  <div className="progress">
                    <CircularProgressbar
                      maxValue={100}
                      minValue={0}
                      styles={buildStyles({
                        textColor: "#0465eb",
                        pathColor: "#0465eb",
                      })}
                      value={percentage.toFixed(0)}
                      text={`${percentage.toFixed(0)}%`}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="pagination-container">
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
}
