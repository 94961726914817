import moment from "moment";
import { InputNumber } from "primereact/inputnumber";
import { CurrencyFormatter } from "../../utils";
import "./styleSheets.css";
import { GS } from "../../services/global";
import { Image } from "react-bootstrap";
import PlusIcon from "../../assets/images/arrowdown.png";
import MinusIcon from "../../assets/images/arrowup.png";
import { commaSeparators } from "../../utils";
export default function StrategicSpendingTableColumn({
  PrincipleData,
  EquityGains,
  updateEquityGains,
  FilterBy,
  Editable,
  setEditable,
  data,
  isSideBar = false,
  setBalanceSheet,
  balanceSheet,
  setCashOnHand,
  cashOnHand,
  accountYour,
  setAccountYour,
  accountPartner,
  setAccountPartner,
  liabilities,
  setLiabilities,
  monthlyCashflow,
  setMonthlyCashflow,
  income,
  setIncome,
  setOtherIncome,
  OtherIncome,
  netSalaryYour,
  setNetSalaryYour,
  netSalaryPartner,
  setNetSalaryPartner,
  rentalIncome,
  setRentalIncome,
  Education,
  setEducation,
  Entertainment,
  setEntertainment,
  HigherEducationandVocationalTraining,
  setHigherEducationandVocationalTraining,
  Childcare,
  setChildcare,
  PrimaryResidence,
  setPrimaryResidence,
  FoodDining,
  setFoodDining,
  RecreationHolidays,
  setRecreationHolidays,
  HealthFitness,
  setHealthFitness,
  GeneralInsurance,
  setGeneralInsurance,
  LoansCreditCards,
  setLoansCreditCards,
  Shopping,
  setShopping,
  Travel,
  setTravel,
  CarLoan,
  setCarLoan,
  setOtherExpenses,
  OtherExpenses,
  LiabilitiesColumns,
  MonthlyCashFlowColumns,
  ExpensesColumns,
  BalanceSheetColumns,
  CashColumns,
  PrincipleColumns,
  IncomeColums,
  OnChange,
  AsutralianAverageExpensesColumn,
  uaaae,
  PersonalExpenses,
  setPersonalExpenses,
  setEquityGain,
  CreditCards,
  updateCreditCard,
  ScrollPosition,
  ExtraCash,
  ShowPrinciples,
  setShowPrinciples,
}) {
  return (
    <>
      <div
        className={`tbl-column ${
          isSideBar
            ? "tbl-header-column"
            : data?.date === moment().format("MMM-YYYY") &&
              ScrollPosition >= 120
            ? "table-title-current"
            : ""
        }`}
      >
        {isSideBar ? (
          <span className={`${ScrollPosition >= 120 ? "table-title" : ""}`}>
            <div className="placeholder-cell"></div>
            {FilterBy === "ByMonth" &&
              BalanceSheetColumns?.map((column, idx) => (
                <div className={column.classes} key={idx}>
                  <p
                    className={`${
                      Editable === column.title && "EditableColumnTitle"
                    }`}
                  >
                    {column.title}
                  </p>
                </div>
              ))}
            {FilterBy === "ByMonth" || FilterBy === "Cash"
              ? CashColumns?.map((column, idx) => (
                  <div
                    className={column.classes}
                    key={idx}
                    onClick={() =>
                      column.title === "Equity Gain" &&
                      setShowPrinciples(!ShowPrinciples)
                    }
                  >
                    <p
                      className={`${
                        Editable === column.title && "EditableColumnTitle"
                      }`}
                    >
                      {column.title === "Equity Gain" ? (
                        <Image
                          className={"collapseIcon"}
                          src={ShowPrinciples ? MinusIcon : PlusIcon}
                        />
                      ) : (
                        ""
                      )}
                      {column.title.includes("Property")
                        ? `IP${
                            column.title[column.title.length - 25]
                          } - Capital Growth Equity`
                        : column.title}
                    </p>
                  </div>
                ))
              : ""}
            {FilterBy === "ByMonth" || FilterBy === "Cash"
              ? ShowPrinciples &&
                PrincipleColumns?.map((column, idx) => (
                  <div className={column.classes} key={idx}>
                    <p
                      className={`${
                        Editable === column.title && "EditableColumnTitle"
                      }`}
                    >
                      {column.title.includes("Property")
                        ? `IP${idx} - Principle`
                        : column.title}
                    </p>
                  </div>
                ))
              : ""}
            {FilterBy === "ByMonth" &&
              MonthlyCashFlowColumns?.map((column, idx) => (
                <div className={column.classes} key={idx}>
                  <p
                    className={`${
                      Editable === column.title && "EditableColumnTitle"
                    }`}
                  >
                    {column.title}
                  </p>
                </div>
              ))}

            {FilterBy === "ByMonth" || FilterBy === "Income"
              ? IncomeColums?.map((column, idx) => (
                  <div className={column.classes} key={idx}>
                    <p
                      className={`${
                        Editable === column.title && "EditableColumnTitle"
                      }`}
                    >
                      {column.title}
                    </p>
                  </div>
                ))
              : ""}

            {FilterBy === "ByMonth" || FilterBy === "Liabilties"
              ? LiabilitiesColumns?.map((column, idx) => (
                  <div className={column.classes} key={idx}>
                    <p
                      className={`${
                        Editable === column.title && "EditableColumnTitle"
                      }`}
                    >
                      {column.title}
                    </p>
                  </div>
                ))
              : ""}
            {FilterBy === "ByMonth" ? (
              <div className="sidebar-cell income-secondary">
                <p>PPR Monthly Mortgage</p>
              </div>
            ) : (
              ""
            )}
            {FilterBy === "ByMonth"
              ? GS.SOT.Equity.properties.map((item, index) => {
                  return (
                    <div key={index} className="sidebar-cell income-secondary">
                      <p>IP{index + 1} Monthly Mortgage</p>
                    </div>
                  );
                })
              : ""}
            {FilterBy === "ByMonth" || FilterBy === "Expenses"
              ? uaaae
                ? AsutralianAverageExpensesColumn.map((column, idx) => (
                    <div className={column.classes} key={idx}>
                      <p
                        className={`${
                          Editable === column.title && "EditableColumnTitle"
                        }`}
                      >
                        {column.title}
                      </p>
                    </div>
                  ))
                : ExpensesColumns?.map((column, idx) => (
                    <div className={column.classes} key={idx}>
                      <p
                        className={`${
                          Editable === column.title && "EditableColumnTitle"
                        }`}
                      >
                        {column.title}
                      </p>
                    </div>
                  ))
              : ""}
          </span>
        ) : (
          <>
            <div
              style={{ paddingTop: 10 }}
              className="header-cell block text-center"
            >
              <p>
                {data?.date === moment().format("MMM-YYYY")
                  ? "Current"
                  : "Forcast"}
              </p>
              <span>{data?.date}</span>
            </div>
            {FilterBy === "ByMonth" && (
              <div
                className="tbl-cell balanceSheet"
                onClick={() => setEditable("Balance Sheet")}
              >
                {Editable === "Balance Sheet" &&
                data?.date === moment().format("MMM-YYYY") ? (
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `$${balanceSheet.toFixed(2)}`)
                    }
                    value={balanceSheet}
                    onChange={(e) => {
                      setBalanceSheet(e.value);
                      OnChange();
                    }}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    inputClassName="tbl-input border-none bg-white rounded-0"
                  />
                ) : data?.balanceSheet?.total ? (
                  CurrencyFormatter.format(data?.balanceSheet?.total)
                ) : (
                  "$0.00"
                )}
              </div>
            )}
            {FilterBy === "ByMonth" || FilterBy === "Cash" ? (
              <>
                <div
                  className="tbl-cell cashOnHand-primary"
                  onClick={() => setEditable("Cash-on hand")}
                >
                  {Editable === "Cash-on hand" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${cashOnHand.toFixed(2)}`)
                      }
                      value={cashOnHand}
                      onChange={(e) => {
                        setCashOnHand(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.balanceSheet?.cashOnHand?.total ? (
                    CurrencyFormatter.format(
                      data?.balanceSheet?.cashOnHand?.total
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
                <div
                  className="tbl-cell cashOnHand-secondary"
                  onClick={() => setEditable("Account (yours)")}
                >
                  {Editable === "Account (yours)" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${accountYour.toFixed(2)}`)
                      }
                      value={accountYour}
                      onChange={(e) => {
                        setAccountYour(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.balanceSheet?.cashOnHand?.accountYour ? (
                    CurrencyFormatter.format(
                      data?.balanceSheet?.cashOnHand?.accountYour
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
                <div
                  className="tbl-cell cashOnHand-secondary"
                  onClick={() => setEditable("Account (partners)")}
                >
                  {Editable === "Account (partners)" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${accountPartner.toFixed(2)}`)
                      }
                      value={accountPartner}
                      onChange={(e) => {
                        setAccountPartner(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.balanceSheet?.cashOnHand?.accountPartner ? (
                    CurrencyFormatter.format(
                      data?.balanceSheet?.cashOnHand?.accountPartner
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
                <div
                  className="tbl-cell cashOnHand-secondary"
                  onClick={() => setEditable("Extra Cash")}
                >
                  {ExtraCash.find(
                    (item) =>
                      data?.date === moment(item?.date).format("MMM-YYYY")
                  ) &&
                  ExtraCash.find(
                    (item) =>
                      data?.date === moment(item?.date).format("MMM-YYYY")
                  )?.value
                    ? CurrencyFormatter.format(
                        ExtraCash.find(
                          (item) =>
                            data?.date === moment(item?.date).format("MMM-YYYY")
                        )?.value
                      )
                    : "$0.00"}
                </div>
                {EquityGains.map((item, idx) => {
                  return (
                    <div
                    key={idx}
                      className="tbl-cell cashOnHand-secondary"
                      onClick={() => setEditable(item.PropertyName)}
                    >
                      {Editable === item.PropertyName &&
                      moment(data?.date).isBetween(
                        moment(item.startDate)
                          .subtract(1, "M")
                          .format("MMM-YYYY"),
                        moment(item.endDate).add(1, "M").format("MMM-YYYY"),
                        "+"
                      ) ? (
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `$${
                              item.value / item.noOfMonths
                            }`)
                          }
                          value={item.value / item.noOfMonths}
                          onChange={(e) => {
                            updateEquityGains(
                              idx,
                              "value",
                              e.value * item.noOfMonths
                            );
                          }}
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          inputClassName="tbl-input border-none bg-white rounded-0"
                        />
                      ) : item.value &&
                        moment(data?.date).isBetween(
                          moment(item.startDate)
                            .subtract(1, "M")
                            .format("MMM-YYYY"),
                          moment(item.endDate).add(1, "M").format("MMM-YYYY"),
                          "+"
                        ) ? (
                        CurrencyFormatter.format(item.value / item.noOfMonths)
                      ) : (
                        "$0.00"
                      )}
                    </div>
                  );
                })}
                <div
                  className="tbl-cell cashOnHand-secondary"
                  onClick={() => setEditable("Equity Gain")}
                >
                  {Editable === "Equity Gain" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${data?.balanceSheet?.EquityGain.toFixed(
                          2
                        )}`)
                      }
                      value={data?.balanceSheet?.EquityGain}
                      onChange={(e) => {
                        GS.SOT.PropertyGenerators[0].equityGained = e.value;
                        setEquityGain(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.balanceSheet?.EquityGain ? (
                    CurrencyFormatter.format(data?.balanceSheet?.EquityGain)
                  ) : (
                    "$0.00"
                  )}
                </div>
                {ShowPrinciples &&
                  PrincipleData?.map((item,index) => {
                    return (
                      <div
                      key={index}
                        className="tbl-cell cashOnHand-secondary"
                        onClick={() =>
                          setEditable(`${item.PropertyName} - Principle`)
                        }
                      >
                        {Editable === `${item.PropertyName} - Principle` ? (
                          <InputNumber
                            onFocus={(e) => (e.target.value = "")}
                            onBlur={(e) =>
                              (e.target.value = `$${item.PrincipleValue}`)
                            }
                            value={item.PrincipleValue}
                            onChange={item.PrincipleValue}
                            mode="currency"
                            currency="USD"
                            locale="en-US"
                            minfractiondigits={1}
                            inputClassName="tbl-input border-none bg-white rounded-0"
                          />
                        ) : item.PrincipleValue ? (
                          CurrencyFormatter.format(item.PrincipleValue)
                        ) : (
                          "$0.00"
                        )}
                      </div>
                    );
                  })}
              </>
            ) : (
              ""
            )}
            {FilterBy === "ByMonth" && (
              <div
                className="tbl-cell monthlyCashFlow"
                onClick={() => setEditable("MONTHLY SAVINGS")}
              >
                {Editable === "MONTHLY SAVINGS" &&
                data?.date === moment().format("MMM-YYYY") ? (
                  <InputNumber
                    onFocus={(e) => (e.target.value = "")}
                    onBlur={(e) =>
                      (e.target.value = `$${monthlyCashflow.toFixed(2)}`)
                    }
                    value={monthlyCashflow}
                    onChange={(e) => {
                      setMonthlyCashflow(e.value);
                      OnChange();
                    }}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    minfractiondigits={1}
                    inputClassName="tbl-input border-none bg-white rounded-0"
                  />
                ) : data?.monthlyCashflow?.total ? (
                  CurrencyFormatter.format(data?.monthlyCashflow?.total)
                ) : (
                  "$0.00"
                )}
              </div>
            )}
            {FilterBy === "ByMonth" || FilterBy === "Income" ? (
              <>
                <div
                  className="tbl-cell income-primary"
                  onClick={() => setEditable("Income")}
                >
                  {Editable === "Income" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) => (e.target.value = `$${income.toFixed(2)}`)}
                      value={income}
                      onChange={(e) => {
                        setIncome(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.income?.total ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.income?.total
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
                <div
                  className="tbl-cell income-secondary"
                  onClick={() => setEditable("Net Salary (yours)")}
                >
                  {Editable === "Net Salary (yours)" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${netSalaryYour.toFixed(2)}`)
                      }
                      value={netSalaryYour}
                      onChange={(e) => {
                        setNetSalaryYour(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.income?.netSalaryYour ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.income?.netSalaryYour
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
                <div
                  className="tbl-cell income-secondary"
                  onClick={() => setEditable("Net Salary (partners)")}
                >
                  {Editable === "Net Salary (partners)" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${netSalaryPartner.toFixed(2)}`)
                      }
                      value={netSalaryPartner}
                      onChange={(e) => {
                        setNetSalaryPartner(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.income?.netSalaryPartner ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.income?.netSalaryPartner
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
                <div
                  className="tbl-cell income-secondary"
                  onClick={() => setEditable("Rental Income")}
                >
                  {Editable === "Rental Income" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${rentalIncome.toFixed(2)}`)
                      }
                      value={rentalIncome || 0}
                      onChange={(e) => {
                        setRentalIncome(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.total ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.income?.rentalIncome
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
                <div
                  className="tbl-cell income-secondary"
                  onClick={() => setEditable("Other Income")}
                >
                  {Editable === "Other Income" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${OtherIncome.toFixed(2)}`)
                      }
                      value={OtherIncome}
                      onChange={(e) => {
                        setOtherIncome(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.income?.OtherIncome ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.income?.OtherIncome
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
              </>
            ) : (
              ""
            )}

            {FilterBy === "ByMonth" || FilterBy === "Liabilties" ? (
              <>
                <div
                  className="tbl-cell liabilities-primary"
                  onClick={() => setEditable("Liabilities")}
                >
                  {Editable === "Liabilities" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${liabilities.toFixed(2)}`)
                      }
                      value={liabilities}
                      onChange={(e) => {
                        setLiabilities(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.balanceSheet?.liabilities?.total ? (
                    CurrencyFormatter.format(
                      data?.balanceSheet?.liabilities?.total
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
                {CreditCards?.map((cardItem, idx) => {
                  return (
                    <div
                      key={idx}
                      className="tbl-cell liabilities-secondary"
                      onClick={() =>
                        setEditable(
                          `${cardItem?.name}-${cardItem?.owner}-${cardItem?.type}`
                        )
                      }
                    >
                      {Editable ===
                        `${cardItem?.name}-${cardItem?.owner}-${cardItem?.type}` &&
                      data?.date === moment().format("MMM-YYYY") ? (
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `$${cardItem?.minimumPayment}`)
                          }
                          value={cardItem?.minimumPayment}
                          onChange={(e) => {
                            updateCreditCard(idx, "minimumPayment", e.value);
                            OnChange();
                          }}
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          inputClassName="tbl-input border-none bg-white rounded-0"
                        />
                      ) : cardItem?.minimumPayment ? (
                        CurrencyFormatter.format(cardItem?.minimumPayment)
                      ) : (
                        "$0.00"
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              ""
            )}
            {FilterBy === "ByMonth" ? (
              <>
                <div
                  className="tbl-cell income-secondary"
                  onClick={() => setEditable("PPR Monthly Mortgage")}
                >
                  <p>{GS.SOT.Equity.PPR.HomeLoanRepaymentType==="Monthly"?commaSeparators(GS.SOT.Equity.PPR.HomeLoanRepayments):GS.SOT.Equity.PPR.HomeLoanRepaymentType==="Weekly"?(commaSeparators((GS.SOT.Equity.PPR.HomeLoanRepayments*52)/12)):(commaSeparators((GS.SOT.Equity.PPR.HomeLoanRepayments)/12))}</p>
                  
                </div>
                {GS.SOT.Equity.properties.map((item, index) => {
                  return (
                    <div
                  className="tbl-cell income-secondary"
                  onClick={() => setEditable("PPR Monthly Mortgage")}
                >
                  <p>{item.LoanRepaymentsType==="Monthly"?commaSeparators(item.LoanRepayments):item.LoanRepaymentsType==="Weekly"?(commaSeparators((item.LoanRepayments*52)/12)):(commaSeparators((item.LoanRepayments)/12))}</p>
                 
                </div>
                  )
                })}
               
              </>
            ) : (
              ""
            )}
            {FilterBy === "ByMonth" || FilterBy === "Expenses" ? (
              <>
                <div
                  className="tbl-cell expense-primary"
                  onClick={() => setEditable("Expenses")}
                >
                  {Editable === "Expenses" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${PersonalExpenses.toFixed(2)}`)
                      }
                      value={PersonalExpenses}
                      onChange={(e) => {
                        setPersonalExpenses(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.PersonalExpenses ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.PersonalExpenses
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
                {!uaaae && (
                  <>
                    <div
                      className="tbl-cell expense-secondary"
                      onClick={() => setEditable("Primary Residence")}
                    >
                      {Editable === "Primary Residence" &&
                      data?.date === moment().format("MMM-YYYY") ? (
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `$${PrimaryResidence.toFixed(2)}`)
                          }
                          value={PrimaryResidence}
                          onChange={(e) => {
                            setPrimaryResidence(e.value);
                            OnChange();
                          }}
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          inputClassName="tbl-input border-none bg-white rounded-0"
                        />
                      ) : data?.monthlyCashflow?.expenses?.PrimaryResidence ? (
                        CurrencyFormatter.format(
                          data?.monthlyCashflow?.expenses?.PrimaryResidence
                        )
                      ) : (
                        "$0.00"
                      )}
                    </div>
                    <div
                      className="tbl-cell expense-secondary"
                      onClick={() => setEditable("Phone Internet Media")}
                    >
                      {Editable === "Phone Internet Media" &&
                      data?.date === moment().format("MMM-YYYY") ? (
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `$${Entertainment.toFixed(2)}`)
                          }
                          value={Entertainment}
                          onChange={(e) => {
                            setEntertainment(e.value);
                            OnChange();
                          }}
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          inputClassName="tbl-input border-none bg-white rounded-0"
                        />
                      ) : data?.monthlyCashflow?.expenses?.Entertainment ? (
                        CurrencyFormatter.format(
                          data?.monthlyCashflow?.expenses?.Entertainment
                        )
                      ) : (
                        "$0.00"
                      )}
                    </div>
                    <div
                      className="tbl-cell expense-secondary"
                      onClick={() => setEditable("Food & Groceries")}
                    >
                      {Editable === "Food & Groceries" &&
                      data?.date === moment().format("MMM-YYYY") ? (
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `$${FoodDining.toFixed(2)}`)
                          }
                          value={FoodDining}
                          onChange={(e) => {
                            setFoodDining(e.value);
                            OnChange();
                          }}
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          inputClassName="tbl-input border-none bg-white rounded-0"
                        />
                      ) : data?.monthlyCashflow?.expenses?.FoodDining ? (
                        CurrencyFormatter.format(
                          data?.monthlyCashflow?.expenses?.FoodDining
                        )
                      ) : (
                        "$0.00"
                      )}
                    </div>

                    <div
                      className="tbl-cell expense-secondary"
                      onClick={() => setEditable("Recreation & Holidays")}
                    >
                      {Editable === "Recreation & Holidays" &&
                      data?.date === moment().format("MMM-YYYY") ? (
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `$${RecreationHolidays.toFixed(
                              2
                            )}`)
                          }
                          value={RecreationHolidays}
                          onChange={(e) => {
                            setRecreationHolidays(e.value);
                            OnChange();
                          }}
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          inputClassName="tbl-input border-none bg-white rounded-0"
                        />
                      ) : data?.monthlyCashflow?.expenses
                          ?.RecreationHolidays ? (
                        CurrencyFormatter.format(
                          data?.monthlyCashflow?.expenses?.RecreationHolidays
                        )
                      ) : (
                        "$0.00"
                      )}
                    </div>
                    <div
                      className="tbl-cell expense-secondary"
                      onClick={() => setEditable("Clothing And Personal Care")}
                    >
                      {Editable === "Clothing And Personal Care" &&
                      data?.date === moment().format("MMM-YYYY") ? (
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `$${Shopping.toFixed(2)}`)
                          }
                          value={Shopping}
                          onChange={(e) => {
                            setShopping(e.value);
                            OnChange();
                          }}
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          inputClassName="tbl-input border-none bg-white rounded-0"
                        />
                      ) : data?.monthlyCashflow?.expenses?.Shopping ? (
                        CurrencyFormatter.format(
                          data?.monthlyCashflow?.expenses?.Shopping
                        )
                      ) : (
                        "$0.00"
                      )}
                    </div>

                    <div
                      className="tbl-cell expense-secondary"
                      onClick={() => setEditable("Medical & Health")}
                    >
                      {Editable === "Medical & Health" &&
                      data?.date === moment().format("MMM-YYYY") ? (
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `$${HealthFitness.toFixed(2)}`)
                          }
                          value={HealthFitness}
                          onChange={(e) => {
                            setHealthFitness(e.value);
                            OnChange();
                          }}
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          inputClassName="tbl-input border-none bg-white rounded-0"
                        />
                      ) : data?.monthlyCashflow?.expenses?.HealthFitness ? (
                        CurrencyFormatter.format(
                          data?.monthlyCashflow?.expenses?.HealthFitness
                        )
                      ) : (
                        "$0.00"
                      )}
                    </div>

                    <div
                      className="tbl-cell expense-secondary"
                      onClick={() => setEditable("Transport")}
                    >
                      {Editable === "Transport" &&
                      data?.date === moment().format("MMM-YYYY") ? (
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `$${Travel.toFixed(2)}`)
                          }
                          value={Travel}
                          onChange={(e) => {
                            setTravel(e.value);
                            OnChange();
                          }}
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          inputClassName="tbl-input border-none bg-white rounded-0"
                        />
                      ) : data?.monthlyCashflow?.expenses?.Travel ? (
                        CurrencyFormatter.format(
                          data?.monthlyCashflow?.expenses?.Travel
                        )
                      ) : (
                        "$0.00"
                      )}
                    </div>

                    <div
                      className="tbl-cell expense-secondary"
                      onClick={() => setEditable("Public Education")}
                    >
                      {Editable === "Public Education" &&
                      data?.date === moment().format("MMM-YYYY") ? (
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `$${Education.toFixed(2)}`)
                          }
                          value={Education}
                          onChange={(e) => {
                            setEducation(e.value);
                            OnChange();
                          }}
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          inputClassName="tbl-input border-none bg-white rounded-0"
                        />
                      ) : data?.monthlyCashflow?.expenses?.Education ? (
                        CurrencyFormatter.format(
                          data?.monthlyCashflow?.expenses?.Education
                        )
                      ) : (
                        "$0.00"
                      )}
                    </div>
                    <div
                      className="tbl-cell expense-secondary"
                      onClick={() =>
                        setEditable("Higher Education and Vocational Training")
                      }
                    >
                      {Editable ===
                        "Higher Education and Vocational Training" &&
                      data?.date === moment().format("MMM-YYYY") ? (
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `$${HigherEducationandVocationalTraining.toFixed(
                              2
                            )}`)
                          }
                          value={HigherEducationandVocationalTraining}
                          onChange={(e) => {
                            setHigherEducationandVocationalTraining(e.value);
                            OnChange();
                          }}
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          inputClassName="tbl-input border-none bg-white rounded-0"
                        />
                      ) : data?.monthlyCashflow?.expenses
                          ?.HigherEducationandVocationalTraining ? (
                        CurrencyFormatter.format(
                          data?.monthlyCashflow?.expenses
                            ?.HigherEducationandVocationalTraining
                        )
                      ) : (
                        "$0.00"
                      )}
                    </div>
                    <div
                      className="tbl-cell expense-secondary"
                      onClick={() => setEditable("Childcare")}
                    >
                      {Editable === "Childcare" &&
                      data?.date === moment().format("MMM-YYYY") ? (
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `$${Childcare.toFixed(2)}`)
                          }
                          value={Childcare}
                          onChange={(e) => {
                            setChildcare(e.value);
                            OnChange();
                          }}
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          inputClassName="tbl-input border-none bg-white rounded-0"
                        />
                      ) : data?.monthlyCashflow?.expenses?.Childcare ? (
                        CurrencyFormatter.format(
                          data?.monthlyCashflow?.expenses?.Childcare
                        )
                      ) : (
                        "$0.00"
                      )}
                    </div>
                    <div
                      className="tbl-cell expense-secondary"
                      onClick={() => setEditable("General Insurance")}
                    >
                      {Editable === "General Insurance" &&
                      data?.date === moment().format("MMM-YYYY") ? (
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `$${GeneralInsurance.toFixed(2)}`)
                          }
                          value={GeneralInsurance}
                          onChange={(e) => {
                            setGeneralInsurance(e.value);
                            OnChange();
                          }}
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          inputClassName="tbl-input border-none bg-white rounded-0"
                        />
                      ) : data?.monthlyCashflow?.expenses?.GeneralInsurance ? (
                        CurrencyFormatter.format(
                          data?.monthlyCashflow?.expenses?.GeneralInsurance
                        )
                      ) : (
                        "$0.00"
                      )}
                    </div>
                    <div
                      className="tbl-cell expense-secondary"
                      onClick={() => setEditable("Loans & Credit Cards")}
                    >
                      {Editable === "Loans & Credit Cards" &&
                      data?.date === moment().format("MMM-YYYY") ? (
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `$${LoansCreditCards.toFixed(2)}`)
                          }
                          value={LoansCreditCards}
                          onChange={(e) => {
                            setLoansCreditCards(e.value);
                            OnChange();
                          }}
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          inputClassName="tbl-input border-none bg-white rounded-0"
                        />
                      ) : data?.monthlyCashflow?.expenses?.LoansCreditCards ? (
                        CurrencyFormatter.format(
                          data?.monthlyCashflow?.expenses?.LoansCreditCards
                        )
                      ) : (
                        "$0.00"
                      )}
                    </div>
                    <div
                      className="tbl-cell expense-secondary"
                      onClick={() => setEditable("Car Loan")}
                    >
                      {Editable === "Car Loan" &&
                      data?.date === moment().format("MMM-YYYY") ? (
                        <InputNumber
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = `$${CarLoan.toFixed(2)}`)
                          }
                          value={CarLoan}
                          onChange={(e) => {
                            setCarLoan(e.value);
                            OnChange();
                          }}
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          minfractiondigits={1}
                          inputClassName="tbl-input border-none bg-white rounded-0"
                        />
                      ) : data?.monthlyCashflow?.expenses?.CarLoan ? (
                        CurrencyFormatter.format(
                          data?.monthlyCashflow?.expenses?.CarLoan
                        )
                      ) : (
                        "$0.00"
                      )}
                    </div>
                  </>
                )}

                <div
                  className="tbl-cell expense-secondary"
                  onClick={() => setEditable("Other")}
                >
                  {Editable === "Other" &&
                  data?.date === moment().format("MMM-YYYY") ? (
                    <InputNumber
                      onFocus={(e) => (e.target.value = "")}
                      onBlur={(e) =>
                        (e.target.value = `$${OtherExpenses.toFixed(2)}`)
                      }
                      value={OtherExpenses}
                      onChange={(e) => {
                        setOtherExpenses(e.value);
                        OnChange();
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      minfractiondigits={1}
                      inputClassName="tbl-input border-none bg-white rounded-0"
                    />
                  ) : data?.monthlyCashflow?.expenses?.OtherExpenses ? (
                    CurrencyFormatter.format(
                      data?.monthlyCashflow?.expenses?.OtherExpenses
                    )
                  ) : (
                    "$0.00"
                  )}
                </div>
              </>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </>
  );
}
