const initialState = {
    tableData: null,
    PropertyGenerators: null,
  };
  
  const PropertyLocks = (state = initialState, action) => {
    switch (action.type) {
      case "GET_LOCKED_PROPERTY":
        return state;
      case "SET_LOCKED_PROPERTY":
        state = action.payload;
        return state;
      default:
        return state;
    }
  };
  
  export default PropertyLocks;
  