
import Header from "../../components/header/Header";
import UserSidebar from "../../components/SideBar/UserSidebar";
import UserProfile from "../../components/UserProfile";
import { HomeContainer, OuterContainer } from "../Home/Home.styles";

export default function Profile() {
  return (
    <OuterContainer>
      <UserSidebar headerPath="Profile" />
      <HomeContainer>
        <Header headerPath="/Profile" />
        <UserProfile />
      </HomeContainer>
    </OuterContainer>
  );
}
