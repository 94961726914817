import React, { useState } from "react";
import {
  PropertyCardContainer,
  PropertyBox,
  PropertyContent,
} from "./PropertyCard.style";
import { Image } from "react-bootstrap";
import OpenImg from "../../assets/images/Expand_down1.svg";
import CloseImg from "../../assets/images/Expand_down2.svg";
import AnimateHeight from "react-animate-height";
const PropertyCard = ({
  img,
  propertyType,
  progressColor,
  PurchasePrice,
  GrossYield,
  LVR,
  Deposit,
  RiskProfile,
  GrossRentalIncome,
  NetCashflow,
  InterestRate,
  SaleRange,
  number,
}) => {
  const [open, setOpen] = useState(true);
  const height = open ? "auto" : 0;
  return (
    <PropertyCardContainer>
      <Image src={img} />
      <PropertyBox>
        <span>Property {number}</span>

        <PropertyContent style={{ marginTop: "-10px" }}>
          <div className="Property-Content-header">
            <Image
              src={open === true ? OpenImg : CloseImg}
              onClick={() => setOpen(!open)}
            />
          </div>
          <AnimateHeight duration={500} height={height}>
            <div className="Propery-body">
              <div className="column">
                <div className="column-content">
                  <p>Property Type</p>
                  <h1>{propertyType}</h1>
                </div>

                <div className="column-content">
                  <p>LVR</p>
                  <h1>{LVR}</h1>
                </div>
                <div className="column-content">
                  <p>
                    {propertyType === "Residential"
                      ? "Gross Yield"
                      : "Net Yield"}
                  </p>
                  <h1>{GrossYield}</h1>
                </div>
              </div>
              <div className="column">
                <div className="column-content">
                  <p>Purchase Price</p>
                  <h1>{PurchasePrice}</h1>
                </div>
                <div className="column-content">
                  <p>Interest Rate</p>
                  <h1>{InterestRate}</h1>
                </div>
                <div className="column-content">
                  <p> Cashflow Annual</p>
                  <h1>{NetCashflow}</h1>
                </div>
              </div>
              <div className="column">
                <div className="column-content">
                  <p>Deposit Required</p>
                  <h1>{Deposit}</h1>
                </div>

                <div className="column-content">
                  <p>Gross Rental Income</p>
                  <h1>{GrossRentalIncome}</h1>
                </div>
                <div className="column-content">
                  <p>Risk Profile</p>
                  <h1 style={{ color: progressColor }}>{RiskProfile}</h1>
                </div>
              </div>
            </div>
            <div className="availableProperty">
              <div className="content">
                {propertyType === "Office" ? (
                  <p>Office Properties for Sale in Price Range</p>
                ) : (
                  <p>Retail Properties for Sale in Price Range</p>
                )}
                <h1 style={{ textAlign: "center" }}>{SaleRange}</h1>
              </div>
            </div>
          </AnimateHeight>
        </PropertyContent>
      </PropertyBox>
    </PropertyCardContainer>
  );
};

export default PropertyCard;
