import { Card, ListGroup } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { commaSeparators, CurrencyFormatter } from "../../utils";
import "./css.css";

const PropertyCard = ({ details, idx, images, page, data }) => {
  const [adminCheck, setadminCheck] = useState(false);
  const state = useSelector((state) => state.IncomePlan);
  const [img, setImg] = useState(images[idx]);

  useEffect(() => {
    if (state.data && state.data[page * 4 - 4 + idx].listing) {
      setImg(
        `${process.env.REACT_APP_PROXY_ADDRESS}${
          state.data[page * 4 - 4 + idx].listing.media[0].url
        }`
      );
    }
    let accountType = sessionStorage.getItem("accountType");
    if (accountType === "Contacts") {
      setadminCheck(false);
    } else {
      setadminCheck(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Card className="property-card" style={{ backgroundColor: "white" }}>
        <div
          className="w-100 imgContainer"
          style={{ backgroundColor: "white" }}
        >
          <img
            src={img}
            alt=""
            style={{
              backgroundColor: "white",
              height: "230px",
              padding: "10px 10px 0px 10px",
            }}
          />
          <div className="figCaption">
            <figcaption>Property {page * 4 - 4 + idx + 1}</figcaption>
          </div>
        </div>
        <Card.Body style={{ backgroundColor: "white" }}>
          <h3 className="popicon">Retail Space</h3>
        </Card.Body>
        <ListGroup
          className="list-group-flush"
          style={{ backgroundColor: "white" }}
        >
          <ListGroup.Item as="li" active id="active">
            <div className="property-total-text" style={{ color: "white" }}>
              Purchase Price
            </div>
            <div
              className={
                !adminCheck ? `restrictions-card` : "property-price-text"
              }
              style={{ color: "white" }}
            >
              <div className={!adminCheck && "hide"}>
                {commaSeparators(
                  (data?.PropertyGenerators[
                    idx
                  ]?.summaryData?.PurchasePrice).toFixed(2) || 0.0
                )}
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="property-total-text">Deposit</div>
            <div
              className={
                !adminCheck ? `restrictions-card` : "property-price-text"
              }
            >
              <div className={!adminCheck && "hide"}>
                {commaSeparators(
                  data?.PropertyGenerators[idx]?.summaryData?.Deposit || 0.0
                )}
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="property-total-text">Stamp Duty</div>
            <div
              className={
                !adminCheck ? `restrictions-card` : "property-price-text"
              }
            >
              <div className={!adminCheck && "hide"}>
                {commaSeparators(
                  data?.PropertyGenerators[idx]?.summaryData?.StampDuty || 0.0
                )}
              </div>
            </div>
          </ListGroup.Item>

          <ListGroup.Item>
            <div className="property-total-text">Acquisition Cost</div>
            <div
              className={
                !adminCheck ? `restrictions-card` : "property-price-text"
              }
            >
              <div className={!adminCheck && "hide"}>
                {commaSeparators(
                  data?.PropertyGenerators[idx]?.summaryData?.AcquisitionCost
                )}
              </div>
            </div>
          </ListGroup.Item>

          <ListGroup.Item>
            <div className="property-total-text">Total Deposit Required</div>
            <div className={"property-price-text"}>
              {commaSeparators(
                data?.PropertyGenerators[idx]?.summaryData?.TotalDepositRequired
              )}
            </div>
          </ListGroup.Item>

          <ListGroup.Item>
            <div className="property-total-text">Total Purchase Cost</div>
            <div
              className={
                !adminCheck ? `restrictions-card` : "property-price-text"
              }
            >
              <div className={!adminCheck && "hide"}>
                {commaSeparators(
                  data?.PropertyGenerators[idx]?.summaryData?.TotalPurchaseCosts
                )}{" "}
              </div>
            </div>
          </ListGroup.Item>

          <ListGroup.Item>
            <div className="property-total-text">Cashflow (Annual)</div>
            <div className="property-price-text">
              {CurrencyFormatter.format(
                data?.PropertyGenerators[idx]?.summaryData?.CashflowAnnual
              )}
            </div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="property-total-text">Cashflow (Monthly)</div>
            <div className="property-price-text">
              {CurrencyFormatter.format(
                data?.PropertyGenerators[idx]?.summaryData?.CashflowMonthly
              )}
            </div>
          </ListGroup.Item>
        </ListGroup>
      </Card>
    </div>
  );
};

export default PropertyCard;
