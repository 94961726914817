import { useState, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { Toast } from "primereact/toast";
import Table from "react-bootstrap/Table";
import Pagination from "../Pagination/Pagination";
import Avatar from "../Avatar/Avatar";
import { Image } from "react-bootstrap";
import DeleteIcon from "../../assets/images/Delete-icon.svg";
import EditIcon from "../../assets/images/Edit-icon.svg";
import { API } from "../../services/api";
const ContactTable = ({ VendorsData, UpdateData, SearchQuery }) => {
  const toast = useRef(null);
  let data = VendorsData || [];
  let PageSize = 50;

  const nPages =
    data?.length > PageSize ? Math.ceil(data?.length / PageSize) : 1;
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (SearchQuery !== "") {
      setCurrentPage(1);
    }
    return data?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDelete = async (id) => {
    let response = await API.delete(`/contacts/${id}`);

    if (response) {
      UpdateData(id);
      showSuccess(response.message);
    }
  };
  const showSuccess = (Message) => {
    toast.current.show({
      severity: "warn",
      life: 2000,
      content: (
        <div
          className="flex flex-column "
          style={{ paddingTop: "0px", paddingBottom: "0px",width:"100%" }}
        >
          <div className="text-center ">
            
            <p className="popup-warn-text">
              {Message}
            </p>
          </div>
          
        </div>
      ),
    });
  };
  // const showSuccess = (Message) => {
  //   toast.current.show({ severity: "success", detail: Message, life: 2000 });
  // };
  return (
    <>
      <Toast ref={toast} />
      <Table responsive>
        <thead>
          <tr>
            <th className="name">Name</th>
            <th>Email</th>
            <th>Contact</th>
            <th>Address</th>
            <th style={{ width: "7%" }}>Type</th>
            <th style={{ width: "3%" }}></th>
            <th style={{ width: "4.9%" }}></th>
          </tr>
        </thead>
        <tbody>
          {currentTableData?.map((item, idx) => {
            return (
              <tr key={idx}>
             
                <td>
                  <div className="avatar-column">
                    <Link to={`/Contact/?id=${item._id}`}>
                      <Avatar
                        width="30px"
                        height="30px"
                        text={item.full_name}
                        fontSize="8.8px"
                        textColor="#fefefe"
                        imgSrc={item.photo}
                      />
                    </Link>
                    <Link to={`/Contact/?id=${item._id}`}>
                      {item.full_name}
                    </Link>
                  </div>
                </td>
                <td>
                  <Link to={`/Contact/?id=${item._id}`}>{item.email}</Link>
                </td>
                <td>
                  <Link to={`/Contact/?id=${item._id}`}>{item.mobile_no}</Link>
                </td>
                <td>
                  <Link to={`/Contact/?id=${item._id}`}>
                    {item.contact_address}
                  </Link>
                </td>
                <td>
                  <Link to={`/Contact/?id=${item._id}`}>Primary</Link>
                </td>
                <td className="LineTd">
                  <Link to={`/Contact/?id=${item._id}`}>
                    <Image src={EditIcon} width="15px" height="15px" />
                  </Link>
                </td>
                <td className="delete-icon">
                  <Image
                    src={DeleteIcon}
                    width="15px"
                    height="15px"
                    onClick={() => handleDelete(item._id)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="pagination-container">
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
};

export default ContactTable;
