const initialState=0;

const generic=(state={
    activeProperty: null,
},action)=>{
    switch (action.type) {
        case "GET_PROPERTY_DATA":
            return state;
        case "SET_PROPERTY_DATA":
            state=action.payload
            return state
        default:
         return state;
    }
}

const second=(state=initialState,action)=>{
    switch (action.type) {
        case "GET_PROPERTY_DATA":
            return state;
        case "SET_PROPERTY_DATA":
            state=action.payload
            return state
        default:
         return state;
    }
}
export {second}
export default generic