import { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../services/api";
import { Vendors } from "../../redux/actions";

import classes from "./stylesheet.module.css";

export default function SignInForm() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [ShowPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resToken, setResToken] = useState(null);
  const [Agree, setAgree] = useState(
    localStorage.getItem("Agree") ? true : false
  );

  const getVendors = async () => {
    let user_id = "610a0f1dc61edfcf62d62c52";
    let response = await API.get(`contacts?office_id=${user_id}`);
    console.log(response)
    dispatch(Vendors(response?.data || []));
  };

  const handleSubmit = async () => {
    localStorage.setItem("Agree", Agree);
    const params = {
      email,
      password,
    };
    const res = await API.post("auth/login", params);
    if (res.access_token) {
      sessionStorage.setItem("Token", res.access_token);
      sessionStorage.setItem("accountType", res.accountType);
      setTimeout(() => getVendors(), 500);
      console.log(res)
      if (res.accountType === "Contacts") {
        
        sessionStorage.setItem("userId", res.userId);
        navigation(`/Clients/Profile?id=${res.userId}`);
      } else {
        sessionStorage.setItem("userId", res.userId);
        navigation("/");
      }
    } else {
      setResToken(res.message);
    }
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Form.Group className="mb-3" controlId="signinForm.email">
        <Form.Control
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={classes.EmailInput}
          placeholder="Email Address"
          required
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="signinForm.password">
        <InputGroup className={classes.PasswordInputGroup}>
          <Form.Control
            type={ShowPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={classes.PasswordInput}
            placeholder="Password"
            required
          />
          <InputGroup.Text className={classes.passwordShowHide}>
            <span
              onClick={() => setShowPassword(!ShowPassword)}
              className={classes.showHideBtn}
            >
              {ShowPassword ? "Hide" : "Show"}
            </span>
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
      {resToken && <p className={classes.hiddenMsg}>{resToken}</p>}
      <Form.Group className="mb-3" controlId="signinForm.checkbox">
        <Form.Check>
          <Form.Check.Input
            type={"checkbox"}
            checked={Agree}
            onChange={() => setAgree(!Agree)}
            className={classes.CheckboxInput}
            required
          />
          <Form.Check.Label className={classes.CheckboxLabel}>
            I agree to all the statements included in the Terms of use / Privacy
            policy.
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>
      <Button className={classes.loginBtn} type="submit">
        Login
      </Button>
    </Form>
  );
}
