import { GS } from "../../services/global"; 
const initialState=GS.SOT.PropertyGenerators.length;

const PropertyGenerator=(state=initialState,action)=>{
    switch (action.type) {
        case "GET_PROPERTY":
            return state;
        case "SET_PROPERTY":
            state=action.payload
            return state
        default:
         return state;
    }
}
export default PropertyGenerator