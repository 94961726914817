import styled from "styled-components";
import { Slider } from "primereact/slider";

export const MySlider = styled(Slider)`
  &.slider-field {
    width: 80%;
  }
  .p-slider-range {
    background: ${({ color }) => `${color} !important`};
  }
  .p-slider-handle {
    border: 2px solid ${({ color }) => color} !important;
    background: white !important;
  }
`;
export const VideoCardContainer = styled.div`
  box-sizing: border-box;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  height: 300px;
  // padding-bottom: 1.5rem;
  img {
    width: 100%;
    height: 151px;
    border-radius: 8px;
  }
`;
export const VideoBox = styled.div`
  margin-left: 22px;
  margin-right: 22px;
  margin-top: -80px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  span {
    font-size: 13px;
    font-family: "Inter";
    background-color: white;
    padding: 12px;
    border-radius: 8px;
    display: inline-block;
    width: 93px;
    font-weight: bold;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;


export const VideoContent = styled.div`
  background-color: white;
  padding: 24px;
  padding-top: 20px;
  border-radius: 8px;

  .Video-Content-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    h1 {
      font-family: Inter;
      font-size: 17.7px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      margin-bottom: 0px;
    }
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
`;
