import Searchbar from "../Searchbar/Searchbar";
import { VendorsListContainer } from "./VendorsListStyle";
import ListTable from "./ListTable";

const VendorsList = ({ VendorsData, SearchQuery, setSearchQuery }) => {
  return (
    <VendorsListContainer>
      <div className="profile-container">
        <h1>Clients</h1>
        <Searchbar
          SearchQuery={SearchQuery}
          setSearchQuery={setSearchQuery}
          placeholder="Search Clients"
        />
      </div>
      <ListTable VendorsData={VendorsData} SearchQuery={SearchQuery}/>
    </VendorsListContainer>
  );
};

export default VendorsList;
