import styled from "styled-components";

export const DashboardContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
padding-left: 1rem;
text-align: left;
h1{
    font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--grey-dark);
  margin-top: 44px;
 
  margin-bottom: 260px;
}
div{
    display: flex;
    justify-content: center;
  
}
`
export const BoxContainer=styled.div`
width: 582px;
  
  background: linear-gradient(247deg, var(--gredient-blue-1), var(--gredient-blue-2) 123%);
display: flex;
flex-direction:column;
padding-left: 92px;
padding-bottom: 48px;
p{
    font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color:var(--primary-yellow);
  padding-top: 48px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  div{
    width: 59px;
  height: 1px;
  flex-grow: 0;
  object-fit: contain;
  background-color:var(--primary-yellow);
  margin-right: 10px;
  }
}
h1{
    font-family: Inter;
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--text-color);
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: 0px;
}
  
`
export const OuterContainer=styled.div`
display: flex;
flex-direction: row;
margin-top: 32px;
padding-left: 1rem;
padding-right: 1rem;


gap:24px;
`
export const LeftContainer=styled.div`
display: flex;
flex-direction: column;
width: 100%;
`
export const TaskCard=styled.div`
padding: 24px;
border-radius: 8px;
  box-shadow: 0.3px 0px 3.1px 0 rgba(0, 0, 0, 0), 1.4px 0px 6.5px 0 rgba(0, 0, 0, 0), 3.4px 0px 13px 0 rgba(0, 0, 0, 0), 6.5px 0px 25.5px 0 rgba(0, 0, 0, 0.01), 11px 0.1px 46.9px 0 rgba(0, 0, 0, 0.01), 17px 0.1px 80px 0 rgba(0, 0, 0, 0.01);
  background-color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color:black !important;
  span{
   color: var(--solid-colors);
   
  }
`
export const RightContainer=styled.div`
display: flex;
flex-direction: column;
gap:20px;
`
export const BlueCard1=styled.div`
border-radius: 8px;
  box-shadow: 0.3px 0px 3.1px 0 rgba(0, 0, 0, 0), 1.4px 0px 6.5px 0 rgba(0, 0, 0, 0), 3.4px 0px 13px 0 rgba(0, 0, 0, 0), 6.5px 0px 25.5px 0 rgba(0, 0, 0, 0.01), 11px 0.1px 46.9px 0 rgba(0, 0, 0, 0.01), 17px 0.1px 80px 0 rgba(0, 0, 0, 0.01);
  background: linear-gradient(
    20deg,
    var(--gredient-blue-1) 0%,
    var(--gredient-blue-2) 100%
  ) !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 23px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 19px;
  width: 240px;
  .txt-container{
    display: flex;
    flex-direction: column;
    margin-right: 10px;
   
    span{
      font-family: Inter;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;

    }
    .goal{
      font-weight: normal;
      margin-bottom: 5px;
    }
  }
  .animation-container{
    img{
      width: 98.2px;
  height: 76.3px;
      
    }
  }
`
export const BlueCard2=styled.div`
border-radius: 8px;
  box-shadow: 0.3px 0px 3.1px 0 rgba(0, 0, 0, 0), 1.4px 0px 6.5px 0 rgba(0, 0, 0, 0), 3.4px 0px 13px 0 rgba(0, 0, 0, 0), 6.5px 0px 25.5px 0 rgba(0, 0, 0, 0.01), 11px 0.1px 46.9px 0 rgba(0, 0, 0, 0.01), 17px 0.1px 80px 0 rgba(0, 0, 0, 0.01);
  background: linear-gradient(
    20deg,
    var(--gredient-blue-1) 0%,
    var(--gredient-blue-2) 100%
  ) !important;
  padding: 24px;
 
  width: 240px;
  span{
    font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
 
  }
`
export const CardsContainer=styled.div`


margin-top: 20px;
.card-row{
  display: flex;
flex-direction: row;
justify-content: space-between;
gap:24px;
}
`
export const SmallCard=styled.div`
padding-left: 24px;
padding-right: 15px;
padding-bottom: 25px;
padding-top: 24px;
box-shadow: 0.3px 0px 3.1px 0 rgba(0, 0, 0, 0), 1.4px 0px 6.5px 0 rgba(0, 0, 0, 0), 3.4px 0px 13px 0 rgba(0, 0, 0, 0), 6.5px 0px 25.5px 0 rgba(0, 0, 0, 0.01), 11px 0.1px 46.9px 0 rgba(0, 0, 0, 0.01), 17px 0.1px 80px 0 rgba(0, 0, 0, 0.01);
  background-color: #fff;
  /* width: 258px; */
  width: 40%;
  height: 96px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  align-items: center;
  .txtContainer{
    width:75%;
    font-family: "Inter";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  display: flex;
  flex-direction: column;
    .span1{
  font-size: 13px;
  margin-bottom: 7px;
  font-family: "Inter";
  color: black !important;
  }
  .span2{
    color: var(--heading-color);
    font-size: 20px;
  font-weight: 500;
  }
  }
  .imgContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    padding-top: 10px;
img{
  width: 100%;
  
}
  }
  
`
export const DonotContainer=styled.div`
margin-top: 16px;
width: 193px;
  height: 171px;
  border-radius: 8px;
  box-shadow: 0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02), 0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02), 0 19px 122px 0 rgba(7, 62, 132, 0.04);
  background-color: #fff;
  padding: 30px 28px 29px 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  .donutgraph {
    width: 12%;
    height: 158px;
    border-radius: 8px;
    box-shadow: 0 1.5px 9.9px 0 rgb(11 61 126 / 2%), 0 7.3px 38.9px 0 rgb(11 61 126 / 2%), 0 19px 122px 0 rgb(7 62 132 / 4%);
   
    position: relative;
    
    cursor: pointer;
    .year{
     
      width: 60px;
      margin-top: 20px;
      font-family: "Inter";
      .year-desc{
        font-family: "Inter" !important;
  font-size: 12px !important;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  
  color: #1a2b56 !important;
      }
    }
   .indicators{
    width: 157px;
    padding-top: -10px;
    bottom: 40px;
    
    span{
      color:#cccccc;
      font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
    }
   }
    .semidonut {
    width: 150px;
    height: 55%;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    overflow: hidden;
    color: #1a2b56;
    display: flex;
    align-items: center;
    flex-flow: column wrap;
    justify-content: flex-end;
    box-sizing: border-box;
    
    div{
      z-index: 100;
      img{
        width: 26px;
        height: 26px;
      }
    
    }
    span{
      margin-top: 3px;
        font-family: "Inter";
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1a2b56;
  position: relative;
  &::after{
position: absolute;
left: 0;
top:20px;
width: 100%;
border-radius: 12px;
    background-color: #e0e0e0;
    content: "";
    height: 2px;
 
  }
      }
    &::after{
  content: "";
  width: 90%;
  height: 200%;
  border: 8px solid;
  border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) #bae7e5 #00a779;
  position: absolute;
  border-radius: 50%;
  left: 5%;
  top: 0;
  box-sizing: border-box;
 z-index:1;
  animation: fillAnimation 1s ease-in;

    }
    @keyframes fillAnimation {
  0% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(135deg);
  }
}
}
}
`
export const PropertyPortfolio=styled.div`
border-radius: 8px;
  box-shadow: 0.3px 0px 3.1px 0 rgba(0, 0, 0, 0), 1.4px 0px 6.5px 0 rgba(0, 0, 0, 0), 3.4px 0px 13px 0 rgba(0, 0, 0, 0), 6.5px 0px 25.5px 0 rgba(0, 0, 0, 0.01), 11px 0.1px 46.9px 0 rgba(0, 0, 0, 0.01), 17px 0.1px 80px 0 rgba(0, 0, 0, 0.01);
  background-color: #fff;
  margin-top: 20px;
  padding: 32px 0px;
  font-family: Inter;
  .portfolio-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0px 32px ;
   span{
    font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color:#323232
   }
   a{
    font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color:var(--heading-color)
   }
  }
  .propertyList{
    margin-top: 15px;
    
    table{
      width: 100%;
      tbody{
        width: 100%;
        tr{
          border-bottom: 1px solid #e0e0e0;
          padding-top: 24px;
          padding-bottom: 23px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          td{
            padding:0px 32px ;
          }
          .property-list-img{
            display: flex;
            flex-direction: row;
            img{
              width: 72px;
  height: 44px;
            }
            span{
              font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color:#323232;
  width: 250px;
  margin-left: 16px;
            }
          }
          
          .value{
            font-family: Inter;
  font-size: 13px;

  span{
    font-weight: 500;
    font-size: 16px;
    color:#323232
  }
          }
        }
      }
    }
  }
`
export const PropertyType=styled.td`
  width: 117px;
  height: 36px;
            padding: 10px 24px;
  border-radius: 8px;
  background-color: ${({bgcolor})=>bgcolor};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color:${({color})=>color};
`
export const PortfolioSection=styled.div`
margin-top: 20px;
display: flex;
flex-direction: row;
gap:22.6px;
width:100%;

overflow-x: hidden;
flex-wrap: wrap;
padding-left: 1rem;
padding-right: 1rem;
`
export const PortfolioCard=styled.div`
border-radius: 8.7px;
  box-shadow: 0.3px 0px 3.4px 0 rgba(0, 0, 0, 0), 1.5px 0px 7.1px 0 rgba(0, 0, 0, 0), 3.7px 0px 14.1px 0 rgba(0, 0, 0, 0), 7.1px 0px 27.7px 0 rgba(0, 0, 0, 0.01), 12px 0.1px 50.9px 0 rgba(0, 0, 0, 0.01), 18.5px 0.1px 86.9px 0 rgba(0, 0, 0, 0.01);
  background-color: #fff;
  min-width: 352px;
  width: 32.2%;
  height: 327px;
  display: flex;
  flex-direction: column;
 
  padding-top: 22.8px;
  
  .chart-header-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #ececec;
    padding-bottom: 20px;
    padding-left: 36.8px;
  padding-right: 30px;
    .chart-names{
      display: flex;
      flex-direction: column;
      .secondarytxt{
        font-family: Inter;
  font-size: 14.1px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
      }
      .bold-txt{
        font-family: Inter;
  font-size: 26.1px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--heading-color);
  margin-bottom: 9.6px;
  margin-top: 3px;
      }
    }
    .chart-info{
      width: 20px;
  height: 20px;
  background-color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  color: white;
  border-radius: 50px;
  font-weight: bold;
    }
  }
  .piechart-section{
    display: flex;
    justify-content: center;
    
    .Pie{
      margin-top: -30px;
      width: 230px !important;
      height: 230px !important;
    }
  }
`