import React, { useState, useEffect, useRef } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import moment from "moment";
import { InputNumber } from "primereact/inputnumber";
import { API } from "../../services/api";
import { GS, publish, subscribe, isWhatPercentOf } from "../../services/global";
import "./css.css";
import EditIcon from "../../assets/images/cil-pencil.png";
import { useNavigate } from "react-router-dom";
import ToolTip from "../ToolTip/ToolTip";
import GradientButton from "../Button/GradientButton";
import { useDispatch, useSelector } from "react-redux";
import {
  Vendors,
  setGenericData,
  setLockedProperties,
} from "../../redux/actions";
import { BsEye, BsEyeSlash, BsLockFill, BsUnlockFill } from "react-icons/bs";
import ArrowIcon from "../../assets/images/arrowIcon1.png";
import { InputTexts } from "../Equity/Equity.style";
import { commaSeparators, CurrencyFormatter } from "../../utils";
import { ContactDropDown } from "../Contact/Contact.style";
import { setGeneratedProperties } from "../../redux/actions/index";
// interface Regions {
//   industry: Array({price:string});
//   other: Array;
//   residential: Array;
//   office: Array;
//   retail: Array;
// }
function PortfolioSummary(props: any) {
  const [Textcolor, setTextcolor] = useState(false);
  const [ScrollPosition, setScrollPosition] = useState(0);
  const [verticalScrollPosition, setVerticalScrollPosition] = useState(0);

  const [regions, setRegions] = useState({
    industry: Array(),
    other: Array(),
    residential: Array(),
    office: Array(),
    retail: Array(),
  });
  const [PageScrollPosition, setPageScrollPosition] = useState(0);
  const { data } = useSelector((state: any) => state.Domain);
  const handlePageScrollPosition = () => {
    const position = window.pageYOffset;
    setPageScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener("scroll", handlePageScrollPosition, {
      passive: true,
    });
    return () => {
      window.removeEventListener("scroll", handlePageScrollPosition);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const TableRef = useRef<any>();
  const tableHeader1Ref = useRef<any>();
  const tableHeader2Ref = useRef<any>();
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.Vendors);
  const lockedPropeties = useSelector((state: any) => state.PropertyLocks);
  let [PropertyGenerators, setPropertyGenerators] = useState(
    GS.SOT.PropertyGenerators
  );

  const [hovering, setHovering] = useState(100);
  let [B3] = useState(parseFloat(GS?.SOT?.BorrowingCalculator?.income_goal));
  let [B4, setB4] = useState(0);
  const [edit, setEdit] = useState({
    financialYear: false,
    cashFlows: false,
    dal: false,
    col: false,
  });
  let [B6] = useState(3);
  let accountType = sessionStorage.getItem("accountType");

  let [B9, setB9] = useState(6);
  let [A12, setA12] = useState(20);
  let [tableData, setTableData] = useState(
    GS.SOT.PortfolioSummary.tableData ? GS.SOT.PortfolioSummary.tableData : []
  );
  const navigate = useNavigate();

  let FY = 0;
  if (isNaN(FY) || FY < 2021) {
    FY = 2023;
  }
  if (FY > 2999) {
    FY = 2999;
  }
  let vlookup: any[] = [];
  for (let year = 2022, n = -1; year < 2999; year++, n++) {
    if (vlookup.length < 1) {
      vlookup.push([year, (B3 / 100) * B6 + B3]);
    } else {
      vlookup.push([year, (vlookup[n][1] / 100) * B6 + vlookup[n][1]]);
    }
  }

  let [B8, setB8] = useState(
    vlookup.find((n: any) => {
      return n[0] === FY;
    })[1]
  );
  let [B11, setB11] = useState((B8 * 100) / (B9 / 2));
  let [B12, setB12] = useState(B11 * (A12 / 100) + B11 * 0.06);

  let [nextDate, setNextDate] = useState(
    GS?.SOT?.PropertyGenerators[1]?.nextDate
  );
  let [currentProgress, setCurrentProgress] = useState(100);
  const [editable, setEditable] = useState({ row: 0, col: 0 });

  function theforumla() {
    setB9(6);
    setA12(20);

    if (isNaN(FY) || FY < 2021) {
      FY = 2023;
    }
    if (FY > 2999) {
      FY = 2999;
    }

    vlookup = [];
    for (let year = 2022, n = -1; year < 2999; year++, n++) {
      if (vlookup.length < 1) {
        vlookup.push([year, (B3 / 100) * B6 + B3]);
      } else {
        vlookup.push([year, (vlookup[n][1] / 100) * B6 + vlookup[n][1]]);
      }
    }
    setB8(
      vlookup.find((n: any) => {
        return n[0] === FY;
      })[1]
    );
    setB11((B8 * 100) / (B9 / 2));
    setB12(B11 * (A12 / 100) + B11 * 0.06);
  }

  useEffect(() => {
    theforumla();
  }); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    publish(`fixedtop`, { fixedtop: false });
    CalculateOnPurchaseMethodChanged([]);
    const data = GS.SOT.PortfolioSummary.Properties["office"]
      .filter(
        (value: any, index: any, self: any) =>
          index === self.findIndex((t: any) => t.price === value.price)
      )
      .sort((a: any, b: any) => a.suburb.localeCompare(b.suburb));

    const data1 = GS.SOT.PortfolioSummary.Properties["retail"]
      .filter(
        (value: any, index: any, self: any) =>
          index === self.findIndex((t: any) => t.price === value.price)
      )
      .sort((a: any, b: any) => a.suburb.localeCompare(b.suburb));
    const data2 = GS.SOT.PortfolioSummary.Properties["residential"]
      .filter(
        (value: any, index: any, self: any) =>
          index === self.findIndex((t: any) => t.price === value.price)
      )
      .sort((a: any, b: any) => a.suburb.localeCompare(b.suburb));
    const data3 = GS.SOT.PortfolioSummary.Properties["other"]
      .filter(
        (value: any, index: any, self: any) =>
          index === self.findIndex((t: any) => t.price === value.price)
      )
      .sort((a: any, b: any) => a.suburb.localeCompare(b.suburb));
    const data4 = GS.SOT.PortfolioSummary.Properties["industry"]
      .filter(
        (value: any, index: any, self: any) =>
          index === self.findIndex((t: any) => t.price === value.price)
      )
      .sort((a: any, b: any) => a.suburb.localeCompare(b.suburb));
    const retail = data1.map((i: any) => {
      return {
        label: i.suburb + " (" + i.name + ")",
        value: i.suburb + " (" + i.name + ")",
        price: i.price,
        suburb: i.suburb,
        state: i.name,
      };
    });
    const industry = data4.map((i: any) => {
      return {
        label: i.suburb + " (" + i.name + ")",
        value: i.suburb + " (" + i.name + ")",
        price: i.price,
        suburb: i.suburb,
        state: i.name,
      };
    });
    const residential = data2.map((i: any) => {
      return {
        label: i.suburb + " (" + i.name + ")",
        value: i.suburb + " (" + i.name + ")",
        price: i.price,
        suburb: i.suburb,
        state: i.name,
      };
    });
    const office = data.map((i: any) => {
      return {
        label: i.suburb + " (" + i.name + ")",
        value: i.suburb + " (" + i.name + ")",
        price: i.price,
        suburb: i.suburb,
        state: i.name,
      };
    });
    const other = data3.map((i: any) => {
      return {
        label: i.suburb + " (" + i.name + ")",
        value: i.suburb + " (" + i.name + ")",
        price: i.price,
        suburb: i.suburb,
        state: i.name,
      };
    });
    setRegions({
      retail: [...retail],
      office: [...office],
      residential: [...residential],
      industry: [...industry],
      other: [...other],
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  window.setTimeout(() => publish("removeoffscreen", {}), 1000);

  subscribe("_property", (e: any) => {
    const pGenerators: any = GS.SOT.PropertyGenerators;
    let tempTableData: any = [...tableData];
    tempTableData.map((row: any, idx: any) => {
      row.map((col: any, cidx: any) => {
        if (pGenerators[e?.detail]) {
          try {
            if (pGenerators[e?.detail].hasOwnProperty("tableData"))
              tempTableData[idx][cidx + 1] =
                Math.round(
                  (pGenerators[cidx].tableData[
                    Object.keys(pGenerators[cidx].tableData)[idx]
                  ] +
                    Number.EPSILON) *
                    100
                ) / 100;
          } catch (e) {}
        }
      });
    });

    let sumPurchasePrice = 0,
      sumTotalCashRequired = 0,
      sumNetAnnualCashflowReturn1Year = 0,
      sumCashflowaftermortagecosts = 0;

    tempTableData.map((row: any, idx: any) => {
      row.map((col: any, cidx: any) => {
        for (let i = 0; i < GS.SOT.PropertyGenerators.length; i++) {
          if (pGenerators[i]) {
            try {
              if (pGenerators[i].hasOwnProperty("tableData")) {
                switch (idx) {
                  case 0:
                    if (cidx === 1) {
                      sumPurchasePrice += col * 1;

                      tempTableData[idx][row.length - 1] =
                        Math.round((sumPurchasePrice + Number.EPSILON) * 100) /
                        100;
                    }
                    break;
                  case 6:
                    if (cidx === 1) {
                      sumTotalCashRequired += col * 1;
                      tempTableData[idx][row.length - 1] =
                        Math.round(
                          (sumTotalCashRequired + Number.EPSILON) * 100
                        ) / 100;
                    }
                    break;
                  case 8:
                    if (cidx === 1) {
                      sumNetAnnualCashflowReturn1Year += col * 1;
                      tempTableData[idx][row.length - 1] =
                        Math.round(
                          (sumNetAnnualCashflowReturn1Year + Number.EPSILON) *
                            100
                        ) / 100;
                    }
                    break;
                  case 14:
                    if (cidx === 1) {
                      sumCashflowaftermortagecosts += col * 1;
                      tempTableData[idx][row.length - 1] =
                        Math.round(
                          (sumCashflowaftermortagecosts + Number.EPSILON) * 100
                        ) / 100;
                    }
                    break;
                }
              }
            } catch (e) {}
          }
        }
      });
    });
    GS.SOT.PortfolioSummary.tableData = tempTableData;
    setTableData(tempTableData);
  });

  subscribe("rendersummary", (e: any) => {
    dispatch(setGeneratedProperties(GS.SOT.PropertyGenerators));
    setPropertyGenerators(GS.SOT.PropertyGenerators);
    setTableData(GS.SOT.PortfolioSummary.tableData);
  });

  function CalculateOnPurchaseMethodChanged(
    growthHacking: {
      equityGainGrowth: 0;
      cashGainGrowth: 0;
      AnnualGrowth: 0;
    }[],
    startAt: any = 1,
    endAt: any = 20
  ) {
    if (growthHacking.length == 0)
      growthHacking.push({
        equityGainGrowth: 0,
        cashGainGrowth: 0,
        AnnualGrowth: 0,
      });
    let Externals = {
      RISKProfilingCommercialE7: 900000,
      EquityCapacityH14: 856837,
      RISKProfilingCommercialM7: 720000,
      RISKProfilingCommercialL7: 288000,
      RISKProfilingCommercialT7: 720000,
      RISKProfilingCommercialS7: 108000,
      ClientInputsC35: GS?.SOT?.PropertyGenerators[0]?.targetYieldReturn,
      RemoveTabCalculateF5: 1000000,
      RemoveTabCalculateF48: 3.7,
      RemoveTabCalculateG49: GS.SOT.BorrowingCalculator.can_borrow1,
      RemoveTabCalculateF49: GS.SOT.BorrowingCalculator.can_borrow1,
      MedianPrices: data,
    };

    if (!GS?.SOT.PortfolioSummary.hasOwnProperty("Properties")) {
      GS.SOT.PortfolioSummary.Properties = {
        retail: [],
        office: [],
        residential: [],
        other: [],
        industry: [],
      };
    }
    let medianPropertyType = "residential";

    if (Externals.ClientInputsC35 <= 5) {
      medianPropertyType = "residential";
    } else if (
      Externals.ClientInputsC35 > 5 &&
      Externals.ClientInputsC35 <= 6
    ) {
      medianPropertyType = "retail";
    } else if (
      Externals.ClientInputsC35 > 6 &&
      Externals.ClientInputsC35 <= 7.5
    ) {
      medianPropertyType = "office";
    } else {
      medianPropertyType = "other";
    }
    if (GS?.SOT.PortfolioSummary.Properties["office"].length === 0) {
      // for (let zi = 0; zi < 201; zi++) {
      //   GS.SOT.PortfolioSummary.Properties.push(GS?.getRandMedianPrice(data));
      // }
      // let ndata = GS.shuffle(data);
      for (let i = 0; i < 25; i++) {
        GS.SOT.PortfolioSummary.Properties["office"].push({
          suburb: data["office"][i].Suburb_Name,
          name: data["office"][i].State,
          price: data["office"][i].Median_Rental_Price,
        });

        GS.SOT.PortfolioSummary.Properties["residential"].push({
          suburb: data["residential"][i].Suburb_Name,
          name: data["residential"][i].State,
          price: data["residential"][i].Median_Rental_Price,
        });

        GS.SOT.PortfolioSummary.Properties["industry"].push({
          suburb: data["industry"][i].Suburb_Name,
          name: data["industry"][i].State,
          price: data["industry"][i].Median_Rental_Price,
        });

        GS.SOT.PortfolioSummary.Properties["other"].push({
          suburb: data["other"][i].Suburb_Name,
          name: data["other"][i].State,
          price: data["other"][i].Median_Rental_Price,
        });

        GS.SOT.PortfolioSummary.Properties["retail"].push({
          suburb: data["retail"][i].Suburb_Name,
          name: data["retail"][i].State,
          price: data["retail"][i].Median_Rental_Price,
        });
      }
      // GS?.SOT.PortfolioSummary.Properties.sort(() => Math.random() - 0.5);
    }

    GS.generateData();

    //@TODO: to stop summary generation if price is low then minimum
    // if (GS?.SOT?.RiskProfile?.initialPropertyType === "Blended") {
    //   if(GS?.SOT?.PropertyGenerators[0].summaryData.PurchasePrice < 350000){
    //     GS.SOT.PropertyGenerators = [...GS.SOT.PropertyGenerators.splice(0, 1)];
    //     GS.calculatePropertyGenerator(
    //       {
    //         Data: {
    //           ...Externals,
    //           ...GS.SOT.PropertyGenerators[0],
    //           ...growthHacking[0],
    //         },
    //       },
    //       GS.SOT.PropertyGenerators.length - 1
    //     );
    //     return
    //   }
    // }

    if (startAt <= 1) {
      GS.SOT.PropertyGenerators = [...GS.SOT.PropertyGenerators.splice(0, 1)];
      let totalDepositLeft =
        GS?.SOT?.PropertyGenerators[0]?.summaryData?.TotalDepositRequired;
      const equityAvailable: number = parseInt(
        !isNaN(GS?.SOT?.PropertyGenerators[0]?.equityGain)
          ? GS?.SOT?.PropertyGenerators[0]?.equityGain
          : 1
      );
      const percentageEquityUsed = isWhatPercentOf(
        totalDepositLeft,
        equityAvailable
      );
      const availableExtraCash = parseInt(
        GS.SOT.StrategicSpending?.ExtraCash?.reduce((a: any, b: any) => {
          return (a += b.value);
        }, 0)
      );
      const availableLiquidAssets = parseInt(
        !isNaN(GS?.SOT?.PropertyGenerators[0]?.liquidAssets)
          ? GS?.SOT?.PropertyGenerators[0]?.liquidAssets
          : 0
      );
      const cashAvailable = parseInt(
        isNaN(GS.SOT.StrategicSpending.cashOnHand)
          ? 0
          : GS.SOT.StrategicSpending.cashOnHand
      );
      if (totalDepositLeft > equityAvailable) {
        if (equityAvailable > 0) {
          GS.SOT.PropertyGenerators[0].useEquity = "Y";
          GS.SOT.PropertyGenerators[0].percentageEquity = 100;
          totalDepositLeft = totalDepositLeft - equityAvailable;
        } else {
          GS.SOT.PropertyGenerators[0].useEquity = "N";
          GS.SOT.PropertyGenerators[0].percentageEquity = 0;
        }
        if (totalDepositLeft > cashAvailable) {
          if (cashAvailable > 0) {
            GS.SOT.PropertyGenerators[0].useCash = "Y";
            GS.SOT.PropertyGenerators[0].percentageCash = 100;
            totalDepositLeft = totalDepositLeft - cashAvailable;
          } else {
            GS.SOT.PropertyGenerators[0].useCash = "N";
            GS.SOT.PropertyGenerators[0].percentageCash = 0;
          }
          if (totalDepositLeft > availableLiquidAssets) {
            if (availableLiquidAssets > 0) {
              GS.SOT.PropertyGenerators[0].useLiquidAssets = "Y";
              totalDepositLeft = totalDepositLeft - availableLiquidAssets;
            } else {
              GS.SOT.PropertyGenerators[0].useLiquidAssets = "N";
            }
            if (totalDepositLeft >= availableExtraCash) {
              if (availableExtraCash > 0) {
                GS.SOT.PropertyGenerators[0].useExtraCash = "Y";
                GS.SOT.PropertyGenerators[0].extraCash = availableExtraCash;
                totalDepositLeft = totalDepositLeft - availableExtraCash;
              } else {
                GS.SOT.PropertyGenerators[0].useExtraCash = "N";
                GS.SOT.PropertyGenerators[0].extraCash = 0;
              }
            } else {
              if (totalDepositLeft > 0) {
                GS.SOT.PropertyGenerators[0].useExtraCash = "Y";
                GS.SOT.PropertyGenerators[0].extraCash =
                  availableExtraCash - totalDepositLeft;
                totalDepositLeft = 0;
              } else {
                GS.SOT.PropertyGenerators[0].useExtraCash = "N";
                GS.SOT.PropertyGenerators[0].extraCash = 0;
                totalDepositLeft = 0;
              }
            }
          } else if (totalDepositLeft === availableLiquidAssets) {
            GS.SOT.PropertyGenerators[0].useLiquidAssets = "Y";
            // GS.SOT.PropertyGenerators[0].liquidAssets = availableLiquidAssets;
            GS.SOT.PropertyGenerators[0].useExtraCash = "N";
            GS.SOT.PropertyGenerators[0].extraCash = 0;
          } else {
            if (totalDepositLeft > 0) {
              GS.SOT.PropertyGenerators[0].useLiquidAssets = "Y";

              totalDepositLeft = 0;
            } else {
              GS.SOT.PropertyGenerators[0].useLiquidAssets = "N";
              totalDepositLeft = 0;
            }
            GS.SOT.PropertyGenerators[0].useExtraCash = "N";
            GS.SOT.PropertyGenerators[0].extraCash = 0;
          }
        } else if (totalDepositLeft === cashAvailable) {
          totalDepositLeft = 0;
          GS.SOT.PropertyGenerators[0].useCash = "Y";
          GS.SOT.PropertyGenerators[0].percentageCash = 100;
          GS.SOT.PropertyGenerators[0].useExtraCash = "N";
          GS.SOT.PropertyGenerators[0].useLiquidAssets = "N";
        } else {
          const percentageCashUsed = isWhatPercentOf(
            totalDepositLeft,
            cashAvailable
          );
          totalDepositLeft = 0;
          GS.SOT.PropertyGenerators[0].useCash = "Y";
          GS.SOT.PropertyGenerators[0].percentageCash =
            percentageCashUsed > 100 ? 100 : percentageCashUsed;
          GS.SOT.PropertyGenerators[0].useExtraCash = "N";
          GS.SOT.PropertyGenerators[0].useLiquidAssets = "N";
        }
      } else if (totalDepositLeft === equityAvailable) {
        GS.SOT.PropertyGenerators[0].useEquity = "Y";
        GS.SOT.PropertyGenerators[0].percentageEquity = 100;
        totalDepositLeft = 0;
        GS.SOT.PropertyGenerators[0].useCash = "N";
        GS.SOT.PropertyGenerators[0].percentageCash = 0;
        GS.SOT.PropertyGenerators[0].useExtraCash = "N";
        GS.SOT.PropertyGenerators[0].useLiquidAssets = "N";
      } else {
        GS.SOT.PropertyGenerators[0].useEquity = "Y";
        GS.SOT.PropertyGenerators[0].percentageEquity =
          percentageEquityUsed > 100 ? 100 : percentageEquityUsed;
        totalDepositLeft = 0;
        GS.SOT.PropertyGenerators[0].useCash = "N";
        GS.SOT.PropertyGenerators[0].percentageCash = 0;
        GS.SOT.PropertyGenerators[0].useExtraCash = "N";
        GS.SOT.PropertyGenerators[0].useLiquidAssets = "N";
      }

      GS.calculatePropertyGenerator(
        {
          Data: {
            ...Externals,
            ...GS.SOT.PropertyGenerators[0],
            ...growthHacking[0],
          },
        },
        GS.SOT.PropertyGenerators.length - 1
      );

      let pTotalCashRequired =
        GS?.SOT?.PropertyGenerators[0]?.summaryData?.TotalDepositRequired;
      let nextDate: any = null;
      let LResult: any = null;
      const pequityGain = GS.SOT?.PropertyGenerators[0]?.equityGain;
      const equityGains = GS.SOT?.Equity?.EquityGains?.reduce(
        (prevValue: any, currentValue: any) =>
          (prevValue += currentValue.value / currentValue.noOfMonths),
        0
      );
      for (const pdata of GS?.Data[0]) {
        if (
          pdata?.balanceSheet?.cashOnHand?.total +
            pequityGain +
            // equityGains +
            GS?.SOT?.PropertyGenerators[0]?.liquidAssets >=
          pTotalCashRequired
        ) {
          if (nextDate) break;
          else if (!nextDate) {
            LResult = pdata?.balanceSheet?.cashOnHand?.total;
            nextDate = pdata?.date;
          }
        }
      }
      setNextDate(nextDate);

      GS.SOT.PropertyGenerators[0].cashAvailable = LResult;
      GS.SOT.PropertyGenerators[0] = {
        ...GS.SOT.PropertyGenerators[0],
        nextDate: nextDate,
      };

      GS.calculatePropertyGenerator(
        {
          Data: {
            ...Externals,
            ...GS.SOT.PropertyGenerators[0],
            ...growthHacking[0],
          },
        },
        GS.SOT.PropertyGenerators.length - 1
      );
    } else {
      GS.SOT.PropertyGenerators = [
        ...GS.SOT.PropertyGenerators.splice(0, startAt),
      ];
    }
    let totalcashAvailable =
      GS?.SOT?.PropertyGenerators[0]?.F3 +
      GS?.SOT?.PropertyGenerators[0]?.F4 +
      GS?.SOT?.PropertyGenerators[0]?.equityGain;
    if (
      totalcashAvailable <
      GS?.SOT?.PropertyGenerators[0]?.summaryData?.TotalDepositRequired
    ) {
      return false;
    }

    let desireIncomeGoal = GS?.SOT?.BorrowingCalculator?.income_goal || 0;
    let currentIncomeGoal =
      GS?.SOT?.PropertyGenerators[0]?.summaryData?.CashflowAnnual || 0;
    let financialYear: any;
    if (currentIncomeGoal < desireIncomeGoal) {
      if (
        GS.SOT.PortfolioSummary.Properties[0] === undefined ||
        GS.SOT.PortfolioSummary.Properties[0] === null
      ) {
        GS.SOT.PropertyGenerators[0].summaryData.State =
          GS?.SOT?.PortfolioSummary?.Properties[0]?.city;
      }

      let ResidentialMedianPrice =
        GS?.SOT?.PortfolioSummary?.Properties["residential"][0].price *
        0.68 *
        52;
      let ComercialMedianPrice =
        GS?.SOT?.PortfolioSummary?.Properties["office"][0]?.price;
      // * 100;
      let RetailMedianPrice =
        GS?.SOT?.PortfolioSummary?.Properties["retail"][0]?.price;
      let RiskFactoredYieldReturn = 0;
      if (GS?.SOT?.PropertyGenerators[0]?.targetYieldReturn !== null) {
        RiskFactoredYieldReturn = parseFloat(
          GS?.SOT?.PropertyGenerators[0]?.targetYieldReturn
            .toString()
            .replace("%", "")
        );
      }

      let thePurchasePrice = 0;
      let MEDIANRENTPRICE_RentIncome = 0;
      const rules = {
        rule1: [5, 7, 7, 6, 5],
        rule2: [7, 5, 6, 6, 5],
        rule3: [5.5, 7, 5, 6, 5],
        rule4: [6.5, 5, 7, 5, 6],
      };

      const nY = parseInt(
        GS?.SOT?.RiskProfile?.NetYieldonProperty.slice(0, -1)
      );

      let oldproperty_pTotalCashRequired =
        GS?.SOT?.PropertyGenerators[0]?.summaryData?.TotalDepositRequired;

      for (let i = startAt < 1 ? 1 : startAt; i <= endAt; i++) {
        let propertyno = i;

        ResidentialMedianPrice =
          GS?.SOT?.PortfolioSummary?.Properties["residential"][propertyno]
            ?.price *
          0.68 *
          52;
        ComercialMedianPrice =
          GS?.SOT?.PortfolioSummary?.Properties["office"][propertyno]?.price;
        //  *100;
        RetailMedianPrice =
          GS?.SOT?.PortfolioSummary?.Properties["retail"][propertyno]?.price;

        GS.generateDataForProperty(moment());

        if (GS?.SOT?.RiskProfile?.initialPropertyType === "Blended") {
          if (GS?.SOT?.RiskProfile?.NetYieldonProperty) {
            const rule =
              nY <= 5.5
                ? "rule1"
                : nY <= 6
                ? "rule3"
                : nY <= 6.5
                ? "rule4"
                : Math.round(nY) <= 7
                ? "rule2"
                : 0;

            if (rule !== 0) {
              const arrayDuplicates = Math.floor(
                propertyno / rules[rule].length
              );
              if (arrayDuplicates > 0) {
                for (let zz = 0; zz < arrayDuplicates; zz++) {
                  rules[rule].push(...rules[rule]);
                }
              }
              RiskFactoredYieldReturn = rules[rule][propertyno];
            }
          }
        } else {
          RiskFactoredYieldReturn = parseFloat(
            GS?.SOT?.PropertyGenerators[0]?.targetYieldReturn
              .toString()
              .replace("%", "")
          );
        }

        if (RiskFactoredYieldReturn < 6) {
          thePurchasePrice =
            (ResidentialMedianPrice / RiskFactoredYieldReturn) * 100;
          MEDIANRENTPRICE_RentIncome = ResidentialMedianPrice;
        } else if (RiskFactoredYieldReturn < 6.99) {
          thePurchasePrice =
            (RetailMedianPrice / RiskFactoredYieldReturn) * 100;
          MEDIANRENTPRICE_RentIncome = RetailMedianPrice;
        } else {
          thePurchasePrice =
            (ComercialMedianPrice / RiskFactoredYieldReturn) * 100;
          MEDIANRENTPRICE_RentIncome = ComercialMedianPrice;
        }

        GS.SOT.PropertyGenerators.push({
          liquidAssets: GS.SOT.PropertyGenerators[i - 1]?.liquidAssets,
          netIncome: GS?.Data[0][1]?.monthlyCashflow?.income?.total,
          extraCash: GS.SOT.PropertyGenerators[i - 1]?.extraCash,
          cashAvailable: GS?.Data[0][1]?.balanceSheet?.cashOnHand?.total,
          useEquity: "Y",
          equityAvailable:
            GS.SOT.PropertyGenerators[i - 1].tableData
              .ReturnonEquitywitha5capitalgrowthrate *
            (GS.SOT.PropertyGenerators[i - 1].tableData.PurchasePrice / 100),
          equityGain:
            GS.SOT.PropertyGenerators[i - 1].tableData
              .ReturnonEquitywitha5capitalgrowthrate *
            (GS.SOT.PropertyGenerators[i - 1].tableData.PurchasePrice / 100),
          percentageEquity: GS.SOT.PropertyGenerators[i - 1].percentageEquity,
          totalAvailableFunds: 0,
          useCash: "Y",
          //@TODO: to stop changing LVR of next properties after lock static value given
          // slider1: GS.SOT.PropertyGenerators[i - 1].slider1,
          slider1: 80,

          percentageCash: GS.SOT.PropertyGenerators[i - 1].percentageCash,
          totalAcquisitionlabel: 0,
          useLiquidAssets: "N",
          totalAcquisition: 0,
          targetYieldReturn: 0,
          useExtraCash: "N",
          slider2: 0,
          totalAssetsRequiredLeveragedLabel: 0,
          totalAssetsRequiredLeveraged: 0,
          cashRequiredLeveragedLabel: 0,
          cashRequiredLeveraged: 0,
          methodforPurchasePrice: "Maximum borrowing capacity + 20% LVR",
          tableData: {
            PurchasePrice: thePurchasePrice,
            Depositassumingleverage20: 0,
            StampDuty: 0,
            SolicitorValuation: 0,
            BuildingReport: 0,
            LoanArrangementFee: 0,
            TotalCashRequired: 0,
            TotalPurchaseCosts: 0,
            NetAnnualCashflowReturnYear1: 0,
            NetYieldonPropertyYear1: RiskFactoredYieldReturn,
            NetYieldonTotalPurchaseCostsYear1: 0,
            CashonCashReturn: 0,
            TotalCashRequired20Costs: 0,
            CostofLoan5paonleverage: 0,
            Cashflowaftermortagecosts: 0,
            CashflowaftermortgageMonthly: 0,
            ReturnonEquitypurecashflowreturn: 0,
            ReturnonEquitywitha5capitalgrowthrate: 0,
            ReturnonEquitywitha7capitalgrowthrate: 0,
            ReturnonEquitywitha10capitalgrowthrate: 0,
          },
          summaryData: {
            PropertyType:
              RiskFactoredYieldReturn < 6 ? "Residential" : "Commercial",
            PurchasePrice: thePurchasePrice,
            Deposit: 0,
            StampDuty: 0,
            TotalDepositRequired: 0,
            AcquisitionCost: 0,
            TotalPurchaseCosts: 0,
            LoantoValueRatioLVR: 0,
            TotalDebt: 0,
            TotalDepositEquity: 0,
            TotalDepositCash: 0,
            AppliedInterestRate: 0,
            PropertyRentIncomeYear1: MEDIANRENTPRICE_RentIncome,
            TotalCostofLending: 0,
            CashflowAnnual: 0,
            CashflowMonthly: 0,
            ReturnonEquityCOC: 0,
            AppliedGrowthRateannualPERC: growthHacking[i]?.AnnualGrowth
              ? growthHacking[i].AnnualGrowth
              : 0,
            EquityGainPERCorDOLLAR: growthHacking[i]?.equityGainGrowth
              ? growthHacking[i].equityGainGrowth
              : 0,
            CashGainDOLLAR: growthHacking[i]?.cashGainGrowth
              ? growthHacking[i].cashGainGrowth
              : 0,
            LMI: 0,
          },
          G3: "",
          A44: 0,
          F44: 0,
          C44: 0,
          A45: "",
          B45: 0,
          A46: 0,
          B46: 0,
          B47: 0,
          B48: 0,
          B49: 0,
          B50: 0,
          B51: 0,
          C51: 0,
          B52: 0,
          B53: 0,
          B54: 0,
          B55: 0,
          B56: 0,
          B57: 0,
          A58: 0,
          B58: 0,
          B59: 0,
          C59: 0,
          B60: 0,
          B61: 0,
          A62: 0,
          B62: 0,
          A63: 0,
          B63: 0,
          A64: 0,
          B64: 0,
        });

        GS.SOT.PropertyGenerators[i].summaryData.State =
          GS?.SOT?.PortfolioSummary?.Properties[i]?.city;

        GS.calculatePropertyGenerator(
          {
            Data: {
              ...Externals,
              ClientInputsC35: RiskFactoredYieldReturn,
              ...GS.SOT.PropertyGenerators[i],
              ...growthHacking[i],
            },
          },
          GS.SOT.PropertyGenerators.length - 1
        );

        oldproperty_pTotalCashRequired +=
          GS?.SOT?.PropertyGenerators[i]?.summaryData?.TotalDepositRequired;
        let pTotalCashRequired = oldproperty_pTotalCashRequired;
        let nextDate: any = null;
        let LResult: any = null;

        const equityGainFromProperties =
          GS.SOT.PropertyGenerators[0].equityGain;
        // const equityGains = GS?.SOT?.Equity?.EquityGains?.reduce(
        //   (prevValue: any, currentValue: any) =>
        //     (prevValue += currentValue.value / currentValue.noOfMonths),
        //   0
        // );
        for (const pdata of GS?.Data[0]) {
          if (
            pdata?.balanceSheet?.cashOnHand?.total +
              equityGainFromProperties +
              // equityGains +
              (growthHacking[i - 1]?.equityGainGrowth
                ? growthHacking[i - 1]?.equityGainGrowth
                : 0) +
              (growthHacking[i - 1]?.cashGainGrowth
                ? growthHacking[i - 1]?.cashGainGrowth
                : 0) >=
            pTotalCashRequired
          ) {
            if (nextDate) break;
            if (!nextDate) {
              LResult =
                pdata?.balanceSheet?.cashOnHand?.total +
                (growthHacking[i - 1]?.equityGainGrowth
                  ? growthHacking[i - 1]?.equityGainGrowth
                  : 0) +
                (growthHacking[i - 1]?.cashGainGrowth
                  ? growthHacking[i - 1]?.cashGainGrowth
                  : 0);
              nextDate = pdata?.date;
            }
          }
        }

        GS.SOT.PropertyGenerators[i].cashAvailable = LResult;
        GS.SOT.PropertyGenerators[i] = {
          ...GS.SOT.PropertyGenerators[i],
          nextDate: nextDate,
        };
        let totalDepositLeft =
          thePurchasePrice * 0.2 +
          thePurchasePrice * 0.055 +
          thePurchasePrice * 0.015;
        const percentageEquityUsed = isWhatPercentOf(
          totalDepositLeft,
          GS.SOT.PropertyGenerators[i].equityAvailable
        );
        const availableExtraCash =
          parseInt(
            GS?.SOT?.StrategicSpending?.ExtraCash?.reduce((a: any, b: any) => {
              return (a += b.value);
            }, 0)
          ) -
          parseInt(
            GS?.SOT?.PropertyGenerators?.reduce((a: any, b: any) => {
              return (a += b.extraCash);
            }, 0)
          );
        const availableLiquidAssets =
          parseInt(GS?.SOT?.Equity?.LiquidAssets?.value) -
          parseInt(
            GS?.SOT?.PropertyGenerators?.reduce((a: any, b: any) => {
              return (a += b.liquidAssets);
            }, 0)
          );
        const cashAvailable = GS.SOT.PropertyGenerators[i].cashAvailable;
        const equityAvailable = GS.SOT.PropertyGenerators[i].equityAvailable;
        if (totalDepositLeft > equityAvailable) {
          if (equityAvailable > 0) {
            GS.SOT.PropertyGenerators[i].useEquity = "Y";
            GS.SOT.PropertyGenerators[i].percentageEquity = 100;
            totalDepositLeft = totalDepositLeft - equityAvailable;
          } else {
            GS.SOT.PropertyGenerators[i].useEquity = "N";
            GS.SOT.PropertyGenerators[i].percentageEquity = 0;
          }
          if (totalDepositLeft > cashAvailable) {
            if (cashAvailable > 0) {
              GS.SOT.PropertyGenerators[i].useCash = "Y";
              GS.SOT.PropertyGenerators[i].percentageCash = 100;
              totalDepositLeft = totalDepositLeft - cashAvailable;
            } else {
              GS.SOT.PropertyGenerators[i].useCash = "N";
              GS.SOT.PropertyGenerators[i].percentageCash = 0;
            }
            if (totalDepositLeft > availableLiquidAssets) {
              if (availableLiquidAssets > 0) {
                GS.SOT.PropertyGenerators[i].useLiquidAssets = "Y";
                GS.SOT.PropertyGenerators[i].liquidAssets =
                  availableLiquidAssets;
                totalDepositLeft = totalDepositLeft - availableLiquidAssets;
              } else {
                GS.SOT.PropertyGenerators[i].useLiquidAssets = "N";
                GS.SOT.PropertyGenerators[i].liquidAssets = 0;
              }
              if (totalDepositLeft >= availableExtraCash) {
                if (availableExtraCash > 0) {
                  GS.SOT.PropertyGenerators[i].useExtraCash = "Y";
                  GS.SOT.PropertyGenerators[i].extraCash = availableExtraCash;
                  totalDepositLeft = totalDepositLeft - availableExtraCash;
                } else {
                  GS.SOT.PropertyGenerators[i].useExtraCash = "N";
                  GS.SOT.PropertyGenerators[i].extraCash = 0;
                }
              } else {
                if (totalDepositLeft > 0) {
                  GS.SOT.PropertyGenerators[i].useExtraCash = "Y";
                  GS.SOT.PropertyGenerators[i].extraCash =
                    availableExtraCash - totalDepositLeft;
                  totalDepositLeft = 0;
                } else {
                  GS.SOT.PropertyGenerators[i].useExtraCash = "N";
                  GS.SOT.PropertyGenerators[i].extraCash = 0;
                }
              }
            } else if (totalDepositLeft === availableLiquidAssets) {
              GS.SOT.PropertyGenerators[i].useLiquidAssets = "Y";
              GS.SOT.PropertyGenerators[i].liquidAssets = availableLiquidAssets;
              GS.SOT.PropertyGenerators[i].useExtraCash = "N";
              GS.SOT.PropertyGenerators[i].extraCash = 0;
            } else {
              if (totalDepositLeft > 0) {
                GS.SOT.PropertyGenerators[i].useLiquidAssets = "Y";
                GS.SOT.PropertyGenerators[i].liquidAssets =
                  availableLiquidAssets - totalDepositLeft;
                totalDepositLeft = 0;
              } else {
                GS.SOT.PropertyGenerators[i].useLiquidAssets = "N";
                GS.SOT.PropertyGenerators[i].liquidAssets = 0;
              }
              GS.SOT.PropertyGenerators[i].useExtraCash = "N";
              GS.SOT.PropertyGenerators[i].extraCash = 0;
            }
          } else if (totalDepositLeft === cashAvailable) {
            totalDepositLeft = 0;
            GS.SOT.PropertyGenerators[i].useCash = "Y";
            GS.SOT.PropertyGenerators[i].percentageCash = 100;
            GS.SOT.PropertyGenerators[i].useExtraCash = "N";
            GS.SOT.PropertyGenerators[i].useLiquidAssets = "N";
          } else {
            const percentageCashUsed = isWhatPercentOf(
              totalDepositLeft,
              cashAvailable
            );
            totalDepositLeft = 0;
            GS.SOT.PropertyGenerators[i].useCash = "Y";
            GS.SOT.PropertyGenerators[i].percentageCash =
              percentageCashUsed > 100 ? 100 : percentageCashUsed;
            GS.SOT.PropertyGenerators[i].useExtraCash = "N";
            GS.SOT.PropertyGenerators[i].useLiquidAssets = "N";
          }
        } else if (totalDepositLeft === equityAvailable) {
          GS.SOT.PropertyGenerators[i].useEquity = "Y";
          GS.SOT.PropertyGenerators[i].percentageEquity = 100;
          totalDepositLeft = 0;
          GS.SOT.PropertyGenerators[i].useCash = "N";
          GS.SOT.PropertyGenerators[i].percentageCash = 0;
          GS.SOT.PropertyGenerators[i].useExtraCash = "N";
          GS.SOT.PropertyGenerators[i].useLiquidAssets = "N";
        } else {
          GS.SOT.PropertyGenerators[i].useEquity = "Y";
          GS.SOT.PropertyGenerators[i].percentageEquity =
            percentageEquityUsed > 100 ? 100 : percentageEquityUsed;
          totalDepositLeft = 0;
          GS.SOT.PropertyGenerators[i].useCash = "N";
          GS.SOT.PropertyGenerators[i].percentageCash = 0;
          GS.SOT.PropertyGenerators[i].useExtraCash = "N";
          GS.SOT.PropertyGenerators[i].useLiquidAssets = "N";
        }

        GS.calculatePropertyGenerator(
          {
            Data: {
              ...Externals,
              ClientInputsC35: RiskFactoredYieldReturn,
              ...GS.SOT.PropertyGenerators[i],
              ...growthHacking[i],
            },
          },
          GS.SOT.PropertyGenerators.length - 1
        );

        if (GS.SOT.PropertyGenerators[i].summaryData?.PurchasePrice < 350000) {
          setB4(nextDate?.split("-")[1]);
          GS.SOT.BorrowingCalculator.FinancialYear = parseInt(
            nextDate?.split("-")[1]
          );
          props.setIncomeGoal({
            ...props.IncomeGoal,
            year: parseInt(nextDate?.split("-")[1]),
          });
          financialYear = parseInt(nextDate?.split("-")[1]);
          i = 999999999;
        } else {
          currentIncomeGoal +=
            GS?.SOT?.PropertyGenerators[i]?.summaryData?.CashflowAnnual;
          if (currentIncomeGoal >= desireIncomeGoal) {
            if (nextDate) {
              setB4(nextDate?.split("-")[1]);
              GS.SOT.BorrowingCalculator.FinancialYear = parseInt(
                nextDate?.split("-")[1]
              );
              financialYear = parseInt(nextDate?.split("-")[1]);
              props.setIncomeGoal({
                ...props.IncomeGoal,
                year: parseInt(nextDate?.split("-")[1]),
              });
            }
            i = 9999999;
          }
        }
      }
    }

    let diff = financialYear - new Date().getUTCFullYear();

    setCurrentProgress(
      isWhatPercentOf(1, diff) >= 100 ? 100 : isWhatPercentOf(1, diff)
    );
    publish(
      "setgoalprogress",
      isWhatPercentOf(currentIncomeGoal, desireIncomeGoal)
    );
    revertLockedData();
  }
  const Wrapper1 = useRef<any>();

  const revertLockedData = () => {
    if (lockedPropeties?.tableData && lockedPropeties?.PropertyGenerators) {
      lockedPropeties?.PropertyGenerators?.map((item: any, idx: any) => {
        if (item.isLocked == true) {
          GS.SOT.PropertyGenerators[idx] =
            lockedPropeties?.PropertyGenerators[idx];
          lockedPropeties?.tableData.forEach((row: any, col: any) => {
            let rowTotal = 0;
            if (
              col !== 0 &&
              col !== 1 &&
              col !== 10 &&
              col !== 9 &&
              col !== 14 &&
              col !== 15 &&
              col !== 20 &&
              col !== 21 &&
              col !== 22
            ) {
              row.forEach((result: any, item: any) => {
                if (item > 0 && item < row.length - 1) {
                  rowTotal += result;
                }
              });
            }
            GS.SOT.PortfolioSummary.tableData[col][idx + 1] = row[idx + 1];
            GS.SOT.PortfolioSummary.tableData[col][row.length - 1] = rowTotal;
          });
        }
      });
    }
    //set financial year as last year property if not income goal not met
    let financialYear =
      GS.SOT?.PropertyGenerators[
        GS.SOT?.PropertyGenerators?.length - 1
      ]?.nextDate?.split("-")[1];
    if (financialYear && B4 === 0) {
      setB4(financialYear);
      GS.SOT.BorrowingCalculator.FinancialYear = parseInt(financialYear);
      props.setIncomeGoal({
        ...props.IncomeGoal,
        year: financialYear,
      });
    }
    API.AutoSave();
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setVerticalScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateProfile = async () => {
    GS.SOT.Progress = { steps: 4, completed: 3, current: 4 };
    await API.AutoSave(window.location.href);
    const updateData = [...state];
    const i = updateData.findIndex((vendor) => vendor._id === props.vendorid);
    updateData[i] = {
      ...updateData[i],
      Progress: [GS.SOT.Progress],
    };
    dispatch(Vendors(updateData));
  };

  const handleLowerScroll = (e: any) => {
    setScrollPosition(e.target.scrollLeft);
    TableRef.current.scrollLeft = Wrapper1.current.scrollLeft;
  };
  const handleUpperScroll = (e: any) => {
    Wrapper1.current.scrollLeft = e.target.scrollLeft;
  };

  let nextBtnNavigation = () => {
    TableRef.current.scrollLeft += 140;
    if (PageScrollPosition > 1200) {
      tableHeader1Ref.current.scrollLeft += 140;
      tableHeader2Ref.current.scrollLeft += 140;
    }
    setScrollPosition(TableRef.current.scrollLeft);
  };
  let prevBtnNavigation = () => {
    TableRef.current.scrollLeft -= 140;
    if (PageScrollPosition > 1200) {
      tableHeader1Ref.current.scrollLeft -= 140;
      tableHeader2Ref.current.scrollLeft -= 140;
    }
    setScrollPosition(TableRef.current.scrollLeft);
  };
  const calculateCashAndEquityAvailable = () => {
    let sum = GS?.SOT?.StrategicSpending?.cashOnHand;
    sum += isNaN(GS?.SOT?.StrategicSpending?.Data[0]?.balanceSheet?.EquityGain)
      ? 0
      : GS?.SOT?.StrategicSpending?.Data[0]?.balanceSheet?.EquityGain;
    let v = 0;
    if (GS?.SOT?.Equity?.EquityGains?.length > 0) {
      GS.SOT.Equity.EquityGains.map((item: any, index: any) => {
        let v1 = item.value / item.noOfMonths;
        v = v + v1;
      });
    }
    return sum + v;
  };
  const showNotifications = () => {
    let shownoti = false;
    // const equityGain = GS.SOT.PropertyGenerators[0]?.equityGain;
    const cashAvailable = isNaN(GS.SOT.StrategicSpending.cashOnHand)
      ? 0
      : GS.SOT.StrategicSpending.cashOnHand;

    const equityAvailable = !isNaN(GS?.SOT?.PropertyGenerators[0]?.equityGain)
      ? GS?.SOT?.PropertyGenerators[0]?.equityGain
      : 0;
    if (GS.SOT.PropertyGenerators[0]) {
      if (GS?.SOT?.RiskProfile?.initialPropertyType === "Blended") {
        if (
          GS.SOT.PropertyGenerators[0].summaryData?.propertyType ===
          "Residential"
        ) {
          if (
            equityAvailable +
              GS?.SOT?.PropertyGenerators[0]?.liquidAssets +
              cashAvailable <
            94500
          ) {
            shownoti = true;
          }
        } else {
          if (
            equityAvailable +
              GS?.SOT?.PropertyGenerators[0]?.liquidAssets +
              cashAvailable <
            210000
          ) {
            shownoti = true;
          }
        }
      } else if (GS?.SOT?.RiskProfile?.initialPropertyType === "Residential") {
        if (
          equityAvailable +
            GS?.SOT?.PropertyGenerators[0]?.liquidAssets +
            cashAvailable <
          94500
        ) {
          shownoti = true;
        }
      } else {
        if (
          equityAvailable +
            GS?.SOT?.PropertyGenerators[0]?.liquidAssets +
            cashAvailable <
          210000
        ) {
          shownoti = true;
        }
      }
    }

    if (shownoti) {
      let TotalDepositReq =
        GS?.SOT?.PropertyGenerators[0]?.summaryData.TotalDepositRequired;
      let amount: any;
      amount =
        TotalDepositReq -
        (equityAvailable +
          GS?.SOT?.PropertyGenerators[0]?.liquidAssets +
          cashAvailable);
      if (isNaN(amount)) {
        amount = "";
      } else {
        amount = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 0,
        }).format(amount);
      }
      if (
        GS?.SOT?.RiskProfile?.initialPropertyType === "Blended" &&
        calculateCashAndEquityAvailable() < 202500 &&
        GS?.SOT?.PropertyGenerators[0]?.targetYieldReturn === 7
      ) {
        return (
          <>
            <p
              style={{
                width: "100%",
                padding: "35px 10px",
                backgroundColor: "#FFF",
                textAlign: "center",
              }}
            >
              Please set the risk profile slider to 5 as your equity is below
              $202,500
            </p>
          </>
        );
      } else {
        return (
          <>
            <p
              style={{
                width: "100%",
                padding: "35px 10px",
                backgroundColor: "#FFF",
                textAlign: "center",
              }}
            >
              {amount} Cash and Equity Required
            </p>
          </>
        );
      }
    } else {
      return <></>;
    }
  };

  const renderSummaryTable = () => {
    let showtable = true;

    if (GS?.SOT?.RiskProfile?.initialPropertyType === "Blended") {
      if (GS?.SOT?.PropertyGenerators[0].summaryData.PurchasePrice < 350000) {
        showtable = false;
      }
    }
    if (showtable === true) {
      return (
        <>
          <div
            className="topScrollBar"
            style={{
              width: TableRef?.current?.getBoundingClientRect()?.width,
            }}
            ref={Wrapper1}
            onScroll={handleUpperScroll}
          >
            <div style={{ width: TableRef?.current?.scrollWidth }} />
          </div>
          <div
            className="table-container mt-4"
            id="table"
            ref={TableRef}
            onScroll={handleLowerScroll}
          >
            <table
              className="tblData"
              id="tbl"
              style={{
                width: `${
                  GS.SOT.PropertyGenerators.length >= 7
                    ? GS.SOT.PropertyGenerators.length * 9
                    : 100
                }%`,
              }}
            >
              <tbody>
                <tr
                  className={`${PageScrollPosition > 1200 ? "fixedTop" : ""} `}
                  ref={tableHeader1Ref}
                  style={{ position: "relative" }}
                >
                  <td
                    valign="bottom"
                    className={`${
                      verticalScrollPosition > 300 ? "ml-5" : "ml-5"
                    } 
                      FirstRowTitle
                      `}
                  >
                    <p>
                      <span className="propertyhead">
                        Risk Factored Yield Return
                      </span>
                    </p>
                  </td>

                  {PropertyGenerators?.map((n: any, idx: any) => {
                    return (
                      <>
                        <td
                          key={idx}
                          valign="bottom"
                          className={`${
                            Textcolor == true ? "tbackground" : ""
                          } ${
                            GS.SOT.PropertyGenerators.length == 1
                              ? "tleft"
                              : "tcenter"
                          }`}
                        >
                          {GS.SOT.PropertyGenerators[idx]?.hidden === false && (
                            <p>
                              <span
                                className={`${
                                  Textcolor == true && idx > 0
                                    ? "tbackground"
                                    : ""
                                } ${
                                  GS.SOT.PropertyGenerators.length == 1
                                    ? ""
                                    : "ml-4"
                                } tablet tablet-${Math.round(
                                  n?.tableData.NetYieldonPropertyYear1
                                )}`}
                              >
                                {Textcolor == true && idx > 0 ? (
                                  <span className="tbackground">**.**</span>
                                ) : isNaN(
                                    n?.tableData.NetYieldonPropertyYear1
                                  ) === false ? (
                                  n?.tableData.NetYieldonPropertyYear1.toFixed(
                                    1
                                  ) + "%"
                                ) : (
                                  ""
                                )}
                              </span>
                            </p>
                          )}
                        </td>
                      </>
                    );
                  })}
                </tr>

                <tr
                  className={`${
                    PageScrollPosition > 1200 ? "fixedTopSecondRow" : ""
                  }`}
                  ref={tableHeader2Ref}
                >
                  <td
                    valign="bottom"
                    className="tblRowTitle"
                    style={{ width: "50px" }}
                  >
                    <p>
                      <span className="propertyhead">Feasibility Markers</span>
                    </p>
                  </td>

                  {PropertyGenerators?.map((n: any, idx: any) => {
                    return (
                      <td
                        key={idx}
                        className="mr-5"
                        valign="bottom"
                        // style={{
                        //   padding: "8px 15px 10px 0px",
                        //   textAlign: "center",
                        // }}
                        style={
                          GS.SOT.PropertyGenerators.length == 1
                            ? {
                                padding: "8px 15px 10px 0px",
                                textAlign: "left",
                              }
                            : {
                                padding: "8px 15px 10px 0px",
                                textAlign: "center",
                              }
                        }
                      >
                        {/* <div className="d-flex ml-3"> */}
                        {GS.SOT.PropertyGenerators[idx]?.hidden === true && (
                          <div className="pb-3">
                            <BsEyeSlash
                              className="remove-icon ml-1"
                              onClick={() => {
                                GS.SOT.PropertyGenerators[idx].hidden = false;
                                setHovering(hovering + 1);
                              }}
                            />
                          </div>
                        )}
                        {GS.SOT.PropertyGenerators[idx]?.hidden === false && (
                          <div style={{ display: "inline-block" }}>
                            <>
                              <p
                                className={`${
                                  Textcolor == true ? "dNone" : ""
                                }`}
                                style={
                                  GS.SOT.PropertyGenerators.length == 1
                                    ? {
                                        marginLeft: "30px",
                                        fontSize: "8px",
                                        fontWeight: "bolder",
                                      }
                                    : { fontSize: "8px", fontWeight: "bolder" }
                                }
                              >
                                {GS.SOT.PortfolioSummary?.Properties[
                                  PropertyGenerators[idx].medianPropertyType
                                ]?.length > 0 &&
                                  GS.SOT.PortfolioSummary?.Properties[
                                    PropertyGenerators[idx].medianPropertyType
                                  ][idx].suburb}
                              </p>
                              <p className="datepill">{n.nextDate}</p>
                            </>

                            <div
                              style={
                                GS.SOT.PropertyGenerators.length == 1
                                  ? { marginLeft: "35px" }
                                  : {}
                              }
                            >
                              <BsEye
                                className="remove-icon ml-1"
                                onClick={() => {
                                  GS.SOT.PropertyGenerators[idx].hidden = true;
                                  setHovering(hovering + 1);
                                }}
                              />
                            </div>
                            <div className="ToolTip-container">
                              <a
                                style={{ color: "black" }}
                                className="propertypill"
                                href="javascript:void(0)"
                                onClick={() => {
                                  if (accountType != "Contacts") {
                                    publish("PropertyActivate", idx);
                                  }
                                }}
                                onMouseEnter={() => setHovering(idx)}
                                onMouseLeave={() => setHovering(100)}
                              >
                                <div className="ToolTip">
                                  <ToolTip
                                    left="0px"
                                    textAlign="center"
                                    width="120px"
                                    show={idx === hovering ? true : false}
                                    txt={`${
                                      isNaN(n.tableData.PurchasePrice)
                                        ? 0
                                        : new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                            maximumFractionDigits: 0,
                                          }).format(n.tableData.PurchasePrice)
                                    }`}
                                  />
                                  Property {idx + 1}
                                </div>
                              </a>

                              {lockedPropeties.PropertyGenerators?.length > 0 &&
                              lockedPropeties?.PropertyGenerators[idx]
                                ?.isLocked === true ? (
                                <BsLockFill
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "1px",
                                  }}
                                  onClick={() => {
                                    GS.SOT.PropertyGenerators[idx].isLocked =
                                      false;

                                    dispatch(
                                      setLockedProperties({
                                        tableData: [
                                          ...GS.SOT.PortfolioSummary.tableData,
                                        ],
                                        PropertyGenerators: [
                                          ...GS.SOT.PropertyGenerators,
                                        ],
                                      })
                                    );
                                    API.AutoSave();
                                  }}
                                />
                              ) : (
                                <BsUnlockFill
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "1.5px",
                                  }}
                                  onClick={() => {
                                    GS.SOT.PropertyGenerators[idx].isLocked =
                                      true;
                                    dispatch(
                                      setLockedProperties({
                                        tableData: [
                                          ...GS.SOT.PortfolioSummary.tableData,
                                        ],
                                        PropertyGenerators: [
                                          ...GS.SOT.PropertyGenerators,
                                        ],
                                      })
                                    );
                                    API.AutoSave();
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </td>
                    );
                  })}
                  <td width="106" valign="bottom">
                    <p>
                      <strong>
                        <span>TOTAL</span>
                      </strong>
                    </p>
                  </td>
                </tr>

                {Boolean(tableData.length) &&
                  tableData.map((itm: any, key: any) => {
                    return (
                      <tr
                        key={key}
                        className={`${
                          Textcolor == true &&
                          (itm[0] == "Property Type" ||
                            itm[0] == "Region" ||
                            itm[0] == "Total Purchase Costs" ||
                            itm[0] == "Total Deposit Equity" ||
                            itm[0] == "Total Deposit Cash" ||
                            itm[0] == "Property Rent Income (Year 1)" ||
                            itm[0] == "Return on Equity (COC)" ||
                            itm[0] == "Equity Gain($)" ||
                            itm[0] == "Cash Gain($)" ||
                            itm[0] == "Purchase Price" ||
                            itm[0] == "Deposit" ||
                            itm[0] == "Stamp Duty(5.5%)" ||
                            itm[0] == "Acquisition Cost")
                            ? "tbackground"
                            : ""
                        }`}
                      >
                        {itm.map((col: any, idx: any) => {
                          if (itm.length - 1 === idx) {
                            //totals
                            return col === 0 ||
                              isNaN(col) === true ||
                              [10, 14, 20, 22].includes(key) === true ? (
                              <td key={idx}>
                                <p>
                                  <span></span>
                                </p>
                              </td>
                            ) : (
                              <td key={idx}>
                                <p>
                                  <div className="icon"></div>
                                  <span>{commaSeparators(col)} </span>
                                </p>
                              </td>
                            );
                          } else if (idx === 0) {
                            //first column
                            return (
                              <td
                                key={idx}
                                className={`${
                                  Textcolor == true ? "blacktxt" : ""
                                }`}
                              >
                                <p>
                                  <div className="icon"></div>

                                  <span
                                    style={
                                      col === "PURCHASE" ||
                                      col === "FINANCE" ||
                                      col === "CASHFLOW" ||
                                      col === "GROWTH HACKING"
                                        ? {
                                            fontWeight: "bold",
                                            color: "black ",
                                          }
                                        : { fontWeight: "normal" }
                                    }
                                  >
                                    {col == "Region" && Textcolor == true
                                      ? "Premium Suburbs"
                                      : col ==
                                          "Property Rent Income (Year 1)" &&
                                        Textcolor == true
                                      ? "Premium Suburbs Income"
                                      : col == "Return on Equity (COC)" &&
                                        Textcolor == true
                                      ? "Return on Equity (Cash on Cash)"
                                      : col}
                                  </span>
                                </p>
                              </td>
                            );
                          } else {
                            return key === 0 || key === 1 ? ( //first and second row except first column
                              <td
                                key={idx}
                                className={` ${
                                  GS.SOT.PropertyGenerators.length == 1
                                    ? "tleft"
                                    : "tcenter "
                                }`}
                              >
                                {GS.SOT.PropertyGenerators[idx - 1]?.hidden ===
                                  false && (
                                  <p>
                                    {lockedPropeties?.PropertyGenerators &&
                                    lockedPropeties?.PropertyGenerators[idx - 1]
                                      ?.isLocked === true &&
                                    editable.row === key &&
                                    editable.col === idx ? (
                                      <div>
                                        <span
                                          style={{ color: "black" }}
                                          onMouseLeave={() =>
                                            setEditable({ row: 0, col: 0 })
                                          }
                                        >
                                          <div className="ToolTip">
                                            <ToolTip
                                              left="0px"
                                              textAlign="center"
                                              width="150px"
                                              show={true}
                                              txt={`Unlock to edit`}
                                            />
                                            {col}
                                          </div>
                                        </span>
                                      </div>
                                    ) : editable.row === key &&
                                      editable.col === idx ? (
                                      <span>
                                        <ContactDropDown
                                          style={{
                                            alignSelf: "center",
                                            border: "1px solid blue",
                                          }}
                                          width="130px !important"
                                          value={col}
                                          onChange={(e: any) => {
                                            const propertyType =
                                              PropertyGenerators[idx - 1]
                                                .medianPropertyType;
                                            // @ts-ignore: Unreachable code error
                                            const region = regions[
                                              propertyType
                                            ].find(
                                              (region: any) =>
                                                region.value === e.target.value
                                            );
                                            GS.SOT.PortfolioSummary.Properties[
                                              PropertyGenerators[
                                                idx - 1
                                              ].medianPropertyType
                                            ][idx - 1] = {
                                              price: region.price,
                                              suburb: region.suburb,
                                              name: region.state,
                                            };
                                            const data = [...tableData];
                                            let arr: any = [];
                                            data[22]
                                              .slice(1)
                                              .forEach(
                                                (item: any, index: any) => {
                                                  arr.push({
                                                    AnnualGrowth: item,
                                                    equityGainGrowth:
                                                      data[23][index + 1],
                                                    cashGainGrowth:
                                                      data[24][index + 1],
                                                  });
                                                }
                                              );
                                            CalculateOnPurchaseMethodChanged(
                                              arr,
                                              idx - 1,
                                              PropertyGenerators.length - 1
                                            );
                                          }}
                                          options={
                                            // @ts-ignore: Unreachable code error

                                            regions[
                                              PropertyGenerators[idx - 1]
                                                .medianPropertyType
                                            ]
                                          }
                                        />
                                        <span
                                          className="px-1 "
                                          style={{
                                            color: "blue",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setEditable({ row: 0, col: 0 });
                                          }}
                                        >
                                          Done
                                        </span>
                                      </span>
                                    ) : (
                                      <span
                                        onClick={() => {
                                          if (
                                            [1].includes(key) === true &&
                                            Textcolor == false
                                          ) {
                                            setEditable({
                                              row: key,
                                              col: idx,
                                            });
                                          }
                                        }}
                                      >
                                        {col}
                                      </span>
                                    )}
                                  </p>
                                )}
                              </td>
                            ) : [10, 14, 20, 22].includes(key) === true ? ( //percentage row
                              isNaN(col) === true ? (
                                <td
                                  key={idx}
                                  className={`${
                                    GS.SOT.PropertyGenerators.length == 1
                                      ? "tleft"
                                      : "tcenter"
                                  }`}
                                >
                                  <p>
                                    <span></span>
                                  </p>
                                </td>
                              ) : (
                                <td
                                  key={idx}
                                  className={`${
                                    GS.SOT.PropertyGenerators.length == 1
                                      ? "tleft"
                                      : "tcenter"
                                  }`}
                                >
                                  {GS.SOT.PropertyGenerators[idx - 1]
                                    ?.hidden === false && (
                                    <p>
                                      {lockedPropeties?.PropertyGenerators &&
                                      lockedPropeties?.PropertyGenerators[
                                        idx - 1
                                      ]?.isLocked === true &&
                                      editable.row === key &&
                                      editable.col === idx ? (
                                        <div>
                                          <span
                                            style={{ color: "black" }}
                                            onMouseLeave={() =>
                                              setEditable({ row: 0, col: 0 })
                                            }
                                          >
                                            <div className="ToolTip">
                                              <ToolTip
                                                left="0px"
                                                textAlign="center"
                                                width="150px"
                                                show={true}
                                                txt={`Unlock to edit`}
                                              />
                                              {Math.round(
                                                (col + Number.EPSILON) * 100
                                              ) / 100}
                                              %
                                            </div>
                                          </span>
                                        </div>
                                      ) : editable.row === key &&
                                        editable.col === idx ? (
                                        <span
                                          onMouseLeave={(e: any) => {
                                            const data = [...tableData];
                                            const checkNumber = parseInt(
                                              String(e.target.value).replace(
                                                /%/g,
                                                ""
                                              )
                                            );
                                            if (!isNaN(checkNumber)) {
                                              let arr: any = [];
                                              data[22]
                                                .slice(1)
                                                .forEach(
                                                  (item: any, index: any) => {
                                                    arr.push({
                                                      AnnualGrowth: item,
                                                      equityGainGrowth:
                                                        data[23][index + 1],
                                                      cashGainGrowth:
                                                        data[24][index + 1],
                                                    });
                                                  }
                                                );
                                              CalculateOnPurchaseMethodChanged(
                                                arr,
                                                idx - 1,
                                                PropertyGenerators.length - 1
                                              );
                                            }
                                            setEditable({ row: 0, col: 0 });
                                          }}
                                        >
                                          <InputTexts
                                            onFocus={(e: any) =>
                                              (e.target.value = "")
                                            }
                                            onBlur={(e: any) =>
                                              (e.target.value = `$${col.toFixed(
                                                2
                                              )}%`)
                                            }
                                            type="text"
                                            style={{ border: "1px solid blue" }}
                                            width="80px"
                                            value={col}
                                            onChange={(e: any) => {
                                              const data = [...tableData];
                                              const checkNumber = parseInt(
                                                String(e.target.value)
                                              );
                                              if (!isNaN(checkNumber)) {
                                                data[key][idx] = parseFloat(
                                                  e.target.value
                                                );
                                                data[key + 1][idx] =
                                                  (data[2][idx] / 100) *
                                                  data[key][idx];
                                                setTableData(data);
                                              }
                                            }}
                                            suffix="%"
                                            className=" bg-white rounded-0"
                                          />
                                        </span>
                                      ) : (
                                        <span
                                          onClick={() => {
                                            if ([22].includes(key) === true) {
                                              setEditable({
                                                row: key,
                                                col: idx,
                                              });
                                            }
                                          }}
                                        >
                                          {Math.round(
                                            (col + Number.EPSILON) * 100
                                          ) / 100}
                                          %
                                        </span>
                                      )}
                                    </p>
                                  )}
                                </td>
                              )
                            ) : [9, 15, 21].includes(key) === true || //header rows
                              isNaN(col) === true ? (
                              <td
                                key={idx}
                                className={`${
                                  GS.SOT.PropertyGenerators.length == 1
                                    ? "tleft"
                                    : "tcenter"
                                }`}
                              >
                                <p>
                                  <span></span>
                                </p>
                              </td>
                            ) : (
                              // $ values rows
                              <td
                                key={idx}
                                className={`${
                                  GS.SOT.PropertyGenerators.length == 1
                                    ? "tleft"
                                    : "tcenter"
                                }`}
                              >
                                {GS.SOT.PropertyGenerators[idx - 1]?.hidden ===
                                  false && (
                                  <p>
                                    {lockedPropeties?.PropertyGenerators &&
                                    lockedPropeties?.PropertyGenerators[idx - 1]
                                      ?.isLocked === true &&
                                    editable.row === key &&
                                    editable.col === idx ? (
                                      <div>
                                        <span
                                          style={{ color: "black" }}
                                          onMouseLeave={() =>
                                            setEditable({ row: 0, col: 0 })
                                          }
                                        >
                                          <div className="ToolTip">
                                            <ToolTip
                                              left="0px"
                                              textAlign="center"
                                              width="150px"
                                              show={true}
                                              txt={`Unlock to edit`}
                                            />
                                            {col > 0 || col < 0
                                              ? new Intl.NumberFormat("en-US", {
                                                  style: "currency",
                                                  currency: "USD",
                                                  maximumFractionDigits: 2,
                                                }).format(col)
                                              : "$0.00"}
                                          </div>
                                        </span>
                                      </div>
                                    ) : editable.row === key &&
                                      editable.col === idx ? (
                                      <span
                                        onMouseLeave={(e: any) => {
                                          const data = [...tableData];
                                          const checkNumber = parseInt(
                                            String(e.target.value)
                                              .replace(/,/g, "")
                                              .substring(1)
                                          );
                                          if (!isNaN(checkNumber)) {
                                            let equityGain: any;
                                            let cashGain: any;
                                            let AnnualGrowth: any;
                                            if (key === 23) {
                                              AnnualGrowth =
                                                (checkNumber / data[2][idx]) *
                                                100;
                                              equityGain = checkNumber;
                                              cashGain = data[key + 1][idx];
                                            } else if (key === 24) {
                                              equityGain = data[key - 1][idx];
                                              cashGain = checkNumber;
                                            }

                                            let arr: any = [];
                                            data[22]
                                              .slice(1)
                                              .forEach(
                                                (item: any, index: any) => {
                                                  arr.push({
                                                    AnnualGrowth: item,
                                                    equityGainGrowth:
                                                      data[23][index + 1],
                                                    cashGainGrowth:
                                                      data[24][index + 1],
                                                  });
                                                }
                                              );

                                            CalculateOnPurchaseMethodChanged(
                                              arr,
                                              idx - 1,
                                              PropertyGenerators.length - 1
                                            );
                                          }
                                          setEditable({ row: 0, col: 0 });
                                        }}
                                      >
                                        <InputNumber
                                          style={{ border: "1px solid blue" }}
                                          onFocus={(e) => (e.target.value = "")}
                                          onBlur={(e) => {
                                            e.target.value = `$${col.toFixed(
                                              2
                                            )}`;
                                          }}
                                          value={col}
                                          onChange={(e) => {
                                            const data = [...tableData];
                                            const checkNumber = parseInt(
                                              String(e.value)
                                            );
                                            if (!isNaN(checkNumber)) {
                                              data[key][idx] = e.value;
                                              if (key === 23) {
                                                data[key - 1][idx] =
                                                  (data[key][idx] /
                                                    data[2][idx]) *
                                                  100;
                                              }
                                              setTableData(data);
                                            }
                                          }}
                                          mode="currency"
                                          currency="USD"
                                          locale="en-US"
                                          inputClassName="tbl-input border-none bg-white rounded-0"
                                        />
                                      </span>
                                    ) : (
                                      <span
                                        onClick={() => {
                                          if ([23, 24].includes(key) === true) {
                                            setEditable({
                                              row: key,
                                              col: idx,
                                            });
                                          }
                                        }}
                                      >
                                        {col > 0 || col < 0
                                          ? new Intl.NumberFormat("en-US", {
                                              style: "currency",
                                              currency: "USD",
                                              maximumFractionDigits: 2,
                                            }).format(col)
                                          : "$0.00"}
                                      </span>
                                    )}
                                  </p>
                                )}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                <tr>
                  {[...Array(PropertyGenerators.length + 2)].map(
                    (n: any, idx: any) => (
                      <td key={idx}></td>
                    )
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </>
      );
    } else {
      return (
        <>
          <p
            style={{
              width: "100%",
              padding: "35px 10px",
              backgroundColor: "#FFF",
              textAlign: "center",
            }}
          >
            {"More Cash and Equity Required"}
          </p>
        </>
      );
    }
  };
  useEffect(() => {
    let accountType = sessionStorage.getItem("accountType");
    if (accountType === "Contacts") {
      setTextcolor(true);
    } else {
      setTextcolor(false);
    }
  }, []);
  return (
    <>
      <div style={{ marginTop: "30px" }}>
        <div className="boxs1 myboxes">
          <div className="grid">
            <div className="col-fixed1">
              <div
                className="icon"
                // onClick={() => setEdit({ ...edit, cashFlows: !edit.cashFlows })}
              >
                <img style={{ color: "var(--main-blue)" }} src={EditIcon} />
              </div>
              <p style={{ marginTop: "10px" }}>
                Lifestyle Goal <br />
              </p>
              {edit.cashFlows === false ? (
                <p>
                  {!isNaN(B3)
                    ? new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 0,
                      }).format(B3)
                    : "$0"}
                </p>
              ) : (
                <div>
                  <InputNumber
                    value={B3}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    min={0.0}
                    className="input-field mr-3 w-100"
                  />
                </div>
              )}
            </div>

            <div className="col-fixed1">
              <div
                className="icon"
                // onClick={() =>
                //   setEdit({ ...edit, financialYear: !edit.financialYear })
                // }
              >
                <img src={EditIcon} />
              </div>
              <p style={{ marginTop: "10px" }}>Goal Achieved in Year</p>
              {edit.financialYear === false ? (
                <div className={Textcolor == true ? "blur1" : ""}>
                  <p className={Textcolor == true ? "blur" : ""}>
                    {B4 === 0 ? "" : B4}
                  </p>
                </div>
              ) : (
                <div
                  className={Textcolor == true ? "blur" : ""}
                  style={{ marginTop: "15px" }}
                ></div>
              )}
            </div>
            <div className="col-fixed">
              <p className="mt-2 ">Total Purchases Costs</p>
              <p className={Textcolor == true ? "  mb-2" : "mb-2"}>
                {tableData &&
                  CurrencyFormatter.format(tableData[8].slice(-1)[0])}
              </p>
            </div>
            <div className="col-fixed">
              <p className="mt-2 ">Average Yield</p>
              <p className="mb-2">
                {(
                  GS?.SOT?.PropertyGenerators?.reduce(
                    (total: any, NextProperty: any) =>
                      (total +=
                        NextProperty?.tableData?.NetYieldonPropertyYear1),
                    0
                  ) / GS?.SOT?.PropertyGenerators.length
                ).toFixed(2)}
                %
              </p>
            </div>
          </div>
          <div className="grid">
            <div className="col-fixed">
              <p>
                <br />
                Next Purchase Date
              </p>
              <p>{nextDate}</p>
            </div>
            <div className="col-fixed">
              <p className="mt-2 ">Cash + Equity Available</p>
              <p className="mb-2">
                {CurrencyFormatter.format(
                  // GS.SOT.StrategicSpending.cashOnHand +
                  //   GS?.SOT?.PropertyGenerators[0]?.equityAvailable
                  calculateCashAndEquityAvailable()
                  // GS?.SOT?.UseableEquityAndCash || 0
                )}
              </p>
            </div>
            <div className="col-fixed">
              <p className="mt-2">Year 1 Cashflow</p>
              <p className="mb-2">
                {CurrencyFormatter.format(
                  GS?.SOT?.PropertyGenerators[0]?.summaryData.CashflowAnnual
                )}
              </p>
            </div>
            <div className="col-fixed">
              <p className="mt-2 ">Year 1 Cashflow (Monthly)</p>
              <p className="mb-2">
                {CurrencyFormatter.format(
                  GS?.SOT?.PropertyGenerators[0]?.summaryData.CashflowMonthly
                )}
              </p>
            </div>
          </div>
        </div>

        <div
          className={`${
            PageScrollPosition > 1200 ? "fixedTopFilters" : ""
          } filter-container  my-3 d-flex  justify-content-end align-items-center`}
        >
          <span
            onClick={prevBtnNavigation}
            className={`prevNavigation1 ${
              ScrollPosition >= 180 ? "active" : ""
            }`}
          >
            <img src={ArrowIcon} alt="" />
          </span>
          <span
            onClick={nextBtnNavigation}
            className={`navigation1 ${
              ScrollPosition <= TableRef?.current?.scrollWidth ? "active" : ""
            }`}
          >
            <img src={ArrowIcon} alt="" />
          </span>
        </div>

        {showNotifications()}
        {renderSummaryTable()}
      </div>
      <div className="d-flex justify-content-end" style={{ marginTop: "50px" }}>
        <GradientButton
          style={{
            position: "fixed",
            bottom: "10px",
            right: "10px",
          }}
          txt={"Next"}
          onClick={async () => {
            await updateProfile();
            navigate(`/Clients/IncomePlan?id=${props.vendorid}`);
          }}
        />
        <GradientButton
          style={{
            position: "fixed",
            bottom: "10px",
            right: "170px",
          }}
          onClick={() => {
            // Navigate(-1);
            props.onBack();
          }}
          txt="Back"
        />
      </div>
    </>
  );
}

export default PortfolioSummary;
