
const initialState = [];

const VendorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_VENDORS_DATA":
      state = action.payload;
      return state;
    case "UPDATE_VENDORS_DATA":
      let old_state = state;
      state = old_state.filter((item) => action.payload !== item._id);
      return state;
    default:
      return state;
  }
};
export default VendorsReducer;
