import Header from "../../components/header/Header";
import UserSidebar from "../../components/SideBar/UserSidebar";
import { HomeContainer, OuterContainer } from "../Home/Home.styles";

import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "./stylesheet.css";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import { API } from "../../services/api";
import GradientButton from "../../components/Button/GradientButton";
export default function Themes() {
  const [medianPrices, setMedianPrices] = useState([])

  function c(number, maximumFractionDigits = 0) {
    if (isNaN(number)) {
      return "$0";
    }
    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: maximumFractionDigits,
    }).format(number);
  }

  const [activeIndex, setActiveIndex] = useState(0);
  const saveRegion = () => {
    let regions = []
    let type = ''
    switch (activeIndex) {
      case 0:
        type = "Residential"
        regions = [...products1]
        break;
      default:
        type = commercials[activeIndex-1]
        regions = [...products2]
        break;
    }
    API.post("/median/regions", { propertyType: type, regions: regions }).then(res => {
      console.info("SAVED", res)
    })
  }
  const getRegion = () => {
    let propertyType = ''
    switch (activeIndex) {
      case 0:
        propertyType = "Residential"
        API.getMedianPricesResi().then(data => {
          setMedianPrices(data)
          console.info(data)
          console.info(medianPrices)
        })
        API.get(`/median/regions/${propertyType}`).then(res => {
          setProducts1([...res.data])
        })
        break;
      default:
        propertyType = commercials[activeIndex-1]
        API.getMedianPricesComm(propertyType).then(data => {
          setMedianPrices(data)
          console.info(data)
          console.info(medianPrices)
        })
        API.get(`/median/regions/${propertyType}`).then(res => {
          setProducts2([...res.data])
        })
        break;
    }



  }

  useEffect(() => {
    getRegion()
  }, [activeIndex])
  const [products1, setProducts1] = useState([
    { name: "Playford" },
    { name: "Bundaberg" },
    { name: "Onkaparinga" },
    { name: "Tamworth" },
    { name: "Ipswich" },
    { name: "Geelong" },
    { name: "Gladstone" },
    { name: "Rockhampton" },
    { name: "Toowoomba" },
    { name: "Onkaparinga" },
  ]);
  const [products2, setProducts2] = useState([
    { name: "Bendigo" },
    { name: "Albury" },
    { name: "Toowoomba" },
    { name: "Bundaberg" },
    { name: "Townsville" },
    { name: "Gympie" },
    { name: "Mackay" },
    { name: "Port Stevens" },
    { name: "Warrnambool" },
    { name: "Tamworth" },
  ]);
  const [products3, setProducts3] = useState([
    { name: "Albury" },
    { name: "Geelong" },
    { name: "Toowoomba" },
    { name: "Gympie" },
    { name: "Tamworth" },
    { name: "Bendigo" },
    { name: "Dubbo" },
    { name: "Townsville" },
    { name: "Gold Coast" },
    { name: "Sunshine Coast" },
  ]);

  const commercials = ["Retail", "Offices", "Industrial Warehouse", "Other"]
  const medianPricescolumns = [
    { field: 'region', header: 'Region' },
    { field: 'State', header: 'State' },
    { field: 'address.postcode', header: 'Postcode' },
    { field: 'Suburb_Name', header: 'Suburb' },
    { field: 'displayAddress', header: 'Display Address' },
    { field: 'Median_Rental_Price', header: 'Median Rental Price' },
    { field: 'Median_Sale_Price', header: 'Median Sale Price' }
  ];
  const columns1 = [

    { field: 'name', header: 'Regions' },

  ];
  const columns2 = [

    { field: "name", header: "Regions" },

  ];
  const columns3 = [

    { field: 'name', header: 'Regions' },

  ];

  const priceEditor = (options) => {
    return (
      <InputNumber
        value={options.value}
        onValueChange={(e) => options.editorCallback(e.value)}
        mode="currency"
        currency="USD"
        locale="en-US"
      />
    );
  };
  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const cellEditor = (options) => {
    if (options.field === "price") return priceEditor(options);
    else return textEditor(options);
  };

  const nameBodyTemplate = (rowData, props) => {
    return `${props.rowIndex + 1}. ${rowData.name}`;
  };
  const isPositiveInteger = (val) => {
    let str = String(val);
    str = str.trim();
    if (!str) {
      return false;
    }
    str = str.replace(/^0+/, "") || "0";
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  };
  const onCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    switch (field) {
      case "quantity":
      case "price":
        if (isPositiveInteger(newValue)) rowData[field] = newValue;
        else event.preventDefault();
        break;

      default:
        if (newValue.trim().length > 0) rowData[field] = newValue;
        else event.preventDefault();
        break;
    }
  };
  const removeit1 = (idx) => {
    let items = [...products1]
    items.splice(idx, 1)
    setProducts1([...items])
  };
  const removeit2 = (idx) => {
    let items = [...products2]
    items.splice(idx, 1)
    setProducts2([...items])
  };
  const removeit3 = (idx) => {
    let items = [...products3]
    items.splice(idx, 1)
    setProducts3([...items])
  };

  const cbt1 = (rowData, props) => {
    return (
      <>{c(rowData.Median_Rental_Price,2)}</>
    );
  };
  const cbt2 = (rowData, props) => {
    return (
      <>{c(rowData.Median_Sale_Price,2)}</>
    );
  };
  const actionBodyTemplate1 = (rowData, props) => {
    return (
      <Button
        className="p-button-rounded p-button-text"
        icon="pi pi-times"
        onClick={() => removeit1(props.rowIndex)}
      />
    );
  };
  const actionBodyTemplate2 = (rowData, props) => {
    return (
      <Button
        className="p-button-rounded p-button-text"
        icon="pi pi-times"
        onClick={() => removeit2(props.rowIndex)}
      />
    );
  };
  const actionBodyTemplate3 = (rowData, props) => {
    return (
      <Button
        className="p-button-rounded p-button-text"
        icon="pi pi-times"
        onClick={() => removeit3(props.rowIndex)}
      />
    );
  };



  const addit1 = () => {
    let items = [...products1]
    items.push({ name: "" });
    setProducts1([...items])
  }
  const addit2 = () => {
    let items = [...products2]
    items.push({ name: "" });
    setProducts2([...items])
  }
  const addit3 = () => {
    let items = [...products3]
    items.push({ name: "" });
    setProducts3([...items])
  }

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);


  const onCustomPage = (event) => {
    // this.setState({
    //     first2: event.first,
    //     rows2: event.rows
    // });
    setFirst(event.first)
    setRows(event.rows)
  }


  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  const template2 = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
        { label: 250, value: 250 },
        { label: 500, value: 500 }
      ];

      return (
        <>
          <div className="p-mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Items per page: </div>
          <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} />
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <div style={{ userSelect: 'none', width: '120px', textAlign: 'center' }}>
          {options.first} - {options.last} of {options.totalRecords}
        </div>
      )
    }
  };
  return (
    <OuterContainer>
      <UserSidebar headerPath="Settings" />
      <HomeContainer>
        <Header headerPath="/Settings/Median Prices" />
        <Card className="mycard customtabs">
          <h1 className="card-title mb-3">Median Prices</h1>
          <div>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
              <TabPanel header="Residential">
                <h1 className="card-title mb-3">Residential</h1>

                <Row className="card-row">
                  <Col sm={3} xs={3} md={3} lg={3}>

                    <div className="d-flex align-items-center">

                      <DataTable value={products1} editMode="cell" className="editable-cells-table" responsiveLayout="scroll">

                        {
                          columns1.map(({ field, header }) => {
                            return <Column key={field} field={field} header={header} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} body={nameBodyTemplate} />
                          })
                        }
                        <Column header="" body={actionBodyTemplate1} style={{ width: '2%' }}></Column>
                      </DataTable>
                    </div>
                    <Button className="p-button-text w100" icon="pi pi-plus" onClick={() => { addit1() }} />
                    <GradientButton classes="ml-auto"
                      txt={"Save"}
                      onClick={async () => {
                        saveRegion();
                      }}
                    />
                  </Col>
                  <Col>
                    <DataTable dataKey="_id" showGridlines
                      paginator first={first} rows={rows} onPage={onCustomPage}
                      paginatorTemplate={template2}
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      globalFilterFields={['region', 'State', 'address.postcode', 'Suburb_Name', 'displayAddress', 'Median_Rental_Price']}
                      value={medianPrices} className="p-datatable-customers" responsiveLayout="scroll"
                    >

                      <Column field="region" filter header="Region" />
                      <Column field="State" filter header="State" />
                      <Column field="address.postcode" filter header="Postcode" />
                      <Column field="Suburb_Name" filter header="Suburb" />
                      <Column field="displayAddress" filter header="Display Address" />
                      <Column body={cbt1} field="Median_Rental_Price" filter header="Rental Price" />
                      <Column body={cbt2} field="Median_Sale_Price" filter header="Sale Price" />

                    </DataTable>
                  </Col>
                </Row>
                {/* <Row className="card-row">
                  <Col sm={4} xs={4} md={4} lg={4}>

                    <div className="d-flex align-items-center">

                      <DataTable value={products1} editMode="cell" className="editable-cells-table" responsiveLayout="scroll">

                        {
                          columns1.map(({ field, header }) => {
                            return <Column key={field} field={field} header={header} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} body={nameBodyTemplate} />
                          })
                        }
                        <Column header="" body={actionBodyTemplate1} style={{ width: '2%' }}></Column>
                      </DataTable>
                    </div>
                    <Button className="p-button-text w100" icon="pi pi-plus" onClick={() => { addit1() }} />
                  </Col>
                  <Col sm={4} xs={4} md={4} lg={4}>

                    <div className="d-flex align-items-center">

                      <DataTable value={products2} editMode="cell" className="editable-cells-table" responsiveLayout="scroll">

                        {
                          columns2.map(({ field, header }) => {
                            return <Column key={field} field={field} header={header} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} body={nameBodyTemplate} />
                          })
                        }
                        <Column header="" body={actionBodyTemplate2} style={{ width: '2%' }}></Column>
                      </DataTable>
                    </div>
                    <Button className="p-button-text w100" icon="pi pi-plus" onClick={() => { addit2() }} />
                  </Col>
                  <Col sm={4} xs={4} md={4} lg={4}>

                    <div className="d-flex align-items-center">

                      <DataTable value={products3} editMode="cell" className="editable-cells-table" responsiveLayout="scroll">

                        {
                          columns3.map(({ field, header }) => {
                            return <Column key={field} field={field} header={header} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} body={nameBodyTemplate} />
                          })
                        }
                        <Column header="" body={actionBodyTemplate3} style={{ width: '2%' }}></Column>
                      </DataTable>
                    </div>
                    <Button className="p-button-text w100" icon="pi pi-plus" onClick={() => { addit3() }} />
                  </Col>
                </Row> */}
              </TabPanel>
              {commercials.map((n, idx) => {
                return (
                  <TabPanel key="idx" header={n}>
                    <h1 className="card-title mb-3">{n}</h1>
                    <Row className="card-row">
                      <Col sm={3} xs={3} md={3} lg={3}>

                        <div className="d-flex align-items-center">

                          <DataTable value={products2} editMode="cell" className="editable-cells-table" responsiveLayout="scroll">

                            {
                              columns1.map(({ field, header }) => {
                                return <Column key={field} field={field} header={header} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} body={nameBodyTemplate} />
                              })
                            }
                            <Column header="" body={actionBodyTemplate2} style={{ width: '2%' }}></Column>
                          </DataTable>
                        </div>
                        <Button className="p-button-text w100" icon="pi pi-plus" onClick={() => { addit2() }} />
                        <GradientButton classes="ml-auto"
                          txt={"Save"}
                          onClick={async () => {
                            saveRegion();
                          }}
                        />
                      </Col>
                      <Col>
                        <DataTable dataKey="_id" showGridlines
                          paginator first={first} rows={rows} onPage={onCustomPage}
                          paginatorTemplate={template2}
                          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                          globalFilterFields={['Region','State', 'postcode', 'Suburb_Name', 'displayAddress', 'Median_Rental_Price']}
                          value={medianPrices} className="p-datatable-customers" responsiveLayout="scroll"
                        >

                          <Column field="Region" filter header="Region" />
                          <Column field="State" filter header="State" />
                          <Column field="postcode" filter header="Postcode" />
                          <Column field="Suburb_Name" filter header="Suburb" />
                          <Column field="displayAddress" filter header="Display Address" />
                          <Column body={cbt1} field="Median_Rental_Price" filter header="Rental Price" />
                          <Column body={cbt2} field="Median_Sale_Price" filter header="Sale Price" />

                        </DataTable>
                      </Col>
                    </Row>
                  </TabPanel>
                )
              })}


            </TabView>
          </div>

        </Card>
      </HomeContainer>
    </OuterContainer>
  );
}
