const initialState=0;

const Mortgage=(state=initialState,action)=>{
    switch (action.type) {
        case "GET_MORTGAGE":
            return state;
        case "SET_MORTGAGE":
            state=action.payload
            return state
        default:
         return state;
    }
}
export default Mortgage