import PropertyCardProfile from "../PropertyCard/PropertyCardProfile";
import { IncomePlanContainer } from "./IncomePlan.style";
import IncomePlanTimeline from "./IncomePlanTimeline";
import Pic1 from "../../../src/assets/images/propertybg.png";
import { GS } from "../../services/global";
import { Row, Col } from "react-bootstrap";
import { commaSeparators, CurrencyFormatter } from "../../utils";
import { API } from "../../services/api";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getImagesFromDomain } from "../../redux/actions";
import GradientButton from "../Button/GradientButton";

const IncomePlan = (props) => {
  const [state, setData] = useState(GS.SOT.PropertyGenerators);
  const { data } = useSelector((state) => state.IncomePlan);
  const dispatch = useDispatch();

  let getImagesData = async () => {
    console.log("property generator",GS.SOT.PropertyGenerators)
    console.log("property PortfolioSummary",GS.SOT.PortfolioSummary)

    const body = GS.SOT.PropertyGenerators.map((item, idx) => {
      let propertyType = item.summaryData.propertyType.includes("Commercial")
        ? "commercial"
        : "residential";
      return {
        state: GS.SOT.PortfolioSummary?.Properties[idx]?.name,
        price: item.summaryData.PurchasePrice,
        propertyTypes: propertyType,
      };
    });

    console.log("body",body)

    try {
      const res = await API.post("domain/propertiesData", { propertiesData: body });
      const updated = res?.data?.map((item, idx) => {
        return { ...state[idx], ...item };
      });
      dispatch(getImagesFromDomain(updated));
    } catch (err) {}
    console.log("body",body)

  };

  useEffect(() => {
    getImagesData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data) setData(data);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  console.log("state",state)
  return (
    <IncomePlanContainer>
      <IncomePlanTimeline data={GS.SOT.PropertyGenerators} />
      <div className="income-plan-detail">
        <Row className="w-100">
          {state?.map((i, indx) => (
            <Col key={indx} md={4} style={{ marginBottom: "10px" }}>
              <PropertyCardProfile
                number={indx + 1}
                img={i.listing ? i.listing.media[i].url : Pic1}
                propertyType={i.summaryData.propertyType}
                PurchasePrice={commaSeparators(i.summaryData.PurchasePrice)}
                Deposit={commaSeparators(i.summaryData.Deposit)}
                GrossYield="7.00%"
                LVR={(i.summaryData.LoantoValueRatioLVR * 100).toFixed(2) + "%"}
                InterestRate="5.50%"
                RiskProfile={GS.SOT?.RiskProfile?.NetYieldonProperty}
                NetCashflow={CurrencyFormatter.format(
                  i.summaryData.CashflowAnnual
                )}
                GrossRentalIncome="$52,500.00"
                SaleRange="1,086"
              />
            </Col>
          ))}
        </Row>
      </div>
      <GradientButton
        style={{
          position: "fixed",
          bottom: "10px",
          right: "10px  ",
        }}
        onClick={() => {
          props.onBack();
        }}
        txt="Back"
      />
    </IncomePlanContainer>
  );
};

export default IncomePlan;
