import styled from "styled-components";

export const HeaderContainer = styled.header`
  box-shadow: 0 0.2px 3.1px 0 rgba(0, 0, 0, 0),
    0 1px 6.5px 0 rgba(0, 0, 0, 0.01), 0 2.4px 13px 0 rgba(0, 0, 0, 0.01),
    0 4.6px 25.5px 0 rgba(0, 0, 0, 0.01), 0 7.8px 46.9px 0 rgba(0, 0, 0, 0.02),
    0 3px 80px 0 rgba(0, 0, 0, 0.01);
  background-color: var(--white);
  padding: 12px 16px !important;
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap:10px;
  width: 100%;

  .coming-DropDown{
    position: absolute;
    width: 200px;
    height: 50px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.02);
    top:70px;
    right: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    align-items: center !important;
    text-align: center;
    border-radius: 10px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: normal;
    z-index:999;
    animation-duration: 1s;
    display: none;
  }
`;
export const HeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  i{
    cursor: pointer;
    display:none;
    @media(max-width:1000px){
      display: flex;
      align-items:center;
      margin-right:6px
    }
  }
`
export const HeaderRight=styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap-reverse;
align-items: center;
gap:30px;
justify-content:space-between;
a{
  text-decoration:none;
}
@media only screen and (max-width:610px){
  gap:15px
}
`
export const Text=styled.p`
font-family: "Inter", sans-serif;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${props=>props.color?props.color:'var(--grey-dark)'};
  font-weight: 500;
`;


export const UserAvatar=styled.div`
display:flex;
flex-direction: row;
align-items: center;
justify-content: center;
width: 56px;
height: 56px;
object-fit: contain;
  background-color: var(--grey-dark);
  border-radius: 50px;
  color: ${(props) => (props.color ? props.color : "var(--grey-dark)")};
`;

