import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";

import CollapseCard from "../CollapseCard";
import PlusIconBlue from "../../assets/images/plusl.png";
import MinusIconBlue from "../../assets/images/minusl.png";

import classes from "./PinderPage.module.css";
import { API } from "../../services/api";
import GradientButton from "../Button/GradientButton";
import { Dropdown } from "primereact/dropdown";

export default function PinderFiltersForCSV({ ApplyFilter }) {
  const [searchQuery, setSearchQuery] = useState("");

  const [selectedZipCode, setSelectedZipCode] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedAgencyName, setSelectedAgencyName] = useState([]);
  const [selectedAgentName, setSelectedAgentName] = useState([]);
  const [ZipCodeOption, setZipCodeOption] = useState([]);
  const [AgentNameOption, setAgentNameOption] = useState([]);
  const [AgencyNameOption, setAgencyNameOption] = useState([]);
  const [CityOption, setCityOption] = useState([]);

  const onSubmitFilters = () => {
    const localFilter = {
      limit: 100,
      skip: 0,
    };

    if (selectedCity) {
      localFilter.city = selectedCity.map((v) => v.name).toString();
    }

    if (selectedAgencyName) {
      localFilter.agency_name = selectedAgencyName
        .map((v) => v.name)
        .toString();
    }
    if (selectedAgentName) {
      localFilter.agent_name = selectedAgentName.map((v) => v.name).toString();
    }

    if (selectedZipCode) {
      localFilter.zip = selectedZipCode.map((v) => v.name).toString();
    }

    // if (searchQuery.length) localFilter.street = `/${searchQuery}/`;
    if (searchQuery.length > 0) {
      localFilter.street = `/${searchQuery}/`;
    } else {
      localFilter.street = ``;
    }

    ApplyFilter(localFilter);
  };

  const [ListingType, setListingType] = useState("Sale");
  const ListingTypeSelectItems = [{ label: "For Sale", value: "Sale" }];

  const getData = async () => {
    const res = await API.get(
      "/listing/domain?limit=10000&skip=0&fields=zip,city,agent_name,agency_name&="
    );
    setZipCodeOption(
      res.data
        .map((v) => v.zip)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((v) => {
          return { name: v };
        })
    );
    setAgentNameOption(
      res.data
        .map((v) => v.agent_name)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((v) => {
          return { name: v };
        })
    );
    setAgencyNameOption(
      res.data
        .map((v) => v.agency_name)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((v) => {
          return { name: v };
        })
    );
    setCityOption(
      res.data
        .map((v) => v.city)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((v) => {
          return { name: v };
        })
    );
  };
  useEffect(() => {
    getData();
  }, []);

  const countryTemplate = (option) => {
    return (
      <div className="country-item">
        <div>{option.name}</div>
      </div>
    );
  };
  const selectedZipCodeTemplate = (option) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.name}</div>
        </div>
      );
    }
  };
  const panelFooterTemplate = () => {
    const selectedItems = selectedZipCode;
    const length = selectedItems ? selectedItems.length : 0;
    return (
      <div className="py-2 px-3">
        <b>{length}</b> item{length > 1 ? "s" : ""} selected.
      </div>
    );
  };

  return (
    <>
      <div className={classes.filterBox}>
        <span className={classes.FilterBoxTitle}>Filters</span>
        <div className="d-flex flex-wrap  justify-content-center mt-3">
          <span className={`${classes.searchInput} p-input-icon-left`}>
            <i className="pi pinder pi-search" />
            <InputText
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search Area"
              className={classes.pinderSearchInputBox}
            />
          </span>
        </div>

        <CollapseCard
          title={<span className={classes.collapseTitle}>Listing Type</span>}
          BodyClass={"pt-0"}
          CardClass={"mt-1 px-1 py-1"}
          CustomMinusIcon={MinusIconBlue}
          CustomPlusIcon={PlusIconBlue}
          IconStyle={{
            width: "20px",
            height: "20px",
          }}
        >
          <Dropdown
            className={classes.filterDropdown}
            value={ListingType}
            options={ListingTypeSelectItems}
            onChange={(e) => setListingType(e.value)}
          />
        </CollapseCard>

        <CollapseCard
          title={<span className={classes.collapseTitle}>Agent Name</span>}
          BodyClass={"pt-0"}
          CardClass={"mt-1 px-1 py-1 bg-white"}
          CustomMinusIcon={MinusIconBlue}
          CustomPlusIcon={PlusIconBlue}
          IconStyle={{
            width: "20px",
            height: "20px",
          }}
        >
          <MultiSelect
            value={selectedAgentName}
            options={AgentNameOption}
            onChange={(e) => setSelectedAgentName(e.value)}
            optionLabel="name"
            placeholder="Select Agent Name"
            filter
            showClear
            className={classes.MultiSelect}
            itemTemplate={countryTemplate}
            selectedItemTemplate={selectedZipCodeTemplate}
            panelFooterTemplate={panelFooterTemplate}
          />
        </CollapseCard>

        <CollapseCard
          title={<span className={classes.collapseTitle}>Suburb</span>}
          BodyClass={"pt-0"}
          CardClass={"mt-1 px-1 py-1 bg-white"}
          CustomMinusIcon={MinusIconBlue}
          CustomPlusIcon={PlusIconBlue}
          IconStyle={{
            width: "20px",
            height: "20px",
          }}
        >
          <MultiSelect
            value={selectedCity}
            options={CityOption}
            onChange={(e) => setSelectedCity(e.value)}
            optionLabel="name"
            placeholder="Select Suburb"
            filter
            showClear
            className={classes.MultiSelect}
            itemTemplate={countryTemplate}
            selectedItemTemplate={selectedZipCodeTemplate}
            panelFooterTemplate={panelFooterTemplate}
          />
        </CollapseCard>
        <CollapseCard
          title={<span className={classes.collapseTitle}>Agency Name</span>}
          CardClass={"mt-1 px-1 py-1 bg-white"}
          BodyClass={"pt-0"}
          CustomMinusIcon={MinusIconBlue}
          CustomPlusIcon={PlusIconBlue}
          IconStyle={{
            width: "20px",
            height: "20px",
          }}
        >
          <MultiSelect
            value={selectedAgencyName}
            options={AgencyNameOption}
            onChange={(e) => setSelectedAgencyName(e.value)}
            optionLabel="name"
            placeholder="Select Agency Name"
            filter
            showClear
            className={classes.MultiSelect}
            itemTemplate={countryTemplate}
            selectedItemTemplate={selectedZipCodeTemplate}
            panelFooterTemplate={panelFooterTemplate}
          />
        </CollapseCard>
        <CollapseCard
          title={<span className={classes.collapseTitle}>Postal Code</span>}
          BodyClass={"pt-0"}
          CardClass={"mt-1 px-1 py-1 bg-white"}
          CustomMinusIcon={MinusIconBlue}
          CustomPlusIcon={PlusIconBlue}
          IconStyle={{
            width: "20px",
            height: "20px",
          }}
        >
          <MultiSelect
            value={selectedZipCode}
            options={ZipCodeOption}
            onChange={(e) => setSelectedZipCode(e.value)}
            optionLabel="name"
            placeholder="Select Postal Code"
            filter
            showClear
            className={classes.MultiSelect}
            itemTemplate={countryTemplate}
            selectedItemTemplate={selectedZipCodeTemplate}
            panelFooterTemplate={panelFooterTemplate}
          />
        </CollapseCard>

        <div className="d-flex justify-content-end mt-2 mr-3">
          <GradientButton
            txt={"Apply Filters"}
            onClick={onSubmitFilters}
            style={{
              height: "32px",
              width: "120px",
              fontFamily: "Inter",
              fontSize: "13px",
            }}
          />
        </div>
      </div>
    </>
  );
}
