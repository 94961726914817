import styled from "styled-components";

export const ToolTipContainer = styled.div`
  position: absolute;
  left: ${({left})=>left?left:0};
  top: -70px;
  display: inline-block;
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.34;
  letter-spacing: normal;
  color:black !important;
  box-shadow: 0 0.4px 2.7px 0 rgba(0, 0, 0, 0.01),
    0 1.5px 5.6px 0 rgba(0, 0, 0, 0.01), 0 3.8px 11.2px 0 rgba(0, 0, 0, 0.01),
    0 7.3px 22px 0 rgba(0, 0, 0, 0.02), 0 12.3px 40.4px 0 rgba(0, 0, 0, 0.02),
    0 19px 69px 0 rgba(0, 0, 0, 0.03);
  background-color: #fff;
  width: ${({width})=>width?width:'125px'};
  text-align: ${({textAlign})=>textAlign?textAlign:'left'};
  height: auto;
  padding: 12px 8px;
  border-radius: 4px;
  z-index: 100;
  transition: all 0.2s ease;
  opacity: ${(props) => (props.show === true ? 1 : 0)};
`;
