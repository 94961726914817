import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";

import CollapseCard from "../CollapseCard";
import PlusIconBlue from "../../assets/images/plusl.png";
import MinusIconBlue from "../../assets/images/minusl.png";

import classes from "./PinderPage.module.css";
import { InputNumber } from "primereact/inputnumber";
import GradientButton from "../Button/GradientButton";
import { Slider } from "primereact/slider";
import { Dropdown } from "primereact/dropdown";

export default function PinderFilters({ ApplyFilter }) {
  const [searchQuery, setSearchQuery] = useState("");

  const [PropertyTypesAll, setPropertyTypesAll] = useState(false);

  const [PropertyTypes, setPropertyTypes] = useState({
    House: false,
    NewHomeDesigns: false,
    SemiDetached: false,
    Duplex: false,
    Terrace: false,
    Villa: false,
    BlockOfUnits: false,
    ApartmentUnitFlat: false,
    NewApartments: false,
    PentHouse: false,
    NewLand: false,
    DevelopmentSite: false,
    VacantLand: false,
    NewHouseLand: false,
    Studio: false,
  });

  const [Bedrooms, setBedrooms] = useState({ min: undefined, max: undefined });
  const [CarSpaces, setCarSpaces] = useState({
    min: undefined,
    max: undefined,
  });
  const [LandSize, setLandSize] = useState({ min: undefined, max: undefined });
  const [Bathrooms, setBathrooms] = useState({
    min: undefined,
    max: undefined,
  });
  const [PostalCode, setPostalCode] = useState("");
  const [RangeSlider, setRangeSlider] = useState([0, 100 * 10000000]);

  useEffect(() => {}, []);

  const onSubmitFilters = () => {
    const localFilter = {};

    localFilter.minBedrooms = Bedrooms?.min || undefined;
    localFilter.maxBedrooms = Bedrooms?.max || undefined;
    localFilter.minBathrooms = Bathrooms?.min || undefined;
    localFilter.maxBathrooms = Bathrooms?.max || undefined;
    localFilter.minCarspaces = CarSpaces?.min || undefined;
    localFilter.maxCarspaces = CarSpaces?.max || undefined;
    localFilter.minLandArea = LandSize?.min || undefined;
    localFilter.maxLandArea = LandSize?.max || undefined;
    localFilter.keywords = [searchQuery] || undefined;

    localFilter.listingType = ListingType;
    localFilter.locations = [{ postCode: PostalCode }];

    if (RangeSlider[0] && RangeSlider[1]) {
      localFilter.minPrice = RangeSlider[0];
      localFilter.maxPrice = RangeSlider[1];
    } else {
      localFilter.minPrice = undefined;
      localFilter.maxPrice = undefined;
    }

    const result = Object.entries(PropertyTypes).filter(
      (v) => v[1] === true && v[0]
    );

    localFilter.propertyTypes = result.map((v) => v[0]);

    ApplyFilter(localFilter);
  };

  const [ListingType, setListingType] = useState("Sale");
  const ListingTypeSelectItems = [
    { label: "For Sale", value: "Sale" },
    { label: "Rent", value: "Rent" },
    { label: "Sold", value: "Sold" },
  ];

  return (
    <>
      <div className={classes.filterBox}>
        <span className={classes.FilterBoxTitle}>Filters</span>
        <div className="d-flex flex-wrap  justify-content-center mt-3">
          <span className={`${classes.searchInput} p-input-icon-left`}>
            <i className="pi pinder pi-search" />
            <InputText
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search Area"
              className={classes.pinderSearchInputBox}
            />
          </span>
        </div>

        <CollapseCard
          title={<span className={classes.collapseTitle}>Listing Type</span>}
          BodyClass={"pt-0"}
          CardClass={"mt-1 px-1 py-1"}
          CustomMinusIcon={MinusIconBlue}
          CustomPlusIcon={PlusIconBlue}
          IconStyle={{
            width: "20px",
            height: "20px",
          }}
        >
          <Dropdown
            className={classes.filterDropdown}
            value={ListingType}
            options={ListingTypeSelectItems}
            onChange={(e) => setListingType(e.value)}
          />
        </CollapseCard>
        <CollapseCard
          title={<span className={classes.collapseTitle}>Property Type</span>}
          CardClass={"mt-1 px-1 py-1 bg-white"}
          CustomMinusIcon={MinusIconBlue}
          CustomPlusIcon={PlusIconBlue}
          IconStyle={{
            width: "20px",
            height: "20px",
          }}
        >
          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId="UseAverageAustralianAnnual"
              checked={PropertyTypesAll}
              onChange={(e) => {
                setPropertyTypesAll(!PropertyTypesAll);
                setPropertyTypes({
                  House: !PropertyTypesAll,
                  NewHomeDesigns: !PropertyTypesAll,
                  SemiDetached: !PropertyTypesAll,
                  Duplex: !PropertyTypesAll,
                  Terrace: !PropertyTypesAll,
                  Villa: !PropertyTypesAll,
                  BlockOfUnits: !PropertyTypesAll,
                  ApartmentUnitFlat: !PropertyTypesAll,
                  NewApartments: !PropertyTypesAll,
                  PentHouse: !PropertyTypesAll,
                  NewLand: !PropertyTypesAll,
                  DevelopmentSite: !PropertyTypesAll,
                  VacantLand: !PropertyTypesAll,
                  NewHouseLand: !PropertyTypesAll,
                  Studio: !PropertyTypesAll,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.House
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              All
            </label>
          </div>

          <label style={{ color: "black" }} className="my-3">
            Houses
          </label>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId=""
              checked={PropertyTypes.House}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  House: !PropertyTypes.House,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.House
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              House
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId="NewHomeDesigns"
              checked={PropertyTypes.NewHomeDesigns}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  NewHomeDesigns: !PropertyTypes.NewHomeDesigns,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.NewHomeDesigns
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              New Home Designs
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId="NewHouseLand"
              checked={PropertyTypes.NewHouseLand}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  NewHouseLand: !PropertyTypes.NewHouseLand,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.NewHouseLand
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              New House Land
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId="SemiDetached"
              checked={PropertyTypes.SemiDetached}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  SemiDetached: !PropertyTypes.SemiDetached,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.SemiDetached
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Semi Detached
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId="Duplex"
              checked={PropertyTypes.Duplex}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  Duplex: !PropertyTypes.Duplex,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.Duplex
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Duplex
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId="Terrace"
              checked={PropertyTypes.Terrace}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  Terrace: !PropertyTypes.Terrace,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.Terrace
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Terrace
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId="Villa"
              checked={PropertyTypes.Villa}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  Villa: !PropertyTypes.Villa,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.Villa
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Villa
            </label>
          </div>

          <label style={{ color: "black" }} className="my-3">
            Apartment
          </label>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId=""
              checked={PropertyTypes.BlockOfUnits}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  BlockOfUnits: !PropertyTypes.BlockOfUnits,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.BlockOfUnits
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Block Of Units
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId=""
              checked={PropertyTypes.ApartmentUnitFlat}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  ApartmentUnitFlat: !PropertyTypes.ApartmentUnitFlat,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.ApartmentUnitFlat
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Apartment Unit Flat
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId=""
              checked={PropertyTypes.NewApartments}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  NewApartments: !PropertyTypes.NewApartments,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.NewApartments
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              New Apartments
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId=""
              checked={PropertyTypes.PentHouse}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  PentHouse: !PropertyTypes.PentHouse,
                });
              }}
            />

            <label
              className={`${
                PropertyTypes.PentHouse
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Pent House
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId=""
              checked={PropertyTypes.Studio}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  Studio: !PropertyTypes.Studio,
                });
              }}
            />

            <label
              className={`${
                PropertyTypes.Studio
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Studio
            </label>
          </div>

          <label style={{ color: "black" }} className="my-3">
            Land
          </label>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId=""
              checked={PropertyTypes.NewLand}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  NewLand: !PropertyTypes.NewLand,
                });
              }}
            />

            <label
              className={`${
                PropertyTypes.NewLand
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              New Land
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId=""
              checked={PropertyTypes.DevelopmentSite}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  DevelopmentSite: !PropertyTypes.DevelopmentSite,
                });
              }}
            />

            <label
              className={`${
                PropertyTypes.DevelopmentSite
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Development Site
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId=""
              checked={PropertyTypes.VacantLand}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  VacantLand: !PropertyTypes.VacantLand,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.VacantLand
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Vacant Land
            </label>
          </div>
        </CollapseCard>

        <CollapseCard
          title={<span className={classes.collapseTitle}>Postcode</span>}
          BodyClass={"pt-0"}
          CardClass={"mt-1 px-1 py-1"}
          CustomMinusIcon={MinusIconBlue}
          CustomPlusIcon={PlusIconBlue}
          IconStyle={{
            width: "20px",
            height: "20px",
          }}
        >
          <InputText
            value={PostalCode}
            onChange={(e) => setPostalCode(e.target.value)}
            inputClassName={classes.PinderRange}
            placeholder="Postcode"
          />
        </CollapseCard>

        <CollapseCard
          title={<span className={classes.collapseTitle}>Bathrooms</span>}
          BodyClass={"pt-0"}
          CardClass={"mt-1 px-1 py-1"}
          CustomMinusIcon={MinusIconBlue}
          CustomPlusIcon={PlusIconBlue}
          IconStyle={{
            width: "20px",
            height: "20px",
          }}
        >
          <InputNumber
            value={Bathrooms?.min}
            onChange={(e) => setBathrooms({ ...Bathrooms, min: e.value })}
            inputClassName={classes.PinderRange}
            placeholder="Min"
          />
          <InputNumber
            value={Bathrooms?.max}
            onChange={(e) => setBathrooms({ ...Bathrooms, max: e.value })}
            inputClassName={classes.PinderRange}
            placeholder="Max"
          />
        </CollapseCard>
        <CollapseCard
          title={<span className={classes.collapseTitle}>Bedrooms</span>}
          BodyClass={"pt-0"}
          CardClass={"mt-1 px-1 py-1"}
          CustomMinusIcon={MinusIconBlue}
          CustomPlusIcon={PlusIconBlue}
          IconStyle={{
            width: "20px",
            height: "20px",
          }}
        >
          <InputNumber
            value={Bedrooms?.min}
            onChange={(e) => setBedrooms({ ...Bedrooms, min: e.value })}
            inputClassName={classes.PinderRange}
            placeholder="Min Area"
          />
          <InputNumber
            value={Bedrooms?.max}
            onChange={(e) => setBedrooms({ ...Bedrooms, max: e.value })}
            inputClassName={classes.PinderRange}
            placeholder="Max Area"
          />
        </CollapseCard>
        <CollapseCard
          title={<span className={classes.collapseTitle}>Car Spaces</span>}
          BodyClass={"pt-0"}
          CardClass={"mt-1 px-1 py-1"}
          CustomMinusIcon={MinusIconBlue}
          CustomPlusIcon={PlusIconBlue}
          IconStyle={{
            width: "20px",
            height: "20px",
          }}
        >
          <InputNumber
            value={CarSpaces?.min}
            onChange={(e) => setCarSpaces({ ...CarSpaces, min: e.value })}
            inputClassName={classes.PinderRange}
            placeholder="Min"
          />
          <InputNumber
            value={CarSpaces?.max}
            onChange={(e) => setCarSpaces({ ...CarSpaces, max: e.value })}
            inputClassName={classes.PinderRange}
            placeholder="Max"
          />
        </CollapseCard>
        <CollapseCard
          title={<span className={classes.collapseTitle}>Land Size</span>}
          BodyClass={"pt-0"}
          CardClass={"mt-1 px-1 py-1"}
          CustomMinusIcon={MinusIconBlue}
          CustomPlusIcon={PlusIconBlue}
          IconStyle={{
            width: "20px",
            height: "20px",
          }}
        >
          <InputNumber
            value={LandSize?.min}
            onChange={(e) => setLandSize({ ...LandSize, min: e.value })}
            inputClassName={classes.PinderRange}
            placeholder="Min"
          />
          <InputNumber
            value={LandSize?.max}
            onChange={(e) => setLandSize({ ...LandSize, max: e.value })}
            inputClassName={classes.PinderRange}
            placeholder="Max"
          />
        </CollapseCard>
        <CollapseCard
          title={<span className={classes.collapseTitle}>Price</span>}
          BodyClass={"pt-1"}
          CardClass={"mt-1 px-1 py-1"}
          CustomMinusIcon={MinusIconBlue}
          CustomPlusIcon={PlusIconBlue}
          IconStyle={{
            width: "20px",
            height: "20px",
          }}
        >
          <Slider
            value={[RangeSlider[0] / 10000000, RangeSlider[1] / 10000000]}
            className="mb-3"
            onChange={(e) =>
              setRangeSlider([e.value[0] * 10000000, e.value[1] * 10000000])
            }
            range
          />
          <InputNumber
            value={RangeSlider[0]}
            onChange={(e) => setRangeSlider([e.value, RangeSlider[1]])}
            inputClassName={classes.PinderRange}
            placeholder="Min Price"
          />
          <InputNumber
            value={RangeSlider[1]}
            onChange={(e) => setRangeSlider([RangeSlider[0], e.value])}
            inputClassName={classes.PinderRange}
            placeholder="Max Price"
          />
        </CollapseCard>
        <div className="d-flex justify-content-end mt-2 mr-3">
          <GradientButton
            txt={"Apply Filters"}
            onClick={onSubmitFilters}
            style={{
              height: "32px",
              width: "120px",
              fontFamily: "Inter",
              fontSize: "13px",
            }}
          />
        </div>
      </div>
    </>
  );
}
