
import Header from "../../components/header/Header";
import UserSidebar from "../../components/SideBar/UserSidebar";
import ThemesSettings from "../../components/ThemesSettings";
import { HomeContainer, OuterContainer } from "../Home/Home.styles";

export default function Themes() {
  return (
    <OuterContainer>
      <UserSidebar headerPath="Settings" />
      <HomeContainer>
        <Header headerPath="/Settings/Themes" />
        <ThemesSettings />
      </HomeContainer>
    </OuterContainer>
  );
}
