const initialState = {
  // data: [
  //   {
  //     _id: "63865cda62520af1337fa949",
  //     Suburb_Name: "ALDGATE",
  //     State: "SA",
  //     Suburb_Id: "loc27ec55ac4cfe",
  //     Median_Sale_Price: 1230000,
  //     Median_Rental_Price: 550,
  //   },
  //   {
  //     _id: "63865cda62520af1337fa94a",
  //     Suburb_Name: "BUNDABERG NORTH",
  //     State: "QLD",
  //     Suburb_Id: "loc852bd01da779",
  //     Median_Sale_Price: 341000,
  //     Median_Rental_Price: 297.5,
  //   },
  //   {
  //     _id: "63865cda62520af1337fa94b",
  //     Suburb_Name: "ONKAPARINGA HILLS",
  //     State: "SA",
  //     Suburb_Id: "loc041ef0a1ab1d",
  //     Median_Sale_Price: 635000,
  //     Median_Rental_Price: 410,
  //   },
  //   {
  //     _id: "63865cda62520af1337fa94c",
  //     Suburb_Name: "TAMWORTH",
  //     State: "NSW",
  //     Suburb_Id: "locd941fb799556",
  //     Median_Sale_Price: 580000,
  //     Median_Rental_Price: 320,
  //   },
  //   {
  //     _id: "63865cda62520af1337fa94d",
  //     Suburb_Name: "IPSWICH",
  //     State: "QLD",
  //     Suburb_Id: "loc0317295e415d",
  //     Median_Sale_Price: 522500,
  //     Median_Rental_Price: 330,
  //   },
  //   {
  //     _id: "63865cda62520af1337fa94e",
  //     Suburb_Name: "GEELONG",
  //     State: "VIC",
  //     Suburb_Id: "loc8494a97d1462",
  //     Median_Sale_Price: 860000,
  //     Median_Rental_Price: 410,
  //   },
  //   {
  //     _id: "63865cda62520af1337fa94f",
  //     Suburb_Name: "GLADSTONE",
  //     State: "NSW",
  //     Suburb_Id: "loc6c032bbb5ce5",
  //     Median_Sale_Price: 521500,
  //     Median_Rental_Price: 367.5,
  //   },
  //   {
  //     _id: "63865cda62520af1337fa950",
  //     Suburb_Name: "ROCKHAMPTON CITY",
  //     State: "QLD",
  //     Suburb_Id: "loc2d3ddabf5d73",
  //     Median_Sale_Price: 240000,
  //     Median_Rental_Price: 242.5,
  //   },
  //   {
  //     _id: "63865cda62520af1337fa951",
  //     Suburb_Name: "TOOWOOMBA CITY",
  //     State: "QLD",
  //     Suburb_Id: "loc35ae937ff1b4",
  //     Median_Sale_Price: 467500,
  //     Median_Rental_Price: 330,
  //   },
  //   {
  //     _id: "63865cda62520af1337fa952",
  //     Suburb_Name: "MACKAY",
  //     State: "QLD",
  //     Suburb_Id: "loc7b0fad950c76",
  //     Median_Sale_Price: 395000,
  //     Median_Rental_Price: 350,
  //   },
  // ],
  data: {
    retail: [],
    industry: [],
    office: [],
    residential: [],
    other: [],
  },
};
const DomainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MEDIAN_PRICES":
      state = { ...state, data: action.payload };
      return state;
    default:
      return state;
  }
};
export default DomainReducer;
