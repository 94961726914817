import { RadioButton } from "primereact/radiobutton";
import { useLayoutEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import styles from "./stylesheet.module.css";

export default function ThemesSettings() {
  const mystate=useSelector((state)=>state.ThemePattern)
  const [Theme, setTheme] = useState("blue");
 
  useLayoutEffect(() => {
    let css = document.documentElement.style;
    if (Theme === "red") {
      css.setProperty(`--gredient-blue-1`, "#DC2335");
      css.setProperty(`--gredient-blue-2`, "#DC2335");
      css.setProperty(`--main-blue`, "#DC2335");
      css.setProperty(`--solid-colors`,"#000000")
      css.setProperty(`--primary-yellow`,"#fff")
    } else {
      console.log(mystate)
      if(mystate==="Propvesting"){
        css.setProperty(`--gredient-blue-1`, "#0565ea");
        css.setProperty(`--gredient-blue-2`, "#03c4fa");
        css.setProperty(`--main-blue`, "#0465eb");
        css.setProperty(`--heading-color`,"#059af4")
        css.setProperty(`--solid-colors`,"#0465eb")
        css.setProperty(`--link-active-color`,"#2196F3")
      }else{
        css.setProperty(`--gredient-blue-1`, "#272660");
        css.setProperty(`--gredient-blue-2`, "#272660");
        css.setProperty(`--main-blue`, "#272660");
        css.setProperty(`--heading-color`,"#262660")
        css.setProperty(`--solid-colors`,"#272660")
        css.setProperty(`--link-active-color`,"#272660")
      }
         

    }
  }, [Theme]);

  return (
    <Card className={`card ${styles.container}`}>
      <h1 className="card-title mb-3">Themes</h1>
      <Row className="card-row">
        <Col sm={12} xs={12} md={12} lg={6}>
          <div className="d-flex align-items-center">
            <RadioButton
              value="blue"
              name="theme"
              id="blue"
              onChange={(e) => setTheme(e.value)}
              checked={Theme === "blue"}
            />
            <label htmlFor="blue" className="ml-2 text-dark">
              {mystate=="Propvesting"?"Blue":"Purple"}   
            </label>
          </div>
        </Col>
      </Row>
      <Row className="card-row">
        <Col sm={12} xs={12} md={12} lg={6}>
          <div className="d-flex align-items-center mt-2">
            <RadioButton
              value="red"
              name="theme"
              id="red"
              onChange={(e) => setTheme(e.value)}
              checked={Theme === "red"}
            />
            <label htmlFor="red" className="ml-2 text-dark">
              Red
            </label>
          </div>
        </Col>
      </Row>
    </Card>
  );
}
