import React from "react";

const NextButton = ({ onClick = () => {}, currentPage, endPage }) => {
  const iconColor = currentPage === endPage ? "var(--white)" : "#1B1B1B";
  const backgroundColor = currentPage === endPage ? "var(--grey-smooky)" : "var(--white)";
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: "pointer" }}
        onClick={onClick}
      >
        <rect
          x=".5"
          y=".5"
          width="31"
          height="31"
          rx="3.5"
          fill={backgroundColor}
        />
        <rect x=".5" y=".5" width="31" height="31" rx="3.5" stroke="#DFE3E8" />
        <path
          d="m13.75 10 5.625 5.625-5.625 5.625"
          stroke={iconColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default NextButton;
