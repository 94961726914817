import { InputText } from "primereact/inputtext";
import { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { API } from "../../services/api";
import Avatar from "../Avatar/Avatar";
import "./stylesheet.css";
import { publish } from "../../services/global";
import { ContactDropDown, ContactContainer } from "../Contact/Contact.style";
import { validateImage } from "../../utils";



export default function VendorProfileInfo({
  vendorid,
  setActiveIndex,
  VendorData,
  OnChange,
}) {
  const [CheckUser, setCheckUser] = useState(false);
  const [FirstName, setFirstName] = useState(VendorData?.data?.first_name);
  const [LastName, setLastName] = useState(VendorData?.data?.last_name);
  const [Mobile, setMobile] = useState(VendorData?.data?.mobile_no);
  const [userdata, setuserdata] = useState({
    photo: VendorData?.data?.photo,
  });

  const [initialRender, setInitial] = useState(true);
const[initialShow,setInitialShow]=useState(true);
  const [PhoneNumber, setPhoneNumber] = useState(
    VendorData?.data?.telephone_no
  );
  const [Email, setEmail] = useState(VendorData?.data?.email);
  const [ContactType, setContactType] = useState(
    VendorData?.data?.contact_type
  );
  const [photo, setPhoto] = useState("");
  const [Address, setAddress] = useState(VendorData?.data?.contact_address);
  const [PrimaryContact, setPrimaryContact] = useState(
    VendorData?.data?.enquiry_source
  );
  const [Agent, setAgent] = useState(VendorData?.data?.agent);

  const [agentTypes, setAgentTypes] = useState([]);
  const ContactTypes = [
    { label: "Client", value: "Vendor" },
    { label: "Agent", value: "Contact" },
  ];
  const ContactTypes1 = [
    { label: "Client", value: "Vendor" },
    { label: "Client", value: "Contact" },
  ];
  const getUsers = async () => {
    const data = await API.get(`users`);
    let usersData = [];
    data.data.map((user) =>{
      if(user.full_name=="PropVesting Admin"){
        usersData.push({ label: "InvestLogic", value: "InvestLogic" })
      }else{
        usersData.push({ label: user.full_name, value: user.full_name })
      }
    }
    );
    console.log(usersData)
    setInitial(false);
    if (!Agent) {
      setAgent(usersData[0].label);
    }
    if(Agent=="PropVesting Admin"){
      setAgent("InvestLogic");
    }
    setAgentTypes(usersData);
  };

  const AddressRef = useRef();
  const autoCompleteRef = useRef();
  let UpdateVendorProfile = async () => {
    let UpdatedData = {
      first_name: FirstName,
      last_name: LastName,
      contact_type: ContactType,
      mobile_no: Mobile,
      telephone_no: PhoneNumber,
      email: Email,
      contact_address: AddressRef.current.value,
      enquiry_source: PrimaryContact,
      agent: Agent,
    };
    let test = validateImage(userdata.photo);
    if (test === true) {
      UpdatedData = { ...UpdatedData, photo: userdata.photo };
      
      await API.put(`contacts/${vendorid}`, UpdatedData);
    }else{
      await API.put(`contacts/${vendorid}`, UpdatedData);
    }
  };

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      AddressRef.current
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();

      setAddress(place.formatted_address);
    });
  }, []);

  useEffect(() => {
    publish(`fixedtop`, { fixedtop: false });
    getUsers();
    return () => {
      setInitial(true);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log("hey")
    if (initialShow) {
      setInitialShow(false);
     
    }else{
      UpdateVendorProfile();
    }
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    userdata,
    FirstName,
    LastName,
    ContactType,
    Mobile,
    PhoneNumber,
    Email,
    Address,
    PrimaryContact,
    Agent,
  ]);
  useEffect(() => {
    let accountType = sessionStorage.getItem("accountType");
    console.log(accountType,"accountType")
    if (accountType.toLowerCase() === "contacts") {
      setCheckUser(true);
    } else {
      setCheckUser(false);
    }
  }, []);
  return (
    <ContactContainer margin="none" style={{ marginTop: "30px" }}>
      <div className="profile-container">
        <h1 className="mt-4">Profile</h1>
     
        <div className="avatar-container">
          <Avatar
            text={VendorData.data.full_name}
            imgSrc={userdata.photo}
            setImgSrc={setPhoto}
            setuserdata={setuserdata}
            data={userdata}
            width="80px"
            height="80px"
            fontSize="23px"
            textColor="#fefefe"
          />

          <div className="avatar-text-container">
            <p className="main-text">
              {FirstName ? FirstName : ""}&nbsp;{LastName ? LastName : ""}
            </p>
            <p className="sub-text">{ContactType}</p>
          </div>
        </div>
      </div>

      <div>
        <Row className="container">
          <Col sm={12} md={4} lg={4}>
            <div className="mb-2">
              <p className="input-label mb-2">First Name</p>
              <InputText
                type={"text"}
                value={FirstName}
                onChange={(e) => setFirstName(e.target.value)}
                className=" edit-input-field"
              />
            </div>
          </Col>
          <Col sm={12} md={4} lg={4}>
            <div className="mb-2">
              <p className="input-label mb-2">Last Name</p>
              <InputText
                type={"text"}
                value={LastName}
                onChange={(e) => setLastName(e.target.value)}
                className=" edit-input-field"
              />
            </div>
          </Col>
          <Col sm={12} md={4} lg={4}>
            <div className="mb-2">
              <p className="input-label mb-2">Email</p>
              <InputText
                type={"email"}
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
                className=" edit-input-field"
              />
            </div>
          </Col>
        </Row>
        <Row className="container">
          <Col sm={12} md={4} lg={4}>
            <div className="mb-2">
              <p className="input-label mb-2">Mobile No</p>
              <InputText
                type={"tel"}
                value={Mobile}
                onChange={(e) => setMobile(e.target.value)}
                className=" edit-input-field"
              />
            </div>
          </Col>
          <Col sm={12} md={4} lg={4}>
            <div className="mb-2">
              <p className="input-label mb-2">Phone Number</p>
              <InputText
                type={"tel"}
                value={PhoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className=" edit-input-field"
              />
            </div>
          </Col>
          <Col sm={12} md={4} lg={4}>
            <div className="mb-2">
              <p className="input-label mb-2">Contact Type</p>
              
              <ContactDropDown
                value={ContactType}
                onChange={(e) => setContactType(e.value)}
                options={CheckUser ? ContactTypes1 : ContactTypes}
                disabled={CheckUser ? true : false}
              />
            </div>
          </Col>
        </Row>
        <Row className="container">
          <Col sm={12} md={4} lg={4}>
            <div className="mb-2">
              <p className="input-label mb-2"> Address</p>
              <InputText
                onFocus={(e) => (e.target.value = "")}
                ref={AddressRef}
                type={"text"}
                value={Address}
                placeholder="Enter Address"
                onChange={(e) => setAddress(AddressRef.current.value)}
                className=" edit-input-field"
              />
            </div>
          </Col>
          {/* <Col sm={12} md={4} lg={4}>
            <div>
              <p className="input-label mb-2">Primary Contact</p>
              <InputText
                type={"text"}
                value={PrimaryContact}
                onChange={(e) => setPrimaryContact(e.target.value)}
                className=" edit-input-field"
              />
            </div>
          </Col> */}
          <Col sm={12} md={4} lg={4}>
            <div className="mb-2">
              <p className="input-label mb-2">Agent</p>
              <ContactDropDown
                style={{ alignSelf: "center" }}
                value={Agent}
                onChange={(e) => setAgent(e.value)}
                options={agentTypes}
              />
            </div>
          </Col>
        </Row>
      </div>
    </ContactContainer>
  );
}
