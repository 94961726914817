import React from "react";
import { ButtonGradient } from "./Button.style";
const GradientButton = ({ txt, onClick, style = {},classes='' }) => {
  return (
    <ButtonGradient className={classes} style={style} onClick={onClick}>
    {txt === undefined ? "Next" : txt}
    </ButtonGradient>
  );
};

export default GradientButton;
