import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { AutoComplete } from "primereact/autocomplete";

import { Checkbox } from "primereact/checkbox";

import CollapseCard from "../CollapseCard";
import PlusIconBlue from "../../assets/images/plusl.png";
import MinusIconBlue from "../../assets/images/minusl.png";

import classes from "./PinderPage.module.css";
import { InputNumber } from "primereact/inputnumber";
import { API } from "../../services/api";
import GradientButton from "../Button/GradientButton";
import { Slider } from "primereact/slider";
import { Dropdown } from "primereact/dropdown";

export default function PinderFiltersForCommercial({
  ApplyFilter,
  ErrorMessage = null,
}) {
  const [searchQuery, setSearchQuery] = useState("");

  const [PropertyTypesAll, setPropertyTypesAll] = useState(false);

  const [PropertyTypes, setPropertyTypes] = useState({
    Retail: false,
    Offices: false,
    ServicedOffices: false,
    DevelopmentLand: false,
    IndustrialWarehouse: false,
    Medical: false,
    MedicalConsulting: false,
    Carspace: false,
    ParkingCarSpace: false,
    Rural: false,
    RuralCommercialFarming: false,
    Motel: false,
    HotelLeisure: false,
    ShowroomsBulkyGoods: false,
    Other: false,
  });

  const [selectedState, setSelectedState] = useState("");
  const [selectedSuburb, setSelectedSuburb] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedPostCode, setSelectedPostCode] = useState("");

  const [LandSize, setLandSize] = useState({ min: undefined, max: undefined });

  const [stateFilter, setStateFilter] = useState([]);

  const [RangeSlider, setRangeSlider] = useState([0, 100 * 10000000]);
  const [stateOrRegion, setStateOrRegion] = useState(false);

  const [Result, setResult] = useState({
    state: false,
    region: false,
    suburb: false,
  });

  const [Error, setError] = useState({
    state: "",
    region: "",
    suburb: "",
  });

  useEffect(() => {}, []);

  const onSubmitFilters = () => {
    setResult({
      state: false,
      region: false,
      suburb: false,
    });
    setError({
      state: "",
      region: "",
      suburb: "",
    });

    if (selectedState !== "") {
      if (selectedRegion === "" && selectedSuburb === "") {
        if (!stateOrRegion) {
          setResult({ suburb: true });
          setError({ suburb: "fill the suburb field" });
        } else {
          setResult({ region: true });
          setError({ region: "fill the region field" });
        }
      } else {
        const localFilter = {};

        localFilter.landAreaMin = LandSize?.min || undefined;
        localFilter.landAreaMax = LandSize?.max || undefined;
        localFilter.keywords = [searchQuery] || undefined;
        localFilter.searchMode = ListingType;

        localFilter.locations = [
          {
            state: selectedState.name || selectedState,
            region: stateOrRegion ? selectedRegion.name || selectedRegion : "",
            suburb: !stateOrRegion ? selectedSuburb.name || selectedSuburb : "",
            postcode: selectedPostCode.name || selectedPostCode,
            area: "",
            street: "",
          },
        ];

        if (RangeSlider[0] || RangeSlider[1]) {
          let price = {
            min: RangeSlider[0],
            max: RangeSlider[1],
            type: "totalAmount",
          };
          localFilter.price = price;
        } else {
          localFilter.price = {
            min: 0,
            max: 100 * 10000000,
            type: "totalAmount",
          };
        }

        const result = Object.entries(PropertyTypes).filter(
          (v) => v[1] === true && v[0]
        );
        localFilter.propertyTypes = result.map((v) => v[0]);

        ApplyFilter(localFilter);
      }
    } else if (selectedState === "") {
      if (selectedRegion !== "" || selectedSuburb !== "") {
        setResult({ state: true });
        setError({ state: "fill the state field" });
      } else if (
        selectedRegion === "" &&
        selectedState === "" &&
        selectedSuburb === "" &&
        selectedPostCode !== ""
      ) {
        setResult({ state: true, suburb: true, region: true });
        setError({
          state: "fill the state field",
          suburb: "fill the suburb field",
          region: "fill the region field",
        });
      } else {
        const localFilter = {};

        localFilter.locations = [
          {
            state: selectedState.name || selectedState,
            region: stateOrRegion ? selectedRegion.name || selectedRegion : "",
            suburb: !stateOrRegion ? selectedSuburb.name || selectedSuburb : "",
            postcode: selectedPostCode.name || selectedPostCode,
            area: "",
            street: "",
          },
        ];

        localFilter.landAreaMin = LandSize?.min || undefined;
        localFilter.landAreaMax = LandSize?.max || undefined;
        localFilter.keywords = [searchQuery] || undefined;

        localFilter.searchMode = ListingType;

        if (RangeSlider[0] || RangeSlider[1]) {
          let price = {
            min: RangeSlider[0],
            max: RangeSlider[1],
            type: "totalAmount",
          };
          localFilter.price = price;
        } else {
          localFilter.price = {
            min: 0,
            max: 100 * 10000000,
            type: "totalAmount",
          };
        }

        const result = Object.entries(PropertyTypes).filter(
          (v) => v[1] === true && v[0]
        );

        localFilter.propertyTypes = result.map((v) => v[0]);

        ApplyFilter(localFilter);
      }
    }
  };

  const [ListingType, setListingType] = useState("forSale");
  const ListingTypeSelectItems = [
    { label: "For Sale", value: "forSale" },
    { label: "For Lease", value: "forLease" },
    { label: "Sold", value: "sold" },
    { label: "Leased", value: "leased" },
  ];

  const [Data, setData] = useState("");
  const getData = async () => {
    const res = await API.get("/stateData");
    setData(res.data);
  };

  const searchFilter = (event, data) => {
    let _filtered;
    if (!event.query.trim().length) {
      _filtered = [...data];
    } else {
      _filtered = data?.filter((dataValue) => {
        return dataValue.name
          .toLowerCase()
          .startsWith(event.query.toLowerCase());
      });
    }

    setStateFilter(_filtered);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className={classes.filterBox}>
        <span className={classes.FilterBoxTitle}>Filters</span>
        <div className="d-flex flex-wrap  justify-content-center mt-3">
          <span className={`${classes.searchInput} p-input-icon-left`}>
            <i className="pi pinder pi-search" />
            <InputText
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search Area"
              className={classes.pinderSearchInputBox}
            />
          </span>
        </div>

        <CollapseCard
          title={<span className={classes.collapseTitle}>Listing Type</span>}
          BodyClass={"pt-0"}
          CardClass={"mt-1 px-1 py-1"}
          CustomMinusIcon={MinusIconBlue}
          CustomPlusIcon={PlusIconBlue}
          IconStyle={{
            width: "20px",
            height: "20px",
          }}
        >
          <Dropdown
            className={classes.filterDropdown}
            value={ListingType}
            options={ListingTypeSelectItems}
            onChange={(e) => setListingType(e.value)}
          />
        </CollapseCard>
        <CollapseCard
          title={<span className={classes.collapseTitle}>Property Type</span>}
          CardClass={"mt-1 px-1 py-1 bg-white"}
          CustomMinusIcon={MinusIconBlue}
          CustomPlusIcon={PlusIconBlue}
          IconStyle={{
            width: "20px",
            height: "20px",
          }}
        >
          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId="UseAverageAustralianAnnual"
              checked={PropertyTypesAll}
              onChange={(e) => {
                setPropertyTypesAll(!PropertyTypesAll);
                setPropertyTypes({
                  Retail: !PropertyTypesAll,
                  Offices: !PropertyTypesAll,
                  ServicedOffices: !PropertyTypesAll,
                  DevelopmentLand: !PropertyTypesAll,
                  IndustrialWarehouse: !PropertyTypesAll,
                  Medical: !PropertyTypesAll,
                  MedicalConsulting: !PropertyTypesAll,
                  Carspace: !PropertyTypesAll,
                  ParkingCarSpace: !PropertyTypesAll,
                  Rural: !PropertyTypesAll,
                  RuralCommercialFarming: !PropertyTypesAll,
                  Motel: !PropertyTypesAll,
                  HotelLeisure: !PropertyTypesAll,
                  ShowroomsBulkyGoods: !PropertyTypesAll,
                  Other: !PropertyTypesAll,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.Retail
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              All
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId=""
              checked={PropertyTypes.Retail}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  Retail: !PropertyTypes.Retail,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.Retail
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Retail
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId="Offices"
              checked={PropertyTypes.Offices}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  Offices: !PropertyTypes.Offices,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.Offices
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Offices
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId="ServicedOffices"
              checked={PropertyTypes.ServicedOffices}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  ServicedOffices: !PropertyTypes.ServicedOffices,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.ServicedOffices
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Serviced Offices
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId="DevelopmentLand"
              checked={PropertyTypes.DevelopmentLand}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  DevelopmentLand: !PropertyTypes.DevelopmentLand,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.DevelopmentLand
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Development Land
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId="IndustrialWarehouse"
              checked={PropertyTypes.IndustrialWarehouse}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  IndustrialWarehouse: !PropertyTypes.IndustrialWarehouse,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.IndustrialWarehouse
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Industrial Warehouse
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId="Medical"
              checked={PropertyTypes.Medical}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  Medical: !PropertyTypes.Medical,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.Medical
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Medical
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId="MedicalConsulting"
              checked={PropertyTypes.MedicalConsulting}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  MedicalConsulting: !PropertyTypes.MedicalConsulting,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.MedicalConsulting
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Medical Consulting
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId=""
              checked={PropertyTypes.Rural}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  Rural: !PropertyTypes.Rural,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.Rural
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Rural
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId=""
              checked={PropertyTypes.Carspace}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  Carspace: !PropertyTypes.Carspace,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.Carspace
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Car Space
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId=""
              checked={PropertyTypes.ParkingCarSpace}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  ParkingCarSpace: !PropertyTypes.ParkingCarSpace,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.ParkingCarSpace
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Parking Car Space
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId=""
              checked={PropertyTypes.RuralCommercialFarming}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  RuralCommercialFarming: !PropertyTypes.RuralCommercialFarming,
                });
              }}
            />

            <label
              className={`${
                PropertyTypes.RuralCommercialFarming
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Rural Commercial Farming
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId=""
              checked={PropertyTypes.Motel}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  Motel: !PropertyTypes.Motel,
                });
              }}
            />

            <label
              className={`${
                PropertyTypes.Motel
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Motel
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId=""
              checked={PropertyTypes.HotelLeisure}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  HotelLeisure: !PropertyTypes.HotelLeisure,
                });
              }}
            />

            <label
              className={`${
                PropertyTypes.HotelLeisure
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Hotel Leisure
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId=""
              checked={PropertyTypes.ShowroomsBulkyGoods}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  ShowroomsBulkyGoods: !PropertyTypes.ShowroomsBulkyGoods,
                });
              }}
            />

            <label
              className={`${
                PropertyTypes.ShowroomsBulkyGoods
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Showrooms Bulky Goods
            </label>
          </div>

          <div className={`${classes.checkbox} mb-1`}>
            <Checkbox
              inputId=""
              checked={PropertyTypes.Other}
              onChange={(e) => {
                setPropertyTypes({
                  ...PropertyTypes,
                  Other: !PropertyTypes.Other,
                });
              }}
            />
            <label
              className={`${
                PropertyTypes.Other
                  ? classes.activeCheckboxLabel
                  : classes.checkboxLabel
              }`}
            >
              Other
            </label>
          </div>
        </CollapseCard>

        <CollapseCard
          title={<span className={classes.collapseTitle}>Location Search</span>}
          BodyClass={"pt-0"}
          CardClass={"mt-1 px-1 py-1"}
          CustomMinusIcon={MinusIconBlue}
          CustomPlusIcon={PlusIconBlue}
          IconStyle={{
            width: "20px",
            height: "20px",
          }}
        >
          <AutoComplete
            value={selectedState}
            suggestions={stateFilter}
            completeMethod={(e) => searchFilter(e, Data.states)}
            field="name"
            onChange={(e) => setSelectedState(e.value)}
            aria-label="Countries"
            dropdownAriaLabel="Select Country"
            placeholder="Select a State"
            className={`${classes.filterState} `}
            inputClassName={`${Result.state && classes.danger}`}
          />
          {Result.state && (
            <label className="text-danger mb-2">{Error.state}</label>
          )}

          <br />
          <div className="d-flex align-items-center gap-2 mb-1  ">
            <label style={{ color: "black" }}>Suburb/Region</label>
            <InputSwitch
              className={`${classes.inputSwitch}`}
              checked={stateOrRegion}
              onChange={(e) => setStateOrRegion(e.value)}
            />
          </div>

          {!stateOrRegion ? (
            <>
              <label style={{ color: "black" }} className="mb-1">
                Suburb
              </label>
              <AutoComplete
                value={selectedSuburb}
                suggestions={stateFilter}
                completeMethod={(e) => searchFilter(e, Data.suburbs)}
                field="name"
                onChange={(e) => setSelectedSuburb(e.value)}
                aria-label="Countries"
                dropdownAriaLabel="Select Country"
                placeholder="Select a Suburb"
                className={`${classes.filterState} 	`}
                inputClassName={`${Result.suburb && classes.danger} `}
              />
              {Result.suburb && (
                <label className="text-danger mb-2">{Error.suburb}</label>
              )}
            </>
          ) : (
            <>
              <label style={{ color: "black" }} className="mb-1">
                Region
              </label>
              <AutoComplete
                value={selectedRegion}
                suggestions={stateFilter}
                completeMethod={(e) => searchFilter(e, Data.regions)}
                field="name"
                onChange={(e) => setSelectedRegion(e.value)}
                aria-label="Countries"
                dropdownAriaLabel="Select Country"
                placeholder="Select a Region"
                className={classes.filterState}
                inputClassName={`${Result.region && classes.danger}`}
              />
              {Result.region && (
                <label className="text-danger mb-2">{Error.region}</label>
              )}
            </>
          )}

          <AutoComplete
            value={selectedPostCode}
            suggestions={stateFilter}
            completeMethod={(e) => searchFilter(e, Data.postcode)}
            field="name"
            onChange={(e) => setSelectedPostCode(e.value)}
            aria-label="Countries"
            dropdownAriaLabel="Select Country"
            placeholder="Postcode"
            className={classes.filterState}
          />
        </CollapseCard>

        <CollapseCard
          title={<span className={classes.collapseTitle}>Land Size</span>}
          BodyClass={"pt-0"}
          CardClass={"mt-1 px-1 py-1"}
          CustomMinusIcon={MinusIconBlue}
          CustomPlusIcon={PlusIconBlue}
          IconStyle={{
            width: "20px",
            height: "20px",
          }}
        >
          <InputNumber
            value={LandSize?.min}
            onChange={(e) => setLandSize({ ...LandSize, min: e.value })}
            inputClassName={classes.PinderRange}
            placeholder="Min"
          />
          <InputNumber
            value={LandSize?.max}
            onChange={(e) => setLandSize({ ...LandSize, max: e.value })}
            inputClassName={classes.PinderRange}
            placeholder="Max"
          />
        </CollapseCard>
        <CollapseCard
          title={<span className={classes.collapseTitle}>Price</span>}
          BodyClass={"pt-1"}
          CardClass={"mt-1 px-1 py-1"}
          CustomMinusIcon={MinusIconBlue}
          CustomPlusIcon={PlusIconBlue}
          IconStyle={{
            width: "20px",
            height: "20px",
          }}
        >
          <Slider
            value={[RangeSlider[0] / 10000000, RangeSlider[1] / 10000000]}
            className="mb-3"
            onChange={(e) =>
              setRangeSlider([e.value[0] * 10000000, e.value[1] * 10000000])
            }
            range
          />
          <InputNumber
            value={RangeSlider[0]}
            onChange={(e) => setRangeSlider([e.value, RangeSlider[1]])}
            inputClassName={classes.PinderRange}
            placeholder="Min Price"
          />
          <InputNumber
            value={RangeSlider[1]}
            onChange={(e) => setRangeSlider([RangeSlider[0], e.value])}
            inputClassName={classes.PinderRange}
            placeholder="Max Price"
          />
        </CollapseCard>
        <p className="my-2 text-danger text-center" style={{fontSize:'14px'}}>{ErrorMessage}</p>
        <div className="d-flex justify-content-end mt-2 mr-3">
          <GradientButton
            txt={"Apply Filters"}
            onClick={onSubmitFilters}
            style={{
              height: "32px",
              width: "120px",
              fontFamily: "Inter",
              fontSize: "13px",
            }}
          />
        </div>
      </div>
    </>
  );
}
