import UserSidebar from "../../components/SideBar/UserSidebar";
import { HomeContainer, OuterContainer } from "../Home/Home.styles";
import Header from "../../components/header/Header";
import VendorsList from "../../components/Vendors-List/VendorsList";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function AllVendors() {
  let state = useSelector((state) => state?.Vendors);
  const [VendorsData, setVendorsData] = useState(null);
  const [SearchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (!VendorsData || VendorsData.length < 1) {
      const data = state.sort(function (a, b) {
        var textA = a.first_name.toUpperCase();
        var textB = b.first_name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      setVendorsData(data);
    }
  }, [state]);// eslint-disable-line react-hooks/exhaustive-deps

  const SpaceRemove = (val) => {
    const replaced = val.replaceAll(" ", "");
    return replaced;
  };


  useEffect(() => {
    if (state.length > 0) {
      let FilteredData = state.filter(
        (item) =>
          item?.full_name.toUpperCase().indexOf(SearchQuery.toUpperCase()) >
            -1 ||
          item?.email.toUpperCase().indexOf(SearchQuery.toUpperCase()) > -1 ||
          SpaceRemove(item?.mobile_no).indexOf(SpaceRemove(SearchQuery)) > -1
      );
      setVendorsData(FilteredData);
    }
  }, [SearchQuery]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <OuterContainer>
      <UserSidebar headerPath="Clients" />
      <HomeContainer>
        <Header headerPath="/Clients" />
        <VendorsList
          SearchQuery={SearchQuery}
          setSearchQuery={setSearchQuery}
          VendorsData={VendorsData}
        />
      </HomeContainer>
    </OuterContainer>
  );
}
