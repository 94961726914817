import { useNavigate, useSearchParams } from "react-router-dom";
import { CurrencyFormatter } from "../../utils";
import { Card, Circle, TimelineContainer } from "./IncomePlan.style";

const IncomePlanTimeline = ({ data }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let id = searchParams.get("id");
  let accountType = sessionStorage.getItem("accountType");

  const getDifference = (date) => {
    const yearDifference =
      new Date(date).getFullYear() - new Date().getFullYear();
    const monthDifference = new Date(date).getMonth() - new Date().getMonth();
    return yearDifference;
    //  + monthDifference;
  };

  function publish(eventName, data) {
    const event = new CustomEvent(eventName, { detail: data });
    document.dispatchEvent(event);
    navigate(`/Clients/Summary?id=${id}`);
  }

  return (
    <TimelineContainer idx={data.length}>
      <div className="timeline mb-2">
        {data.map((item, idx) => {
          return (
            <>
              <Circle idx={idx} length={data.length} />
              <Card idx={idx} length={data.length}>
                <div>
                  {item.nextDate
                    ? getDifference(item.nextDate) <= 1
                      ? getDifference(item.nextDate) + " Year"
                      : getDifference(item.nextDate) + " Years"
                    : ""}
                </div>

                <div
                  style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                  onClick={() => {
                    if (accountType != "Contacts") {
                      publish("PropertyActivate", idx);
                    }
                  }}
                >
                  <strong>Purchase property {idx + 1}</strong>
                </div>
                <div style={{ whiteSpace: "nowrap" }}>
                  <p>{item.nextDate}</p>
                  <p>
                    {CurrencyFormatter.format(item.summaryData.CashflowMonthly)}
                    (CFM)
                  </p>
                </div>
              </Card>
            </>
          );
        })}
      </div>
    </TimelineContainer>
  );
};

export default IncomePlanTimeline;
