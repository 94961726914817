import Header from "../../components/header/Header";
import PinderPage from "../../components/PinderPage";
import UserSidebar from "../../components/SideBar/UserSidebar";
import { HomeContainer, OuterContainer } from "../Home/Home.styles";

function Pinder() {
  return (
    <OuterContainer>
      <UserSidebar headerPath="Pinder" />
      <HomeContainer>
        <Header headerPath="/Pinder" />
        <PinderPage />
      </HomeContainer>
    </OuterContainer>
  );
}

export default Pinder;
