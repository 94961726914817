import { SeparateCamelCase } from "../../utils";
import classes from "./PinderPage.module.css";

export default function PinderSummary({ totals }) {
  const sum = totals?.map((v) => v.total) || [];
  return (
    <>
      <div className={classes.summaryBox}>
        <div
          className={`${classes.summaryTitle} d-flex flex-wrap  justify-content-between`}
        >
          <span>Total</span>
          <span>{sum.reduce((partialSum, a) => partialSum + a, 0) || 0}</span>
        </div>
        .
        {totals?.map((summaryItem, index) => {
          return (
            <div
              key={"summary" + index}
              className={`${classes.summaryItem} d-flex flex-wrap  justify-content-between`}
            >
              <span>{SeparateCamelCase(summaryItem?.name)}</span>
              <span>{summaryItem?.total}</span>
            </div>
          );
        })}
      </div>
    </>
  );
}
