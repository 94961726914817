const initialState = true;

const SidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_SIDEBAR":
      state = true;
      return state;
    case "CLOSE_SIDEBAR":
      state = false;
      return state;
    default:
      return state;
  }
};
export default SidebarReducer;
