import Navigation from "./routes/Navigation";
import "animate.css";
import { useEffect } from "react";
import { API } from "./services/api";
import { useDispatch, useSelector } from "react-redux";
import { getMedianPrice, Vendors } from "./redux/actions";
import { randomData } from "./randomPrices";
function App() {
  let dispatch = useDispatch();

  let state = useSelector((state: any) => state?.Vendors);

  const getVendors = async () => {
    let user_id = "610a0f1dc61edfcf62d62c52";
    let response = await API.get(`contacts?office_id=${user_id}`);
    dispatch(Vendors(response?.data || []));
  };

  const getSuburbStats = () => {
    const token = sessionStorage.getItem("Token");
    if (token && state.length < 1) {
      getVendors();

      (async () => {
        // try {
        const { data, offices, other, retail, warehouse } = await API.get(
          "pricefinder/suburb/statistics"
        );

        const medianPrices = {
          office: offices,
          other: other,
          retail: retail,
          industry: warehouse,
          residential: data,
        };
        dispatch(getMedianPrice(medianPrices));
        // } catch (err) {
        //   dispatch(getMedianPrice(randomData));
        // }
      })();
    }
  };

  useEffect(() => {
    getSuburbStats();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <Navigation />;
}

export default App;
