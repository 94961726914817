const initialState = {
  data: null,
};

const IncomePlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PROPERTY_IMAGES":
      return { ...state, data: action.payload };
    default:
      return state;
  }
};
export default IncomePlanReducer;
