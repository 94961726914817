import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import Home from "../pages/Home";
import SignIn from "../pages/SignIn";
import AllCalculator from "../pages/Calculator";
import ContactPage from "../pages/Contact";
import ContactList from "../pages/ContactList/ContactList";
import AllVendors from "../pages/AllVendors";
import Vendor from "../pages/Vendor";
import CreateContact from "../pages/CreateContact/CreateContact";
import Settings from "../pages/Settings";
import Themes from "../pages/Themes";
import MedianPrices from "../pages/MedianPrices";
import Slider from "../pages/Slider";
import Profile from "../pages/Profile/Profile";
import Pinder from "../pages/Pinder/Pinder";
import Signup from "../pages/Signup";
import { useSelector } from "react-redux";
import ResetPassword from "../pages/SignIn/ResetPassword";

const Auth = () => {
  let auth_token = sessionStorage.getItem("Token");
  return auth_token ? <Outlet /> : <Navigate to="/signin" />;
};

export default function Navigation() {
  const mystate = useSelector((state: any) => state.ThemePattern);
  let css = document.documentElement.style;
  if (mystate == "Propvesting") {
    css.setProperty(`--gredient-blue-1`, "#0565ea");
    css.setProperty(`--gredient-blue-2`, "#03c4fa");
    css.setProperty(`--main-blue`, "#0465eb");
    css.setProperty(`--heading-color`, "#059af4");
    css.setProperty(`--gradient-blue-3`, "#01C4FA");
    css.setProperty(`--gradient-blue-4`, "#01BAF8");
    css.setProperty(`--gradient-blue-5`, "#0269EB");
    css.setProperty(`--solid-colors`, "#0465eb");
    css.setProperty(`--link-active-color`, "#2196F3");
    css.setProperty(`--goldColor`, "#0582f0");
    css.setProperty(`--arrowColor`, "#000");
    css.setProperty(`--primary-blue`, "#a9c8ff");
    css.setProperty(`--slider-fillColor`, "#2196F3");
    css.setProperty(`--bg-pdf-color`, "#3d66b0");
  } else {
    css.setProperty(`--gredient-blue-1`, "#272660");
    css.setProperty(`--gredient-blue-2`, "#272660");
    css.setProperty(`--gradient-blue-3`, "#272660");
    css.setProperty(`--gradient-blue-4`, "#272660");
    css.setProperty(`--gradient-blue-5`, "#272660");
    css.setProperty(`--main-blue`, "#272660");
    css.setProperty(`--heading-color`, "#262660");
    css.setProperty(`--solid-colors`, "#272660");
    css.setProperty(`--link-active-color`, "#272660");
    css.setProperty(`--goldColor`, "#BC9C2F");
    css.setProperty(`--arrowColor`, "#BC9C2F");
    css.setProperty(`--primary-blue`, "#fee468");
    css.setProperty(`--slider-fillColor`, "#BC9C2F");
    css.setProperty(`--bg-pdf-color`, "#272660");
  }
  return (
    <Routes>
      <Route path={"/signin"} element={<SignIn />} />
      <Route path={"/reset-password"} element={<ResetPassword />} />

      <Route path={"/signup"} element={<Signup />} />
      <Route element={<Auth />}>
        <Route path="/" element={<Home />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/Calculator" element={<AllCalculator />} />
        <Route path="/Clients/:page" element={<Vendor />} />
        {sessionStorage.getItem("accountType") !== "Contacts" && (
          <>
            <Route path="/Pinder/:q" element={<Pinder />} />
            <Route path="/Contact" element={<ContactPage />} />
            <Route path="/Clients" element={<AllVendors />} />
            <Route path="/ContactList" element={<ContactList />} />
            <Route path="/CreateContact" element={<CreateContact />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/settings/assumptions" element={<Settings />} />
            <Route path="/settings/themes" element={<Themes />} />
            <Route path="/settings/slider" element={<Slider />} />
            <Route path="/settings/median-prices" element={<MedianPrices />} />
          </>
        )}
        <Route path="/*" element={<Home />} />
      </Route>
    </Routes>
  );
}
