import styled from "styled-components";
export const RiskProgressContainer = styled.div`
  margin-top: 20px;
  padding: 24px 34px 31px 32px;
  border-radius: 8px;
  box-shadow: 0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02),
    0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02),
    0 19px 122px 0 rgba(7, 62, 132, 0.04);
  background-color: var(--white);
  padding-bottom: 1.5rem;
  padding-top: 3.5rem;
  overflow-x: hidden;
  height: 328px;
  h1 {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-main-color);
    padding-bottom: 50px;
  }
  .Ecllipse-container {
    margin-top: 15px;
    transition: all 0.3s ease-in-out;
    z-index: 5;
  }
  .progrssBar-container {
    height: 3px;
    background-color: #ebf2fa;
    flex-grow: 0;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    z-index: 0;
    margin-top: -10px;
    .progress-bar {
      width: 0%;
      height: 3px;
      border-radius: 10px;
      background-color: yellow;
      z-index: 0;
      transition: all 0.3s ease-in-out;
    }
  }
  .progessBar-actions-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 20px;
    .progress-txt-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .line1 {
        @media (max-width: 2560px) {
          margin-left: 10%;
        }
        @media (max-width: 2200px) {
          margin-left: 4%;
        }
        @media (max-width: 1870px) {
          margin-left: 0%;
        }
        @media (max-width: 1600px) {
          margin-left: -5%;
        }
        @media (max-width: 1430px) {
          margin-left: -9%;
        }
      }
      .progress-Text {
        font-family: Inter;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #cccccc;
        position: relative;
        cursor: pointer;
      }
      .text1 {
      }

      .text2 {
        position: relative;
        left: 30%;
        @media (max-width: 2560px) {
          left: 50%;
        }
        @media (max-width: 2400px) {
          left: 46%;
        }
        @media (max-width: 2300px) {
          left: 42%;
        }
        @media (max-width: 2160px) {
          left: 37%;
        }
        @media (max-width: 2000px) {
          left: 33%;
        }
        @media (max-width: 1820px) {
          left: 24%;
        }

        @media (max-width: 1650px) {
          left: 21%;
        }
        @media (max-width: 1550px) {
          left: 17%;
        }
        @media (max-width: 1400px) {
          left: 13%;
        }
      }
      .text3 {
        position: relative;
        left: 65%;
        @media (max-width: 2560px) {
          left: 92%;
        }
        @media (max-width: 2450px) {
          left: 87%;
        }
        @media (max-width: 2360px) {
          left: 83%;
        }
        @media (max-width: 2270px) {
          left: 79%;
        }
        @media (max-width: 2200px) {
          left: 75%;
        }
        @media (max-width: 2100px) {
          left: 71%;
        }
        @media (max-width: 2000px) {
          left: 67%;
        }
        @media (max-width: 1900px) {
          left: 63%;
        }
        @media (max-width: 1800px) {
          left: 57%;
        }
        @media (max-width: 1700px) {
          left: 53%;
        }
        @media (max-width: 1650px) {
          left: 50%;
        }
        @media (max-width: 1600px) {
          left: 47%;
        }
        @media (max-width: 1500px) {
          left: 44%;
        }
        @media (max-width: 1430px) {
          left: 40%;
        }
        @media (max-width: 1330px) {
          left: 36%;
        }
        @media (max-width: 1260px) {
          left: 32%;
        }
      }
      .progress {
        width: 2px;
        height: 10px;
        background-color: var(--grey-smooky);
        margin-bottom: 14px;
      }
      .line2 {
        position: relative;
        left: 30%;
        @media (max-width: 2560px) {
          left: 50%;
        }
        @media (max-width: 2400px) {
          left: 46%;
        }
        @media (max-width: 2300px) {
          left: 42%;
        }
        @media (max-width: 2160px) {
          left: 37%;
        }
        @media (max-width: 2000px) {
          left: 33%;
        }
        @media (max-width: 1820px) {
          left: 24%;
        }

        @media (max-width: 1650px) {
          left: 21%;
        }
        @media (max-width: 1550px) {
          left: 17%;
        }
        @media (max-width: 1400px) {
          left: 13%;
        }
      }
      .line3 {
        position: relative;
        left: 65%;
        @media (max-width: 2560px) {
          left: 92%;
        }
        @media (max-width: 2450px) {
          left: 87%;
        }
        @media (max-width: 2360px) {
          left: 83%;
        }
        @media (max-width: 2270px) {
          left: 79%;
        }
        @media (max-width: 2200px) {
          left: 75%;
        }
        @media (max-width: 2100px) {
          left: 71%;
        }
        @media (max-width: 2000px) {
          left: 67%;
        }
        @media (max-width: 1900px) {
          left: 63%;
        }
        @media (max-width: 1800px) {
          left: 57%;
        }
        @media (max-width: 1700px) {
          left: 53%;
        }
        @media (max-width: 1650px) {
          left: 50%;
        }
        @media (max-width: 1600px) {
          left: 47%;
        }
        @media (max-width: 1500px) {
          left: 44%;
        }
        @media (max-width: 1430px) {
          left: 40%;
        }
        @media (max-width: 1330px) {
          left: 36%;
        }
        @media (max-width: 1260px) {
          left: 32%;
        }
      }
    }
  }
`;
export const RiskPropertyContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  transition: all 0.2s ease;
  z-index: 0;
  .row {
    width: 110% !important;
  }
`;
export const Disclaimer = styled.div`
  width: 98%;
  display: flex;
  justify-content: end;
  font-size: 14px;
  margin-bottom:20px
`;
