import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import PinderSummaryCSV from "./PinderSummaryCSV";
import {
  GetLisitngCsvData,
  GetLisitngCsvSummary,
} from "../../core/services/domain-csv";
import PinderFiltersForCSV from "./PinderFiltersForCSV";
import PinderTable from "./PinderTable";

export default function ImportCSV() {
  const [TableData, setTableData] = useState([]);
  const [FetchedData, setFetchedData] = useState([]);
  const [lsitingSummaryTotal, setLisitngSummary] = useState([]);

  const GetPinderDomainCsvSummary = async () => {
    try {
      let response = await GetLisitngCsvSummary();
      if (response) {
        const { status, data } = response;
        if (status) {
          setLisitngSummary(data?.list || 0);
        }
      }
    } catch (error) {
      setLisitngSummary(0);
    }
  };

  const GetPinderDomainCsvTableData = async (query) => {
    try {
      let response = await GetLisitngCsvData(query);
      if (response) {
        const { status, data } = response;
        if (status) {
          setFetchedData([...data]);
          setTableData([...data]);
        }
      }
    } catch (error) {
      setFetchedData([]);
      setTableData([]);
    }
  };

  useEffect(() => {
    GetPinderDomainCsvSummary();
    GetPinderDomainCsvTableData({ limit: 100, skip: 0 });
  }, []);

  const ApplyFilter = (value) => {
    GetPinderDomainCsvTableData(value);
  };
  // const headers = [
  //   "Days Active",
  //   "Address",
  //   "Link",
  //   "Price",
  //   "Floor Area",
  //   "Price/M2",
  //   "Land Area",
  //   "Income",
  //   "Yield",
  //   "Invest",
  //   "Tenure Type",
  //   "Type",
  //   "Number of Tenants",
  //   "Description",
  //   "Auction",
  // ];
  const headers=[
    {name:"Day Active",key:"days_active"},
    {name:"Address",key:"address"},
    {name:"Link",key:"link"},
    {name:"Price",key:"price"},
    {name:"Floor Area",key:"floor_area"},
    {name:"Price/M2",key:"price_m"},
    {name:"Land Area",key:"land_area"},
    {name:"Income",key:"est_income"},
    {name:"Yield",key:"yeild"},
    {name:"Invest",key:"invest"},
    {name:"Tenure Type",key:"tenure_type"},
    {name:"Type",key:"type"},
    {name:"Number of Tenants",key:"number_of_tenants"},
    {name:"Description",key:"description"},
    {name:"Auction",key:"auction"},
  ]

  

  return (
    <div>
      <Row className="mt-4">
        <Col sm={12} md={12} lg={3}>
          <PinderFiltersForCSV ApplyFilter={ApplyFilter} />
        </Col>
        <Col sm={12} md={12} lg={4}></Col>
        <Col sm={12} md={12} lg={5}>
          <PinderSummaryCSV totals={lsitingSummaryTotal} />
        </Col>
      </Row>
      {TableData.length > 0 ? (
        <PinderTable
          // ActiveColumnFilter={ActiveColumnFilter}
          // setActiveColumnFilter={setActiveColumnFilter}
          // selectedOptions={selectedOptions}
          // setSelectedOptions={setSelectedOptions}
          headers={headers}
          ApiType="CSV"
          tableData={TableData}
        />
      ) : (
        ""
      )}
    </div>
  );
}
