import styled from 'styled-components'

export const PaginationWrapper = styled.ul`
display: flex;
gap:10px;
`
export const Box=styled.li`
width: 32px;
  height: 32px;
  flex-grow: 0;
  object-fit: contain;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02), 0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02), 0 19px 122px 0 rgba(7, 62, 132, 0.04);
  background-color: var(--white);
  border: 1px solid;
  border-color: ${props=>props.active===true?"var(--main-blue)":"#dfe3e8"};
  color: ${props=>props.active===true?"var(--main-blue)":"#000"};
  cursor: pointer;
  a{
    text-decoration: none;
    font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  
  cursor: pointer;
  }

`