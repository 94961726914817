import React from 'react'

const BoxActive = ({color}) => {
  return (
    <div><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M29.334 9.333a6.667 6.667 0 0 0-6.667-6.667H9.334a6.667 6.667 0 0 0-6.667 6.667v13.333a6.667 6.667 0 0 0 6.667 6.667h13.333a6.667 6.667 0 0 0 6.667-6.666V9.332z" fill={color} stroke={color} stroke-width="1.5" stroke-linecap="round"/>
    <path d="m14.667 12 3.057 3.057c.52.52.52 1.365 0 1.886L14.667 20" stroke="#fff" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</div>
  )
}

export default BoxActive