
import Header from "../../components/header/Header";
import UserSidebar from "../../components/SideBar/UserSidebar";
import SliderSettings from "../../components/SliderSettings";
import { HomeContainer, OuterContainer } from "../Home/Home.styles";

export default function Slider() {
  return (
    <OuterContainer>
      <UserSidebar headerPath="Settings" />
      <HomeContainer>
        <Header headerPath="/Settings/Slider" />
        <SliderSettings />
      </HomeContainer>
    </OuterContainer>
  );
}
