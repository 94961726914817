import styled from "styled-components";

export const SearchbarContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  border: solid 1px var(--grey-smooky);
  padding-left: 10px;
  // width: 272px;
  overflow: hidden;
  height: 36px;
  .search-icon{
    color:#999;
  }
`;
export const SearchbarText = styled.input`
  width: 100%;
  border-radius: 0px;
  background-color: transparent;
  border: none !important;
  outline: none !important;
  padding: 10px 10px 10px 10px;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  color: #999;
  &:focus {
    outline: none !important;
    border: none !important;
  }
`;

export const SearchResultContainer = styled.div`
  position: absolute;
  top: 65px;
  border-radius: 8px;
  border: solid 1px var(--grey-smooky);
  padding-left: 10px;
  width: 272px;
  max-height: 300px;
  min-height: 150px;
  overflow: hidden auto;
  background: var(--white);
  z-index: 1000;
  box-shadow: 0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02),
    0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02),
    0 19px 122px 0 rgba(7, 62, 132, 0.04);
  a {
    color: black;
    text-decoration: none;
    font-size: 13px;
  }
  &::-webkit-scrollbar {
    height: auto !important;
    width: 7px !important;
  }

  &::-webkit-scrollbar-track {
    background: #f3f4f8 !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 30px !important;
    background-color: #666666 !important;
  }
`;
