import { CurrencyFormatter } from "../../utils";
import { TimelineContainer } from "./IncomePdf.style";

const PdfTimeline = ({ data, page }) => {
  const getDifference = (date) => {
    const yearDifference =
      new Date(date).getFullYear() - new Date().getFullYear();
    return yearDifference;
  };

  return (
    <TimelineContainer idx={4}>
      <div className="timeline">
        <div className="timeline">
          <div className="circle-1"></div>
          {data.length > 1 && <div className="circle-2"></div>}
          {data.length > 2 && <div className="circle-3"></div>}
          <div className="timeline-card card-1">
            <div>
              {data[0].nextDate
                ? getDifference(data[0].nextDate) <= 1
                  ? ` ${data[0].nextDate}( ${getDifference(
                      data[0].nextDate
                    )}  Year )`
                  : `${data[0].nextDate}( ${getDifference(
                      data[0].nextDate
                    )} Years )`
                : ""}
            </div>
            <div>
              <p>Purchase property {page * 4 - 4 + 0 + 1}</p>
              <p>
                {CurrencyFormatter.format(data[0].summaryData.CashflowMonthly)}(CFM)
              </p>
            </div>
          </div>
          {data.length > 1 && (
            <div className="timeline-card card-2">
              <div>
                {data[1].nextDate
                  ? getDifference(data[1].nextDate) <= 1
                    ? ` ${data[1].nextDate}( ${getDifference(
                        data[1].nextDate
                      )} Year )`
                    : ` ${data[1].nextDate}( ${getDifference(
                        data[1].nextDate
                      )}  Years )`
                  : ""}
              </div>
              <div>
                <p>Purchase property {page * 4 - 4 + 1 + 1}</p>
                <p>
                  {CurrencyFormatter.format(
                    data[1].summaryData.CashflowMonthly
                  )}(CFM)
                </p>
              </div>
            </div>
          )}
          {data.length > 2 && (
            <div className="timeline-card card-3">
              <div>
                {data[2].nextDate
                  ? getDifference(data[2].nextDate) <= 1
                    ? ` ${data[2].nextDate}( ${getDifference(
                        data[2].nextDate
                      )} Year )`
                    : ` ${data[2].nextDate}( ${getDifference(
                        data[2].nextDate
                      )}  Years )`
                  : ""}
              </div>
              <div>
                <p>Purchase property {page * 4 - 4 + 2 + 1}</p>
                <p>
                  {CurrencyFormatter.format(
                    data[2].summaryData.CashflowMonthly
                  )}(CFM)
                </p>
              </div>
            </div>
          )}
          {data.length > 3 && (
            <div className="timeline-card card-4">
              <div>
                {data[3].nextDate
                  ? getDifference(data[3].nextDate) <= 1
                    ? `${data[3].nextDate}( ${getDifference(
                        data[3].nextDate
                      )} Year )`
                    : ` ${data[3].nextDate}( ${getDifference(
                        data[3].nextDate
                      )}  Years )`
                  : ""}
              </div>
              <div>
                <p>Purchase property {page * 4 - 4 + 3 + 1}</p>
                <p>
                  {CurrencyFormatter.format(
                    data[2].summaryData.CashflowMonthly
                  )}(CFM)
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </TimelineContainer>
  );
};

export default PdfTimeline;
