import { useState } from "react";
import { commaSeparators } from "../../utils";
import { ContactContainer, ContactDropDown } from "../Contact/Contact.style";
import { InputNumbers } from "../Equity/Equity.style";
import InfoBox from "../InfoBox";

const ClientPreferences = ({
  propertytype,
  setPropertyType,
  incomeGoal = 0,
  setIncomeGoal,
}) => {
  const [Hover, setHover] = useState("hideInfoBox");
  const options = [
    { value: "Residential", label: "Residential Only (5%)" },
    { value: "Commercial - Retail", label: "Commercial Only (6%)" },
    { value: "Commercial - Office", label: "Commercial Only (7%)" },
    { value: "Blended", label: "Blended" },
  ];
  return (
    <ContactContainer margin="none">
      <div className="profile-container">
        <h1>Client Lifestyle Goals</h1>
      </div>

      <div
        className="py-3"
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "25px",
          flexWrap: "wrap",
        }}
      >
        <div>
          <p className="input-label mb-2">
            <span>Lifestyle Goal</span>
            <i
              onMouseOver={() => setHover("showInfoBox")}
              onMouseLeave={() => setHover("hideInfoBox")}
              className="ml-2 pi pi-info-circle "
            ></i>
          </p>
          <InfoBox
            className={`infobox ${Hover}`}
            onMouseLeave={() => setHover("hideInfoBox")}
            style={{ width: "320px", left: 310, position: "absolute" }}
            show={Hover}
          >
            <p>
              Your Lifestyle Goal represents the amount of money you want to
              earn from your portfolio to fund your Lifestyle
            </p>
          </InfoBox>
          <InputNumbers
            style={{ width: "250px" }}
            onFocus={(e) => (e.target.value = "")}
            onBlur={(e) => (e.target.value = commaSeparators(incomeGoal))}
            minfractiondigits={0}
            className="edit-input-field"
            mode="currency"
            currency="USD"
            locale="en-US"
            name="CashAvailable"
            value={incomeGoal}
            onChange={(e) => {
              setIncomeGoal(e.value);
            }}
          />
        </div>
        <div>
          <p className="input-label mb-2">Portfolio Makeup</p>
          <ContactDropDown
            style={{ alignSelf: "center" }}
            width="250px !important"
            value={propertytype}
            onChange={(e) => {
              setPropertyType(e.value)
              window.scrollBy(0,400)
            }}
            options={options}
          />
        </div>
      </div>
    </ContactContainer>
  );
};

export default ClientPreferences;
