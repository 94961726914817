import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import Calculator from "../../components/Calculator";

import { OuterContainer, HomeContainer } from "../Home/Home.styles";
import Header from "../../components/header/Header";
import UserSidebar from "../../components/SideBar/UserSidebar";
const AllCalculator = () => {
  return (
    <OuterContainer>
      <UserSidebar headerPath="Calculator" />
      <HomeContainer>
        <Header headerPath="/Calculator" />
        <Calculator />
      </HomeContainer>
    </OuterContainer>
  );
};

export default AllCalculator;
