import React, { useState, useEffect } from "react";
import {
  Disclaimer,
  RiskProgressContainer,
  RiskPropertyContainer,
} from "./RiskProfile.style";
import { GS } from "../../services/global";
import { API } from "../../services/api";
import { useSearchParams } from "react-router-dom";
import PropertyCard from "../PropertyCard/PropertyCardProfile";
import AI2 from "../../../src/assets/images/AgressiveImage2.png";
import Resi1 from "../../../src/assets/images/hardcodedcards/resi1.png";
import Resi2 from "../../../src/assets/images/hardcodedcards/resi2.png";
import Resi3 from "../../../src/assets/images/hardcodedcards/resi3.png";
import Retail1 from "../../../src/assets/images/hardcodedcards/retail1.png";
import Retail2 from "../../../src/assets/images/hardcodedcards/retail2.png";
import Retail3 from "../../../src/assets/images/hardcodedcards/retail3.png";
import Office1 from "../../../src/assets/images/hardcodedcards/office1.png";
import Office2 from "../../../src/assets/images/hardcodedcards/office2.png";
import Office3 from "../../../src/assets/images/hardcodedcards/office3.png";
import GradientButton from "../Button/GradientButton";
import { useNavigate } from "react-router-dom";
import SliderComponent from "../SliderComponent/SliderComponent";
import ClientPreferences from "./ClientPreferences";
import VideoCard from "./RiskProfilingInfo/VideoCard";
import { Col, Row } from "react-bootstrap";
const RiskProfile = ({
  setActiveIndex,
  vendorid,
  OnChange,
  IncomeGoal,
  setIncomeGoal,
}) => {
  let Navigate = useNavigate();
  const [progressColor, setProgessColor] = useState("var(--primary-green)");
  const [Property, setProperty] = useState("Conservative");
  const [propertyType, setPropertyType] = useState("Residential");

  const [searchParams] = useSearchParams();
  const [sliderVal, setSliderVal] = useState(17.5);
  const [initialState, setInitialState] = useState(true);
  const [RiskVal, setRiskVal] = useState(GS.SOT.Equity.NetYieldonProperty);
  let id = searchParams.get("id");

  function setNetYieldonProperty(val, property) {
    GS.SOT.PropertyGenerators.forEach((n, idx) => {
      if (val) {
        GS.SOT.PropertyGenerators[idx].targetYieldReturn = val;
      }
      if (property) {
        GS.SOT.PropertyGenerators[idx].initialPropertyType = property;
      }
    });
  }

  const handleClick = (v) => {
    if (v === "Conservative") {
      setSliderVal(17.5);
      setRiskVal(5);
      setProperty("Conservative");
      setProgessColor("#00a779");

      if (propertyType !== "Blended") {
        setNetYieldonProperty(5, "Residential");
        setPropertyType("Residential");
        GS.SOT.RiskProfile.initialPropertyType = "Residential";
      } else {
        setNetYieldonProperty(5, "Blended");
        GS.SOT.RiskProfile.initialPropertyType = "Blended";
      }

      GS.SOT.RiskProfile.NetYieldonProperty = `${5}%`;
    } else if (v === "Moderate") {
      setSliderVal(52.5);
      setRiskVal(6);
      setProperty("Moderate");
      setProgessColor("#fee468");

      if (propertyType !== "Blended") {
        setNetYieldonProperty(6, "Commercial - Retail");
        setPropertyType("Commercial - Retail");
        GS.SOT.RiskProfile.initialPropertyType = "Commercial - Retail";
      } else {
        setNetYieldonProperty(6, "Blended");
        GS.SOT.RiskProfile.initialPropertyType = "Blended";
      }

      GS.SOT.RiskProfile.NetYieldonProperty = `${6}%`;
    } else if (v === "Aggressive") {
      setSliderVal(87.5);
      setRiskVal(7);
      setProperty("Aggressive");
      setProgessColor("#cd4a5e");

      if (propertyType !== "Blended") {
        setNetYieldonProperty(7, "Commercial - Office");
        setPropertyType("Commercial - Office");
        GS.SOT.RiskProfile.initialPropertyType = "Commercial - Office";
      } else {
        setNetYieldonProperty(7, "Blended");
        GS.SOT.RiskProfile.initialPropertyType = "Blended";
      }
      GS.SOT.RiskProfile.NetYieldonProperty = `${7}%`;
    }

    API.AutoSave(window.location.href);
  };
  const handleIncomeGoal = (value) => {
    GS.SOT.BorrowingCalculator.income_goal = parseFloat(value);
    setIncomeGoal({ ...IncomeGoal, value: value });
    API.AutoSave(window.location.href);
  };

  const handlePropertyType = (value) => {
    if (value === "Residential") {
      setSliderVal(17.5);
      setRiskVal(5);
      setProperty("Conservative");
      setProgessColor("#00a779");
      GS.SOT.RiskProfile.NetYieldonProperty = `${5}%`;
      setNetYieldonProperty(5, value);
    } else if (value === "Commercial - Office") {
      setSliderVal(87.5);
      setRiskVal(7);
      setProperty("Aggressive");
      setNetYieldonProperty(7, value);
      GS.SOT.RiskProfile.NetYieldonProperty = `${7}%`;
      setProgessColor("#cd4a5e");
    } else if (value === "Commercial - Retail") {
      setSliderVal(52.5);
      setRiskVal(6);
      setProperty("Moderate");
      GS.SOT.RiskProfile.NetYieldonProperty = `${6}%`;
      setNetYieldonProperty(6, value);
      setProgessColor("#fee468");
    } else {
      setNetYieldonProperty(null, value);
      if (value === "Blended") {
      } else {
        setProgessColor("#cd4a5e");
      }
    }
    GS.SOT.RiskProfile.initialPropertyType = value;
    setPropertyType(value);
    API.AutoSave(window.location.href);
  };
  const updateData = async () => {
    await API.AutoSave(window.location.href);
  };

  useEffect(() => {
    const fetchData = async () => {
      let d = await API.get(`/vendor-profile/get/${id}`);
      const { data } = d;
      if (data) {
        if (data.VendorId === undefined) {
          initalBarColor("5%", "Residential");
        } else {
          initalBarColor(
            data.RiskProfile.NetYieldonProperty,
            data.RiskProfile.initialPropertyType
          );
        }
      }
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (initialState) {
      setInitialState(false);
    } else {
      updateData();
    }
  }, [sliderVal]); // eslint-disable-line react-hooks/exhaustive-deps

  const initalBarColor = (value, propertyType) => {
    const isWholeNumber = !value.includes(".");
    const valPercentage = value.substring(0, 1);
    const propertVal =
      valPercentage === "5" ? 17.5 : valPercentage === "6" ? 52.5 : 87.5;
    const sliderValue = isWholeNumber
      ? propertVal
      : value.split(".")[1].substring(0, 1) * 3.5 + propertVal;
    setRiskVal(value.slice(0, -1));
    setProperty(
      valPercentage < 6
        ? "Conservative"
        : valPercentage < 7
        ? "Moderate"
        : "Aggressive"
    );
    if (value.slice(0, -1) === 5 && isWholeNumber) {
      setPropertyType("Residential");
    } else {
      setPropertyType(propertyType);
    }
    setProgessColor(
      valPercentage < 6 ? "#00a779" : valPercentage < 7 ? "#fee468" : "#cd4a5e"
    );
    setSliderVal(sliderValue);
  };

  const updateContact = async (data, id) => {
    GS.SOT.Progress = { steps: 4, completed: 0, current: 1 };
    await API.AutoSave(window.location.href);
    return await API.put(`contacts/${id}`, data);
  };
  return (
    <>
      <ClientPreferences
        incomeGoal={IncomeGoal.value}
        propertytype={propertyType}
        setPropertyType={(val) => handlePropertyType(val)}
        setIncomeGoal={(val) => handleIncomeGoal(val)}
      />
      <Row>
        <Col md={9} lg={9} xl={9} xxl={9}>
          <RiskProgressContainer>
            <h1>Investor Risk Profile</h1>
            <SliderComponent
              value={sliderVal}
              setSliderVal={setSliderVal}
              min={0}
              max={100}
              setPropertyType={(val) => handlePropertyType(val)}
              step={3.5}
              RiskVal={RiskVal}
              setRiskVal={setRiskVal}
              Property={Property}
              propertyType={propertyType}
              setProperty={setProperty}
              progressColor={progressColor}
              setProgessColor={setProgessColor}
              OnChange={() => OnChange()}
            />
            <div className="progessBar-actions-container">
              <div className="progress-txt-container">
                <div
                  className="progress line1"
                  style={{
                    backgroundColor:
                      Property === "Conservative" ? "#00a779" : "#e0e0e0",
                  }}
                ></div>
                <p
                  className="progress-Text text1"
                  style={{
                    color: Property === "Conservative" ? "#00a779" : "#e0e0e0",
                  }}
                  onClick={() => handleClick("Conservative")}
                >
                  Conservative
                </p>
              </div>
              <div className="progress-txt-container">
                <div
                  className="progress line2"
                  style={{
                    backgroundColor:
                      Property === "Moderate" ? "#fee468" : "#e0e0e0",
                  }}
                ></div>
                <p
                  className="progress-Text text2"
                  style={{
                    color: Property === "Moderate" ? "#fee468" : "#e0e0e0",
                  }}
                  onClick={() => handleClick("Moderate")}
                >
                  Moderate
                </p>
              </div>
              <div className="progress-txt-container">
                <div
                  className="progress line3"
                  style={{
                    backgroundColor:
                      Property === "Aggressive" ? "#cd4a5e" : "#e0e0e0",
                  }}
                ></div>
                <p
                  className="progress-Text text3"
                  style={{
                    color: Property === "Aggressive" ? "#cd4a5e" : "#e0e0e0",
                  }}
                  onClick={() => handleClick("Aggressive")}
                >
                  Aggressive
                </p>
              </div>
            </div>
          </RiskProgressContainer>
        </Col>

        <Col md={3} lg={3} xl={3} xxl={3}>
          <VideoCard riskVal={RiskVal} property={Property} video={AI2} />
        </Col>
      </Row>
      <RiskPropertyContainer>
        <Row>
          {Property === "Conservative" && (
            <>
              <Col
                md={6}
                lg={6}
                xl={6}
                xxl={4}
                style={{ marginBottom: "20px" }}
              >
                <PropertyCard
                  img={Resi1}
                  title="Central Childcare Centre with Long WALE"
                  propertyType="Residential"
                  progressColor={progressColor}
                  PurchasePrice="$375,000.00"
                  Deposit="$98,630.00"
                  GrossYield="5408.00%"
                  LVR="80.00%"
                  InterestRate="5.50%"
                  RiskProfile="Conservative"
                  GrossRentalIncome="$20,280.00"
                  NetCashflow="$13,672.50"
                  SaleRange="49,321"
                  IndustrialSale="1086"
                  OfficeSale="1086"
                  number="1"
                />
              </Col>
              <Col
                md={6}
                lg={6}
                xl={6}
                xxl={4}
                style={{ marginBottom: "20px" }}
              >
                <PropertyCard
                  img={Resi2}
                  title="Medical Centre near Hospital"
                  propertyType="Residential"
                  progressColor={progressColor}
                  PurchasePrice="519,000.30"
                  Deposit="$136,497.00"
                  GrossYield="5.41%"
                  LVR="80.00%"
                  InterestRate="5.50%"
                  RiskProfile="Conservative"
                  GrossRentalIncome="$28,080.00"
                  NetCashflow="-$3,882.00"
                  SaleRange="49,321"
                  number="2"
                />
              </Col>
              <Col
                md={6}
                lg={6}
                xl={6}
                xxl={4}
                style={{ marginBottom: "20px" }}
              >
                <PropertyCard
                  img={Resi3}
                  title="Medical Centre near Hospital"
                  propertyType="Residential"
                  progressColor={progressColor}
                  PurchasePrice="$780,000.00"
                  Deposit="$205,140.00"
                  GrossYield="6.33%"
                  LVR="80.00%"
                  InterestRate="5.50%"
                  RiskProfile="Conservative"
                  GrossRentalIncome="$49,400.00"
                  NetCashflow="-$975.00"
                  SaleRange="49,321"
                  number="3"
                />
              </Col>
            </>
          )}
          {Property === "Moderate" && (
            <>
              <Col
                md={6}
                lg={6}
                xl={6}
                xxl={4}
                style={{ marginBottom: "20px" }}
              >
                <PropertyCard
                  img={Retail1}
                  title="Industrial with Strong Tenant"
                  propertyType="Retail"
                  progressColor={progressColor}
                  PurchasePrice="$600,000.00"
                  Deposit="$217,800.00"
                  GrossYield="7.09%"
                  LVR="70.00%"
                  InterestRate="5.50%"
                  RiskProfile="Moderate"
                  GrossRentalIncome="$42,530.00"
                  NetCashflow="$19,430.00"
                  SaleRange="1,086"
                  number="1"
                />
              </Col>
              <Col
                md={6}
                lg={6}
                xl={6}
                xxl={4}
                style={{ marginBottom: "20px" }}
              >
                <PropertyCard
                  img={Retail2}
                  title="Rental with Superlative Returns"
                  propertyType="Retail"
                  progressColor={progressColor}
                  PurchasePrice="$1,100,000.00"
                  Deposit="$399,300.00"
                  GrossYield="6.98%"
                  LVR="70.00%"
                  InterestRate="5.50%"
                  RiskProfile="Moderate"
                  GrossRentalIncome="$76,800.00"
                  NetCashflow="$34,450.00"
                  SaleRange="1,086"
                  number="2"
                />
              </Col>
              <Col
                md={6}
                lg={6}
                xl={6}
                xxl={4}
                style={{ marginBottom: "20px" }}
              >
                <PropertyCard
                  img={Retail3}
                  title="Rental with Superlative Returns"
                  propertyType="Retail"
                  progressColor={progressColor}
                  PurchasePrice="$2,300,000.00"
                  Deposit="$834,900.00"
                  GrossYield="6.09%"
                  LVR="70.00%"
                  InterestRate="5.50%"
                  RiskProfile="Moderate"
                  GrossRentalIncome="$140,000.00"
                  NetCashflow="$51,450.00"
                  SaleRange="1,086"
                  number="3"
                />
              </Col>
            </>
          )}
          {Property === "Aggressive" && (
            <>
              <Col
                md={6}
                lg={6}
                xl={6}
                xxl={4}
                style={{ marginBottom: "20px" }}
              >
                <PropertyCard
                  img={Office1}
                  title="Regional Retail Shop with High Returns"
                  propertyType="Office"
                  progressColor={progressColor}
                  PurchasePrice="$750,000.00"
                  Deposit="$272,250.00"
                  GrossYield="7.00%"
                  LVR="70.00%"
                  InterestRate="5.50%"
                  RiskProfile="Aggressive"
                  GrossRentalIncome="$52,500.00"
                  NetCashflow="$23,625.00"
                  SaleRange="1,086"
                  number="1"
                />
              </Col>
              <Col
                md={6}
                lg={6}
                xl={6}
                xxl={4}
                style={{ marginBottom: "20px" }}
              >
                <PropertyCard
                  img={Office2}
                  title="8.5% yield return with 2 x year lease"
                  propertyType="Office"
                  progressColor={progressColor}
                  PurchasePrice="$1,010,000.00"
                  Deposit="$366,630.00"
                  GrossYield="7.87%"
                  LVR="70.00%"
                  InterestRate="5.50%"
                  RiskProfile="Aggressive"
                  GrossRentalIncome="$79,511.00"
                  NetCashflow="$40,626.00"
                  SaleRange="1,086"
                  number="2"
                />
              </Col>
              <Col
                md={6}
                lg={6}
                xl={6}
                xxl={4}
                style={{ marginBottom: "20px" }}
              >
                <PropertyCard
                  img={Office3}
                  title="8.5% yield return with 2 x year lease"
                  propertyType="Office"
                  progressColor={progressColor}
                  PurchasePrice="$1,500,000.00"
                  Deposit="$544,500.00"
                  GrossYield="7.50%"
                  LVR="70.00%"
                  InterestRate="5.50%"
                  RiskProfile="Aggressive"
                  GrossRentalIncome="$112,500.00"
                  NetCashflow="$54,750.00"
                  SaleRange="1,086"
                  number="3"
                />
              </Col>
            </>
          )}
        </Row>
      </RiskPropertyContainer>
      <Disclaimer>
        <span>Example Properties</span>
      </Disclaimer>

      <div className="button-container">
        <GradientButton
          style={{
            position: "fixed",
            bottom: "10px",
            right: "37px",
          }}
          onClick={() => {
            updateContact();
            setActiveIndex(1);
            Navigate(`/Clients/Finances?id=${vendorid}`);
          }}
        />
      </div>
    </>
  );
};

export default RiskProfile;
