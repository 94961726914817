import classes from "./YieldBox.module.css";
import { useEffect, useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import GradientButton from "../Button/GradientButton";
import { CurrencyFormatter } from "../../utils";

export default function EstIncome({
  setClickedColumn,
  ClickedColumn,
  columnId,
  value,
  setEditValue,
  stringToFirstNumber
}) {
  const [ShowEstIncomeBox, setShowEstIncomeBox] = useState(false);
  const [InputValue, setInputValue] = useState(null);
  const [EstIncome, setEstIncome] = useState(null);

console.log(ClickedColumn,"ClickedColumn")

  useEffect(() => {
    if (ClickedColumn !== columnId) {
      setShowEstIncomeBox(false);
    }
  }, [ClickedColumn]);
  const handleToggle = () =>{
    setShowEstIncomeBox(!ShowEstIncomeBox)
  }
  return (
    <span className={classes.ColumnYieldBoxContainer}>
      <span onClick={handleToggle} className={`${classes.ToggleYieldBoxButton}`}>
        {EstIncome ? CurrencyFormatter.format( EstIncome ) : value? value:"Set Estimated Income"}
      </span>
      {ShowEstIncomeBox && (
        <div className={classes.YieldBox}>
          <span className={`border-0 ${classes.columnTitle}`}>
            Set Estimated Income 
            <i
              onClick={() => setShowEstIncomeBox(false)}
              className={`pi pi-times`}
            ></i>
          </span>
          <div className={"d-flex justify-center align-center gap-2"}>
            <InputNumber
              onFocus={(e) => (e.target.value = "")}
              onBlur={(e) => (e.target.value = e.target.value)}
              value={InputValue || 0}
              onChange={(e) => {
                setInputValue(e.value);
              }}
              mode="decimal"
            //   minFractionDigits={1}
            //   maxFractionDigits={2}
            //   suffix="%"
              className="border-0"
              inputClassName={classes.InputValue}
            />
            <GradientButton
              txt={"Set"}
              onClick={() => {
                setEditValue({id:columnId,columnName:'income',value:InputValue})
                setClickedColumn("");
                setShowEstIncomeBox(false);
                setEstIncome(InputValue);
              }}
              style={{
                height: "32px",
                width: "80px",
                fontFamily: "Inter",
                fontSize: "13px",
              }}
            />
          </div>
        </div>
      )}
    </span>
  );
}
