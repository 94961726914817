import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { OuterContainer, HomeContainer } from "../Home/Home.styles";
import Header from "../../components/header/Header";
import UserSidebar from "../../components/SideBar/UserSidebar";
import Contact from "../../components/Contact/Contact";
const ContactPage = () => {
  return (
    <OuterContainer>
      <UserSidebar headerPath="Contacts" />
      <HomeContainer>
        <Header headerPath="/Contact" />
        <Contact />
      </HomeContainer>
    </OuterContainer>
  );
};

export default ContactPage;
