export const Images = {
  Profile: require("./people.png"),
  "Profile-Active": require("./people-active.png"),

  // "Equity Calculator": require("./house.png"),
  // "Equity Calculator-Active": require("./house-active.png"),

  "Risk Profile": require("./secure.webp"),
  "Risk Profile-Active": require("./secure-active.png"),

  "Income Goal": require("./profit.png"),
  "Income Goal-Active": require("./profitActive.png"),

  Finances: require("./people.png"),
  "Finances-Active": require("./people-active.png"),

  Vendor: require("./people.png"),
  "Vendor-Active": require("./people-active.png"),

  "Portfolio Plan": require("./profit.png"),
  "Portfolio Plan-Active": require("./profitActive.png"),

  "Risk Profiling": require("./secure.webp"),
  "Risk Profiling-Active": require("./secure-active.png"),

  // "Assets/Equity": require("./house.png"),
  // "Assets/Equity-Active": require("./house-active.png"),

  Spending: require("./iconHome.png"),
  "Spending-Active": require("./homeActive.png"),

  Summary: require("./houses.png"),
  "Summary-Active": require("./housesActive.png"),

  "Portfolio Plan Detail": require("./Rectangle-125.png"),
};
