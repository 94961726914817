import React,{useState,useEffect} from "react";
import { DashboardContainer,
   BoxContainer,
   OuterContainer,
   LeftContainer,
   RightContainer,
   TaskCard,
   BlueCard1,
   BlueCard2,
   CardsContainer,
   SmallCard, 
   DonotContainer,PropertyPortfolio,PropertyType,PortfolioSection,PortfolioCard} from "./UserDashboard.style";
   import pic1 from "../../assets/images/Vector1.png"
   import pic2 from "../../assets/images/Vector2.png"
   import pic3 from "../../assets/images/Vector3.png"
   import pic4 from "../../assets/images/Vector4.png"
   import pic5 from "../../assets/images/Vector5.png"
   import pic6 from "../../assets/images/Vector6.png"
   import thunder from "../../assets/images/thunder.png"
   import ArrowLeft from "../../assets/images/arrow-left.png"
   import PropertyPic from "../../assets/images/property-img.png"
   import PropertyPic2 from "../../assets/images/property-img2.png"
   import PropertyPic3 from "../../assets/images/property-img3.png"
   import PropertyPic4 from "../../assets/images/property-img4.png"
   import BoxActive from "../SvgComponents/BoxActive";
   import BoxInactive from "../SvgComponents/BoxInactive";
   import { CurrencyFormatter } from "../../utils";
  import { useSelector } from "react-redux";
   import Chart from "../Charts/Chart";
import { GS } from "../../services/global";
const UserDashboard = () => {
  const [CheckAdmin, setCheckAdmin] = useState(false);
  const mystate=useSelector((state)=>state.UserProfile)
  console.log(GS.SOT)
let [tableData, setTableData] = useState(
  GS.SOT.PortfolioSummary.tableData ? GS.SOT.PortfolioSummary.tableData : []
);
const[TaskLeft,setTaskLeft]=useState(GS.SOT.Progress.steps-GS.SOT.Progress.completed)
  const [IncomeGoal, setIncomeGoal] = useState({ value: parseFloat(GS?.SOT?.BorrowingCalculator?.income_goal), year: parseInt(GS?.SOT?.BorrowingCalculator?.FinancialYear) });
  const data = {
    labels: ['Product 1', 'Product 2', 'Product 3', 'Product 4'],
    datasets: [{
      label: 'Weekly Sales',
      data: [15, 15, 15, 9],
      backgroundColor: [
        '#00a779',
        '#fee468',
        '#c1e5e3',
        '#1c816d',
      ],
      
      borderWidth: 1
    }]
  };
  const data2 = {
    labels: ['Product 1', 'Product 2', 'Product 3'],
    datasets: [{
      label: 'Weekly Sales',
      data: [15, 15, 40],
      backgroundColor: [
        '#1c816d',
        '#00a76d',
        '#c3de95',
      
      ],
      
      borderWidth: 1
    }]
  };
  const data3 = {
    labels: ['Product 1', 'Product 2'],
    datasets: [{
      label: 'Weekly Sales',
      data: [20, 55],
      backgroundColor: [
        '#bae7e5',
        '#e5f5f4',
       
      ],
      
      borderWidth: 1
    }]
  };
  // config 
  const options = {
    
    
      plugins:{
        legend:{
          display:true,
          position:"right",
          padding: 50,
        },
       
        datalabels:{
          color:"white",
          backgroundColor:"black",
          formatter:(value,context)=>{
            console.log(value)
            console.log(context)
            return `${value}%`
          }
        },
        
      },
      
   
    // plugins:[ChartDataLabels]
  };
  const calculateCashAndEquityAvailable = () => {
    let sum = GS?.SOT?.StrategicSpending?.cashOnHand;
    sum += isNaN(GS?.SOT?.StrategicSpending?.Data[0]?.balanceSheet?.EquityGain)
      ? 0
      : GS?.SOT?.StrategicSpending?.Data[0]?.balanceSheet?.EquityGain;
    let v = 0;
    if (GS?.SOT?.Equity?.EquityGains?.length > 0) {
      GS.SOT.Equity.EquityGains.map((item, index) => {
        let v1 = item.value / item.noOfMonths;
        v = v + v1;
      });
    }
    return sum + v;
  };
  const [portfolioValue,setPortfolioValue]=useState(0)

  useEffect(() => {
    let accountType = sessionStorage.getItem("accountType");
    if (accountType === "Contacts") {
      setCheckAdmin(false);
      if(tableData.length>0){
        setPortfolioValue(tableData[8].slice(-1)[0])
      }
    } else {
      setCheckAdmin(true);
    }
  }, []);
  return (
    <>
    {/* <DashboardContainer>
      <h1> </h1>
      <div>
        <BoxContainer>
          <p>
            <div></div> Coming Soon
          </p>
          <h1>
            We Are <br /> Launching Soon
          </h1>
        </BoxContainer>
      </div>
    </DashboardContainer> */}
    {CheckAdmin==true && <DashboardContainer>
      <h1> </h1>
      <div>
        <BoxContainer>
          <p>
            <div></div> Coming Soon
          </p>
          <h1>
            We Are <br /> Launching Soon
          </h1>
        </BoxContainer>
      </div>
    </DashboardContainer>}
    {CheckAdmin==false && <>
    <OuterContainer>
      <LeftContainer>
<TaskCard style={{color:"black "}}>
Hi {mystate.first_name}! <br/>
You have <span>{TaskLeft} task</span> to complete.
</TaskCard>
<CardsContainer>
  <div className="card-row" style={{marginBottom:"20px"}}>
  <SmallCard>
    <div className="txtContainer">
      <span className="span1" style={{color:"black "}}>Lifestyle Goal</span>
      <span className="span2">{IncomeGoal
                        ? CurrencyFormatter.format(IncomeGoal?.value)
                        : "$0.00"}</span>
    </div>
    <div className="imgContainer">
      <img src={pic1}/>
    </div>
  </SmallCard>
  <SmallCard>
    <div className="txtContainer">
      <span className="span1" style={{color:"black "}}>In Which Financial Year</span>
      <span className="span2">{IncomeGoal.year}</span>
    </div>
    <div className="imgContainer">
      <img src={pic2}/>
    </div>
  </SmallCard>
  <SmallCard>
    <div className="txtContainer">
      <span className="span1" style={{color:"black "}}>Total Portfolio Value</span>
      <span className="span2"> 
      {tableData &&
                  CurrencyFormatter.format(portfolioValue)}
          
           </span>
    </div>
    <div className="imgContainer">
      <img src={pic3}/>
    </div>
  </SmallCard>
  </div>
  <div className="card-row">
  <SmallCard>
    <div className="txtContainer">
      <span className="span1" style={{color:"black "}}>Average Yield</span>
      <span className="span2"> {isNaN((
                  GS?.SOT?.PropertyGenerators?.reduce(
                    (total, NextProperty) =>
                      (total +=
                        NextProperty?.tableData?.NetYieldonPropertyYear1),
                    0
                  ) / GS?.SOT?.PropertyGenerators.length
                ))?"0":(
                  GS?.SOT?.PropertyGenerators?.reduce(
                    (total, NextProperty) =>
                      (total +=
                        NextProperty?.tableData?.NetYieldonPropertyYear1),
                    0
                  ) / GS?.SOT?.PropertyGenerators.length
                ).toFixed(2)}%</span>
    </div>
    <div className="imgContainer">
      <img src={pic4}/>
    </div>
  </SmallCard>
  <SmallCard>
    <div className="txtContainer">
      <span className="span1" style={{color:"black "}}>Cash + Equity Available</span>
      <span className="span2">{CurrencyFormatter.format(
                  // GS.SOT.StrategicSpending.cashOnHand +
                  //   GS?.SOT?.PropertyGenerators[0]?.equityAvailable
                  calculateCashAndEquityAvailable() || 0
                )}</span>
    </div>
    <div className="imgContainer">
      <img src={pic5}/>
    </div>
  </SmallCard>
  <SmallCard>
    <div className="txtContainer">
      <span className="span1" style={{color:"black "}}>Year 1 Cashflow (Monthly)</span>
      <span className="span2">{isNaN(CurrencyFormatter.format(
                  GS?.SOT?.PropertyGenerators[0]?.summaryData.CashflowMonthly
                ))?"$0.00":(CurrencyFormatter.format(
                  GS?.SOT?.PropertyGenerators[0]?.summaryData.CashflowMonthly
                ))}</span>
    </div>
    <div className="imgContainer">
      <img src={pic6}/>
    </div>
  </SmallCard>
  </div>
</CardsContainer>
<PropertyPortfolio>
  <div className="portfolio-header">
<span >My Property Portfolio</span>
<a>View All <img src={ArrowLeft}/></a>
  </div>
  <div className="propertyList">
    <table>
      
      <tbody>
        <tr>
          <td className="property-list-img"><img src={PropertyPic}/><span>3 Commercial Road,
Burleigh Heads, QLD 4220</span></td>
<PropertyType color="#00a779" bgcolor="#ebfff9">Primary</PropertyType>
<td className="value">Valued at
<br/><span>$1,340,000.00</span></td>
<td className="property-icon"> <BoxActive color="#272660"/></td>
        </tr>
        <tr>
          <td className="property-list-img"><img src={PropertyPic2}/><span>Office unit within office precinct,
Burleigh Heads, QLD 4220</span></td>
<PropertyType color="#b7b6e2" bgcolor="#272660">Investment</PropertyType>
<td className="value">Valued at
<br/><span>$1,340,000.00</span></td>
<td className="property-icon"> <BoxInactive color="#272660"/></td>
        </tr>
        <tr>
          <td className="property-list-img"><img src={PropertyPic3}/><span>CBD Retail Free holding
Regional CBD</span></td>
<PropertyType color="#ebb6be" bgcolor="#cd4a5e">Investment</PropertyType>
<td className="value">Valued at
<br/><span>$1,340,000.00</span></td>
<td className="property-icon"> <BoxInactive color="#272660"/></td>
        </tr>
        <tr>
          <td className="property-list-img"><img src={PropertyPic4}/><span>Strata-Industrial Shed with existing lease</span></td>
<PropertyType color="#03c4fa" bgcolor="#0565ea">Primary</PropertyType>
<td className="value">Valued at
<br/><span>$1,340,000.00</span></td>
<td className="property-icon"> <BoxInactive color="#272660"/></td>
        </tr>
      </tbody>
    </table>
  </div>
</PropertyPortfolio>
      </LeftContainer>
      <RightContainer>
      <BlueCard1>
        <div className="txt-container">
          <span className="goal">Income Goal</span>
          <span>{IncomeGoal
                        ? CurrencyFormatter.format(IncomeGoal?.value)
                        : "$0.00"}</span>
        </div>
        <div className="animation-container">
        <img
                  id="1"
                  loop="infinite"
                  src={ "https://i.ibb.co/nPzKW1j/goal-Anime-Animation.gif" }
                  alt="Goal Anime Animation"
                  playing={true}
                />
        </div>
      </BlueCard1>
      <BlueCard2>
      <span>
      Lifestyle Goal
      </span>
   <DonotContainer>
   <div
               
                  className="donutgraph d-flex flex-column justify-content-center align-items-center"
                >
                  <div className="semidonut margin">
                    <div>
                      <img src={thunder}/>
                    </div>
                    <span>
                    {IncomeGoal
                        ? CurrencyFormatter.format(IncomeGoal?.value)
                        : "$0.00"}
                    </span>
                  </div>
                
                  <div className="indicators">
                    <span>0%</span>
                    <span>100%</span>
                  </div>
                  <div className="year">
                    <span className="year-desc">Year {IncomeGoal.year}</span>
                  </div>
                 
                </div>
   </DonotContainer>
      </BlueCard2>
      </RightContainer>
    </OuterContainer>
    {/* <PortfolioSection>
<PortfolioCard>
  <div className="chart-header-section">
    <div className="chart-names">
      <span className="secondarytxt">SUMMARY</span>
      <span className="bold-txt">5.987,34</span>
      <span className="secondarytxt">Current Portfolio Allocation</span>
    </div>
    <div className="chart-info">i</div>
  </div>
  <div className="piechart-section">
  <Chart 
  data={data}
  options={options}
  />
  </div>
</PortfolioCard>
<PortfolioCard>
<div className="chart-header-section">
    <div className="chart-names">
      <span className="secondarytxt">INCOME PLAN</span>
      <span className="bold-txt">5.987,34</span>
      <span className="secondarytxt">Current Portfolio Allocation</span>
    </div>
    <div className="chart-info">i</div>
  </div>
  <div className="piechart-section">
  <Chart 
  data={data2}
  options={options}
  />
  </div>
</PortfolioCard>
<PortfolioCard>
<div className="chart-header-section">
    <div className="chart-names">
      <span className="secondarytxt">FINANCE</span>
      <span className="bold-txt">5.987,34</span>
      <span className="secondarytxt">Current Portfolio Allocation</span>
    </div>
    <div className="chart-info">i</div>
  </div>
  <div className="piechart-section">
  <Chart 
  data={data3}
  options={options}
  />
   </div>
</PortfolioCard>
    </PortfolioSection> */}
    </>}
    </>
  );
};

export default UserDashboard;
