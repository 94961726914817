import classes from "./ColumnFilter.module.css";
import { useEffect, useState } from "react";
import { MultiSelect } from "primereact/multiselect";

export default function ColumnFilter({
  data,
  tableData,
  columnTitle,
  clickedFilter,
  setActiveColumnFilter,
  ActiveColumnFilter,
  selectedOptions,
  setSelectedOptions,
  setTableData,
}) {
  const [ShowFilter, setShowFilter] = useState(false);
  const [Options, setOptions] = useState([]);
  console.log("data123 ", data);
  useEffect(() => {
    if (ShowFilter) {
      setTableData(tableData);
      setSelectedOptions({ by: "", value: [] });
    }
    if (clickedFilter === columnTitle?.name) {
      setShowFilter(!ShowFilter);
    } else {
      setShowFilter(false);
    }
  }, [clickedFilter]);

  const handleClick = () => {
    setShowFilter(!ShowFilter);
  };

  const StructureData = async () => {
    try {
      if (data.length) {
        let ColumnData = [];

        ColumnData = data.map((item) => item[columnTitle?.key]);

        let UniqueSet = new Set(ColumnData);
        ColumnData = Array.from(UniqueSet);

        let StructuredData = ColumnData.map((item) => {
          if (
            item === "null" ||
            item === "undefined" ||
            item === null ||
            item === undefined
          ) {
            return { name: 0 };
          }
          return { name: item };
        });

        setOptions([...StructuredData]);
      }
    } catch (error) {
      setOptions([]);
    }
  };

  useEffect(() => {
    StructureData();
  }, []);

  const MultiselectTemplate = (option) => {
    return (
      <div className="Multiselect-item">
        <div>{option.name}</div>
      </div>
    );
  };

  const panelFooterTemplate = () => {
    return <div className="py-2 px-3"></div>;
  };

  const selectedOptionsTemplate = (option) => {
    if (option) {
      return (
        <div className="Multiselect-item Multiselect-item-value">
          {/* <div>{option.name}</div> */}
          {/* <div>Selected</div> */}

        </div>
      );
    }
    return "Select Options";
  };

  return (
    <span className={classes.ColumnFilterContainer}>
        <i
        onClick={handleClick}
        className={`pi pi-filter ${classes.ToggleFilterButton}`}
      ></i>
      {ShowFilter && (
        <div className={classes.filter}>
          <span className={classes.columnTitle}>
            {columnTitle?.name}
            <i
              onClick={() => setShowFilter(false)}
              className={`pi pi-times ${classes.crossFilter}`}
            ></i>
          </span>
          {/* <span className={classes.FilterTitle}>Sort</span> */}
          <div className={classes.button}>
            <div
              onClick={() =>
                setActiveColumnFilter({ by: columnTitle?.key, type: "asc" })
              }
              className={`${
                ActiveColumnFilter.type === "asc" ? classes.selected : ""
              } d-flex align-items-center justify-content-between`}
            >
              <span  className={classes.sort}>
              <i className="pi pi-arrow-up mr-3"></i>
              Sort Ascending </span>
            { ActiveColumnFilter.type === "asc" &&
            <i className="pi pi-check"></i>}
            </div>
            <div
              onClick={() =>
                setActiveColumnFilter({ by: columnTitle?.key, type: "desc" })
              }
              className={`${
                ActiveColumnFilter.type === "desc" ? classes.selected : ""
              } d-flex align-items-center justify-content-between`}
            >
              <span className={classes.sort}>
              <i className="pi pi-arrow-down mr-3"></i>
              Sort Descending</span>
              {ActiveColumnFilter.type === "desc" &&
              <i className="pi pi-check"></i>
            }
            </div>
          </div>
          {/* <span className={`mt-2 ${classes.FilterTitle}`}>Search</span> */}
          <div className={classes.searchSelect}>
          <MultiSelect
            value={selectedOptions.value}
            options={Options}
            onChange={(e) =>
              setSelectedOptions({ by: columnTitle?.key, value: e.value })
            }
            optionLabel="name"
            placeholder="Select Options"
            filter
            className={classes.searchFilter}
            itemTemplate={MultiselectTemplate}
            // selectedItemTemplate={selectedOptionsTemplate}
            panelFooterTemplate={panelFooterTemplate}
          />
          </div>
        </div>
      )}
    </span>
  );
}
