import styled from "styled-components";
import { Dropdown } from "primereact/dropdown";

export const ContactDropDown = styled(Dropdown)`
  width:${({width})=>(width?width: "100% !important")} ;
  border: 1px solid #ced4da !important;
  &:focus {
    border: 1px solid #ced4da !important;
  }
`;
export const ContactContainer = styled.div`
  margin-top: ${({ margin }) => (margin ? "20px" : "51px")};
  margin-right: ${({ margin }) => (margin ? "0rem" : "1rem")};
  margin-left: ${({ margin }) => (margin ? "0rem" : "1rem")};
  padding: 48px 48px 48px 32px;
  background-color: ${({ bg }) => (bg ? "transparent" : "var(--white)")};
  box-shadow: ${({ bg }) =>
    bg
      ? "none"
      : "0 1.5px 9.9px 0 rgba(11, 61, 126, 0.02), 0 7.3px 38.9px 0 rgba(11, 61, 126, 0.02), 0 19px 122px 0 rgba(7, 62, 132, 0.04)"};
  border-radius: 8px;
  box-sizing: border-box;

  overflow: hidden;

  .profile-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap:wrap-reverse;
    h1 {
      font-family: Inter;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--blackColor);
    }
  }
  .avatar-container {
    display: flex;
    flex-direction: row;
   
    .avatar-text-container {
      display: flex;
      flex-direction: column;
      margin-left: 13px;
      justify-content: center;
      p {
        font-family: "Inter";
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        margin-bottom: 0px;
      }
      .main-text {
        margin-bottom: 2px;
        font-size: 20px;
        color: var(--blackColor);
      }
      .sub-text {
        font-size: 16px;
        color: var(--grey);
      }
    }
  }

  .contact-input-container {
    .contact-input-group-container {
      display: flex;
      flex-direction: row;
      margin-bottom: 30px;
      .contact-input-group {
        display: flex;
        flex-direction: column;
        label {
          font-family: Inter;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--black-main-color);
          margin-bottom: 8px;
        }
        input {
          border-radius: 4px;
          padding: 15px 0px 14px 16.8px;
          width: 336px;
          border: 1px solid #cccccc;
          height: 36px;
          outline: none;
          font-family: Inter;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--black-main-color);

          &:focus {
            outline: none;
          }
        }
        .dropDown-container {
          .Drop-box {
            width: 336px;
            border: 1px solid #cccccc;
            border-radius: 4px;
            outline: none;
            overflow: hidden;
            height: 36px;
            padding-top: 5px;
            box-sizing: border-box;
            .dropDown-field {
              width: 100%;
              border-radius: 0px;
              border: none !important;
              padding: none !important;
              font-family: "Inter" !important;
              outline: none;
              padding-left: 10px;
              padding-top: -10px;
              height: auto !important;
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
      .group1 {
        margin-right: 48px;
      }
    }
  }
`;
