import { useParams } from "react-router-dom";
import { useEffect } from "react";
import UserSidebar from "../../components/SideBar/UserSidebar";
import VendorsTabs from "../../components/VendorsTabs";
import { HomeContainer, OuterContainer } from "../Home/Home.styles";
import Header from "../../components/header/Header";
import { useDispatch, useSelector } from "react-redux";
import { setLockedProperties, ToggleSubSidebar } from "../../redux/actions";

export default function Vendor() {
  let { page } = useParams();
  const dispatch = useDispatch();
  const { open } = useSelector((state) => state.SubSidebar);
  useEffect(() => {
    dispatch(ToggleSubSidebar({ ...open, Vendors: true }));
    return () => {
      dispatch(
        setLockedProperties({ PropertyGenerators: null, tableData: null })
      );
      dispatch(ToggleSubSidebar({ ...open, Vendors: false }));
    };
  }, []); //eslint-disable-line

  return (
    <OuterContainer>
      <UserSidebar headerPath="Clients" />
      <HomeContainer>
        <Header headerPath={`/Clients/${page}`} />
        <VendorsTabs activeTab={page} />
      </HomeContainer>
    </OuterContainer>
  );
}
