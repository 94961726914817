import { GS } from "./global";
import { parseErrors } from "../utils/index";
class APIService {
  // constructor() {}
  public title = "PropVest";
  public autoSavetimeout: any = null;

  getMedianPricesResi() {
    let token = sessionStorage.getItem("Token");
    return fetch(`${process.env.REACT_APP_API_BASEURL}/pricefinder/suburb/median-prices`,{
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((d) => d.data);
  }
  getMedianPricesComm(type:any) {
    let token = sessionStorage.getItem("Token");
    return fetch(`${process.env.REACT_APP_API_BASEURL}/pricefinder/comm/median-prices/${type}`,{
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((d) => d.data);
  }

  async get(url: any = "", params: any = {}) {
    let token = sessionStorage.getItem("Token");
    return fetch(`${process.env.REACT_APP_API_BASEURL}${url}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          parseErrors(response);
        } else {
          return response.json().then((response) => response);
        }
      })
      .catch((err) => {
        parseErrors(err);
      });
  }

  async post(url: any = "", params: any = {}) {
    let token = await sessionStorage.getItem("Token");
    const data = JSON.stringify(params);
    return fetch(`${process.env.REACT_APP_API_BASEURL}${url}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((err) => {
        parseErrors(err);
      });
  }
  async put(url: any = "", params: any = {}) {
    let token = sessionStorage.getItem("Token");
    const data = JSON.stringify(params);
    return fetch(`${process.env.REACT_APP_API_BASEURL}${url}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((err) => {
        parseErrors(err);
      });
  }
  async AutoUpdateUser(){
    let data=JSON.stringify(GS.update_user)
    let token = sessionStorage.getItem("Token");
    clearTimeout(this.autoSavetimeout);
    this.autoSavetimeout = setTimeout(() => {
      return fetch(`${process.env.REACT_APP_API_BASEURL}/contacts/${GS.user_id}`,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: data,
        }
      )
        .then((response) => response.json())
        .then((response) => {
          return response;
        })
        .catch((err) => {
          parseErrors(err);
        });
    }, 2000);
  }
  
  async UpdateVendorContacts(UpdatedData: any = {}, VendorId: any) {
    clearTimeout(this.autoSavetimeout);
    this.autoSavetimeout = setTimeout(() => {
      return this.put(`contacts/${VendorId}`, UpdatedData);
    }, 1000);
  }
  async delete(url: any = "") {
    let token = sessionStorage.getItem("Token");
    return fetch(`${process.env.REACT_APP_API_BASEURL}${url}`, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((err) => {
        parseErrors(err);
      });
  }
 

  async AutoSave(url:any="") {
    if (!GS.isFetchedSOT) return false;
   
    let token = await sessionStorage.getItem("Token");
    delete GS.SOT._id;
    const data = JSON.stringify(GS.SOT);

    clearTimeout(this.autoSavetimeout);
    this.autoSavetimeout = setTimeout(() => {
      return fetch(
        `${process.env.REACT_APP_API_BASEURL}vendor-profile/save/${GS.SOT.VendorId}`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: data,
        }
      )
        .then((response) => response.json())
        .then((response) => {
          return response;
        })
        .catch((err) => {
          parseErrors(err);
        });
    }, 2000);
  }
}

let API = new APIService();

function subscribe(eventName: any, listener: any) {
  document.addEventListener(eventName, listener);
}

function unsubscribe(eventName: any, listener: any) {
  document.removeEventListener(eventName, listener);
}

function publish(eventName: any, data: any) {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
}

export { API, publish, subscribe, unsubscribe };
