import React, { useState, useEffect } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { API } from "../../services/api";
import { OuterContainer, HomeContainer } from "../Home/Home.styles";
import Header from "../../components/header/Header";
import UserSidebar from "../../components/SideBar/UserSidebar";
import Contactlist from "../../components/Contact-List/Contactlist";
import { useDispatch, useSelector } from "react-redux";
import { Vendors } from "../../redux/actions";

const ContactList = () => {
  let dispatch = useDispatch();
  let state = useSelector((state) => state?.Vendors);
  const [VendorsData, setVendorsData] = useState(null);
  const [SearchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    if (!VendorsData || VendorsData.length < 1) {
      const data = state.sort(function (a, b) {
        var textA = a.first_name.toUpperCase();
        var textB = b.first_name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      setVendorsData(data);
    }
  }, [state]);// eslint-disable-line react-hooks/exhaustive-deps
  
  const SpaceRemove = (val) => {
    const replaced = val.replaceAll(" ", "");
    return replaced;
  };
  useEffect(() => {
    if (state.length > 0) {
      let FilteredData = state.filter(
        (item) =>
          item?.full_name.toUpperCase().indexOf(SearchQuery.toUpperCase()) >
            -1 ||
          item?.email.toUpperCase().indexOf(SearchQuery.toUpperCase()) > -1 ||
          SpaceRemove(item?.mobile_no).indexOf(SpaceRemove(SearchQuery)) > -1
      );
      setVendorsData(FilteredData);
    }
  }, [SearchQuery]);// eslint-disable-line react-hooks/exhaustive-deps

  let UpdateData = async () => {
    let user_id = "610a0f1dc61edfcf62d62c52";
    let response = await API.get(`contacts?office_id=${user_id}`);
    // AlphabeticOrder(response?.data ||[])
    const data1 = response?.data.sort(function (a, b) {
      var textA = a.first_name.toUpperCase();
      var textB = b.first_name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    dispatch(Vendors(data1));
    setVendorsData(data1);
  };

  return (
    <OuterContainer>
      <UserSidebar headerPath="Contacts" />
      <HomeContainer>
        <Header headerPath="/Contact" />
        <Contactlist
          SearchQuery={SearchQuery}
          setSearchQuery={setSearchQuery}
          headerPath="/Contact"
          VendorsData={VendorsData}
          UpdateData={UpdateData}
        />
      </HomeContainer>
    </OuterContainer>
  );
};

export default ContactList;
