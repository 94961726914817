import { useState } from "react";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import AnimateHeight from "react-animate-height";

import PlusIcon from "../../assets/images/plus.svg";
import MinusIcon from "../../assets/images/minus.svg";
import classes from "./CollapseCard.module.css";

function CollapseCard({
  children,
  title,
  CustomPlusIcon,
  CustomMinusIcon,
  CardClass = "",
  BodyClass = "",
  IconStyle,
}) {
  const [ToggleCard, setToggleCard] = useState(false);
  return (
    <Card className={`${classes.Body} ${CardClass}`}>
      <Card.Header
        className={classes.header}
        onClick={() => setToggleCard(!ToggleCard)}
      >
        {title}
        <Image
          className={!IconStyle && classes.collapseIcon}
          style={IconStyle}
          src={
            ToggleCard
              ? CustomMinusIcon
                ? CustomMinusIcon
                : MinusIcon
              : CustomPlusIcon
              ? CustomPlusIcon
              : PlusIcon
          }
        />
      </Card.Header>
      <AnimateHeight duration={500} height={ToggleCard ? "auto" : 0}>
        <Card.Body className={BodyClass}>{children}</Card.Body>
      </AnimateHeight>
    </Card>
  );
}

export default CollapseCard;
