import React, { useState, useEffect } from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import "./css.css";
import logo from "../../assets/images/logo1.png";
import sideImage from "../../assets/images/ppm-img.png";
import GoalAnimeAnimation from "../../assets/images/goal-animation-static.png";
import { commaSeparators, CurrencyFormatter } from "../../utils";

const IncomePdf = ({ data, idx }) => {
  const [adminCheck, setadminCheck] = useState(false);
  useEffect(() => {
    let accountType = sessionStorage.getItem("accountType");
    if (accountType === "Contacts") {
      setadminCheck(false);
    } else {
      setadminCheck(true);
    }
  }, []);
  return (
    <div id={`pdfToPrint${idx + 1}`} key={idx}>
      {data && (
        <Row className="firstProperty">
          <div className="header1">
            <div className="pt-5 px-5">
              <img src={logo} alt=""></img>
            </div>
            <div className="portfolioMetrics  px-5">
              <img width="200px" src={GoalAnimeAnimation} alt="goalAnime" />
            </div>
          </div>

          <Col lg={3} md={3} className=" px-4 ">
            <div>
              <h3>Financial</h3>
            </div>
            <ListGroup className="pb-4">
              <ListGroup.Item as="li" active id="active">
                Your Income Details
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="total-text">
                  Your Salary Income(x)<small>(Annual)</small>
                </div>
                <div className="price-text">
                  {commaSeparators(data.BorrowingCalculator.salary_after_tax)}
                </div>
              </ListGroup.Item>
              {data?.BorrowingCalculator?.Checked && (
                <ListGroup.Item>
                  <div className="total-text">
                    Partner Salary Income(x)<small>(Annual)</small>
                  </div>
                  <div className="price-text">
                    {commaSeparators(
                      data.BorrowingCalculator?.partner_salary_after_tax
                    )}
                  </div>
                </ListGroup.Item>
              )}
              <ListGroup.Item>
                <div className="total-text">
                  Other Income(x)<small>(Annual)</small>
                </div>

                <div className="price-text">
                  {commaSeparators(data.BorrowingCalculator.other_income_net)}
                </div>
              </ListGroup.Item>
            </ListGroup>
            <ListGroup className="pb-4">
              <ListGroup.Item as="li" active id="active">
                Your Expense Details
              </ListGroup.Item>

              <ListGroup.Item>
                <div className="total-text"># of Dependants</div>

                <div className="price-text">
                  {data.BorrowingCalculator.dependants}
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="total-text">Total Expenses</div>
                <div className="price-text">
                  {commaSeparators(
                    data?.StrategicSpending?.Data[0]?.monthlyCashflow?.expenses
                      ?.total
                  ) +
                    " " +
                    data.BorrowingCalculator.other_payments_2_term}
                </div>
              </ListGroup.Item>
            </ListGroup>
            <ListGroup className="pb-4">
              <ListGroup.Item as="li" active id="active">
                Loan Details (Estimated)
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="total-text">Interest Rate</div>
                <div className="price-text">
                  {data?.Equity?.PPR?.PPRInterestRate}
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="total-text">Loan Term</div>
                <div className="price-text">
                  {data?.Equity?.PPR?.LoanTerm} Years
                </div>
              </ListGroup.Item>
            </ListGroup>

            <ListGroup className="pb-4">
              <ListGroup.Item as="li" active id="active">
                {adminCheck == true
                  ? "Loan to Value Ratio"
                  : "Usable Equity Details"}
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="total-text">Equity Available</div>
                <div className="price-text">
                  {commaSeparators(data?.Equity?.PPR?.totalEquity)}
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="total-text">Cash Available</div>
                <div className="price-text">
                  {commaSeparators(data?.StrategicSpending?.cashOnHand)}
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="total-text">Estimate LVR</div>
              </ListGroup.Item>
            </ListGroup>
            <ListGroup>
              <ListGroup.Item as="li" active id="active">
                {adminCheck == true
                  ? "Your Results (Estimate Only)"
                  : "Max Borrowing Capacity"}
              </ListGroup.Item>
              <ListGroup.Item>
                <div>You Can Borrow Upto</div>
                <div className="price-text">
                  {commaSeparators(data?.BorrowingCalculator?.can_borrow1)}
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col lg={3} md={3} className="col2 px-4">
            <div>
              <h3>First Property ID {idx + 1}</h3>
            </div>
            <ListGroup className="li1 pb-4">
              <ListGroup.Item as="li" active id="active">
                Purchase Details
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="total-text">Purchase Price</div>
                <div
                  className={`price-text ${
                    idx > 0 && adminCheck == false ? "tcolor" : ""
                  }`}
                >
                  {commaSeparators(
                    data?.PropertyGenerators[idx]?.summaryData.PurchasePrice ||
                      0.0
                  )}
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="total-text">Deposit</div>
                <div className="price-text">
                  {commaSeparators(
                    data?.PropertyGenerators[idx]?.summaryData.Deposit || 0.0
                  )}
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="total-text">Stamp Duty</div>
                <div className="price-text">
                  {commaSeparators(
                    data?.PropertyGenerators[idx]?.summaryData.StampDuty || 0.0
                  )}
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="total-text">Acquisition Cost</div>
                <div className="price-text">
                  {commaSeparators(
                    data?.PropertyGenerators[idx]?.summaryData
                      ?.AcquisitionCost || 0.0
                  )}
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="total-text">Total Deposit Required</div>
                <div className="price-text">
                  {commaSeparators(
                    data?.PropertyGenerators[idx]?.summaryData
                      ?.TotalDepositRequired || 0.0
                  )}
                </div>
              </ListGroup.Item>

              <ListGroup.Item>
                <div className="total-text">Total Purchase Cost</div>
                <div className="price-text">
                  {commaSeparators(
                    data?.PropertyGenerators[idx]?.summaryData
                      ?.TotalPurchaseCosts
                  )}
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="total-text">Applied Interest Rate</div>
                <div className="price-text">
                  {
                    data?.PropertyGenerators[idx]?.summaryData
                      ?.AppliedInterestRate
                  }
                  %
                </div>
              </ListGroup.Item>
            </ListGroup>
            <ListGroup className="li2 pb-4">
              <ListGroup.Item>
                <div className="total-text">Property Rent Income (Year 1)</div>
                <div className="price-text">
                  {commaSeparators(
                    data?.PropertyGenerators[idx]?.summaryData
                      ?.PropertyRentIncomeYear1
                  )}
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="total-text">Total Cost of Lending</div>
                <div className="price-text">
                  {commaSeparators(
                    data?.PropertyGenerators[idx]?.summaryData
                      ?.TotalCostofLending
                  )}
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="total-text">Cashflow (Annual)</div>
                <div className="price-text">
                  {CurrencyFormatter.format(
                    data?.PropertyGenerators[idx]?.summaryData?.CashflowAnnual
                  )}
                </div>
              </ListGroup.Item>

              <ListGroup.Item>
                <div className="total-text">Return on Equity (COC)</div>
                <div className="price-text">
                  {CurrencyFormatter.format(
                    data?.PropertyGenerators[idx]?.summaryData
                      ?.ReturnonEquityCOC
                  )}
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Col>

          <Col
            lg={6}
            md={6}
            style={{ width: "49%" }}
            className="portfolioMetrics pr-5"
          >
            <div>
              <h3>Property Portfolio Metrics</h3>
            </div>
            <ListGroup>
              <ListGroup.Item
                className={!adminCheck ? `client-restrictions` : ""}
              >
                <div
                  className={!adminCheck ? `restrictions-text` : "total-text"}
                >
                  Life Style Goal
                </div>
                {adminCheck && (
                  <div className="total-text">
                    {CurrencyFormatter.format(
                      data?.BorrowingCalculator?.income_goal || 0.0
                    )}
                  </div>
                )}
              </ListGroup.Item>
              <ListGroup.Item
                className={!adminCheck ? `client-restrictions` : ""}
              >
                <div
                  className={!adminCheck ? `restrictions-text` : "total-text"}
                >
                  Portfolio Makeup
                </div>
                
                {adminCheck && (
                  <div className="total-text">
                    {data?.RiskProfile?.initialPropertyType}
                  </div>
                )}</ListGroup.Item>
              <ListGroup.Item
                className={!adminCheck ? `client-restrictions` : ""}
              >
                <div
                  className={!adminCheck ? `restrictions-text` : "total-text"}
                >
                  Risk Profile
                </div>
                {adminCheck && (
                  <div className="total-text">
                    {data?.RiskProfile?.NetYieldonProperty}
                  </div>
                )}
              </ListGroup.Item>
              <ListGroup.Item
                className={!adminCheck ? `client-restrictions` : ""}
              >
                <div
                  className={!adminCheck ? `restrictions-text` : "total-text"}
                >
                  Goal Year
                </div>
                {adminCheck && (
                  <div className="total-text">
                    {data?.BorrowingCalculator?.FinancialYear}
                  </div>
                )}
              </ListGroup.Item>
              <ListGroup.Item
                className={!adminCheck ? `client-restrictions` : ""}
              >
                <div
                  className={!adminCheck ? `restrictions-text` : "total-text"}
                >
                  Current Portfolio Value
                </div>
                {adminCheck && (
                  <div>
                    {CurrencyFormatter.format(
                      data?.BorrowingCalculator?.income_goal || 0.0
                    )}
                  </div>
                )}
              </ListGroup.Item>
              <ListGroup.Item
                className={!adminCheck ? `client-restrictions` : ""}
              >
                <div
                  className={!adminCheck ? `restrictions-text` : "total-text"}
                >
                  New Portfolio Value
                </div>
                {adminCheck && (
                  <div>
                    {CurrencyFormatter.format(
                      data?.BorrowingCalculator?.income_goal || 0.0
                    )}
                  </div>
                )}
              </ListGroup.Item>
            </ListGroup>
            <div className="portfolio-design">
              <img className="coverimg" src={sideImage} alt="" />
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default IncomePdf;
